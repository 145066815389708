import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import {
    Modal as NCModal,
    Button as NCButton,
    Radio as NCRadio,
    HotKeys as NCHotKeys,
    Tooltip as NCTooltip,
    Select as NCSelect,
    Input as NCInput,
    InputNumber as NCInputNumber,
} from '@platform/base';
import { ajax, formDownload, getPlatformLang, toast, getMultiLang } from '@platform/api';
require('./index.less');

const Option = NCSelect.Option || NCSelect.NCOption;
//const OptGroup = NCSelect.OptGroup;
export class PrintOutputModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            json: {},
            show: true,
            data: { //确定输出以后传给后台数据
                printTemplateID: props.printTemplateMenu.length ? props.printTemplateMenu[0]['m_ctemplateid'] : '', //模板id
                outputMode: 'pdf',
                MultiConfig: '1',
                pdfConfig: false,
                htmlConfig: false,
                prefix: '',
                threshold: 0,
                dsPositionPolicy: 4,
                fileVolume: 16000,
                sheetNamePreFix: "新的工作表",
                isDifDocDifFile: false,
                canModifyType: props.canModifyType || false,
            },
        };
    }

    componentWillMount() {
        let { json } = this.props
        if (json) {
            this.setState({ json });
        } else {
            let callback = (json, bool, inlt) => {
                let data = this.state.data;
                data.sheetNamePreFix = json['api-print-0022'] || "新的工作表",

                this.setState({ json, data });
            }
            getPlatformLang({ moduleId: 'api-print', callback })
        }
    }

    close = () => {
        this.setState({ show: false });
        this.closeTimer = setTimeout(() => {
            let element = document.getElementsByClassName('print-output-modal-liuyjv-wrapper')[0];
            if (element) {
                ReactDOM.unmountComponentAtNode(element);
                clearTimeout(this.closeTimer);
                document.getElementById('app').removeChild(element);
                this.closeTimer = null;
            }
        }, 200);
    };
    open = () => {
        this.setState({ show: true });
    };

    // 提交事件
    submit = () => {
        let { data = {} } = this.props;
        const { outputtype = "templateOutput", isCipher = true } = data;
        //新加：license校验成功后，再打印
        ajax({
            method: 'post',
            url: '/nccloud/platform/print/queryPrintAuthorize.do',
            data: {},
            success: res => {
                data = Object.assign({}, this.props.data, this.state.data);

                let printTemplateID = this.state.data.printTemplateID;
                data.printTemplateName = '';
                let printTemplateMenu = this.props.printTemplateMenu;
                if (printTemplateMenu && printTemplateMenu instanceof Array)
                    printTemplateMenu.forEach(item => {
                        if (item.m_ctemplateid == printTemplateID) {
                            data.printTemplateName = item.m_vtemplatename;
                        }
                    })

                let submitData = {
                    url: this.props.url || '/nccloud/reva/revecont/print.do',
                    params: outputtype === "directOutput" ?
                        Object.assign({}, Object.assign({}, data, { outputSetting: JSON.stringify(data), download: "directOutput", outputMode: data.outputMode, output: true }))
                        :  Object.assign({},data, { outputSetting: JSON.stringify(data), outputMode: data.outputMode, output: true }),
                    enctype: 2,
                    isCipher: isCipher,
                };
                formDownload(submitData);
                if (typeof this.props.callback === 'function') {
                    this.props.callback();
                }
                this.close();
            },
            error: () => {
            }
        })

    };

    handlePrintTemplateIDChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { printTemplateID: value }) });
    };
    handleTypeChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { outputMode: value }) });
    };

    handleOtherSheet(type, val) {
        this.setState({ data: Object.assign({}, this.state.data, { [type]: val }) });
    }

    gerMultitaskSetup = () => {
        //  "api-print-0018": "工作表名称","api-print-0019": "行数阈值","api-print-0020": "多任务设置","api-print-0021": "注：设置单个Excel文件的行数，超过此行数自动新建文件"
        const { outputMode, sheetNamePreFix, dsPositionPolicy, fileVolume, isDifDocDifFile, canModifyType = false } = this.state.data;
        const { json = {} } = this.state;
        let multiTask = "";
        const radioStyle = { display: 'block' };
        const dsPositionPolicyList = [
            { display: json["api-print-0013"], value: 4 }, //全部打印任务导出到同一个工作表
            { display: json["api-print-0014"], value: 5 }, //每一个打印任务导出到不同的工作表
            { display: json["api-print-0015"], value: 6 }, //每一个打印任务导出到不同的工作簿
        ];
        const isDifDocDifFileList = [
            { display: json["api-print-0016"], value: false }, //全部打印任务导出到同一个文件
            { display: json["api-print-0017"], value: true }, //每一个打印任务导出到不同的文件
        ];
        if (outputMode === "excel") {
            multiTask = <div>
                <NCRadio.NCRadioGroup
                    name="type"
                    selectedValue={dsPositionPolicy}
                    onChange={this.handleOtherSheet.bind(this, "dsPositionPolicy")}
                >
                    {dsPositionPolicyList.map((item, index) => {
                        return (<NCRadio disabled={!canModifyType} style={radioStyle} key={index.toString()} value={item.value}>
                            {item.display}
                        </NCRadio>)
                    })}
                </NCRadio.NCRadioGroup>
                <div className="form-region">
                    <p className="label">{json["api-print-0018"]}：</p>
                    <div className="in-form">
                        <NCInput value={sheetNamePreFix}
                            onChange={this.handleOtherSheet.bind(this, "sheetNamePreFix")} />
                    </div>
                </div>
                <div className="form-region">
                    <NCTooltip inverse placement="top" overlay={json["api-print-0021"]}>
                        <p className="label">{json["api-print-0019"]}：</p>
                    </NCTooltip>

                    <div className="in-form">
                        <NCInputNumber iconStyle={1} min={1} value={fileVolume}
                            onChange={this.handleOtherSheet.bind(this, "fileVolume")} />
                    </div>
                </div>
            </div>
        } else if (["pdf", "html"].includes(outputMode)) {
            multiTask = <div>
                <NCRadio.NCRadioGroup
                    name="type"
                    selectedValue={isDifDocDifFile}
                    onChange={this.handleOtherSheet.bind(this, "isDifDocDifFile")}
                >
                    {isDifDocDifFileList.map((item, index) => {
                        return (<NCRadio disabled={!canModifyType} style={radioStyle} key={index.toString()} value={item.value}>
                            {item.display}
                        </NCRadio>)
                    })}
                </NCRadio.NCRadioGroup>
            </div>
        }
        return <div className="task-container">
            <h3>{json["api-print-0020"]}</h3>
            {multiTask}
        </div>
    }

    render() {
        let { printTemplateMenu, data = {} } = this.props;
        let { json } = this.state;
        let hideChoice = !printTemplateMenu || printTemplateMenu.length <= 1;
        let { outputtype = "templateOutput" } = data;
        let templateChoice = () => {
            if (hideChoice) {
                return ''
            } else {
                return (
                    <div className="body" style={{ padding: '14px' }}>
                        <span>{json['api-print-0008' || '选择模板']}：</span>{' '}
                        <NCSelect showClear={false}
                            value={this.state.data.printTemplateID}
                            //style={{ width: 200, marginRight: 6 }}
                            onChange={this.handlePrintTemplateIDChange}
                            disabled={printTemplateMenu.length <= 1 ? true : false}
                            fieldid="print-list"
                            className='print-out-select'
                        >
                            {printTemplateMenu.map((item, index) => {
                                return (
                                    <Option
                                        value={item && item.m_ctemplateid}
                                        key={index}
                                        title={item && item.m_vtemplatename}
                                    >
                                        {item && item.m_vtemplatename}
                                    </Option>
                                );
                            })}
                        </NCSelect>
                    </div>
                )
            }
        }

        return (
            <NCModal
                fieldid="output"
                className='print-output-wrapper senior'
                show={this.state.show}
                ref={NCModal => (this.NCModal = NCModal)}
                onHide={this.close}
                zIndex={220} //cardtable最大化之后会被盖住，所以加这个属性
            >

                <NCHotKeys
                    keyMap={{
                        sureBtnHandler:
                            NCHotKeys.USUAL_KEYS.NC_MODAL_CONFIRM,
                        cancelBtnHandler:
                            NCHotKeys.USUAL_KEYS.NC_MODAL_CALCEL,
                    }}
                    handlers={{
                        sureBtnHandler: () => {
                            // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发 by bbqin
                            if (this.NCModal && this.NCModal.isTopModal()) {
                                this.submit();
                            }
                        },
                        cancelBtnHandler: () => {
                            // 取消按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                            if (this.NCModal && this.NCModal.isTopModal()) {
                                this.close();
                            }
                        },
                    }}
                    className="print-template-hotkeys-wrapper"
                    focused={true}
                    attach={document.body}
                    display="inline"
                />

                <NCModal.Header closeButton>
                    <NCModal.Title>{this.props.title || json['api-print-0009'] || '输出到文件'}</NCModal.Title>
                </NCModal.Header>

                <NCModal.Body>
                    <div className="print-output-template-wrapper">
                        <div className="print-choice">
                            <NCRadio.NCRadioGroup
                                name="type"
                                selectedValue={this.state.data.outputMode}
                                onChange={this.handleTypeChange.bind(this)}
                            >
                                <NCRadio key="excel" value="excel">
                                    {json['api-print-0010' || '输出到EXCEL文件']}

                                </NCRadio>

                                <NCRadio key="pdf" value="pdf">
                                    {json['api-print-0011' || '输出到PDF文件']}

                                </NCRadio>

                                <NCRadio key="html" value="html">
                                    {json['api-print-0012' || '输出到HTML文件']}

                                </NCRadio>
                            </NCRadio.NCRadioGroup>
                        </div>
                        {
                            templateChoice()
                        }
                        {this.gerMultitaskSetup()}
                    </div>
                </NCModal.Body>

                <NCModal.Footer>
                    <NCTooltip
                        placement="top"
                        inverse
                        overlay={`${json['api-print-0006']}  (${NCHotKeys.USUAL_KEYS.NC_MODAL_CONFIRM
                            })`}
                        trigger={["hover", "focus"]}
                    >
                        <NCButton onClick={this.submit} colors="primary" fieldid="confirm">
                            {json['api-print-0006' || '确定']}(<span className="text-decoration-underline">Y</span>)
	                    </NCButton>
                    </NCTooltip>
                    <NCTooltip
                        placement="top"
                        inverse
                        overlay={`${json['api-print-0007']}  (${NCHotKeys.USUAL_KEYS.NC_MODAL_CALCEL
                            })`}
                        trigger={["hover", "focus"]}
                    >
                        <NCButton onClick={this.close} colors="info" fieldid="cancel">
                            {json['api-print-0007' || '取消']}(<span className="text-decoration-underline">N</span>)
	                    </NCButton>
                    </NCTooltip>
                </NCModal.Footer>
            </NCModal>
        );
    }
}

export function output(props) {
    //funcode和appcode一样，nodekey？oids？//问董跃龙
    //默认是模板输出，如果outputtype是directOutput，是直接输出
    let { funcode, nodekey, appcode, oids, outputtype = "templateOutput" } = props.data
    //先查模板
    let callback = (json, bool, inlt) => {
        if (outputtype === "directOutput") {
            return ReactDOM.render(<PrintOutputModal
                {...props}
                printTemplateMenu={[]}
                json={json}
            ></PrintOutputModal>, div)
        } else {
            ajax({
                method: 'post',
                url: '/nccloud/platform/print/queryTemplates.do', //加 license 校验
                data: { funcode, nodekey, appcode, oids },
                mode: 'normal',
                success: res => {
                    let data = res.data.data || res.data;
                    if (!data) {
                        let error = res.data.error || res.error;
                        if (error) {
                            if (typeof error === 'object') {
                                let keys = Object.values(error)
                                toast({ color: 'warning', content: keys.join('||') });
                            } else {
                                toast({ color: 'warning', content: String(res) });
                            }

                        }
                        return
                    }

                    //list模板列表，list是数组，元素为object，object为模板标识，
                    let list = res.data.data

                    let isShow = document.getElementsByClassName('print-output-modal-liuyjv-wrapper')[0];
                    //阻止连续多次点击时页面出现多个toast弹框
                    if (isShow) {
                        return false;
                    }
                    let div = document.createElement('div');
                    div.className = 'print-output-modal-liuyjv-wrapper';
                    document.getElementById('app').appendChild(div);

                    return ReactDOM.render(<PrintOutputModal
                        {...props}
                        printTemplateMenu={list}
                        json={json}
                    ></PrintOutputModal>, div)
                },
                error: res => {
                    toast({ color: 'danger', content: res.message });
                },
            })
        }

    }
    getMultiLang({ moduleId: 'api-print', callback })
}

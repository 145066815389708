import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Radio, Button, HotKeys, Tooltip } from '@platform/base';
import { toast, getMultiLang, formDownload, ajax, gzip as Gzip, getGlobalStorage } from '@platform/api';

require('./index.less');

export class PrintTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            appcode: this.props.appcode || '',
            nodekey: this.props.nodekey || '',
            type: this.props.type || '',
            url: this.props.url || '',
            data: this.props.data || '',
            printTemplateMenu: this.props.printTemplateMenu || [],
            printTemplateID: '',
            json: {},
        };
    }

    componentWillMount() {
        let { json } = this.props
        if (json) {
            this.setState({
                printTemplateID: this.props.printTemplateMenu.length ? this.props.printTemplateMenu[0].m_ctemplateid : '',
                json,
            });
        } else {
            let callback = (json, bool, inlt) => {
                this.setState({
                    printTemplateID: this.props.printTemplateMenu.length ? this.props.printTemplateMenu[0].m_ctemplateid : '',
                    json,
                });
            }
            getMultiLang({ moduleId: 'api-print', callback })
        }
    }

    close = () => {
        this.setState({ show: false });
        this.closeTimer = setTimeout(() => {
            let element = document.getElementsByClassName('print-modal-liuyjv-wrapper')[0];
            if (element) {
                ReactDOM.unmountComponentAtNode(element);
                clearTimeout(this.closeTimer);
                document.getElementById('app').removeChild(element);
                this.closeTimer = null;
            }
        }, 200);
    };
    submit = () => {
        if (!this.state.printTemplateID) {
            return toast({ color: 'warning', content: this.state.json['api-print-0004'] || '模板ID参数为空' })
        }
        let data = Object.assign({}, this.state.data, { printTemplateID: this.state.printTemplateID });
        this.props.callback(this.state.type, this.state.url, data, this.props.isCipher);
        this.close();
    };
    handleChange = value => {
        this.setState({ printTemplateID: value });
    };

    render() {
        let { json } = this.state
        return (
            <Modal
                fieldid="print-template"
                show={this.state.show}
                className='print-template-modal-wrapper senior'
                ref={Modal => (this.Modal = Modal)}
                onHide={this.close}
            >
                <HotKeys
                    keyMap={{
                        sureBtnHandler:
                            HotKeys.USUAL_KEYS.MODAL_CONFIRM,
                        cancelBtnHandler:
                            HotKeys.USUAL_KEYS.MODAL_CALCEL,
                    }}
                    handlers={{
                        sureBtnHandler: () => {
                            // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发 by bbqin
                            if (this.Modal && this.Modal.isTopModal()) {
                                this.submit();
                            }
                        },
                        cancelBtnHandler: () => {
                            // 取消按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                            if (this.Modal && this.Modal.isTopModal()) {
                                this.close();
                            }
                        },
                    }}
                    className="print-template-hotkeys-wrapper"
                    focused={true}
                    attach={document.body}
                    display="inline"
                />
                <Modal.Header closeButton>
                    <Modal.Title className={'u-modal-title'}>{this.props.modalTitle || json['api-print-0005'] || '打印模板选择'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body-wrapper">
                        <Radio.RadioGroup
                            name="fruit"
                            selectedValue={this.state.printTemplateID}
                            onChange={this.handleChange.bind(this)}
                        >
                            {this.state.printTemplateMenu.map((item, index) => {
                                return (
                                    <Radio className="nc-radio-wrapper" value={item && item.m_ctemplateid}>
                                        {item && item.m_vtemplatename}
                                    </Radio>
                                );
                            })}
                        </Radio.RadioGroup>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Tooltip
                        placement="top"
                        inverse
                        overlay={`${json['api-print-0006']}  (${HotKeys.USUAL_KEYS.MODAL_CONFIRM
                            })`}
                        trigger={["hover", "focus"]}
                    >
                        <Button onClick={this.submit} colors="primary" fieldid="confirm">
                            {json['api-print-0006' || '确定']}(<span className="text-decoration-underline">Y</span>)
	                    </Button>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        inverse
                        overlay={`${json['api-print-0007']}  (${HotKeys.USUAL_KEYS.MODAL_CALCEL
                            })`}
                        trigger={["hover", "focus"]}
                    >
                        <Button onClick={this.close} colors="info" fieldid="cancel">
                            {json['api-print-0007' || '取消']}(<span className="text-decoration-underline">N</span>)
	                    </Button>
                    </Tooltip>
                </Modal.Footer>
            </Modal>
        );
    }
}

function getUserAgent() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    //判断是否Opera浏览器
    if (userAgent.indexOf("Opera") > -1) {
        return "Opera";
    }
    //判断是否Firefox浏览器
    if (userAgent.indexOf("Firefox") > -1) {
        return "FF";
    }
    //判断是否chorme浏览器
    if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
    }
    //判断是否Safari浏览器
    if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
    }
    //判断是否IE浏览器
    if (
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera
    ) {
        return "IE";
    }
    //判断是否Edge浏览器
    if (userAgent.indexOf("Trident") > -1) {
        return "Edge";
    }
}

function htmlPrint(type, url, data, json1, callback) {
    let resCallback = (json, bool, inlt) => {
        ajax({
            method: "post",
            url: url,
            data: data,
            print: true,
            mode: "normal",
            success: res => {
                if (res.data) {
                    if (callback && typeof callback === "function") {
                        callback();
                    }
                    var newWin = window.open(""); //新开页面
                    // newWin.location = 'http://www.baidu.com';
                    if (newWin) {
                        newWin.document.title = json["api-print-0003"] || "打印预览";
                        // newWin.document.write(res.data); //输入要打印的内容
                        newWin.document.body.innerHTML = res.data; // write 会导致ie下不能打印的问题
                        // 打印后置
                        if (getUserAgent() === "Chrome") {
                            newWin.print();
                            newWin.close();
                        } else {
                            setTimeout(() => {
                                newWin.print();
                                newWin.close();
                            }, 50);
                        }
                    } else {
                        toast({
                            color: "warning",
                            content:
                                json["api-print-0001"] ||
                                "您的浏览器阻止了弹出式窗口,请查看浏览器设置",
                        });
                    }
                }
            },
            error: res => {
                toast({ color: "danger", content: res.message });
            },
        });
    }
    getMultiLang({ moduleId: "api-print", callback: resCallback });
}

function pdfPrint(type, url, data, isCipher = true) {
    formDownload({
        params: data,
        url: url,
        enctype: 2,
        isCipher: isCipher
    });
}

export function print(
    type = "html",
    url,
    data,
    searchTemplate = true,
    isCipher = true,
) {
    let gzip = getGlobalStorage("localStorage", "gzip");
    let gziptools = new Gzip();
    //let callback = (json, bool, inlt) => {
    if (searchTemplate) {
        let { funcode, nodekey, appcode, oids } = data;
        ajax({
            method: "post",
            url: "/nccloud/platform/print/queryTemplates.do",
            data: { funcode, nodekey, appcode, oids },
            //mode: "normal",
            //print: true,
            async: false,
            success: res => {
                let list = res.data.data || res.data;
                let error = res.data.error || res.error;

                //if (gzip) {
                //list = gziptools.unzip(list);
                //}
                if (error) {
                    toast({ color: "danger", content: error });
                    return console.error(error);
                }
                if (Array.isArray(list)) {
                    if (list.length === 1) {
                        if (type === 'html') {
                            htmlPrint(
                                type,
                                url,
                                {
                                    ...data,
                                    printTemplateID: list[0]["m_ctemplateid"]
                                        ? list[0]["m_ctemplateid"]
                                        : null,
                                },
                                {},
                            )
                        } else {
                            pdfPrint(
                                type,
                                url,
                                {
                                    ...data,
                                    printTemplateID: list[0]["m_ctemplateid"]
                                        ? list[0]["m_ctemplateid"]
                                        : null,
                                },
                                isCipher
                            )
                        }
                        // print(
                        // 	type,
                        // 	url,
                        // 	{
                        // 		...data,
                        // 		printTemplateID: list[0]["m_ctemplateid"]
                        // 			? list[0]["m_ctemplateid"]
                        // 			: null
                        // 	},
                        // 	json
                        // );
                    } else {
                        if (list.length < 1) {
                            getMultiLang({
                                moduleId: "api-print",
                                callback: json => {
                                    toast({
                                        color: "warning",
                                        content: json["api-print-0002"] || "未查询到任何模板信息",
                                    });
                                },
                            });
                            return;
                        }
                        let isShow = document.getElementsByClassName(
                            "print-modal-liuyjv-wrapper",
                        )[0];
                        //阻止连续多次点击时页面出现多个toast弹框
                        if (isShow) {
                            return false;
                        }
                        let div = document.createElement("div");
                        div.className = "print-modal-liuyjv-wrapper";
                        document.getElementById("app").appendChild(div);
                        return ReactDOM.render(
                            <PrintTemplate
                                appcode={appcode}
                                nodekey={nodekey}
                                type={type}
                                url={url}
                                data={data}
                                printTemplateMenu={list}
                                //callback={print}
                                callback={type === 'html' ? htmlPrint : pdfPrint}
                                isCipher={isCipher}
                            />,
                            div,
                        );
                    }
                } else {
                    if (list.data.length === 1) {
                        if (type === 'html') {
                            htmlPrint(
                                type,
                                url,
                                {
                                    ...data,
                                    printTemplateID: list.data[0]["m_ctemplateid"]
                                        ? list.data[0]["m_ctemplateid"]
                                        : null,
                                },
                                {},
                            )
                        } else {
                            pdfPrint(
                                type,
                                url,
                                {
                                    ...data,
                                    printTemplateID: list.data[0]["m_ctemplateid"]
                                        ? list.data[0]["m_ctemplateid"]
                                        : null,
                                },
                                isCipher
                            )
                        }
                        // print(
                        // 	type,
                        // 	url,
                        // 	{
                        // 		...data,
                        // 		printTemplateID: list.data[0]["m_ctemplateid"]
                        // 			? list.data[0]["m_ctemplateid"]
                        // 			: null
                        // 	},
                        // 	json
                        // );
                    } else if (list.data.length > 1) {
                        let isShow = document.getElementsByClassName(
                            "print-modal-liuyjv-wrapper",
                        )[0];
                        //阻止连续多次点击时页面出现多个toast弹框
                        if (isShow) {
                            return false;
                        }
                        let div = document.createElement("div");
                        div.className = "print-modal-liuyjv-wrapper";
                        document.getElementById("app").appendChild(div);
                        return ReactDOM.render(
                            <PrintTemplate
                                appcode={appcode}
                                nodekey={nodekey}
                                type={type}
                                url={url}
                                //json={json}
                                data={data}
                                printTemplateMenu={list.data}
                                //callback={print}
                                callback={type === 'html' ? htmlPrint : pdfPrint}
                                isCipher={isCipher}
                            />,
                            div,
                        );
                    } else {
                        getMultiLang({
                            moduleId: "api-print",
                            callback: json => {
                                toast({
                                    color: "warning",
                                    content: json["api-print-0002"] || "未查询到任何模板信息",
                                });
                            },
                        });
                        return;
                        // return toast({
                        // 	color: "warning",
                        // 	content: json["api-print-0002"] || "未查询到任何模板信息"
                        // });
                    }
                }
            },
            error: res => {
                toast({ color: "danger", content: res.message });
            },
        });
    } else {
        if (type == "html") {
            htmlPrint(type, url, data, {});
        } else {
            pdfPrint(type, url, data, isCipher)
        }
    }
    //};

}

export function printer(type = "html", url, data, isCipher = true) {
    let { funcode, nodekey, appcode, oids } = data;
    //let callback = (json, bool, inlt) => {
    ajax({
        method: "post",
        url: "/nccloud/platform/print/queryTemplates.do",
        data: { funcode, nodekey, appcode, oids },
        //mode: "normal",
        async: false,
        success: res => {
            let list = res.data.data || res.data;
            if (list.length === 1) {
                // print(
                //     type,
                //     url,
                //     {
                //         ...data,
                //         printTemplateID: list[0]["m_ctemplateid"]
                //             ? list[0]["m_ctemplateid"]
                //             : null
                //     },
                //     json
                // );
                if (type === 'html') {
                    htmlPrint(
                        type,
                        url,
                        {
                            ...data,
                            printTemplateID: list[0]["m_ctemplateid"]
                                ? list[0]["m_ctemplateid"]
                                : null,
                        },
                        {},
                    )
                } else {
                    pdfPrint(
                        type,
                        url,
                        {
                            ...data,
                            printTemplateID: list[0]["m_ctemplateid"]
                                ? list[0]["m_ctemplateid"]
                                : null,
                        }),
                        isCipher
                }
            } else {
                if (list.length < 1) {
                    getMultiLang({
                        moduleId: "api-print",
                        callback: json => {
                            toast({
                                color: "warning",
                                content: json["api-print-0002"] || "未查询到任何模板信息",
                            });
                        },
                    })
                    return;
                }
                let isShow = document.getElementsByClassName(
                    "print-modal-liuyjv-wrapper",
                )[0];
                //阻止连续多次点击时页面出现多个toast弹框
                if (isShow) {
                    return false;
                }
                let div = document.createElement("div");
                div.className = "print-modal-liuyjv-wrapper";
                document.getElementById("app").appendChild(div);
                return ReactDOM.render(
                    <PrintTemplate
                        appcode={appcode}
                        nodekey={nodekey}
                        type={type}
                        url={url}
                        // json={json}
                        data={data}
                        printTemplateMenu={list}
                        //callback={print}
                        callback={type === 'html' ? htmlPrint : pdfPrint}
                        isCipher={isCipher}
                    />,
                    div,
                );
            }
        },
        error: res => {
            toast({ color: "danger", content: res.message });
        },
    });
    //};
    //getMultiLang({ moduleId: "api-print", callback });
}
// 渲染下拉菜单和更多下拉里面的Menu列表
import React, { Component } from 'react';
import classNames from 'classnames';
import { Menu as Menu, Upload as Upload } from '@platform/base';
import BtnItemTip from './BtnItemTip';

const { Item, Divider: Divider, ItemGroup: ItemGroup } = Menu;

// querySelector  不能識別數字開頭 不能識別特殊字符 ·/·
const replaceSpecialStr = function (str) {
    return str.replace(/\/|\\|,/g, '') || str;
};

function
    strlen(str) {
    var len = 0;
    for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
            len++;
        } else {
            len += 2;
        }
    }
    return len;
}

/**
 * 展示帮助
 */
const SHOW_HELPER = true;

/**
 * 首字母大写  add by bbqin 暂时不考虑多键的情况
 * @param {*} str
 */
function firstToUpperCase(str) {
    // 把加号前后的字符全转译
    return str.toLowerCase().replace(/( |^)[a-z]|\+[a-z]/g, L => L.toUpperCase());
}

// const IS_TEST_ENV = NODE_ENV === 'test';
export const renderMenuItem = function (data, specialDisabled, wrapperVisible) {
    // more 更多操作 下拉下面的禁用要加删除线，通过传specialDisabled 来区分
    data = data.filter(it => {
        return it.visible || !it.hasOwnProperty('visible');
    });

    return data.map((item, index) => {
        if (item.visible !== false) {
            return item.title ? (
                <ItemGroup title={item.title}>
                    {item.children.map(({ key, title, disabled, visible, uploadConfig, keyboard, area, parentCode }) => {
                        return (
                            visible !== false && (
                                <Item
                                    key={key}
                                    fieldid={key}
                                    btn-code={key}
                                    disabled={disabled}
                                    className="dropdown-btn-item"
                                >
                                    <BtnItemTip
                                        overlay={title}
                                        wrapperVisible={wrapperVisible}
                                    >
                                        <div className="dropdown-btn-item-box">
                                            <div
                                                ref={dom => {
                                                    this.itemDom = dom;
                                                }}
                                                btn-code={key}
                                                className={classNames(replaceSpecialStr(` nc-${area}-btn-${parentCode}-${key}  `), 'btn-item-left')}
                                            >
                                                {/* 特殊禁用加删除线 */}
                                                {specialDisabled && disabled ? (
                                                    <span
                                                        className="main-title"
                                                        style={{ cursor: 'default' }}
                                                    >{title}
                                                    </span>
                                                ) : uploadConfig && !disabled ? (
                                                    <Upload showUploadList={false} {...uploadConfig}>
                                                        {title}
                                                    </Upload>
                                                ) : (
                                                    title
                                                )}
                                            </div>
                                            <div
                                                className="btn-item-right"
                                                onClick={e => {
                                                    if (!e.target || (specialDisabled && disabled) || !uploadConfig || disabled) {
                                                        return false;
                                                    }
                                                    let prevUpload = e.target.previousElementSibling.querySelector('input');
                                                    prevUpload && prevUpload.click();
                                                }}
                                            >
                                                {keyboard && SHOW_HELPER ? `${firstToUpperCase(keyboard)}` : ''}
                                            </div>
                                        </div>
                                    </BtnItemTip>
                                </Item>
                            )
                        );
                    })}
                </ItemGroup>
            ) : (
                [
                    item.children.map(({ key, title, disabled, visible, uploadConfig, keyboard, area, parentCode }) => {
                        return (
                            visible !== false && (
                                <Item
                                    key={key}
                                    fieldid={key}
                                    btn-code={key}
                                    disabled={disabled}
                                    className="dropdown-btn-item"
                                // onClick={this.onClick} // bbqin  由于 item上的click事件和当前事件重复触发 去除该事件
                                >
                                    <BtnItemTip
                                        overlay={title}
                                    >
                                        <div className="dropdown-btn-item-box">
                                            <div className="btn-item-left" btn-code={key}>
                                                {specialDisabled && disabled ? (
                                                    <span
                                                        className="main-title"
                                                        style={{ cursor: 'default' }}
                                                    >{title}
                                                    </span>
                                                ) : uploadConfig && !disabled ? (
                                                    !disabled ? (
                                                        <Upload
                                                            showUploadList={false}
                                                            {...uploadConfig}
                                                            className={classNames(replaceSpecialStr(` nc-${area}-btn-${parentCode}-${key}  `))}
                                                        >
                                                            {title}
                                                        </Upload>
                                                    ) : title
                                                ) : (
                                                    title
                                                )
                                                }
                                            </div>
                                            <div
                                                className="btn-item-right"
                                                onClick={e => {
                                                    if (!e.target || (specialDisabled && disabled) || !uploadConfig || disabled) {
                                                        return false;
                                                    }
                                                    let prevUpload = e.target.previousElementSibling.querySelector('input');
                                                    prevUpload && prevUpload.click();
                                                }}
                                            >
                                                {keyboard && SHOW_HELPER ? `${firstToUpperCase(keyboard)}` : ''}
                                            </div>
                                        </div>
                                    </BtnItemTip>
                                </Item>
                            )
                        );
                    }),
                    data[index + 1] && <Divider />,
                ]
            );
        }
    });
};

export const renderMenu = function (data, specialDisabled, tabindex = 0, maxHeight = 500, visible) {
    return (
        <div style={{ overflow: 'auto', maxHeight: maxHeight }}><Menu
            className="button-app-dropdown-menu-wrapper"
            onClick={info => {
                this.onClick(info);
                this.onVisibleChange ? this.onVisibleChange() : null;
            }}
            tabIndex={tabindex}
        >
            {renderMenuItem.call(this, data, specialDisabled, visible)}
        </Menu></div>
    );
};

export class RenderMenu extends Component {
    constructor() {
        super();
        this.state = {
            maxHeight: 500, // 下拉菜单是否显示
        };

    }

    componentDidMount() {
        this.setMaxHeight();
    }

    componentWillReceiveProps() {
        this.setMaxHeight();
    }

    setMaxHeight = () => {
        let clientHeight = document.body.clientHeight;//浏览器可视区域高度
        let buttonApp = document.querySelector('.button-app-wrapper');
        let dropdownWrapper = buttonApp && buttonApp.querySelector('.button-app-dropdown-menu-wrapper');
        let dropdownClient = dropdownWrapper && dropdownWrapper.getBoundingClientRect();
        let top = dropdownClient && dropdownClient.top;
        //console.log("获取距离视口顶层高度", top)
        let maxHeight = top && clientHeight - top - 50;
        if (maxHeight && maxHeight > 0) {
            this.setState({ maxHeight: maxHeight })
        }
    }

    render() {
        let { maxHeight } = this.state;
        let { data, specialDisabled, tabindex = 0, onClick, onVisibleChange, visible } = this.props;
        return (

            <Menu
                className="button-app-dropdown-menu-wrapper"
                style={{ overflow: 'auto', maxHeight: maxHeight }}
                onClick={info => {
                    onClick(info);
                    onVisibleChange ? onVisibleChange() : null;
                }}
                tabIndex={tabindex}
            >
                {renderMenuItem.call(this, data, specialDisabled, visible)}
            </Menu>

        )
    }
}

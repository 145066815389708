/*
 * @Author: wanglongx
 * @Date: 2019-05-10 10:39:14
 * @LastEditors: wanglongx
 * @desc 外层方案修改
 * @LastEditTime: 2020-08-04 13:49:09
 */

import React, { Component } from 'react';
import classnames from 'classnames';
import { Input, HotKeys as NCHotKeys, Tooltip as NCTooltip } from '@platform/base';
// query items 
import { toast } from '@platform/api';

export default class Item extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        value: this.props.value,
        showFlag: true,
    }

    componentWillReceiveProps(nextProps, nextState) {
        const { searchInfo } = nextProps;
        if (searchInfo.showFlag) {
            this.setState({ showFlag: true });
        }
        if ('value' in nextProps) {
            this.setState({ value: nextProps.value });
        }
    }

    handleChange = v => {
        this.setState({ value: v });
    }

    // 确定按钮时 名称重复校验 空校验
    handleConfirm = e => {
        const { searchInfo, multiLang, value, editSearchPlan, beSureBtnClick } = this.props;
        if (e) {
            e.stopPropagation();
        }
        if (this.state.value === value) {
            this.setState({ showFlag: true });
            return;
        } else if (searchInfo.searchPlanCache.find(item => item.name === this.state.value) !== undefined) {
            {
                toast({ content: multiLang['page-search-0083'], color: 'warning' });
            }
            this.setState({ showFlag: false });
            return;
        } else if (this.state.value.replace(/\s+/g, '') === '') {
            {
                toast({ content: multiLang['page-search-0032'], color: 'warning' });
            }
            this.setState({ showFlag: false });
            return;
        }
        this.setState({ showFlag: true });
        editSearchPlan(this.state.value);
        beSureBtnClick();
    }

    render() {

        const { chooseSearchPlan, item, searchAdvBtnClick, delSearchPlan, multiLang, searchInfo, value } = this.props;
        const current = searchInfo.currentPlan || searchInfo.defaultPlan;
        let currentPlan = current.name;
        const currentpk = current.pk_ncc_queryscheme;
        let iconClass = classnames({ 'icon-disable': item.isMyPlan || item.isquickqs });
        let itemClass = classnames({ 'item-current': currentPlan == value });
        return (
            <div className={`query_item ${itemClass}`} >
                {this.state.showFlag ?
                    (
                        <span className="search_planName" onClick={chooseSearchPlan}>
                            {this.state.value}&nbsp;&nbsp;
                            {currentPlan == this.state.value && currentpk == this.props.item.pk_ncc_queryscheme ? <i className="iconfont icon-shenpitongguo" /> : null}
                        </span>
                    ) : (
                        <NCHotKeys
                            keyMap={{
                                high: [
                                    'enter',
                                ],
                            }}
                            handlers={{ high: this.handleConfirm }}
                        >
                            <Input
                                className="search_input"
                                autoFocus={true}
                                value={this.state.value}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                onChange={v => {
                                    this.setState({ value: v });
                                }}
                            />
                        </NCHotKeys>
                    )}
                {this.state.showFlag ? (
                    <span className="search-plan-set">
                        <NCTooltip
                            delayShow={800}
                            inverse
                            delayHide={1}
                            overlay={multiLang['page-search-0092']}
                            placement="top"
                        >
                            <i
                                className={`iconfont icon-cxzhongmingming ${iconClass}`}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (item.isMyPlan || item.isquickqs) return;
                                    this.setState({ showFlag: !this.state.showFlag });
                                }}
                            />
                        </NCTooltip>
                        <NCTooltip
                            delayShow={800}
                            inverse
                            delayHide={1}
                            overlay={multiLang['page-search-0093']}
                            placement="top"
                        >
                            <i
                                className="iconfont icon-cxxiugai"
                                onClick={searchAdvBtnClick}
                            />
                        </NCTooltip>
                        <NCTooltip
                            delayShow={800}
                            delayHide={1}
                            inverse
                            overlay={multiLang['page-search-0094']}
                            placement="top"
                        >
                            <i
                                className={`iconfont icon-cxshanchu ${iconClass}`}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (item.isMyPlan || item.isquickqs) return;
                                    delSearchPlan();
                                }}
                            />
                        </NCTooltip>
                    </span>
                ) : (
                        <div className="search-list-button">
                            <span
                                className="button-item right"
                                onClick={this.handleConfirm}
                            >
                                {multiLang['page-search-0009']}
                            </span>
                            <span
                                className="button-item left"
                                onClick={e => {
                                    e.stopPropagation();
                                    this.setState({
                                        showFlag: true,
                                        value: this.props.value,
                                    });
                                }}
                            >
                                {multiLang['page-search-0010']}
                            </span>
                        </div>
                    )}
            </div>
        );
    }

}
// import { isObj, warningOnce } from '@platform/api';
// import { ViewModel } from '@platform/api'

// 设置显隐性
export function setCardPaginationVisible(cardPaginationBtn, flag) {
    if (this.myCardPagination && this.myCardPagination.state) {
        //if (flag != this.myCardPagination.state.visible) {
        this.myCardPagination.setState({ visible: flag })
        //}
    }
}

//设置当前 id 
// status:2  新增
// status:1  修改
export function setCardPaginationId(idObj = {}) {
    if (this.myCardPagination && this.myCardPagination.state && idObj && idObj.id) {
        if (!idObj.status) {
            idObj.status = 1;
        }
        if (idObj.status == 1) {//修改
            this.myCardPagination.setState({ activeId: idObj.id })
        }
        if (idObj.status == 2) {//新增
            this.myCardPagination.state.allpks.push(idObj.id);
            this.myCardPagination.setState({
                activeId: idObj.id,
                allpks: this.myCardPagination.state.allpks,
            })
        }
        if (idObj.status == 3) {//删除
            let index = this.myCardPagination.state.allpks.indexOf(idObj.id);
            if (index != -1) {
                this.myCardPagination.state.allpks.splice(index, 1);
                this.myCardPagination.setState({ allpks: this.myCardPagination.state.allpks })
            }
        }
    }
}

//获取删除后下一条卡片页面数据的id
export function getNextCardPaginationId(idObj) {
    if (this.myCardPagination && this.myCardPagination.state) {
        if (idObj && idObj.id) {
            let allpks = this.myCardPagination.state.allpks;
            if (allpks.length > 1) {
                let index = allpks.indexOf(idObj.id);
                if (index == allpks.length - 1) { //是最后一条
                    return allpks[allpks.length - 2]
                } else { //不是最后一条
                    return allpks[index + 1]
                }
            }
        }
    }

}

//设置组件allpks
export function setCardPaginationAllpks(allpks, success) {
    if (this.myCardPagination && this.myCardPagination.state && Array.isArray(allpks)) {
        this.myCardPagination.setState({
            allpks: allpks
        },()=>{
            typeof success === 'function' && success();
        })
    }
}

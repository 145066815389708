// import DatePicker from 'bee-datepicker';
import { moment } from '@platform/api';
import {
    Input as FormControl,
    Checkbox as NCCheckbox,
    // NCSwitch,
    NumberInput as NCNumber,
    // NCInput,
    // NCMessage,
    Select,
    // NCSelectBetween,
    // NCRadio,
    // NCBetweenNum,
    // NCRow as Row,
    Col,
    Tooltip as NCTooltip,
    Timepicker as NCTimepicker,
    DatePicker as NCDatePicker,
    RangePickerClient as NCRangePicker,
    DatePickerClient as NCDatePickerClient,
    DateTimePickerBetween as NCDateTimePickerBetween,
    TZDatePickerStart as NCTZDatePickerStart,
    TZDatePickerRangeDay as NCTZDatePickerRangeDay,
    TZDatePickClientHourTime as NCTZDatePickClientHourTime,
    TZDatePickClientTime as NCTZDatePickClientTime,
    TZDatePickerEnd as NCTZDatePickerEnd,
    TZDatePickerStartBetween as NCTZDatePickerStartBetween,
    TZDatePickerEndBetween as NCTZDatePickerEndBetween,
    MultiLangText as NCMultiLangText,
    Refer,
    BetweenNum as NCBetweenNum,
    SelectBetween as NCSelectBetween,
} from '@platform/base';
// import { PubSub } from '@platform/api';
const { ReferLoader } = Refer;
// import toast from '../../api/toast';
import { getSysFieldid, isFunction, isArray, isEmpty, PubSub, formatDatetime, formatNumber, getBusinessInfo, compareTimeFunction } from '@platform/api';
import { getFunctionValueFromViewModal } from './_methods';
// import ReferLoader from '../../containers/Refer/ReferLoader';
// import { localeLang } from '../../public/currentLocale';
// import getSysFieldid from '../../api/getSysFieldid';

require('./createSearch.less');

const Option = Select.Option;

class templateEngine {
    constructor({
        id,
        searchInfo,
        snapshotItem,
        isAdvDom = false,
        onAfterEvent,
        setStateEve,
        renderItem,
        showRequire = true,
        context = {},
        createScript,
        showPlaceholder = true,
        multiLang,
        LangData,
        parentRef,
        clickSearch,
        hiddenFunList
    }) {
        this.templetItem = searchInfo.itemsMap[snapshotItem.attrcode];
        let businessInfo = getBusinessInfo();
        this.Date = businessInfo ? moment(businessInfo.businessDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        // this.Date = '2020-10-20';
        if (!this.templetItem) {
            //toast({ content: '查询方案损坏！', color: 'warning' });

            return null;
        }
        if (searchInfo.status == 'super' || searchInfo.status == 'normal') this.hideLabel = true;
        else this.hideLabel = false;

        Object.assign(this, {
            id,
            searchInfo,
            isAdvDom,
            snapshotItem,
            onAfterEvent,
            setStateEve,
            renderItem,
            showRequire,
            context,
            createScript,
            showPlaceholder,
            multiLang,
            LangData,
            parentRef,
            clickSearch,
            hiddenFunList
        });

        return this.createTemplateItemDom();
    }

    //  事件触发清除警告 通过全局的事件订阅发布 绕开了徐洋之前的逻辑(类组件内部状态处理有问题)
    //  采用全局事件订阅发布的方式 可能存在问题 待处理
    clearRquiredWraning(item) {
        if (item && item.required) {
            PubSub.publish('clearWarning', item.attrcode);
        }
    }

    trustString(data) {
        if (typeof data === 'string') {
            return formatNumber(data, true);
        } else if (Array.isArray(data)) {
            return data.filter(item => item).map(item => formatNumber(item, true)).join('~');
        }
    }

    isTrueEmpty(data) {
        if (isEmpty(data)) return true;
        else if (Array.isArray(data)) {
            return data.filter(item => item).length === 0;
        }
    }

    //获得查询条件的操作符的展示
    getQueryOperateDisplay(queryOperateType) {
        let result = '';
        if (queryOperateType) {
            queryOperateType = queryOperateType.replace(/^@+|@$/g, '');
            let opt = String(queryOperateType).split('@')[0];
            switch (opt) {
                case '<>':
                    result = '≠';
                    break;
                // case 'between':
                //     result = '≠';
                //     break;
                case '<':
                    result = '<';
                    break;
                case '>':
                    result = '>';
                    break;
                case '>=':
                    result = '≥';
                    break;
                case '<=':
                    result = '≤';
                    break;
            }
        }

        return result;
    }

    //getDateDisplay
    getDateDisplay(value) {
        if (isNaN(Date.parse(value))) {
            return value;
        } else {
            const format = 'YYYY-MM-DD';
            return moment(value).format(format);
        }
    }

    timeFunCallBack(data, timeValue) {
        if (data && timeValue) {
            data.initialvalue = {
                display: timeValue.label,
                value: timeValue.value,
            };
        }
        this.setStateEve();
    }

    /* 编辑后事件 */
    onAfterEve(field, val, index, eventType) {
        if (index == undefined) {
            index = 0;
        }
        if (isFunction(this.onAfterEvent)) {
            // code value modleID operationSign betweenIndex
            this.onAfterEvent(field, val, this.id, this.snapshotItem.operationSign, index, eventType);
        }
    }

    //input输入改变,更新state
    onInputChange(item, val) {
        this.clearRquiredWraning(item);
        this.snapshotItem.initialvalue.value = val;
        this.snapshotItem.initialvalue.display = val;//xuyangt 2018/11/20添加
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, val, 0, 'onchange'));
    }

    /* input失去焦点事件 */
    inputBlur(item, type, val) {
        if (type === 'number') this.snapshotItem.initialvalue.value = val;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, val, 0, 'onblur'));
    }

    //select改变，更新state
    onSelectedChange(item, val) {
        this.clearRquiredWraning(item);
        if (val === '__clear__' || val === undefined) {
            // 清空

            this.snapshotItem.initialvalue = {
                value: '',
                display: '',
            };
        } else {
            this.snapshotItem.initialvalue = {
                value: val,
                display: item.options.find(i => i.value == val).display,
            };
        }
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, val === '__clear__' ? undefined : val));
    }

    onSelectedBetweenChange(item, i, val) {
        if (val === undefined) val = '';
        this.clearRquiredWraning(item);
        let data = this.snapshotItem;
        data.initialvalue.display = data.initialvalue.display || [];
        data.initialvalue.value = data.initialvalue.value || [];
        data.initialvalue.display[i] = item.options.find(i => i.value == val).display;
        data.initialvalue.value[i] = val;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, data.initialvalue.value));
    }

    /* select多选 */
    onMultipleChange(item, val = []) {
        this.clearRquiredWraning(item);
        let opt = item.options;
        let valuelist = opt.filter(vv => val.some(v => vv.value == v));
        this.snapshotItem.initialvalue = {
            display: valuelist.map(vv => vv.display).join(','),
            value: valuelist.map(vv => vv.value).join(','),
        };
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, this.snapshotItem.initialvalue.value));
    }

    onDateSelect(data) { }

    //日期选择事件
    onDateChange(item, date, display) {
        this.clearRquiredWraning(item);
        if (typeof date === 'string' || !date) this.snapshotItem.initialvalue.value = date;
        else if (typeof date === 'object') {
            if (Array.isArray(date)) {
                this.snapshotItem.initialvalue.value = date;
            } else {
                this.snapshotItem.initialvalue.value = date.value;
            }
        }
        this.snapshotItem.initialvalue.display = display;

        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, date));
    }

    onDateTimeBetweenChange(item, i, value, display) {
        this.clearRquiredWraning(item);
        let data = this.snapshotItem;
        let oldDisplay = data.initialvalue.display || '';
        data.initialvalue.value = data.initialvalue.value || [];
        //判断是否initialvalue是数组
        if (!Array.isArray(data.initialvalue.value)) {
            data.initialvalue.value = data.initialvalue.value.split(',');
        }
        // 判断display 是否为数组
        if (!Array.isArray(data.initialvalue.display)) {
            data.initialvalue.display = Array.from(2);
            data.initialvalue.value.map((item, index) => {
                if (item) {
                    data.initialvalue.display[index] = oldDisplay.split(',')[index];
                }
            });
        }

        data.initialvalue.display[i] = display;
        data.initialvalue.value[i] = value;
        //在这里判断两个时间的先后顺序，如果顺序不对，就调整一下
        if (data.initialvalue.value.length === 2) {
            let firstval = data.initialvalue.value[0];
            let secondval = data.initialvalue.value[1];
            if (firstval && secondval) {
                if (!compareTimeFunction(data.initialvalue.value[0], data.initialvalue.value[1])) {
                    data.initialvalue.display = [data.initialvalue.display[1], data.initialvalue.display[0]];
                    data.initialvalue.value = [data.initialvalue.value[1], data.initialvalue.value[0]];
                }
            }

        }
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, data.initialvalue));
    }

    //区间类型日期事件
    onRangDateChange(item, date, display) {
        this.clearRquiredWraning(item);
        this.snapshotItem.initialvalue.value = date || '';
        this.snapshotItem.initialvalue.display = (Array.isArray(display) && display.join(',')) || '';
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, this.snapshotItem.initialvalue));
    }

    //参照更改事件
    referChangeEve(item, val, foolValue) {
        this.clearRquiredWraning(item);
        this.snapshotItem.initialvalue = { ...foolValue, ...val };

        if (Array.isArray(val)) {
            val.forEach(vv => {
                let VALUE = getFunctionValueFromViewModal({ display: vv.refname, value: vv.refpk }, this.context);
                vv.refname = VALUE.display;
                vv.refpk = VALUE.value;
                this.snapshotItem.runWithChildren = vv.runWithChildren;
                this.snapshotItem.refurl = vv.queryTreeUrl;
            }, this);
        } else {
            let VALUE = getFunctionValueFromViewModal({ display: val.refname, value: val.refpk }, this.context);
            val.refname = VALUE.display;
            val.refpk = VALUE.value;
        }
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, val));
    }

    betweenReferChangeEve(i, item, value, foolValue) {
        this.clearRquiredWraning(item);
        let data = this.snapshotItem;

        data.initialvalue.display = data.initialvalue.display || [];
        data.initialvalue.value = data.initialvalue.value || [];
        data.initialvalue.display[i] = foolValue.display;
        data.initialvalue.value[i] = foolValue.value;
        data.runWithChildren = value.runWithChildren;
        if (Array.isArray(value)) {
            value.forEach(vv => {
                let VALUE = getFunctionValueFromViewModal({ display: vv.refname, value: vv.refpk }, this.context);
                vv.refname = VALUE.display;
                vv.refpk = VALUE.value;
                this.snapshotItem.runWithChildren = vv.runWithChildren;
                this.snapshotItem.refurl = vv.queryTreeUrl;
            }, this);
        }
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, value, i));
    }

    //单选change事件
    radioChange(item, val) {
        this.clearRquiredWraning(item);
        this.snapshotItem.initialvalue.value = val;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, val));
    }

    // checkbox_switch 事件
    onChangeCheckbox_switch(item, e) {
        this.clearRquiredWraning(item);
        if (item.disabled) return;
        e.stopPropagation();
        let value = !this.snapshotItem.initialvalue.value;
        this.snapshotItem.initialvalue.value = value;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, value));
    }

    //开关事件
    switchChange(item) {
        this.clearRquiredWraning(item);
        let value = !this.snapshotItem.initialvalue.value;
        this.snapshotItem.initialvalue.value = value;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, value));
    }

    // 数字类型事件
    changeNumber(item, data) {
        if (Array.isArray(data)) {
            if (data[0] || data[1]) {
                this.clearRquiredWraning(item);
            }
        } else {
            if (!isEmpty(data)) {
                this.clearRquiredWraning(item);
            }
        }
        this.snapshotItem.initialvalue.value = data;
        this.snapshotItem.initialvalue.display = data;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, this.snapshotItem.initialvalue.value));
    }

    //对最大值和最小值进行合法性校验
    checkNumberHandler(data) {
        let result = true;
        let propList = ['min', 'max'];
        // propList.forEach((item) => {
        // 	if (data.hasOwnProperty(item)) {
        // 		if (typeof data[item] !== 'string' && typeof data[item] !== 'number') {
        // 			result = false;
        // 			console.error(item + '类型错误');
        // 		}
        // 	}
        // });
        if (propList.every(item => data.hasOwnProperty(item))) {
            if (data.max < data.min) {
                result = false;
                console.error('错误：最小值大于最大值');
            }
        }

        return result;
    }

    onMultiLangTextChange(item, data) {
        this.clearRquiredWraning(item);
        let value = '';
        /*  Object.keys(data).forEach(key => {
             if (data[key].index == this.snapshotItem.initialvalue.index) {
                 this.snapshotItem.initialvalue = {
                     value: data[key].value,
                     display: data[key].value,
                     index: data[key].index,
                 };
                 value = data[key].value;
             }
         }); */
        // xiquanquan
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                data[key].index = data[key].index || '1';
                if (data[key].index == this.snapshotItem.initialvalue.index) {
                    this.snapshotItem.initialvalue = {
                        value: data[key].value,
                        display: data[key].value,
                        index: data[key].index,
                    };
                    value = data[key].value;
                    break;
                }
            }
        }
        this.snapshotItem.valueMap = data;
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, value));
    }



    onMultiLangTextSelect(item, value, index) {
        this.clearRquiredWraning(item);
        this.snapshotItem.initialvalue = {
            value,
            index,
            display: value,
        };
        if (!this.snapshotItem.valueMap) {
            this.snapshotItem.valueMap = {};
            this.snapshotItem.valueMap[this.snapshotItem.attrcode + index] = { value };
        }
        this.setStateEve(this.onAfterEve.bind(this, item.attrcode, value));
    }


    getFuntionValue = value => {
        let multiLang = this.multiLang;
        const functionList = {
            '#day(-1)#': multiLang['page-search-0060'],
            '#day(0)#': multiLang['page-search-0061'],
            '#day(1)#': multiLang['page-search-0062'],
            '#month(-1)#': multiLang['page-search-0063'],
            '#month(0)#': multiLang['page-search-0064'],
            '#month(1)#': multiLang['page-search-0065'],
            '#week(-1)#': multiLang['page-search-0066'],
            '#week(0)#': multiLang['page-search-0067'],
            '#week(1)#': multiLang['page-search-0068'],
            '#quarter(-1)#': multiLang['page-search-0069'],
            '#quarter(0)#': multiLang['page-search-0070'],
            '#quarter(1)#': multiLang['page-search-0071'],
            '#year(-1)#': multiLang['page-search-0072'],
            '#year(0)#': multiLang['page-search-0073'],
            '#year(1)#': multiLang['page-search-0074'],
            '#lastDayOfMonth#': multiLang['page-search-0075'],
            '#lastDayOfWeek#': multiLang['page-search-0076'],
            '#finalDayOfLastMonth#': multiLang['page-search-0077'],
        };
        return functionList[value];
    };

    createTemplateItemDom() {
        let { id, snapshotItem, templetItem, renderItem, multiLang, LangData, hiddenFunList, isAdvDom } = this;
        let that = this;
        let data = templetItem;
        const disabledOperationSign = ['is null', 'is not null'];
        if (disabledOperationSign.some(item => item === snapshotItem.operationSign)) {
            var disabled = true;
        } else {
            var disabled = data.disabled;
        }
        let val;
        let initialvalue = snapshotItem.initialvalue;
        let type = data.itemtype || {};
        let hiddenfunlist = hiddenFunList[snapshotItem.attrcode];
        let showTimeFunction = true;
        if (hiddenfunlist === true) {
            showTimeFunction = false;
        } else if (hiddenfunlist === undefined) {
            hiddenfunlist = [];
        }
        if (
            snapshotItem.hasOwnProperty('operationSign') &&
            (type === 'datepicker' || type === 'NCTZDatePickerEnd' || type === 'NCTZDatePickerStart')
        ) {
            if (snapshotItem.operationSign === 'between') {
                type = 'rangepicker';
            }
        } else if (type === 'textarea') {
            type = 'input';
        } else if (type === 'radio' || type === 'checkbox') {
            type = 'select';
        }

        if (type === 'select') {
            //多选 val为 数组['1','4']； 单选val为字符串 ’1‘
            if (data.isMultiSelectedEnabled) {
                val = initialvalue.value ? String(initialvalue.value).split(',') : [];
                initialvalue.display = [];
                if (templetItem.options) {
                    val.forEach(v => {
                        initialvalue.display.push(templetItem.options.find(o => o.value == v)?.display || '');
                    });
                }
                initialvalue.display = initialvalue.display.join(',');
            } else {
                if (snapshotItem.operationSign === 'between') {
                    if (Array.isArray(initialvalue.value)) {
                        val = initialvalue.value;
                    } else {
                        val = [];
                    }
                } else {
                    val = String(initialvalue.value) || '';
                }
            }
        } else if (type === 'checkbox') {
            val = initialvalue.value ? String(initialvalue.value).split(',') : '';
        } else if (type === 'checkbox_switch') {
            val = initialvalue.value || false;
        } else if (type === 'datepicker') {
            typeof initialvalue.value === 'string' ? (val = initialvalue.value) : (val = '');
        } else if (
            type === 'rangepicker' ||
            ((type === 'datetimepicker' || type === 'NCTZDatePickerEnd' || type === 'NCTZDatePickerStart') &&
                snapshotItem.operationSign === 'between')
        ) {
            if (Array.isArray(initialvalue.value)) {
                val = initialvalue.value;
            } else if (initialvalue.value) {
                val = initialvalue.value.split(',');
            } else {
                val = [];
            }
        } else {
            val = (initialvalue || {}).value || (initialvalue || {}).display;
        }
        if ((type === 'datepicker' || type === 'NCTZDatePickerEnd' || type === 'NCTZDatePickerStart') && snapshotItem.operationSign === '=') {
            if (Array.isArray(initialvalue.value)) {
                val = initialvalue.value[0];
            }
        }

        //  必输项标识
        let mustFillIn1 = null;
        let mustFillIn2 = null;
        if (data.required && this.showRequire) {
            mustFillIn1 = <span className="mustFillIn_search1">*</span>;
        }
        let betweenType = ['refer', 'select', 'datetimepicker', 'number'];
        if (data.required && this.showRequire && snapshotItem.operationSign === 'between' && betweenType.includes(type)) {
            mustFillIn2 = <span className="mustFillIn_search2">*</span>;
        }
        let searchChildDom = null;
        // 查询条件的tip值
        let fieldTip = '';
        let timeValue = '';
        let optText = that.getQueryOperateDisplay(data.queryOperateType);
        let label = isAdvDom ? data.advLable || data.label : data.label;
        data.placeholder = this.showPlaceholder ? label + optText : '';
        if (!data.hasOwnProperty('maxlength')) {
            data.maxlength = Infinity;
        }

        switch (type) {
            case 'refer':
                let refData = initialvalue,
                    referDom = null;
                if (renderItem[data.attrcode]) {
                    referDom = renderItem[data.attrcode];
                }
                let refDisplay = '';
                // else if (data.refcode) {
                // 	this.createScript(that.searchInfo.moduletype, id, data.attrcode, data.refcode);
                // }
                if (snapshotItem.hasOwnProperty('operationSign') && snapshotItem.operationSign === 'between') {
                    let VALUE = [];
                    VALUE = (refData.value || []).map((v, index) => {
                        let vv = {
                            value: v,
                            display: refData.display ? refData.display[index] : '',
                        };
                        return getFunctionValueFromViewModal(vv, this.context);
                    });
                    refDisplay = VALUE.map(v => v.display).join('~');
                    let vv = {
                        value: '',
                        display: '',
                    };
                    if (referDom) {
                        referDom = {
                            ...referDom,
                            props: {
                                ...referDom.props,
                                ...data,
                                placeholder: data.placeholder,
                                foolValue: { ...initialvalue, ...(isEmpty(VALUE) ? vv : VALUE[0]) },
                                onChange: that.betweenReferChangeEve.bind(that, '0', data),
                                showTitle: false,
                            },
                        };
                    }
                    searchChildDom = (
                        <div className="between-refer" fieldid={getSysFieldid(data.attrcode)}>
                            <div className="startRefer between-refer-item">
                                {referDom || (
                                    <ReferLoader
                                        {...data}
                                        placeholder={data.placeholder}
                                        foolValue={{ ...initialvalue, ...(isEmpty(VALUE[0]) ? vv : VALUE[0]) }}
                                        onChange={that.betweenReferChangeEve.bind(that, '0', data)}
                                        showTitle={false}
                                    />
                                )}
                            </div>
                            <div className="between-refer-line">-{mustFillIn2}</div>
                            <div className="endRefer between-refer-item">
                                {referDom || (
                                    <ReferLoader
                                        {...data}
                                        placeholder={data.placeholder}
                                        foolValue={{ ...initialvalue, ...(isEmpty(VALUE[1]) ? vv : VALUE[1]) }}
                                        onChange={that.betweenReferChangeEve.bind(that, '1', data)}
                                        showTitle={false}
                                    />
                                )}
                            </div>
                        </div>
                    );
                } else {
                    if (Array.isArray(refData.value)) {
                        refData = {
                            value: refData.value[0],
                            display: refData.display[0],
                        };
                    }
                    let VALUE = getFunctionValueFromViewModal(refData, this.context);
                    refDisplay = VALUE.display;
                    if (referDom) {
                        referDom = {
                            ...referDom,
                            props: {
                                ...referDom.props,
                                ...data,
                                placeholder: data.placeholder,
                                foolValue: { ...initialvalue, ...VALUE },
                                onChange: that.referChangeEve.bind(that, data),
                                showTitle: false,
                            },
                        };
                    }
                    searchChildDom = (
                        <span className="search-dom">
                            {referDom || (
                                <ReferLoader
                                    {...data}
                                    placeholder={data.placeholder}
                                    foolValue={{ ...initialvalue, ...VALUE }}
                                    onChange={that.referChangeEve.bind(that, data)}
                                    showTitle={false}
                                />
                            )}
                        </span>
                    );
                }
                fieldTip = refDisplay && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{refDisplay}</span> : '';

                break;
            case 'input':
                searchChildDom = (
                    <span className="search-dom">
                        <FormControl
                            disabled={!!disabled}
                            value={val}
                            isCheckChinese={!!data.maxlength}
                            maxlength={data.maxlength}
                            onBlur={that.inputBlur.bind(that, data)}
                            placeholder={data.placeholder}
                            onChange={that.onInputChange.bind(that, data)}
                            maxlength={data.maxlength}
                        />
                    </span>
                );
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{val}</span> : '';
                break;
            case 'select':
                if (val === '') {
                    val = undefined;
                }
                if (data.isMultiSelectedEnabled) {
                    //多选暂时这样，UE还没有出，后续再改
                    searchChildDom = (
                        <span className="search-dom">
                            <Select
                                disabled={!!disabled}
                                className="multiple-select"
                                multiple
                                getPopupContainer={() => {
                                    return this.parentRef || document.body;
                                }}
                                style={{ marginRight: 6 }}
                                placeholder={data.placeholder}
                                value={val}
                                onChange={that.onMultipleChange.bind(that, data)}
                                fieldid={data.attrcode}
                                supportSearch={data.supportSearch}
                            >
                                {data.options &&
                                    data.options.map((val, index) => {
                                        return (
                                            <Option key={index} value={String(val.value)}>
                                                {val.display}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </span>
                    );
                } else {
                    if (snapshotItem.hasOwnProperty('operationSign') && snapshotItem.operationSign === 'between') {
                        searchChildDom = (
                            <span className="search-dom">
                                <NCSelectBetween
                                    disabled={!!disabled}
                                    showSearch
                                    style={{ marginRight: 6 }}
                                    placeholder={data.placeholder}
                                    value={val}
                                    mustFill={mustFillIn2}
                                    optionFilterProp="children"
                                    onChange={that.onSelectedBetweenChange.bind(that, data)}
                                    fieldid={data.attrcode}
                                    options={data.options}
                                />
                            </span>
                        );
                    } else {
                        searchChildDom = (
                            <span className="search-dom">
                                <Select
                                    disabled={!!disabled}
                                    showSearch

                                    showClear={!data.required}
                                    getPopupContainer={trigger => {
                                        const node = trigger.parentNode.parentNode;
                                        return node || document.body;
                                    }}
                                    style={{ marginRight: 6 }}
                                    placeholder={data.placeholder}
                                    value={val}
                                    optionFilterProp="children"
                                    onChange={that.onSelectedChange.bind(that, data)}
                                    fieldid={data.attrcode}
                                    supportSearch={data.supportSearch}
                                >
                                    {/* <Option key={'__clear__'} value={'__clear__'} style={{ color: '#00b39e' }}>
										清空
									</Option> */}
                                    {data.options &&
                                        data.options.map((val, index) => {
                                            return (
                                                <Option key={index} value={String(val.value)}>
                                                    {val.display}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </span>
                        );
                    }
                }

                let tips = initialvalue.display;
                if (Array.isArray(data.options)) {
                    if (Array.isArray(val)) {
                        let arrOptionDisplay = [];
                        val.forEach(element => {
                            let opt = data.options.find(item => item.value == element);
                            if (opt) {
                                arrOptionDisplay.push(opt.display);
                            }
                        });
                        tips = arrOptionDisplay.join('~');
                    } else {
                        let option = data.options.find(item => item.value == val);
                        if (option) {
                            tips = option.display;
                        }
                    }
                }
                if (initialvalue && initialvalue.value === 'Y') {
                    tips = multiLang['page-search-0089'];
                } else if (initialvalue && initialvalue.value === 'N') {
                    tips = multiLang['page-search-0090'];
                }
                fieldTip = !this.isTrueEmpty(val) && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{tips}</span> : '';
                break;
            case 'datepicker':
                const format = 'YYYY-MM-DD';

                searchChildDom = (
                    <span className="search-dom">
                        <NCDatePickerClient
                            panelValue={this.Date}
                            arrowKeyDownEnable
                            fieldid={data.attrcode}
                            disabled={!!disabled}
                            //format={format}
                            onSelect={that.onDateSelect}
                            onChange={that.onDateChange.bind(that, data)}
                            // locale={zhCN}
                            value={val}
                            placeholder={data.placeholder}
                            operationSign={snapshotItem.operationSign}
                            //timeFunCallBack = {timeFunCallBack.bind(that,data)}
                            showTimeFunction={showTimeFunction}
                            hiddenFunList={hiddenfunlist}
                            multiLang={multiLang}
                            LangData={LangData}
                            showClear={true}
                        />
                    </span>
                );
                timeValue = this.getFuntionValue(val) || initialvalue.display;
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(timeValue, 'date')}</span> : '';
                break;
            case 'datetimepicker':
                if (snapshotItem.hasOwnProperty('operationSign') && snapshotItem.operationSign === 'between') {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCDateTimePickerBetween
                                panelValue={this.Date}
                                arrowKeyDownEnable
                                fieldid={data.attrcode}
                                disabled={!!disabled}
                                className="search"
                                mustFill={mustFillIn2}
                                //format="YYYY-MM-DD HH:mm:ss"
                                onSelect={that.onDateSelect}
                                onChange={that.onDateTimeBetweenChange.bind(that, data)}
                                // locale={zhCN}
                                value={val}
                                placeholder={data.placeholder}
                                showTimeFunction={showTimeFunction}
                                hiddenFunList={hiddenfunlist}
                                showClear={true}
                            />
                        </span>
                    );
                    if (this.isTrueEmpty(initialvalue.display)) {
                        timeValue = '';
                    } else {
                        let first = this.getFuntionValue(val[0]) || initialvalue.display[0];
                        let second = this.getFuntionValue(val[1]) || initialvalue.display[1];
                        timeValue = Array.isArray(initialvalue.display) ? `${formatDatetime(first, 'datetime') || ''}~${formatDatetime(second, 'datetime') || ''}` : formatDatetime(initialvalue.display, 'datetime');
                    }
                } else {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCTZDatePickClientTime
                                panelValue={this.Date}
                                arrowKeyDownEnable
                                fieldid={data.attrcode}
                                disabled={!!disabled}
                                //format="YYYY-MM-DD HH:mm:ss"
                                onSelect={that.onDateSelect}
                                onChange={that.onDateChange.bind(that, data)}
                                // locale={zhCN}
                                value={val}
                                placeholder={data.placeholder}
                                //timeFunCallBack = {timeFunCallBack.bind(that,data)}
                                showTimeFunction={showTimeFunction}
                                hiddenFunList={hiddenfunlist}
                            />
                        </span>
                    );
                    timeValue = this.getFuntionValue(val) || initialvalue.display;
                    timeValue = formatDatetime(timeValue, 'datetime');
                }
                // console.log('datetimepicker----datetimepicker', timeValue);
                //此处有问题，时间函数类型的日期时间型的 val是 函数值，不能拿来做tips 需要一次准换
                fieldTip = !isEmpty(val) && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{timeValue}</span> : '';
                break;
            case 'timepicker':
                searchChildDom = (
                    <span className="search-dom">
                        <NCTimepicker
                            fieldid={data.attrcode}
                            arrowKeyDownEnable
                            disabled={!!disabled}
                            format="HH:mm:ss"
                            onSelect={that.onDateSelect}
                            onChange={that.onDateChange.bind(that, data)}
                            // locale={zhCN}
                            value={val}
                            placeholder={data.placeholder}
                            //timeFunCallBack = {timeFunCallBack.bind(that,data)}
                            showTimeFunction={showTimeFunction}
                            hiddenFunList={hiddenfunlist}
                            onEnter={this.clickSearch}
                        />
                    </span>
                );
                timeValue = initialvalue.value;
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(timeValue, 'time')}</span> : '';
                break;
            case 'rangepicker':
                searchChildDom = (
                    <span className="search-dom">
                        <NCRangePicker
                            panelValues={this.Date}
                            fieldid={data.attrcode}
                            arrowKeyDownEnable
                            disabled={!!disabled}
                            //format={Rangformat}
                            onSelect={that.onDateSelect}
                            onChange={that.onRangDateChange.bind(that, data)}
                            value={isEmpty(val) ? '' : val}

                            showClear={true}
                            placeholder={data.placeholder || ' '}
                            dateInputPlaceholder={[
                                multiLang['page-search-0039'] + '(YYYY-MM-DD)',
                                multiLang['page-search-0040'] + '(YYYY-MM-DD)',
                            ]}
                            showTimeFunction={showTimeFunction}
                            hiddenFunList={hiddenfunlist}
                            multiLang={multiLang}
                            LangData={LangData}
                            onEnter={this.clickSearch}
                        />
                    </span>
                );
                let displayArray = [];
                if (Array.isArray(initialvalue.display)) {
                    displayArray = initialvalue.display;
                } else if (initialvalue.display) {
                    if (initialvalue.display.includes(',')) {
                        displayArray = (initialvalue.display || '').split(',');
                    } else {
                        displayArray = (initialvalue.display || '').split('~');
                    }
                }
                timeValue = [];
                for (var i = 0; i < val.length; i++) {
                    if (val[i]) {
                        if (val[i].indexOf('#') !== -1) {
                            timeValue.push(this.getFuntionValue(val[i]));
                        } else {
                            timeValue.push(formatDatetime(this.getDateDisplay(displayArray[i]), 'date'));
                        }
                    }
                }
                timeValue = timeValue.join('~');
                fieldTip = !isEmpty(timeValue) && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{timeValue}</span> : '';
                break;
            case 'datePickerNoTimeZone':
                searchChildDom = (
                    <span className="search-dom">
                        <NCDatePicker
                            panelValue={this.Date}
                            fieldid={data.attrcode}
                            arrowKeyDownEnable
                            disabled={!!disabled}
                            //format="YYYY-MM-DD"
                            onSelect={that.onDateSelect}
                            onChange={that.onDateChange.bind(that, data)}
                            // locale={zhCN}
                            value={val}
                            placeholder={data.placeholder}
                            //timeFunCallBack = {timeFunCallBack.bind(that,data)}
                            showTimeFunction={showTimeFunction}
                            hiddenFunList={hiddenfunlist}
                            showTime={false}
                            onEnter={this.clickSearch}
                            showClear={true}
                        />
                    </span>
                );
                // timeValue = moment(val).format(format);
                // timeValue = this.getFuntionValue(val);
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(val, 'date')}</span> : '';
                break;
            case 'NCTZDatePickerStart':
                if (snapshotItem.hasOwnProperty('operationSign') && snapshotItem.operationSign === 'between') {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCTZDatePickerStartBetween
                                panelValue={this.Date}
                                arrowKeyDownEnable
                                fieldid={data.attrcode}
                                disabled={!!disabled}
                                //format="YYYY-MM-DD HH:mm:ss"
                                onSelect={that.onDateSelect}
                                onChange={that.onDateTimeBetweenChange.bind(that, data)}
                                // locale={zhCN}
                                value={val}
                                placeholder={data.placeholder}
                                showTimeFunction={showTimeFunction}
                                hiddenFunList={hiddenfunlist}
                                showTime={false}
                                showToday={false}
                                onEnter={this.clickSearch}
                                showClear={true}
                            />
                        </span>
                    );
                    if (this.isTrueEmpty(initialvalue.display)) {
                        timeValue = '';
                    } else {
                        let first = this.getFuntionValue(val[0]) || initialvalue.display[0];
                        let second = this.getFuntionValue(val[1]) || initialvalue.display[1];
                        timeValue = Array.isArray(initialvalue.display) ? `${formatDatetime(first, 'date') || ''}~${formatDatetime(second, 'date') || ''}` : formatDatetime(initialvalue.display, 'date');
                    }

                } else {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCTZDatePickerStart
                                panelValue={this.Date}
                                arrowKeyDownEnable
                                fieldid={data.attrcode}
                                disabled={!!disabled}
                                //format={data.format || 'YYYY-MM-DD HH:mm:ss'}
                                placeholder={data.placeholder}
                                value={val}
                                onSelect={that.onDateSelect}
                                onChange={that.onDateChange.bind(that, data)}
                                showTimeFunction={showTimeFunction}
                                hiddenFunList={hiddenfunlist}
                                showToday={false}
                                showTime={false}
                                onEnter={this.clickSearch}
                                showClear={true}
                            />
                        </span>
                    );
                    timeValue = initialvalue.display ? initialvalue.display.split(' ')[0] : '';
                }
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(timeValue, 'date')}</span> : '';
                break;
            case 'NCTZDatePickerRangeDay':
                searchChildDom = (
                    <span className="search-dom">
                        <NCTZDatePickerRangeDay
                            panelValue={this.Date}
                            arrowKeyDownEnable
                            fieldid={data.attrcode}
                            disabled={!!disabled}
                            //format={data.format || 'YYYY-MM-DD HH:mm:ss'}
                            placeholder={data.placeholder}
                            value={val}
                            onSelect={that.onDateSelect}
                            onChange={that.onRangDateChange.bind(that, data)}
                            onEnter={this.clickSearch}
                            hiddenFunList={hiddenfunlist}
                        />
                    </span>
                );
                break;
            case 'NCTZDatePickClientHourTime':
                searchChildDom = (
                    <span className="search-dom">
                        <NCTZDatePickClientHourTime
                            panelValue={this.Date}
                            arrowKeyDownEnable
                            fieldid={data.attrcode}
                            disabled={!!disabled}
                            //format={data.format || 'YYYY-MM-DD HH:mm:ss'}
                            placeholder={data.placeholder || ''}
                            value={val}
                            onSelect={that.onDateSelect}
                            onChange={that.onDateChange.bind(that, data)}
                            onEnter={this.clickSearch}
                            hiddenFunList={hiddenfunlist}
                        />
                    </span>
                );
                timeValue = initialvalue.display;
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(timeValue, 'date')}</span> : '';
                break;
            case 'NCTZDatePickClientTime':
                searchChildDom = (
                    <span className="search-dom">
                        <NCTZDatePickClientTime
                            panelValue={this.Date}
                            arrowKeyDownEnable
                            fieldid={data.attrcode}
                            disabled={!!disabled}
                            //format={data.format || 'YYYY-MM-DD HH:mm:ss'}
                            placeholder={data.placeholder || ''}
                            value={val}
                            onSelect={that.onDateSelect}
                            onChange={that.onDateChange.bind(that, data)}
                            onEnter={this.clickSearch}
                            hiddenFunList={hiddenfunlist}
                        />
                    </span>
                );
                timeValue = initialvalue.display;
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(timeValue, 'datetime')}</span> : '';
                break;
            case 'NCTZDatePickerEnd':
                if (snapshotItem.hasOwnProperty('operationSign') && snapshotItem.operationSign === 'between') {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCTZDatePickerEndBetween
                                panelValue={this.Date}
                                arrowKeyDownEnable
                                fieldid={data.attrcode}
                                disabled={!!disabled}
                                //format="YYYY-MM-DD HH:mm:ss"
                                onSelect={that.onDateSelect}
                                onChange={that.onDateTimeBetweenChange.bind(that, data)}
                                // locale={zhCN}
                                value={val}
                                placeholder={data.placeholder}
                                showTimeFunction={showTimeFunction}
                                hiddenFunList={hiddenfunlist}
                                showTime={false}
                                showToday={false}
                                onEnter={this.clickSearch}
                                showClear={true}
                            />
                        </span>
                    );
                    if (this.isTrueEmpty(initialvalue.display)) {
                        timeValue = '';
                    } else {
                        timeValue = Array.isArray(initialvalue.display) ? `${formatDatetime(initialvalue.display[0], 'date') || ''}~${formatDatetime(initialvalue.display[1], 'date') || ''}` : formatDatetime(initialvalue.display, 'date');
                    }
                } else {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCTZDatePickerEnd
                                panelValue={this.Date}
                                arrowKeyDownEnable
                                fieldid={data.attrcode}
                                disabled={!!disabled}
                                //format={data.format || 'YYYY-MM-DD HH:mm:ss'}
                                placeholder={data.placeholder || ''}
                                value={val}
                                onSelect={that.onDateSelect}
                                onChange={that.onDateChange.bind(that, data)}
                                showTimeFunction={showTimeFunction}
                                hiddenFunList={hiddenfunlist}
                                showTime={false}
                                showToday={false}
                                onEnter={this.clickSearch}
                                showClear={true}
                            />
                        </span>
                    );
                    timeValue = initialvalue.display ? initialvalue.display.split(' ')[0] : '';
                }
                fieldTip = val && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{formatDatetime(timeValue, 'date')}</span> : '';
                break;
            case 'radio':
                searchChildDom = (
                    <span className="search-dom">
                        <Select
                            disabled={!!disabled}
                            showSearch
                            style={{ marginRight: 6 }}
                            placeholder={data.placeholder}
                            value={val}
                            optionFilterProp="children"
                            onChange={that.onSelectedChange.bind(that, data)}
                            fieldid={data.attrcode}
                        >
                            {data.options &&
                                data.options.map((val, index) => {
                                    return (
                                        <Option key={index} value={String(val.value)}>
                                            {val.display}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </span>
                );
                // searchChildDom = (
                // 	<span>
                // 		<span className="radioName">{data.label?data.label+':':''}&nbsp;</span>
                // 		<NCRadio.NCRadioGroup
                // 			name={data.label}
                // 			selectedValue={String(val)}
                // 			onChange={radioChange.bind(that, data)}
                // 		>
                // 			{data.options.map((val, index) => {
                // 				return (
                // 					<NCRadio disabled={!!disabled} key={index} value={String(val.value)}>
                // 						{val.display}
                // 					</NCRadio>
                // 				);
                // 			})}
                // 		</NCRadio.NCRadioGroup>
                // 	</span>
                // );

                break;
            case 'number':
                let dataScale = (data.initialvalue || {}).scale;
                let scale = !isEmpty(dataScale) && dataScale != '-1' ? dataScale : data.scale;
                if (snapshotItem.hasOwnProperty('operationSign') && snapshotItem.operationSign === 'between') {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCBetweenNum
                                disabled={!!disabled}
                                onChange={that.changeNumber.bind(that, data)}
                                onBlur={that.inputBlur.bind(that, data, 'number')}
                                scale={Number(scale)}
                                mustFill={mustFillIn2}
                                max={that.checkNumberHandler(data) ? data.max : null}
                                min={that.checkNumberHandler(data) ? data.min : null}
                                maxlength={Number(data.maxlength)}
                                placeholder={data.placeholder}
                                defaultPlaceholder={data.defaultPlaceholder || { start: data.label + multiLang['page-search-0078'], end: data.label + multiLang['page-search-0079'] }}
                                // start={{
                                // 	placeholder: '起始'
                                // }}
                                // end={{
                                // 	placeholder: '结束'
                                // }}
                                value={val || []}
                            />
                        </span>
                    );
                } else {
                    searchChildDom = (
                        <span className="search-dom">
                            <NCNumber
                                disabled={!!disabled}
                                onBlur={that.inputBlur.bind(that, data, 'number')}
                                scale={Number(scale)}
                                value={val}
                                maxlength={Number(data.maxlength)}
                                placeholder={data.placeholder}
                                onChange={that.changeNumber.bind(that, data)}
                                max={that.checkNumberHandler(data) ? data.max : null}
                                min={that.checkNumberHandler(data) ? data.min : null}
                            />
                        </span>
                    );
                }
                fieldTip = !this.isTrueEmpty(val) && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{this.trustString(val)}</span> : '';
                break;
            case 'checkbox':
                if (val === '') {
                    val = undefined;
                }
                /* 初始化checkbox的时候, initialvalue数据格式应该是什么样的 ?先用数组 */
                if (initialvalue.value || initialvalue.value == 0) {
                    data.options.forEach(option => {
                        if (isArray(initialvalue.value)) {
                            if (initialvalue.value.includes(option.value)) {
                                option.checked = true;
                            }
                        }
                    });
                }
                searchChildDom = (
                    <span className="search-dom">
                        <Select
                            disabled={!!disabled}
                            className="multiple-select"
                            multiple
                            style={{ marginRight: 6 }}
                            placeholder={data.placeholder}
                            value={val}
                            onChange={that.onMultipleChange.bind(that, data)}
                            fieldid={data.attrcode}
                        >
                            {data.options.map((val, index) => {
                                return (
                                    <Option key={index} value={String(val.value)} title={val.display}>
                                        {val.display}
                                    </Option>
                                );
                            })}
                        </Select>
                    </span>
                );
                break;
            case 'checkbox_switch':
                searchChildDom = (
                    <span
                        className="condition-item-contant checkbox"
                        style={{ cursor: 'pointer' }}
                        onClick={e => that.onChangeCheckbox_switch.call(that, data, e)}
                    >
                        <span className="switchSpan">{data.label} </span>
                        <NCCheckbox
                            disabled={!!disabled}
                            ref="test"
                            checked={initialvalue.value}
                            type="switch"
                            onClick={e => that.onChangeCheckbox_switch.call(that, data, e)}
                        />
                    </span>
                );
                break;
            case 'switch':
                searchChildDom = (
                    <span
                        className="condition-item-contant switch  search-dom"
                        style={{ cursor: 'pointer' }}
                        onClick={that.switchChange.bind(that, data)}
                    >
                        <span className="switchSpan">{data.label}</span>
                        <NCCheckbox
                            className="checkbox"
                            disabled={!!disabled}
                            checked={val}
                            onClick={that.switchChange.bind(that, data)}
                        />
                    </span>
                );
                break;
            case 'password':
                searchChildDom = (
                    <span className="search-dom">
                        <FormControl
                            disabled={!!disabled}
                            value={val}
                            onBlur={that.inputBlur.bind(that, data)}
                            placeholder={data.placeholder}
                            onChange={that.onInputChange.bind(that, data)}
                            maxlength={data.maxlength}
                            type="password"
                        />
                    </span>
                );
                break;
            case 'residtxt':
                searchChildDom = (
                    <span className="search-dom">
                        {data.languageMeta && (
                            <NCMultiLangText
                                disabled={!!disabled}
                                value={snapshotItem.valueMap || {}}
                                LangIndex={snapshotItem.initialvalue.index || ''}
                                attrcode={data.attrcode}
                                isCheckChinese={!!data.maxlength}
                                maxlength={data.maxlength}
                                languageMeta={data.languageMeta}
                                onBlur={that.inputBlur.bind(that, data)}
                                placeholder={data.placeholder}
                                onSelect={that.onMultiLangTextSelect.bind(that, data)}
                                onChange={that.onMultiLangTextChange.bind(that, data)}
                            />
                        )}
                    </span>
                );
                fieldTip = !isEmpty(val) && data.label ? <span><b style={{ color: 'black', fontWeight: 'bold' }}>{data.label + '：'}</b>{initialvalue.display}</span> : '';
                break;
        }
        let tip = <div className="te-tips" style={{ fontSize: '13px', wordBreak: 'break-all' }}>{fieldTip}</div>;
        // 通过api设置值
        if (initialvalue.value) {
            if (Array.isArray(initialvalue.value)) {
                if (initialvalue.value[0] || initialvalue.value[1]) {
                    this.clearRquiredWraning(data);
                }
            } else {
                this.clearRquiredWraning(data);
            }
        }
        return (
            <div key={data.attrcode} /* onClick={() => console.log('触发单击事件')} onFocus={() => console.log('触发焦点事件')} */>
                {Number(data.leftspace) ? <Col md={Number(data.leftspace)} xs={xs} sm={sm} /> : null}
                {
                    <NCTooltip
                        delayShow={800}
                        inverse
                        className="search"
                        overlay={fieldTip && this.showPlaceholder ? tip : null}
                        placement="top"
                        trigger='hover'
                    //className='search-area-tooltip'
                    // style={!fieldTip && { display: 'none' }}
                    >
                        <div className="condition-contant">
                            {mustFillIn1}
                            {searchChildDom}
                        </div>
                    </NCTooltip>
                }

                {Number(data.rightspace) ? <Col md={Number(data.rightspace)} xs={xs} sm={sm} key={index} /> : null}
            </div>
        );
    }
}

export { templateEngine };

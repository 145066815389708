import { toast, ajax as Ajax, isFunction, isArray, isEmpty, isObj, ViewModel, localeLang, LocalToDongbaTime, DongbaToLocalTime } from '@platform/api';
import { moment } from '@platform/api';
// import { isFunction, isArray, isEmpty, isObj } from '../../public';
// import { localeLang } from '../../public/currentLocale';
// import ViewModel from '../../shell/viewmodel/viewmodel';
import {
    _getAllSearchData,
    setSearchPlanName,
} from './methods';
// import toast from '../../api/toast';
// import { DongbaToLocalTime, LocalToDongbaTime } from '../../public/timer';

let { removeGlobalStorage } = ViewModel;

// =========以下为平台内部使用的方法========================

let OperationOptions = [];
export function setOperationOptionsMultiLang(_multiLang) {
    const multiLang = _multiLang;
    if (isEmpty(OperationOptions)) {
        OperationOptions = [
            {
                display: multiLang['page-search-0047'],
                value: '=',
            },
            {
                display: multiLang['page-search-0047'],
                value: '==',
            },
            {
                display: multiLang['page-search-0048'],
                value: '>',
            },
            {
                display: multiLang['page-search-0049'],
                value: '<',
            },
            {
                display: multiLang['page-search-0050'],
                value: 'between',
            },
            {
                display: multiLang['page-search-0051'],
                value: '>=',
            },
            {
                display: multiLang['page-search-0052'],
                value: '<=',
            },
            {
                display: multiLang['page-search-0053'],
                value: '<>',
            },
            {
                display: multiLang['page-search-0054'],
                value: 'like',
            },
            {
                display: multiLang['page-search-0055'],
                value: 'left like',
            },
            {
                display: multiLang['page-search-0056'],
                value: 'right like',
            },
            {
                display: multiLang['page-search-0057'],
                value: 'is null',
            },
            {
                display: multiLang['page-search-0084'],
                value: 'not like',
            },
            {
                display: multiLang['page-search-0085'],
                value: 'is not null',
            },
        ];
    }
}
//处理自然时间转多时区和起终点 time 是时间 flage：start 当天0点，end当天24点 假就是不处理
export function naturalTimeHandler(time, flag) {
    let format = '';
    switch (flag) {
        case 'start':
            format = 'YYYY-MM-DD HH:mm:ss';
            break;
        case 'end':
            format = 'YYYY-MM-DD HH:mm:ss';
            break;
    }
    const loc = DongbaToLocalTime(moment(time)).format(format);
    const dong8 = LocalToDongbaTime(moment(loc)).format('YYYY-MM-DD HH:mm:ss');
    return dong8;

}
/* 处理运算符 */
export function createOperation(data) {
    data = data || '';
    data = data.replace(/^@+|@$/g, '');
    let o = data.split('@');
    let newArr = [];
    o.forEach(item => {
        for (let i = 0; i < OperationOptions.length; i++) {
            if (item === OperationOptions[i].value) {
                newArr.push(OperationOptions[i]);
                break;
            }
        }
    });
    return newArr;
}
export function _clone(obj) {
    return JSON.parse(JSON.stringify(obj === undefined ? '' : obj));
}

export function getTimezoneOffset() {
    return -1 * new Date().getTimezoneOffset() / 60;
}
//从缓存中获取函数翻译值
export function getFunctionValueFromViewModal(VALUE, context = {}) {
    let reslut = _clone(VALUE);
    let obj = {
        '#mainorg#': {
            // 默认主组织
            display: context.org_Name || '',
            value: context.pk_org || '',
        },
        '#DefaultCostRegion#': {
            // 默认财务成本域
            display: context.defaultCostRegionName || '',
            value: context.defaultCostRegionPk || '',
        },
        '#DefaultCreditCtlRegion#': {
            //默认信用域
            display: context.defaultCreditCtlRegionName || '',
            value: context.defaultCreditCtlRegionPk || '',
        },
        '#accountingbook#': {
            //默认账簿
            display: context.defaultAccbookName || '',
            value: context.defaultAccbookPk || '',
        },
        '#operator#': {
            //当前操作员
            display: context.userName || '',
            value: context.userId || '',
        },
        '#currentgroup#': {
            //当前集团
            display: context.groupName || '',
            value: context.groupId || '',
        },
        '#DefaultLiactCostrgFunction#': {
            //默认利润中心成本域
            display: context.defaultCenterContentName || '',
            value: context.defaultCenterContentPK || '',
        },
        "#DefaultCostrgOrLiactCostrgFunc#": {
            //默认成本域
            display: context.defaultCostRegionName || context.defaultCenterContentName || '',
            value: context.defaultCostRegionPk || context.defaultCenterContentPK || '',
        },
        "#busioperator#": {
            //当前人员
            display: context.defaultPsndocName || '',
            value: context.defaultPsndocPK || '',
        },
        "#rbacdept#": {
            //当前人员所属部门  数组
            display: Array.isArray(context.defaultDeptName) ? context.defaultDeptName.join(',') : '',
            value: Array.isArray(context.defaultDeptPK) ? context.defaultDeptPK.join(',') : '',
        },
    };

    if (Array.isArray(reslut)) {
        reslut.map(item => {
            if (item.value && item.value.indexOf('#') !== -1) {
                item = obj[item.value] || item;
            }
            return item;
        });
    } else {
        if (reslut.value && reslut.value.indexOf('#') !== -1) {
            reslut = obj[reslut.value] || reslut;
        }
    }
    return reslut;
}

// 为提供默认的 条件组的初始值
export function initDefaultSnapshot(items, context, isTemplateOrder) {
    function getValue(item) {
        let initialvalue = {
            display: '',
            value: '',
        };
        if (item.itemtype === 'checkbox_switch') {
            initialvalue.value = false;
        } else if (item.itemtype === 'refer' && item.initialvalue) {
            let refervalue = getFunctionValueFromViewModal(item.initialvalue, context);
            item.initialvalue.display = refervalue.display || item.initialvalue.display;
        } else if (item.itemtype === 'residtxt') {
            let index = item.languageMeta.filter(val => val.languageCode == localeLang)[0]?.index || 1;
            initialvalue.index = index;
            if (item.initialvalue && !item.initialvalue.hasOwnProperty('index')) {
                item.initialvalue.index = index;
            }
        }
        return item.initialvalue && (!isEmpty(item.initialvalue.value) || item.initialvalue.index) ? item.initialvalue : initialvalue;
    }
    let result = [];
    if (isArray(items)) {
        let snapshotList = items.filter(item => (item.visible || item.isfixedcondition) && item.isdrag);
        const requireList = [];
        const fiexedList = [];
        const otherList = [];

        snapshotList.forEach((item, index) => {
            if (!(Number(item.visibleposition) > 0)) {
                item.visibleposition = 99999 + index;
            }
            if (item.isfixedcondition) {
                fiexedList.push(item);
            } else if (item.required) {
                requireList.push(item);
            } else {
                otherList.push(item);
            }
        });
        /**

         * 预置方案排序规则：查询模板中字段的排序分为“默认显示字段排序”、查询区域字段顺序、必填项顺序、固定条件顺序
排序规则：固定条件顺序>必填项顺序>默认显示字段排序中设置的顺序>查询区域字段顺序
举例：1.当一个字段设置了“默认显示字段排序”为10，在查询区域中字段显示在第12个，则在查询模板中此字段显示在第10的位置；
           2.当一个字段设置了“默认显示字段排序”为10，在查询区域中字段显示在第8个，又设置了固定条件，则此字段在查询模板中显示在第1位；
         */
        fiexedList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
        if (isTemplateOrder) {
            const notFixed = requireList.concat(otherList).sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
            snapshotList = fiexedList.concat(notFixed);

        } else {

            requireList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
            otherList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
            snapshotList = fiexedList.concat(requireList).concat(otherList);
        }

        // snapshotList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
        snapshotList.forEach((item, index) => {
            let value = getValue(item);
            let obj = {
                attrcode: item.attrcode,
                isdefault: true,
                initialvalue: value,
                operationSign: item.queryOperateType ? item.queryOperateType.split('@')[0] : '',
                operationOptions: createOperation(item.queryOperateType),
                remove: item.required != true && item.isfixedcondition != true,
                // label 查询区收起时需要 add by wanglongx
                label: item.label,
                isfixedcondition: !!item.isfixedcondition,
                isExtend: !!item.isfixedcondition,
                visible: true,
            };
            if (item.itemtype === 'residtxt' && value.value) {
                obj.valueMap = {};
                obj.valueMap[item.attrcode + value.index] = { value: value.value };
            }
            result.push(obj);
        });
    }
    return result;
}
//方案平铺
export function planExtend(searchInfo, items, context, result = [], level = 0) {
    if (Array.isArray(items)) {
        items.forEach(item => {
            if (item.children) {
                planExtend(searchInfo, item.children, context, result, ++level);
            } else {
                let initialvalue = {
                    display: '',
                    value: '',
                };
                item.initialvalue = item.initialvalue || item.value;
                let tempItem = searchInfo.itemsMap[item.attrcode];
                if (tempItem) {
                    if (tempItem.itemtype === 'checkbox_switch') {
                        initialvalue.value = false;
                    } else if (tempItem.itemtype === 'refer' && item.initialvalue) {
                        let refervalue = getFunctionValueFromViewModal(item.initialvalue, context);
                        item.initialvalue.display = refervalue.display || item.initialvalue.display;
                    } else if (tempItem.itemtype === 'residtxt') {
                        let index = tempItem.languageMeta.filter(val => val.languageCode == localeLang)[0]?.index || 1;
                        initialvalue.index = index;
                        if (item.initialvalue && !item.initialvalue.hasOwnProperty('index')) {
                            item.initialvalue.index = index;
                        }
                    }
                    let obj = {
                        attrcode: item.attrcode,
                        refpk: item.attrcode,
                        initialvalue:
                            item.initialvalue &&
                                (item.initialvalue.value || item.initialvalue.value === false || item.initialvalue.display || item.initialvalue.index)
                                ? item.initialvalue
                                : initialvalue, //此处initialvalue判空可能有问题，调试的时候再改 xuyang
                        operationSign: item.operationSign || (item.queryOperateType && item.queryOperateType.split('@')[0]) || '',
                        remove: item.hasOwnProperty('remove')
                            ? item.remove
                            : tempItem.required != true && tempItem.isfixedcondition != true,
                        visible: true,
                    };
                    if (tempItem.itemtype === 'refer') {
                        obj.runWithChildren = !!item.runWithChildren;
                        obj.refurl = item.refurl || '';
                    } else if (tempItem.itemtype === 'residtxt') {

                        obj.valueMap = {};
                        if (!item.hasOwnProperty('valueMap') && obj.initialvalue.value) {
                            obj.valueMap[item.attrcode + obj.initialvalue.index] = { value: obj.initialvalue.value };
                        } else if (item.hasOwnProperty('valueMap')) {
                            obj.valueMap = item.valueMap;
                        }

                    }
                    if (tempItem.isfixedcondition && level < 2) {
                        obj.visible = false;
                    }
                    result.push(obj);
                }
            }
        });
    }
    return result;
}
// 将查询方案转换成 snapshot
export function plan2snapshotTranslate(searchInfo, items, context, createOperation, preSnapMap = {}, level = 0) {
    if (Array.isArray(items)) {
        items.forEach(item => {
            if (item.children) {
                item.children = plan2snapshotTranslate(searchInfo, item.children, context, createOperation, preSnapMap, ++level);
            } else {
                let initialvalue = {
                    display: '',
                    value: '',
                };
                if (preSnapMap[item.attrcode]) {
                    item.initialvalue = preSnapMap[item.attrcode];
                }
                item.initialvalue = item.initialvalue || item.value;
                let tempItem = searchInfo.itemsMap[item.attrcode];
                if (tempItem) {
                    if (tempItem.itemtype === 'checkbox_switch') {
                        initialvalue.value = false;
                    } else if (tempItem.itemtype === 'refer' && item.initialvalue) {
                        let refervalue = getFunctionValueFromViewModal(item.initialvalue, context);
                        item.initialvalue.display = refervalue.display || item.initialvalue.display;
                    } else if (tempItem.itemtype === 'residtxt') {
                        let index = tempItem.languageMeta.filter(val => val.languageCode == localeLang)[0]?.index || 1;
                        initialvalue.index = index;
                        if (item.initialvalue && !item.initialvalue.hasOwnProperty('index')) {
                            item.initialvalue.index = index;
                        }
                    }

                    item.refpk = item.attrcode;
                    item.initialvalue =
                        item.initialvalue &&
                            (item.initialvalue.value || item.initialvalue.value === false || item.initialvalue.display || item.initialvalue.index)
                            ? item.initialvalue
                            : initialvalue; //此处initialvalue判空可能有问题，调试的时候再改 xuyang
                    item.operationSign = item.operationSign || (tempItem.queryOperateType && tempItem.queryOperateType.split('@')[0]) || '';
                    item.operationOptions = createOperation(tempItem.queryOperateType);
                    item.remove = item.hasOwnProperty('remove')
                        ? item.remove
                        : tempItem.required != true && tempItem.isfixedcondition != true;
                    if (tempItem.itemtype === 'residtxt' && !item.hasOwnProperty('valueMap') && item.initialvalue.value) {
                        item.valueMap = {};
                        item.valueMap[item.attrcode + item.initialvalue.index] = { value: item.initialvalue.value };
                    }
                    item.visible = item.visible === false ? false : true;
                    //需要注释掉
                    // item.visible = !(!item.isfixedcondition && tempItem.isfixedcondition && level < 2);
                } else {
                    item.visible = false;
                }
            }
        });
        return items.filter(item => item.visible !== false);
    }
    return [];
}
//根据快照 过滤模板
export function templateFilter(itemsMap, snapshot) {
    let result = [];
    if (isArray(snapshot)) {
        snapshot.forEach(item => {
            if (itemsMap.hasOwnProperty(item.attrcode)) {
                result.push(itemsMap[item.attrcode]);
            }
        });
    }
    return result;
}
// 根据模板重新修改 snapshot
export function snapshotChangeByTemplate(snapshot, items, context) {
    let newSnapshot = initDefaultSnapshot(items, context);
    newSnapshot.forEach(newItem => {
        snapshot.forEach(oldItem => {
            if (newItem.attrcode === oldItem.attrcode) {
                //此处判断有问题，如果是多个相同的条件的时候 会出现赋值混乱。
                newItem.initialvalue = oldItem.initialvalue;
                newItem.operationSign = oldItem.operationSign;
            }
        });
    });
    return newSnapshot;
}
// 获得有数据的snapshot
export function snapshotFilter(snapshot, itemsMap) {
    if (Array.isArray(snapshot)) {
        return snapshot.filter(item => {
            return !isEmpty(item.initialvalue.value);
        });
    } else {
        return [];
    }
}
//查询条件所有值都添加了
export function allItemsFillComplete(snapshot, searchInfo, context) {
    let result = true;
    if (snapshot[0] && snapshot[0].attrcode == 'root') {
        snapshot = planExtend(searchInfo, snapshot, context);
    }
    snapshot.forEach(item => {
        // 必填项无值 不执行查询 其他情况执行查询
        if (searchInfo.itemsMap[item.attrcode]['itemtype'] === 'refer') item.initialvalue = getFunctionValueFromViewModal(item.initialvalue, context);
        if (searchInfo.itemsMap[item.attrcode]['required'] && isEmpty(item.initialvalue.value)) {
            result = false;
        }
    });
    return result;
}
//树结构转数组
export function tree2Array(data, reuslt = []) {
    if (Array.isArray(data)) {
        data.forEach(item => {
            if (item) {
                if (item.children) {
                    tree2Array(item.children, reuslt);
                } else {
                    reuslt.push(item);
                }
            }
        });
    }
    return reuslt;
}
//时间比较函数
export function CompareDate(d1, d2) {
    // console.log(d1, d2);
    return new Date(d1.replace(/-/g, '/')) > new Date(d2.replace(/-/g, '/'));
}
export function isDateFunction(value) {
    value = String(value);
    return value.indexOf('#') !== -1;
}

function addMark(data) {
    data.map(item => {
        if (
            item.initialvalue &&
            (item.initialvalue.value === '' ||
                item.initialvalue.value === null ||
                (isArray(item.initialvalue.value) && !item.initialvalue.value.length) ||
                (isArray(item.initialvalue.value) && item.initialvalue.value[0] === null))
        ) {
            item.isEmpty = true;
        } else {
            item.isEmpty = false;
        }
        if (Array.isArray(item.children)) {
            addMark(item.children);
        }
    });
}

/**
 * @description: 后台交互AJAX
 * @param {flag} String 交互类型 (edit编辑方案名称, save保存方案, add新增方案)
 * @param {type} Boolean 是否外层调用  
 * @param {currentPlanName} String 外层使用时传入的方案名称  
 * @return: 
 */
export function beSureBtnClick(flag, type, currentPlanName) {
    /* 此处ajax */
    // enter 键触发时  存在参数错误的情况 type = enter 

    currentPlanName = currentPlanName ? (typeof currentPlanName == 'string' ? currentPlanName : undefined) : undefined;

    if (typeof type == 'boolean') {
        this.props = this;
        this.multiLang = this.state.json;
    } else {
        if (flag == 'save' && this.state.currentPlan.name === this.multiLang['page-search-0018']) {
            {
                toast({ content: this.multiLang['page-search-0016'], color: 'warning' });
            }
            return;
        } else if (this.state.searchPlanName.replace(/\s+/g, '') === '') {
            {
                toast({ content: this.multiLang['page-search-0032'], color: 'warning' });
            }
            // 模态框不隐藏
            if (flag === 'add') {
                this.props.modal.show('addSearchPlan@' + this.props.moduleId);
            } else if (flag === 'edit') {
                this.props.modal.show('editSearchPlanName@' + this.props.moduleId);
            }
            return;
        } else if (flag !== 'save') {
            if (!isEmpty(this.state.searchPlan)) {
                if (flag === 'edit' && this.state.currentPlan.name === this.state.searchPlanName) {
                    this.props.modal.close('editSearchPlanName@' + this.props.moduleId);
                    return;
                }
                let currentPlan = this.state.searchPlan.find(item => item.name === this.state.searchPlanName);
                if (currentPlan) {
                    {
                        toast({ content: this.multiLang['page-search-0083'], color: 'warning' });
                    }
                    // 模态框不隐藏
                    if (flag === 'add') {
                        this.props.modal.show('addSearchPlan@' + this.props.moduleId);
                    } else if (flag === 'edit') {
                        this.props.modal.show('editSearchPlanName@' + this.props.moduleId);
                    }
                    return;
                }
            }
        }
    }

    let that = this;
    let data = {
        public: null, // 公共区域查询条件
        nonpublic: null, // 非公共区域查询条件
    };
    let state = null;

    /*
    *    如果业务组自定义查询条件，保存方案时，需要业务组返回其自定义的查询条件
    *    状态：
    *    1、业务组只添加部分自定义查询条件，此时分两部分：公共查询区、自定义查询区
    *    2、业务组替换整个右侧body区，只有自定义查询区
    *    3、只有公共查询区
    * */

    // 获取公共查询区域查询条件及state
    let runThis = () => {
        let searchInfo = this.state.searchInfo;
        data.public = _getAllSearchData.call(this, this.props.moduleId, this.state.searchInfo, false);
        if (searchInfo.status == 'super') {
            let child = searchInfo.snapshotMap.super[0].children.filter(item => item.isExtend !== true);
            state = [{ ...searchInfo.snapshotMap.super[0], children: child }];
        } else {
            state = searchInfo.snapshotMap[searchInfo.status].filter(item => item.isExtend !== true); //这块有问题xuyangt
        }

        addMark(state);
    };

    if (this.state.userControlAll) {
        // 业务组替换整个查询区
        data.nonpublic = this.props.saveSearchPlan();
    } else {
        data.nonpublic = typeof this.props.saveSearchPlan === 'function' ? this.props.saveSearchPlan() : null;
        runThis();
    }

    let pk = this.state.currentPlan ? this.state.currentPlan.pk_ncc_queryscheme : null;
    if (typeof type == 'boolean') pk = this.state.searchInfo.searchPlanCache.find(item => item.name == currentPlanName).pk_ncc_queryscheme;
    if (!this.props.hasOwnProperty('oid')) {
        // console.error(this.multiLang['page-search-0041']);
    }
    let saveSearchPlanData = {
        name: this.state.searchPlanName,
        conditionobj4web: data, //查询条件
        renderobj4web: state, // 查询条件前台数据结构，用于渲染查询区
        areaid: this.props.oid || this.props.meta.oid, //这块是一个暂时的处理，因为有些场景this.props.oid ==undefined 原因还没查出来
        isprepared: false, //预制方案
        isquickqs: false, //默认方案 ,只能修改，不能删除
    };
    if (pk) {
        saveSearchPlanData.pk_ncc_queryscheme = pk;
    }
    if (this.state.currentPlan) {
        saveSearchPlanData.isprepared = this.state.currentPlan.isprepared;
        saveSearchPlanData.isquickqs = this.state.currentPlan.isquickqs;
    }

    // editAjax 修改方案名称

    let editAjax = () => {

        let newdate = this.state.searchInfo.searchPlanCache.find(item => (currentPlanName || this.state.currentPlan.name) == item.name);
        saveSearchPlanData.renderobj4web = newdate.renderobj4web;
        saveSearchPlanData.name = this.state.searchPlanName;
        saveAjax();
    };
    // saveAjax 修改方案保存
    let saveAjax = () => {
        Ajax({
            url: '/nccloud/riart/queryscheme/updateQS.do',
            data: saveSearchPlanData,
            success: res => {
                if (res.success) {
                    {
                        toast({ content: this.multiLang['page-search-0033'], color: 'success' });
                    }
                    // 修改成功后，更新state数据
                    if (typeof type == 'boolean') {
                        // 外部修改 
                        let i = that.state.searchInfo.searchPlanCache.findIndex(
                            e => e.pk_ncc_queryscheme === saveSearchPlanData.pk_ncc_queryscheme,
                        );
                        if (i !== -1) {
                            saveSearchPlanData.pk_ncc_queryscheme = res.data;
                            that.state.searchInfo.searchPlanCache[i] = _clone({
                                ...that.state.searchInfo.searchPlanCache[i],
                                ...saveSearchPlanData,
                            });
                            // that.state.searchInfo.currentPlan = saveSearchPlanData;
                            // setSearchPlanName.call(that._this._this, that.id, saveSearchPlanData.name); 修复部分节点 不存在快速方案 只有预制方案
                            if (currentPlanName == (that.state.searchInfo.currentPlan && that.state.searchInfo.currentPlan.name)) {
                                that.state.searchInfo.currentPlan = saveSearchPlanData;
                            }
                            that.setState({ searchInfo: that.state.searchInfo });
                            let keySessionS = this._this.myState.search.keySessionS;
                            if (!isEmpty(keySessionS)) {
                                keySessionS.forEach(item => removeGlobalStorage('localStorage', item));

                            }

                        }
                        return;
                    }
                    // enter 键 关闭modal 
                    if (typeof type == 'string' && type == 'enter') {
                        this.props.modal.close('editSearchPlanName@' + this.props.moduleId);
                    }
                    // 内部修改
                    let i = that.state.searchPlan.findIndex(
                        e => e.pk_ncc_queryscheme === saveSearchPlanData.pk_ncc_queryscheme,
                    );
                    if (i !== -1) {
                        saveSearchPlanData.pk_ncc_queryscheme = res.data;
                        that.state.searchPlan[i] = _clone({
                            ...that.state.searchPlan[i],
                            ...saveSearchPlanData,
                        });
                        that.props.searchPlanCacheChange(that.state.searchPlan);
                    }
                    this.state.currentPlan = saveSearchPlanData;
                    // 更新查询方案名称
                    if (!this.state.userControlAll) {
                        setSearchPlanName.call(that.props._this._this, that.props.id, saveSearchPlanData.name);

                        // saveSearchPlanData.isquickqs &&
                        // 	this.props.afterEditSearchPlan(saveSearchPlanData.renderobj4web);
                    }
                    // 当外层方案不是预置方案时 更新界面方案 当前保存的方案是 界面上展示的方案
                    if (that.state.searchInfo.currentPlan != undefined) {
                        if (this.state.currentPlan.pk_ncc_queryscheme == that.state.searchInfo.currentPlan.pk_ncc_queryscheme) {
                            let a = _clone(this.state.currentPlan);
                            that.props.upDateSearchPlan(a);
                        }
                    }
                    let keySessionS = this.props._this._this.myState.search.keySessionS;
                    if (!isEmpty(keySessionS)) {
                        keySessionS.forEach(item => removeGlobalStorage('localStorage', item));

                    }
                }
            },
        });
    };

    let addAjax = () => {
        /* 避免新增方案时有pk */
        if (saveSearchPlanData.hasOwnProperty('pk_ncc_queryscheme')) {
            delete saveSearchPlanData.pk_ncc_queryscheme;
        }
        saveSearchPlanData.isquickqs = false;
        saveSearchPlanData.isprepared = false;
        saveSearchPlanData.sequenc =
            ((Array.isArray(this.props.searchPlanCache) && this.props.searchPlanCache.length) || 0) + 1;
        Ajax({
            url: '/nccloud/riart/queryscheme/addQS.do',
            data: saveSearchPlanData,
            success: res => {
                if (res.success) {
                    {

                        toast({ content: this.multiLang['page-search-0034'], color: 'success' });
                    }
                    // enter 键 关闭modal 
                    if (typeof type == 'string' && type == 'enter') {
                        this.props.modal.close('addSearchPlan@' + this.props.moduleId);
                    }
                    saveSearchPlanData.pk_ncc_queryscheme = res.data;
                    this.state.searchPlan.push(_clone(saveSearchPlanData));
                    this.props.searchPlanCacheChange(this.state.searchPlan);
                    this.state.currentPlan = saveSearchPlanData;
                    this.setState({ searchPlan: this.state.searchPlan, currentPlan: this.state.currentPlan });
                    if (!this.state.userControlAll) {
                        setSearchPlanName.call(that.props._this._this, that.props.id, saveSearchPlanData.name);
                    }
                    let keySessionS = this.props._this._this.myState.search.keySessionS;
                    if (!isEmpty(keySessionS)) {
                        keySessionS.forEach(item => removeGlobalStorage('localStorage', item));

                    }
                }
            },
        });
    };

    if (flag === 'edit') {
        // 修改时，先校验方案名称是否重复 ，重复时，提示。不重复，覆盖 old 
        // 修改方案名称 取state 之前的数据 只修改 方案名称
        editAjax();
    } else if (flag === 'save') {
        // 新增时，校验方案名称是否重复 ，重复时，提示。不重复，新增 old 
        // 直接保存 
        let currentPlan = this.state.searchPlan.find(item => item.name === this.state.searchPlanName);
        saveSearchPlanData.pk_ncc_queryscheme = currentPlan.pk_ncc_queryscheme;
        saveSearchPlanData.isdefault = currentPlan.isdefault;
        saveSearchPlanData.isquickqs = currentPlan.isquickqs;
        saveSearchPlanData.isprepared = currentPlan.isprepared;
        saveSearchPlanData.isdelete = currentPlan.isdelete;
        saveAjax();
        // checkRepeat();
    } else if (flag === 'add') {
        // 新增 方案 
        addAjax();
    }
}

/* 删除查询方案确定按钮 */
export function beSureBtnClick_Del(type, pk_ncc_queryscheme, callback) {
    let pk = null;
    let that = this;
    if (typeof type == 'boolean') {
        this.props = this;
        this.multiLang = this.state.json;
        pk = pk_ncc_queryscheme;
    } else {
        pk = this.state.currentPlan.pk_ncc_queryscheme;
    }
    Ajax({
        url: '/nccloud/riart/queryscheme/deleteQS.do',
        data: { pk_ncc_queryscheme: pk },
        success: res => {
            if (res.success) {
                {

                    toast({ content: this.multiLang['page-search-0038'], color: 'success' });
                }
                // 外部修改
                if (type) {
                    // let currentMap = this.state.searchInfo.searchPlanCache ;
                    // let currentPlan = currentMap.find(item => item.pk_ncc_queryscheme == pk_ncc_queryscheme);
                    // let i = currentMap.length;
                    // while (i--) {
                    // 	let item = currentMap[i];
                    // 	if (item.pk_ncc_queryscheme === currentPlan.pk_ncc_queryscheme) {
                    // 		currentMap.splice(i, 1);
                    // 		this.props.searchPlanCacheChange(currentMap);
                    // 		break;
                    // 	}
                    // }
                    this.state.searchInfo.searchPlanCache = this.state.searchInfo.searchPlanCache.filter(item => item.pk_ncc_queryscheme != pk_ncc_queryscheme);
                    // 删除时当前方案时  当前置为预制方案
                    this.meta.queryschemes = this.state.searchInfo.searchPlanCache.filter(item => item.pk_ncc_queryscheme != '001');
                    if ((this.state.searchInfo.currentPlan && this.state.searchInfo.currentPlan.pk_ncc_queryscheme) == pk_ncc_queryscheme) {
                        let quickqs = this.state.searchInfo.searchPlanCache.find(item => item.isquickqs);
                        if (quickqs) {
                            this.state.chooseSearchPlan(quickqs);
                        } else {
                            this.state.chooseSearchPlan(this.state.searchInfo.defaultPlan);
                        }
                    }
                    let keySessionS = this._this.myState.search.keySessionS;
                    if (!isEmpty(keySessionS)) {
                        keySessionS.forEach(item => removeGlobalStorage('localStorage', item));

                    }
                    this.setState({ searchInfo: this.state.searchInfo });
                    callback && callback();
                    return;
                }

                let i = this.state.searchPlan.length;
                while (i--) {
                    let item = this.state.searchPlan[i];
                    if (item.pk_ncc_queryscheme === this.state.currentPlan.pk_ncc_queryscheme) {
                        this.state.searchPlan.splice(i, 1);
                        this.props.searchPlanCacheChange(this.state.searchPlan);
                        break;
                    }
                }
                let quickqs = this.state.searchInfo.searchPlanCache.find(item => item.isquickqs);
                if (this.state.currentPlan.pk_ncc_queryscheme === this.state.currentPlan.pk_ncc_queryscheme) {
                    if (quickqs) {
                        this.clickSearchPlan(quickqs);
                    } else {
                        this.clickSearchPlan(this.state.searchInfo.defaultPlan);
                    }
                }
                // 删除外部方案
                if (this.state.currentPlan.pk_ncc_queryscheme == (this.state.searchInfo.currentPlan && this.state.searchInfo.currentPlan.pk_ncc_queryscheme)) {

                    if (quickqs) {
                        that.props.upDateSearchPlan(quickqs);
                    } else {
                        that.props.upDateSearchPlan(this.state.searchInfo.defaultPlan);
                    }
                }
                let keySessionS = this.props._this._this.myState.search.keySessionS;
                if (!isEmpty(keySessionS)) {
                    keySessionS.forEach(item => removeGlobalStorage('localStorage', item));
                }
                this.setState({ searchPlan: this.state.searchPlan });
            }
        },
    });
}


/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-04 14:24:29
 * @Description: file content
 */
import React from 'react';
import { Button } from '@platform/base';
// require('./index.less');


export function createButton(id, config = {}) {
    // console.log('页面调用了createButton方法，请使用createButtonApp方法重新创建按钮，后续createButton将删除');
    let { disabled = false, name = '', onButtonClick, buttonColor = '', ...others } = config;
    if (!this.state.button.hasOwnProperty(id)) {
        //初始化
        this.state.button[id] = { disabled };
        this.state.button[id] = Object.assign(this.state.button[id], config);
    }
    return (
        <Button
            disabled={this.state.button[id].disabled}
            onClick={() => {
                if (window.top.proxyAction) {
                    window.top.proxyAction(onButtonClick, this, name)({ ...this.props, ...this.output }, id);
                } else if (typeof onButtonClick === 'function') {
                    onButtonClick.call(this, { ...this.props, ...this.output }, id);
                    //$PE.proxy(onButtonClick, this, name)({ ...this.props, ...this.output }, id);
                }
            }}
            {...others}
            color={buttonColor}
            id={id}
            className={`${!this.state.button[id].hasOwnProperty('visible') || this.state.button[id].visible
                ? 'show'
                : 'hide'}  button-component ${buttonColor}`}
        >
            {name}
        </Button>
    );
}

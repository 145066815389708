/**
 * zhanghengh
 * 18/5/31
 * 公式编辑器的搜索树部分，用的包装后的钉耙Tree和Input，如有问题，可以参考钉耙的搜索树的例子
 * 后端是汪建伟
 */
import React, { Component } from "react";
import { Tree, Input } from "@platform/base";
const { TreeNode } = Tree;
import { ajax, toast, isObj, isString, getMultiLang } from "@platform/api";
require("./metaattr.less");

const dataList = []; //自己得到的key和title的数组   这里以后有时间优化一下，这个dataList
// const pathList = []; //已经加载过得节点路径，记录下来，再次展开就不再发请求

export default class MetaAttr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedKeys: [], //指定展开的节点
            treeData: [], //树数据
            isAsync: false,
            searchValue: "", //输入input 的值
            autoExpandParent: true, //是否自定展开父节点
            json: {}, //多语
        };
    }
    /**
     * 得需要打开的TreeNode的key得需要打开的TreeNode的key
     * @param {*} key
     * @param {*} tree
     */
    getParentKey = (key, tree) => {
        let parentKey;

        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.inputSig === key)) {
                    parentKey = node.inputSig;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };
    /**
     * zhanghengh 18/5/31
     * 得到数据数据的{inputSig, displayName}数组的函数
     */

    generateList = data => {
        const { isAsync } = this.state;
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            let { inputSig, displayName, refcode, refname } = node;
            if (isAsync) {
                displayName = refname + ' ' + refcode;
                inputSig = refcode;
            }
            dataList.push({
                displayName,
                inputSig,
            });
            if (node.children) {
                this.generateList(node.children);
            }
        }
    };

    componentWillMount() {
        const { metaParam, metaUrl } = this.props;
        // 初始化调用getMultiLang方法获取多语
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData }, () => {
                ajax({
                    url: isString(metaUrl)
                        ? metaUrl
                        : '/nccloud/formula/web/formulaentitytree.do',
                    loading: false,
                    data: isObj(metaParam) ? metaParam : {},
                    success: res => {
                        if (metaUrl && metaUrl === '/nccloud/riart/print/mdtree.do') {
                            let treeData = res.data.rows || [];
                            this.setState({ treeData, isAsync: true });
                            this.generateList(treeData);
                        } else {
                            this.setState({ treeData: [res.data], isAsync: false });
                            this.generateList([res.data]);
                        }

                    },
                    error: () => {
                        toast({
                            color: 'danger',
                            content: `${this.state.json['formula0010']}`,
                        });
                    },
                });

            });
        };
        getMultiLang({ moduleId: 'container_formulaEditor', callback }); // moduleId为所需加载json文件夹名称前缀
    }

    /**
     * zhanghengh 18/5/31
     * 当打开或关闭树节点触发的方法
     */
    handleExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    /**
     * @value 搜索的值
     * zhanghengh 18/5/31
     * input onchange 回调
     */
    handleInput = value => {
        const _this = this;
        const { treeData, isAsync } = this.state;
        const expandedKeys = [];
        dataList.forEach(item => {
            const { displayName, inputSig } = item;
            if (value && displayName.indexOf(value) > -1) {
                if (isAsync) {
                    expandedKeys.push(inputSig);
                } else {
                    expandedKeys.push(_this.getParentKey(inputSig, treeData));
                }

            }
        });
        const uniqueExpandedKeys = [];
        expandedKeys.forEach(item => {
            if (item && uniqueExpandedKeys.indexOf(item) === -1) {
                uniqueExpandedKeys.push(item);
            }
        });
        this.setState({
            expandedKeys: uniqueExpandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    /**
     * zhanghengh 18/5/31
     * 选择树节点时候的回调
     */
    handleDoubleClick = value => {
        // 添加判断让树的根节点不可以双击赋值 罗敏让加的，说的和nc保持一致
        if (!value.includes('$$formulaTreeRootFlag$$')) {
            this.props.setName(value);
        }
    };
    /**
     * zhanghengh 18/5/31
     * 选择树节点时候的回调
     */
    handleSelect = (value, event) => {
        this.props.setExplain(event.node.props.explain);
    };

    //加载异步树数据
    loadData = node => {
        const { metaParam, metaUrl } = this.props;
        const { queryCondition = {} } = metaParam;
        const { props = {} } = node;
        const { pos } = props;
        return new Promise(resolve => {
            // if (pathList.includes(pos)) {
            //     resolve();
            //     return;
            // }
            ajax({
                url: metaUrl,
                loading: false,
                data: {
                    queryCondition: {
                        mdclass: queryCondition.mdclass,
                        pid: props.refpk,
                    },
                },
                success: res => {
                    let { treeData } = this.state;
                    let path = pos.split('-');
                    // pathList.push(pos);

                    let set = (data, path) => {
                        path.shift();
                        if (path.length) {
                            if (!data[path[0]].children) {
                                data[path[0]].children = res.data.rows;
                            } else {
                                let children = data[path[0]].children;
                                set(children, path);
                            }
                        }
                    }
                    set(treeData, path);

                    this.setState(treeData);
                    this.generateList(treeData);
                    resolve();
                },
                error: () => {
                    toast({
                        color: 'danger',
                        content: `${this.state.json['formula0010']}`,
                    });
                    resolve();
                },
            });
        });
    }

    createAsyncTree = () => {
        const {
            searchValue,
            expandedKeys,
            autoExpandParent,
            treeData,
        } = this.state;
        // 只给根节点设置一次标记的标志
        // let callNumFlag = true;
        // 需要给根节点加的标记
        // let rootFlag = '';
        const loop = data => data.map(item => {
            const { refcode, refname, refpk, children, isleaf } = item;
            let displayName = refname + ' ' + refcode;
            const index = displayName.search(searchValue);
            const beforeStr = displayName.substr(0, index);
            const afterStr = displayName.substr(index + searchValue.length);
            const title =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span className="u-tree-searchable-filter">
                            {searchValue}
                        </span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{displayName}</span>
                );

            // if (!isleaf) {
            //     // 只对根节点设置一次，其他下级节点不生效
            //     // 添加判断让树的根节点不可以双击赋值 罗敏让加的，说的和nc保持一致
            //     rootFlag = '$$formulaTreeRootFlag$$';
            //     // callNumFlag = false;
            // } else {
            //     rootFlag = '';
            // }
            if (Array.isArray(children) && children[0]) {
                return (
                    <TreeNode
                        key={refcode}
                        title={title}
                        explain={refname}
                        isLeaf={isleaf}
                        refpk={refpk}
                    >
                        {loop(children)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode key={refcode} title={title} explain={refname} isLeaf={isleaf} refpk={refpk} />
            );
        });
        return (
            <Tree
                onDoubleClick={this.handleDoubleClick}
                onSelect={this.handleSelect}
                onExpand={this.handleExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                loadData={this.loadData}
            >
                {loop(treeData, [])}
            </Tree>
        );
    }

    createSyncTree = () => {
        const {
            searchValue,
            expandedKeys,
            autoExpandParent,
            treeData,
        } = this.state;
        // 只给根节点设置一次标记的标志
        let callNumFlag = true;
        // 需要给根节点加的标记
        let rootFlag = '';

        const loop = data => data.map((item, dataIndex) => {
            const { displayName, inputSig, children, hintMsg } = item;
            const index = displayName.search(searchValue);
            const beforeStr = displayName.substr(0, index);
            const afterStr = displayName.substr(index + searchValue.length);
            const title =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span className="u-tree-searchable-filter">
                            {searchValue}
                        </span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{displayName}</span>
                );

            if (callNumFlag && dataIndex === 0) {
                // 只对根节点设置一次，其他下级节点不生效
                // 添加判断让树的根节点不可以双击赋值 罗敏让加的，说的和nc保持一致
                rootFlag = '$$formulaTreeRootFlag$$';
                callNumFlag = false;
            } else {
                rootFlag = '';
            }

            if (Array.isArray(children) && children[0]) {
                return (
                    <TreeNode
                        key={inputSig + rootFlag}
                        title={title}
                        explain={hintMsg}
                    >
                        {loop(children)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode key={inputSig} title={title} explain={hintMsg} />
            );
        });
        return (
            <Tree
                onDoubleClick={this.handleDoubleClick}
                onSelect={this.handleSelect}
                onExpand={this.handleExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
            >
                {loop(treeData)}
            </Tree>
        );
    }

    /**
     * zhanghengh 18/5/31 
     * 创建全部区域的方法
     */
    _createAll = () => {
        const { json, isAsync, searchValue } = this.state;
        const { tabPaneHeight } = this.props;
        return (
            <div className="search-tree-area formulaeditor-search-tree-area">
                <Input
                    style={{ width: '100%' }}
                    placeholder={json['formula0017']}
                    value={searchValue}
                    onChange={this.handleInput}
                />
                <div className="tree-content" style={{ height: tabPaneHeight - 26 }}>
                    {isAsync ? this.createAsyncTree() : this.createSyncTree()}
                </div>
            </div>
        );
    };
    render() {
        return <div>{this._createAll()}</div>;
    }
}

import React, { Component } from 'react';
import classNames from 'classnames';
import { Button as Button } from '@platform/base';
import { RenderMenu } from '../MenuUtil';
import { getSysFieldid } from '@platform/api';

require('./index.less');

// querySelector  不能識別數字開頭 不能識別特殊字符 ·/·
const replaceSpecialStr = function (str) {
    return str.replace(/\/|\\|,/g, '') || str;
};

export default class More extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
        };
        this.timer = null;
        this.timerIndex = true;
    }

    onClick = info => {
        // 在Popover的content中的Menu中调用该事件 由于call绑定了this，Menu和MenuItem中的this都指向More组件
        let key = info.key;
        let title = info.item.props.children;
        this.props.onClick({ key, title });
        this.setState({ show: false });
    };

    render() {
        const {
            list,
            title,
            btncolor,
            disabled,
            json,
            tabindex = 0,
            clskey,
            area,
        } = this.props;
        const { show } = this.state;
        let length = list.length;
        for (let items of list) {
            let len = items['children'].length;
            if (len > 1) {
                if (
                    items.hasOwnProperty('visible') &&
                    items.visible === false
                ) {
                    length -= 1;
                } else {
                    for (let item of items['children']) {
                        // 添加一个topParentCode 解决找不到上传class的问题 bbqin
                        item.topParentCode = items.parentCode || item.parentCode;
                        // console.log(item.hasOwnProperty('visible'), item)
                        if (
                            item.hasOwnProperty('visible') &&
                            item.visible === false
                        ) {
                            len -= 1;
                        } else {
                            len = len;
                        }
                        // console.log(len, '-len')
                    }
                    if (len === 0) {
                        items.visible = false;
                        length -= 1;
                    }
                }
            }
        }
        return (
            <div
                className='mores-wrapper'
                onMouseLeave={() => {
                    this.timerIndex = true;

                    this.timer = setTimeout(() => {
                        if (this.timerIndex) {
                            this.setState({ show: false });
                        }
                    }, 300);
                }}
            >
                <div
                    className="mores-in-wrapper"
                    onMouseEnter={() => {
                        this.timerIndex = false;
                        clearTimeout(this.timer);
                    }}
                    fieldid={getSysFieldid(`${clskey}_btn`)}
                    btn-code={clskey}
                >
                    <Button
                        disabled={disabled}
                        className={classNames(
                            replaceSpecialStr(` nc-${area}-${clskey}-icon `),
                            'moreButton-component',
                            {
                                'button-primary': btncolor == 'button_main',
                            },
                        )}
                        onClick={() => !disabled && this.setState({ show: !show })
                        }
                        tabindex={tabindex}
                        fieldid={clskey}
                        btn-code={clskey}
                    >
                        {title || json['page-button-0004']}
                        <i
                            className={`arrow icon iconfont ${btncolor == 'button_main'
                                    ? 'button-primary'
                                    : ''
                                } ${show ? 'icon-hangcaozuoxiangshang1' : 'icon-hangcaozuoxiala1'
                                }`
                            }
                        />
                    </Button>
                    {/* <div className='more-beijing-wrapper' /> */}
                    <span
                        className={classNames({
                            'mores-sanjiao': show,
                            'mores-sanjiao mores-sanjiao-hide': !show,
                        })}
                    />
                    <div
                        fieldid={getSysFieldid(`${clskey}_btn_list`)}
                        className={
                            show
                                ? 'mores-list-wrapper'
                                : 'mores-list-wrapper mores-list-hide-wrapper'
                        }
                        // onMouseLeave={() => {
                        //     this.setState({show: false})
                        // }}
                        style={{
                            // width: `${length * 140 + 30}px`,
                            maxWidth: '1024px',
                            whiteSpace: 'nowrap',
                        }}
                        btn-code={clskey}
                    >
                        {/* {renderMenu.call(this, list, true, tabindex)} */}
                        <RenderMenu
                            data={list}
                            specialDisabled={true}
                            tabindex={tabindex}
                            onClick={this.onClick}
                            visible={show}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

More.defaultProps = {
    list: [],
    popPlacement: 'bottomRight', // 弹窗窗口的位置
};

import React, { Component } from 'react';
import { toast } from '@platform/api';
import { ajax } from '@platform/api';
import getUserId from './getUserId';
import getUserInfo from './getUserInfo';
//import BillErrorTrack from '..//BillErrorTrack';
import { BillErrorTrack } from '@platform/base';
import { codes } from './closeStatus';
import { ViewModel, getSafeRandom } from '@platform/api'
import { cardCache } from '@platform/api';
import { clearToast } from '@platform/api';
import { gzip as Gzip } from '@platform/api';
import { getMultiLang } from '@platform/api';

const gzip = new Gzip();

require('./index.less');

let { getGlobalStorage, setGlobalStorage, getData, setData } = ViewModel;

const { updateHeadCache, newUpdateHeadCache } = cardCache;

const defaultUserId = ''; // renyjk 仅仅用于本地测试 
const isLocal = NODE_ENV === 'development';
const nameSpace = 'nccloud_platfrom_native_socket_billinfo';

/**
 * 生成唯一标识
 */
function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-bitwise
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

//let wsLocation = '172.27.35.1:9991';
//let wsLocation = '172.20.53.89:8883';
//let wsLocation = '172.20.58.20:80';

/**
 * 建立webSocket连接，并监听事件
 * @param {*} config 
 */
export function connectMesg(config) {
    return (
        <Socket
            getThis={_this => {
                this.mySocket = _this;
            }}
            pageInstance={this}
            config={config || {}}
            output={this.output}
            rootprops={this.props}
        />
    );
}

/**
 * 主动显示提示
 * @param {*} config 
 */
export function showToast(config = {}) {
    if (config.gtxid && this.mySocket) {
        // 使用gtxid进行对象的存储  这样connect中才能比较好一一对应
        top.SocketConfig = top.SocketConfig || {};
        top.SocketConfig[config.gtxid] = {
            ...config,
            ...(top.SocketConfig[config.gtxid] || {}),
        };
    }
    //查询追溯数据
    (config.gtxid || config.message) && ajax({
        url: '/nccloud/platform/fixdata/QueryErrormsgAction.do',
        data: { gtxid: config.gtxid || '' },
        success: res => {
            // console.log(res); res.data && 
            if (res.data && res.success) {
                let topMark = 'socketErrorToast' + config.gtxid;
                let toastMark = top && top.document.querySelectorAll(`.toast-zijinyun-project[data-mark="${topMark}"]`);
                // CLOUD-183261  当设置了showtoast为false之后  他们也可能刷新内页  导致这个提示弹出
                if (top.isShowToast && this.mySocket && toastMark && toastMark.length && JSON.stringify(top.isShowToast).indexOf('false') !== -1) {
                    return;
                }
                // 先弹出了外层  然后我刷新内页会导致再弹出一个 所以这里要清理下外层的toast
                if (toastMark && toastMark.length) {
                    Array.from(toastMark).forEach(el => {
                        if (el) {
                            el.parentNode.removeChild(el);
                        }
                    });
                    let toasts = Object.assign([], window.toastModal),
                        n = 0;
                    toasts.forEach((val, index) => {
                        if (val && val.props && val.props.mark && val.props.mark === topMark && window.toastModal) {
                            window.toastModal.splice(index - n, 1);
                            n++;
                        }
                    });
                }
                // 多语获取
                let viewTitle = this.mySocket ? this.mySocket.viewTitleLang : '发送失败';
                let viewBtnText = this.mySocket ? this.mySocket.viewBtnLang : '立即查看';

                toast({
                    mark: 'socketErrorToast', // 解决当前页面刷新导致的多次弹出问题 也可以为切换数据也cardPagination使用
                    color: 'danger',
                    title: viewTitle,
                    customBtnBeforeClose: config.isShowView !== false ? (
                        <span onClick={() => {
                            if (this.mySocket) {
                                this.mySocket.gtxid = config.gtxid;
                                this.mySocket.billpk = config.billpk;
                                this.mySocket.setState({ showBillTrack: true });
                            }
                        }
                        }
                        > {viewBtnText}
                        </span>
                    ) : <span />,
                    groupOperation: true,
                    groupOperationMsg: [config.message || res.data || ''],
                });
            } else {
                // 没有获取数据情况比较复杂  暂时不进行提示
                console.log('没有res.data或者res不成功', res);
            }
        },
        error: err => {
            if (err) {
                console.error(err);
                toast({ color: 'danger', content: JSON.stringify(err.message) });
            }
        },
    });
}

class Socket extends Component {
    constructor(props) {
        super(props);
        typeof this.props.getThis === 'function' && this.props.getThis(this);
        this.state = {
            showBillTrack: false,
            json: {},
            LangData: null,
        };
        this.gtxid = '';
        this.billpk = '';
        this.billtype = this.props.config.billtype;

        //修改表格连续多次更新，只有最后一次更新有效问题
        this.refreshTableData = {};

        // 页面连接标识，用户接收消息时，判断是不是该连接
        this.tabid = '';

        // 生成唯一id   解决当前图标因为id唯一导致的不能多次渲染的问题
        this.cuuid = uuidv4();

        // 全局通信器
        top.SocketMessageFun = top.SocketMessageFun || {};
        top.SocketMessageFun[this.cuuid] = this.handleMessage;
    }

    componentWillMount() {
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData });
            this.viewTitleLang = json['page-socket-0001'];
            this.viewBtnLang = json['page-socket-0002'];
        };
        getMultiLang({ moduleId: 'page_socket', callback });
    }

    componentDidMount() {
        // 信息初始化到page上 给其他组件跨使用
        let { pageInstance, config } = this.props;
        let {
            isShowToast = true,
            tableAreaCode, //列表表格区域编码
            billpkname, //列表表格主键名
            formAreaCode, //卡片表头区域编码
            billtype,
            billtypeFromList, //'string' 若有值，列表的billtype从列表行上取该字段
            errorViewType,
            dataSource,
            extraInfos, // 所有的extraInfos 根据区域等区分 
        } = config;
        // toast 比较特殊  单独一个参数
        top.isShowToast = top.isShowToast || {};
        top.isShowToast[this.cuuid] = isShowToast;

        let oldBillInfo = getData(nameSpace) || {};

        let nativeSocketBillInfo = {
            ...oldBillInfo,
            billpkname,
            billtype,
            errorViewType,
            dataSource,
            extraInfos,
            billtypeFromList,
        };

        console.log('websocket 初始化参数:', config);

        if (tableAreaCode) { // 列表
            nativeSocketBillInfo.tableAreaCode = tableAreaCode;
        }

        if (formAreaCode) { //卡片
            nativeSocketBillInfo.formAreaCode = formAreaCode;
        }

        setData(nameSpace, nativeSocketBillInfo);

        // tabid 给消息交互做页签标识  当前页签的才产生交互
        if (!this.tabid) {
            this.tabid = top && top.WebSocketTabid ? top.WebSocketTabid : this.randomNumber();
            // 挂载下 给ajax 使用
            window.WebSocketTabid = this.tabid;
            top.WebSocketTabid = this.tabid;
            // 将tabid 放置全局缓存，重试、回退使用
            //this.addTabid();
        }
        console.log('当前页签的tabid为:', window.WebSocketTabid);

        //关闭当前页签
        window.addEventListener('beforeunload', this.handleBeforeUnLoad);
    }

    handleBeforeUnLoad = () => {

        // 修复审批中心 回退成功不更新的bug
        top.SocketMessageFun[this.cuuid] = null;
        delete top.SocketMessageFun[this.cuuid];

        //this.removeTabid();

        //有可能页签未关闭，1s后未关闭，重新放入localStorage
        // setTimeout(() => {
        //     this.addTabid();
        // }, 1000);
    }

    componentWillUnmount() {
        // 销毁之后删除tabid
        //this.removeTabid();
        top.SocketMessageFun[this.cuuid] = null;
        delete top.SocketMessageFun[this.cuuid];
        this.props.pageInstance.mySocket = null;
        window.removeEventListener('beforeunload', this.handleBeforeUnLoad);
    }

    //打开页签，将页签id存localStorage
    addTabid = () => {
        let tabidStore = getGlobalStorage('localStorage', 'tabidStore');
        if (tabidStore) {
            tabidStore = JSON.parse(tabidStore);
            if (tabidStore instanceof Array && this.tabid) {
                tabidStore.push(this.tabid);
            }
        } else {
            tabidStore = [this.tabid];
        }
        setGlobalStorage('localStorage', 'tabidStore', JSON.stringify(tabidStore));
    }

    //判断socket返回的tadid是否是当前打开页签的
    hasOpenedTab = tabid => {
        let tabidStore = getGlobalStorage('localStorage', 'tabidStore');
        if (tabidStore) {
            tabidStore = JSON.parse(tabidStore);
            if (tabidStore instanceof Array && tabidStore.length && tabidStore.includes(tabid)) return true;
        }
        return false;
    }

    //页面关闭时，将页签id从localStorage中删除
    removeTabid = () => {
        let tabidStore = getGlobalStorage('localStorage', 'tabidStore');
        if (tabidStore) {
            tabidStore = JSON.parse(tabidStore);
            if (tabidStore instanceof Array && tabidStore.length && this.tabid) {
                let index = tabidStore.indexOf(this.tabid);
                if (index != -1) {
                    tabidStore.splice(index, 1);
                    setGlobalStorage('localStorage', 'tabidStore', JSON.stringify(tabidStore));
                }
            }
        }
    }

    //将多次行数据更新合并为一次更新
    mergeTableData = () => {
        let refreshTable = [];
        for (let item of Object.values(this.refreshTableData)) {
            refreshTable.push(...item);
        }
        return refreshTable;
    }

    //接收消息 处理界面
    handleMessage = evt => {
        let userid = getUserId() || defaultUserId,
            ds = getUserInfo('datasource') || '',
            data = JSON.parse(evt.data);
        if (data && data.origin === 'websocket-server') {
            // system 是 true时，为公共系统提示
            return;
        }

        let resMessage = gzip.unzip(data.message) || {};

        let { pageInstance, config } = this.props;
        let {
            isShowView = true,
            errorViewType = true,
            isShowToast = true,
            onMessage, //注册回调
            tableAreaCode, //列表表格区域编码
            tableType = 'table', //表格类型：默认是simpleTable
            billpkname, //列表表格主键名
            headBtnAreaCode, //卡片标题区按钮区域编码
            formAreaCode, //卡片表头区域编码
            dataSource, // 缓存的pk
        } = config;

        // 当前用户，激活页签下显示
        let condition = data.targetids.includes(userid + ds) && !document.hidden;

        // 为 saga 错误类型时显示
        let saga_mesg = data.origin === 'ncc-server' && resMessage.type === 'saga_error';

        if (condition) {
            if (saga_mesg) {
                //if (resMessage.error) {
                if (resMessage.billtype) this.billtype = resMessage.billtype;
                //根据 billpkname 获取当前单据的更新数据
                if (Array.isArray(resMessage.refreshData) && resMessage.refreshData.length > 0) {
                    let currentRefreshData = resMessage.refreshData[0];
                    if (resMessage.refreshData.length > 1 && billpkname) {
                        let currentItem = resMessage.refreshData.find(e => e[billpkname]);
                        if(currentItem){
                            currentRefreshData = currentItem;
                        }
                    }
                    this.gtxid = currentRefreshData.saga_gtxid;
                    this.billpk = currentRefreshData[billpkname || '_businessPk'];
                    if (currentRefreshData.billtype) {
                        this.billtype = currentRefreshData.billtype;
                    }
                }
            }
        }

        // 使用gtxid进行对象的存储  这样connect中才能比较好一一对应
        if (top && this.gtxid) {
            top.SocketConfig = top.SocketConfig || {};
            top.SocketConfig[this.gtxid] = {
                isShowView,
                errorViewType,
                isShowToast,
            };
        }

        //正常的单据saga错误消息
        let billSagaErrorMesg = data.targetids.includes(userid + ds) && resMessage.tabid === this.tabid;

        //若返回的tabid 跟当前所有打开页签都不匹配，则对比pk后, 更新当前激活页签数据
        //使用场景：tabid不匹配时，只对比当前页签数据，更新当前页签
        // 1.重试或回退 返回的 tadid 始终是第一次重试的 tabid--后台无法处理;
        // 2.点了提交，关闭页签，又重新打开页签后，消息才返回；由于tabid不对，当前页签无法更新
        //let hintErrorMesg = condition && !this.hasOpenedTab(resMessage.tabid);
        let hintErrorMesg = condition && (resMessage.isRepeat || resMessage.isCompensate);
        console.log('若为重试/回退操作,只更新激活页签,hintErrorMesg:', hintErrorMesg);

        // 为龙哥新增 重试、回退成功后的错误消息
        let hint_mesg = data.origin === 'ncc-server' && resMessage.type === 'saga_hint';
        // 重试或者回退成功的消息提示
        if (hint_mesg) {
            if (!resMessage.error) {
                clearToast('socketErrorToast'); // 清理一进入页面就提示处理的哪个问题
            }
        }

        // 消息和交互分開
        if (billSagaErrorMesg || hintErrorMesg) {
            //调用业务组注册的回调
            if (typeof onMessage === 'function') {
                // 错误中心，重试或者回退成功的提示，无法获取到，因为重试或者回退成功，龙哥给的tabid不对(始终是第一次重试的tabid)
                onMessage({ ...this.props, ...this.output }, resMessage);
            }

            if (typeof window.top.onSocketMessage === 'function') {
                window.top.onSocketMessage(resMessage);
            }

            //标准交互只监听来源为 ncc-server 且 错误类型为 saga_error 的消息
            if (!saga_mesg) return;

            // 更新界面时，先对比pk是否相同，相同时，在更新组件数据  已确认全领域 pk 唯一
            //1------------------更新列表 ----------------------//
            //更新表格状态
            if (tableAreaCode) {
                if (tableType === 'simpleTable') {
                    tableType = 'table';
                }

                let billpk = null;
                // 必然要取一次  否则错误中心操作时不能正确更新页面 NCC-93396
                if (saga_mesg && data.targetids.includes(userid + ds)) {
                    //if (resMessage.error) {
                    if (resMessage.billtype) this.billtype = resMessage.billtype;
                    //根据 billpkname 获取当前单据的更新数据
                    if (Array.isArray(resMessage.refreshData) && resMessage.refreshData.length > 0) {
                        let currentRefreshData = resMessage.refreshData[0];
                        if (resMessage.refreshData.length > 1 && billpkname) {
                            let currentItem = resMessage.refreshData.find(e => e[billpkname]);
                            if(currentItem){
                                currentRefreshData = currentItem;
                            }     
                        }
                        billpk = currentRefreshData[billpkname || '_businessPk'];
                    }
                }

                // 更新表格数据
                // 存在问题：连续调用多次，表格只更新一次， state更新问题
                // 根据billpk gtxid累加数据 更新时 累加数据一次更新
                this.refreshTableData[billpk || this.billpk || tableAreaCode] = resMessage.refreshData;
                if (!resMessage.refreshData) { // 有一些节点不传refreshData （财务杨龙  会计平台  平台日志）
                    return;
                }
                let refreshData = this.mergeTableData();
                pageInstance[tableType].updateDataByRefresh(tableAreaCode, billpkname, refreshData, resMessage.msg);
            }
            //2-------------------更新卡片-----------------------//
            if (formAreaCode && Array.isArray(resMessage.refreshData)) {

                let oldBillPk = pageInstance.form.getFormItemsValue(formAreaCode, billpkname);
                oldBillPk = oldBillPk && oldBillPk.value; // 当前页面billpk

                let refreshData = resMessage.refreshData[0];
                if (resMessage.refreshData.length > 1) {
                    refreshData = resMessage.refreshData.find(e => (e[billpkname] && e[billpkname] == oldBillPk) || e['_businessPk'] == oldBillPk);
                }
                let billpk = refreshData && (refreshData[billpkname] || refreshData['_businessPk']); // 返回数据中的billpk

                console.log('billpk:', billpk, 'oldBillPk', oldBillPk);

                //更新标题区按钮状态 
                if (headBtnAreaCode && billpk == oldBillPk) {
                    pageInstance.button.toggleErrorStatus(headBtnAreaCode, { isError: resMessage.error });
                }

                //更新卡片表头数据
                pageInstance.form.updateDataByRefresh(formAreaCode, refreshData, billpkname, dataSource, resMessage.msg);
            }

            // 更新缓存
            // let newData = resMessage.refreshData && (resMessage.refreshData[0] || {});
            // dataSource && formAreaCode && updateHeadCache(dataSource, newData[billpkname], formAreaCode, newData);

            // 特殊场景处理，一次返回多条数据的情况 --修复黄开高问题，待验证
            let newData = resMessage.refreshData || [];
            dataSource && formAreaCode && newUpdateHeadCache(dataSource, billpkname, formAreaCode, newData);
        }
    }

    // 创建连接时间戳
    randomNumber = () => {
        const now = new Date();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        let hour = now.getHours();
        let minutes = now.getMinutes();
        let seconds = now.getSeconds();
        return now.getFullYear().toString() + month.toString() + day + hour + minutes + seconds + (Math.round(getSafeRandom() * 23 + 100)).toString();
    }

    render() {
        let { config } = this.props;
        let {
            billtype, //单据类型
            errorViewType = true, // 是否显示 单据追溯
        } = config;

        return errorViewType === 'none' ? null : (
            <BillErrorTrack
                show={this.state.showBillTrack}
                close={() => {
                    this.setState({ showBillTrack: false });
                }}
                pk={this.billpk} //单据id
                type={this.billtype || billtype} //单据类型
                gtxid={this.gtxid} //错误id
                errorViewType={errorViewType}
                fromToastTip={true}
            />
        );
    }
}
import React, { PureComponent } from 'react';
import { Modal, Button, Radio, HotKeys, Tooltip, Select, Input, InputNumber } from '@platform/base';
import { formDownload, toast, ajax, getMultiLang } from '@platform/api';
require("./index.less");
const Option = Select.Option;

function is(a, b) {
    return a === b;
}

export default class PrintOutput extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            json: {},
            printTemplateMenu: [],
            data: {
                outputMode: 'pdf',
                MultiConfig: '1',
                pdfConfig: false,
                printTemplateID: '',
                htmlConfig: false,
                prefix: '',
                threshold: 0,
                dsPositionPolicy: 4,
                fileVolume: 16000,
                sheetNamePreFix: '新的工作表',
                isDifDocDifFile: false,
                canModifyType: props.canModifyType || false,
            },
        }
    }

    componentWillMount() {
        let { json } = this.props
        if (json) {
            this.setState({
                json,
            });
        } else {
            let callback = (json, bool, inlt) => {
                let data = this.state.data;
                data.sheetNamePreFix = json['api-print-0022'] || "新的工作表",
                this.setState({
                    json,
                    data,
                });
            }
            getMultiLang({ moduleId: 'api-print', callback })
        }
    }

    shouldComponentUpdate = (nextProps = {}, nextState = {}) => {
        const thisProps = this.props || {}, thisState = this.state || {};
        if (Object.keys(thisProps).length !== Object.keys(nextProps).length ||
            Object.keys(thisState).length !== Object.keys(nextState).length) {
            return true;
        }
        for (const key in nextProps) {
            if (!is(thisProps[key], nextProps[key])) {
                return true;
            }
        }
        for (const key in nextState) {
            if (thisState[key] !== nextState[key] || !is(thisState[key], nextState[key])) {
                return true;
            }
        }
        return false;
    }

    close = () => {
        this.setState({ show: false });
    }
    open = () => {
        let { funcode, nodekey, appcode, oids } = this.props.data

        ajax({
            method: 'post',
            url: '/nccloud/platform/print/queryTemplates.do',
            data: { funcode, nodekey, appcode, oids },
            mode: 'normal',
            success: res => {
                let data = res.data.data || res.data;
                if (!data) {
                    let error = res.data.error || res.error;
                    if (error) {
                        if (typeof error === 'object') {
                            let keys = Object.values(error)
                            toast({ color: 'warning', content: keys.join('||') });
                        } else {
                            toast({ color: 'warning', content: String(res) });
                        }

                    }
                    return
                }
                let list = res.data.data || []
                let printTemplateID = list.length ? list[0]['m_ctemplateid'] : ''

                this.setState({
                    printTemplateMenu: list,
                    data: { ...this.state.data, printTemplateID },
                    show: true,
                });

            },
            error: res => {
                toast({ color: 'danger', content: res.message });
            },
        })

    }

    // 提交事件
    submit = () => {
        //新加：license校验成功后，再打印
        ajax({
            method: 'post',
            url: '/nccloud/platform/print/queryPrintAuthorize.do',
            data: {},
            success: res => {
                let data = Object.assign({}, this.props.data, this.state.data)

                let printTemplateID = this.state.data.printTemplateID;

                data.printTemplateName = '';
                let printTemplateMenu = this.state.printTemplateMenu;
                if (printTemplateMenu && printTemplateMenu instanceof Array)
                    printTemplateMenu.forEach(item => {
                        if (item.m_ctemplateid == printTemplateID) {
                            data.printTemplateName = item.m_vtemplatename;
                        }
                    })

                let submitData = {
                    url: this.props.url || '/nccloud/reva/revecont/print.do',
                    params: Object.assign({}, Object.assign({}, data, { outputSetting: JSON.stringify(data), download: "directOutput", outputMode: data.outputMode, output: true })),
                    enctype: 2,
                    isCipher: true
                };
                formDownload(submitData);
                if (typeof this.props.callback === 'function') {
                    this.props.callback()
                }
                this.close()
            },
            error: () => {
            }
        })
    }

    handleTypeChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { outputMode: value }) });
    }
    handlePrintTemplateIDChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { printTemplateID: value }) });
    };
    handleMultiConfigChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { MultiConfig: value }) });
    }

    changePdfConfigCheck = value => {
        console.log(value)
        this.setState({ data: Object.assign({}, this.state.data, { pdfConfig: value }) });
    }

    changeHtmlConfigCheck = value => {
        this.setState({ data: Object.assign({}, this.state.data, { htmlConfig: value }) });
    }
    handlePrefixInputChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { prefix: value }) });
    }
    handleThresholdInputChange = value => {
        this.setState({ data: Object.assign({}, this.state.data, { threshold: value }) });
    }

    handleOtherSheet(type, val) {
        this.setState({ data: Object.assign({}, this.state.data, { [type]: val }) });
    }

    gerMultitaskSetup = () => {
        //  "api-print-0018": "工作表名称","api-print-0019": "行数阈值","api-print-0020": "多任务设置","api-print-0021": "注：设置单个Excel文件的行数，超过此行数自动新建文件"
        const { outputMode, sheetNamePreFix, dsPositionPolicy, fileVolume, isDifDocDifFile, canModifyType = false } = this.state.data;
        const { json = {} } = this.state;
        let multiTask = "";
        const radioStyle = {
            display: 'block',
        };
        const dsPositionPolicyList = [
            { display: json["api-print-0013"], value: 4 },//全部打印任务导出到同一个工作表
            { display: json["api-print-0014"], value: 5 },//每一个打印任务导出到不同的工作表
            { display: json["api-print-0015"], value: 6 },//每一个打印任务导出到不同的工作簿
        ];
        const isDifDocDifFileList = [
            { display: json["api-print-0016"], value: false },//全部打印任务导出到同一个文件
            { display: json["api-print-0017"], value: true },//每一个打印任务导出到不同的文件
        ];
        if (outputMode === "excel") {
            multiTask = <div>
                <Radio.RadioGroup
                    name="type"
                    selectedValue={dsPositionPolicy}
                    onChange={this.handleOtherSheet.bind(this, "dsPositionPolicy")}
                >
                    {dsPositionPolicyList.map((item, index) => {
                        return (<Radio disabled={!canModifyType} style={radioStyle} key={index.toString()} value={item.value}>
                            {item.display}
                        </Radio>)
                    })}
                </Radio.RadioGroup>
                <div className="form-region">
                    <p className="label">{json["api-print-0018"]}：</p>
                    <div className="in-form">
                        <Input value={sheetNamePreFix}
                            onChange={this.handleOtherSheet.bind(this, "sheetNamePreFix")} />
                    </div>
                </div>
                <div className="form-region">
                    <Tooltip inverse placement="top" overlay={json["api-print-0021"]}>
                        <p className="label">{json["api-print-0019"]}：</p>
                    </Tooltip>

                    <div className="in-form">
                        <InputNumber iconStyle={1} min={1} value={fileVolume}
                            onChange={this.handleOtherSheet.bind(this, "fileVolume")} />
                    </div>
                </div>
            </div>
        } else if (["pdf", "html"].includes(outputMode)) {
            multiTask = <div>
                <Radio.RadioGroup
                    name="type"
                    selectedValue={isDifDocDifFile}
                    onChange={this.handleOtherSheet.bind(this, "isDifDocDifFile")}
                >
                    {isDifDocDifFileList.map((item, index) => {
                        return (<Radio disabled={!canModifyType} style={radioStyle} key={index.toString()} value={item.value}>
                            {item.display}
                        </Radio>)
                    })}
                </Radio.RadioGroup>
            </div>
        }
        return <div className="task-container">
            <h3>{json["api-print-0020"]}</h3>
            {multiTask}
        </div>
    }

    render() {
        let { type } = this.state.data,
            { json, printTemplateMenu } = this.state
        /*   let ConfigTag = () => {

               if (type === 'excel') {
                   return <div className='config'>
                       <fieldset>
                           <legend>多任务设置</legend>
                           <Radio.RadioGroup
                               name="MultiConfig"
                               selectedValue={this.state.data.MultiConfig}
                               onChange={this.handleMultiConfigChange.bind(this)}>

                               <Radio key={1} value="1">全部打印任务导出到同一个工作表</Radio>

                               <Radio key={2} disabled value="2">每个打印任务导出到不同的工作表</Radio>

                               <Radio key={3} disabled value="3">每个打印任务导出到不同的工作簿</Radio>

                           </Radio.RadioGroup>
                       </fieldset>

                       <Form>
                           <FormItem isRequire={true}
                                       labelName="工作表前缀"
                                       htmlType="chinese"
                                       errorMessage="前缀格式错误"
                                       method="blur"
                           >
                               <FormControl name="prefix"
                                              value={this.state.data.prefix}
                                              onChange={this.handlePrefixInputChange}
                                              placeholder="只能输入中文"/>
                           </FormItem>
                           <FormItem isRequire={true}
                                       labelName="行数阀值"
                                       method="blur"
                                       reg={/^[0-9]+$/}
                                       errorMessage="行数阀值格式错误">
                               <FormControl name="threshold"
                                              value={this.state.data.threshold}
                                              onChange={this.handleThresholdInputChange}
                               />
                           </FormItem>
                       </Form>

                   </div>
               } else if (type === 'pdf') {

                   return <div className='Config'>
                       <Checkbox checked={this.state.data.pdfConfig}
                                   onChange={this.changePdfConfigCheck}>每一个打印任务导出到不同的文件</Checkbox>
                   </div>
               } else {
                   return <div className='Config'>
                       <Checkbox checked={this.state.data.htmlConfig}
                                   onChange={this.changeHtmlConfigCheck}>每一个打印任务导出到不同的文件</Checkbox>
                   </div>
               }
           }*/
        let templateChoice = () => {
            if (!printTemplateMenu || printTemplateMenu.length <= 1) {
                return ''
            } else {
                return (
                    <div className="body" style={{ padding: '20px' }}>
                        <span>{json['api-print-0008' || '选择模板']}：</span>{' '}
                        <Select
                            value={this.state.data.printTemplateID}
                            //style={{ width: 200, marginRight: 6 }}
                            onChange={this.handlePrintTemplateIDChange}
                            disabled={printTemplateMenu.length <= 1 ? true : false}
                            fieldid="print-list"
                            className='print-out-select'
                        >
                            {printTemplateMenu.map((item, index) => {
                                return (
                                    <Option
                                        value={item && item.m_ctemplateid}
                                        key={index}
                                        title={item && item.m_vtemplatename}
                                    >
                                        {item && item.m_vtemplatename}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                )
            }
        }
        return <Modal
            {...this.props}
            fieldid="print"
            show={this.state.show}
            ref={Modal => (this.Modal = Modal)}
            className="senior print-output-contain"
            onHide={this.close}
            zIndex={220} //cardtable最大化之后会被盖住，所以加这个属性
        >
            <HotKeys
                keyMap={{
                    sureBtnHandler:
                        HotKeys.USUAL_KEYS.MODAL_CONFIRM,
                    cancelBtnHandler:
                        HotKeys.USUAL_KEYS.MODAL_CALCEL,
                }}
                handlers={{
                    sureBtnHandler: () => {
                        // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发 by bbqin
                        if (this.Modal && this.Modal.isTopModal()) {
                            this.submit();
                        }
                    },
                    cancelBtnHandler: () => {
                        // 取消按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                        if (this.Modal && this.Modal.isTopModal()) {
                            this.close();
                        }
                    },
                }}
                className="print-template-hotkeys-wrapper"
                focused={true}
                attach={document.body}
                display="inline"
            />

            <Modal.Header closeButton>
                <Modal.Title>{this.props.title || json['api-print-0009'] || '输出到文件'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className="print-template-wrapper">
                    <div className="header">
                        <Radio.RadioGroup
                            name="type"
                            selectedValue={this.state.data.outputMode}
                            onChange={this.handleTypeChange.bind(this)}>

                            <Radio key='excel' value="excel">
                                {json['api-print-0010' || '输出到EXCEL文件']}
                            </Radio>

                            <Radio key='pdf' value="pdf">{json['api-print-0011' || '输出到PDF文件']}</Radio>

                            <Radio key='html' value="html">{json['api-print-0012' || '输出到HTML文件']}</Radio>

                        </Radio.RadioGroup>
                    </div>
                    {
                        templateChoice()
                    }
                    {/*<div className="config">

                            {ConfigTag()}
                        </div>*/}
                    {this.gerMultitaskSetup()}
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Tooltip
                    placement="top"
                    inverse
                    overlay={`${json['api-print-0006']}  (${
                        HotKeys.USUAL_KEYS.MODAL_CONFIRM
                        })`}
                    trigger={["hover", "focus"]}
                >
                    <Button fieldid="confirm" onClick={this.submit} colors="primary">
                        {json['api-print-0006' || '确定']}(<span className="text-decoration-underline">Y</span>)
                    </Button>
                </Tooltip>
                <Tooltip
                    placement="top"
                    inverse
                    overlay={`${json['api-print-0007']}  (${
                        HotKeys.USUAL_KEYS.MODAL_CALCEL
                        })`}
                    trigger={["hover", "focus"]}
                >
                    <Button fieldid="cancel" onClick={this.close} colors="info">
                        {json['api-print-0007' || '取消']}(<span className="text-decoration-underline">N</span>)
                    </Button>
                </Tooltip>
            </Modal.Footer>
        </Modal>

    }
}

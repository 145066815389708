import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip, BillErrorTrack } from '@platform/base';
//import BillErrorTrack from '../BillErrorTrack';

import { getMultiLang, ViewModel } from '@platform/api';

const nameSpace = 'nccloud_platfrom_native_socket_billinfo';

require('./index.less');

/**
 * 用于列表操作列的组件
 */
export function createErrorButton(config) {
    let { record = {}, sucessCallBack } = config;
    // record 可能还有一级  需要完善下数据
    record = record.values || record;

    // 这个方法以后会无效
    return typeof sucessCallBack === 'function' && sucessCallBack(this.props, record, this);
    // return saga_status.value !== '1' && typeof sucessCallBack === 'function' && sucessCallBack(this.props, record, this);

    // return (
    //     <CrossServiceListBtn
    //         pageInstance={this}
    //         isError={saga_status.value === "1"}
    //         sucessCallBack={sucessCallBack}
    //         output={this.output}
    //         rootprops={this.props}
    //     />
    // )

}

// class CrossServiceListBtn extends Component {
//     constructor(props) {
//         super(props);
//     }

//     render() {
//         let { isError, sucessCallBack } = this.props;
//         return (
//             <span>{!isError && typeof sucessCallBack === "function" && sucessCallBack()}</span>
//         )
//     }
// }

/**
 * 用于卡片标题区
 */


export function createErrorFlag(config) {
    let { headBtnAreaCode } = config;
    return (
        <CrossServiceCardBtn
            getThis={_this => {
                this.myCrossServiceBtn[headBtnAreaCode] = _this;
            }}
            pageInstance={this}
            moduleId={headBtnAreaCode}
            output={this.output}
            rootprops={this.props}
        />
    );
}

// get nearest parent element matching selector   origin from stackoverflow  add by bbqin
const Closest = function (el, selector) {
    if (!el) {
        return null;
    }
    var matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

    while (el) {
        if (matchesSelector.call(el, selector)) {
            break;
        }
        el = el.parentElement;
    }
    return el;
};


class CrossServiceCardBtn extends Component {
    constructor(props) {
        super(props);
        typeof this.props.getThis === 'function' && this.props.getThis(this);
        this.state = {
            isError: this.props.isError || false,
            showBillTrack: false,
            json: {},
            LangData: null,
        };
        this.gtxid = '';
        this.billpk = '';
        this.billtype = '';

    }

    componentWillMount() {
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData });
        };
        getMultiLang({ moduleId: 'base_errorTip', callback });
    }

    //根据 props 更新 
    componentWillReceiveProps(nextProps) {
        let oldState = this.state;
        let isEquat = true;
        for (let pop of Object.keys(nextProps)) {
            if (oldState.hasOwnProperty(pop) && oldState[pop] !== nextProps[pop]) {
                isEquat = false;
                oldState[pop] = nextProps[pop];
            }
        }
        //判断两个对象是否相等
        if (!isEquat) {
            this.setState({ oldState });
        }
    }

    queryBillTrack = () => {
        let { pageInstance } = this.props;

       // let { formAreaCode, billtype, billpkname } = pageInstance.nativeSocketBillInfo;
        let { formAreaCode, billtype, billpkname } = ViewModel.getData(nameSpace) || {};

        this.billtype = billtype;

        let gtxid = pageInstance.form.getFormItemsValue(formAreaCode, 'saga_gtxid');
        this.gtxid = gtxid && gtxid.value;

        let billpk = pageInstance.form.getFormItemsValue(formAreaCode, billpkname);
        this.billpk = billpk && billpk.value;

        this.setState({ showBillTrack: true });

    }

    componentDidMount() {
        let isError = this.state.isError,
            $this = ReactDOM.findDOMNode(this),
            $parent = Closest($this, '.nc-bill-header-area,.u-affix-content');
        if (!$parent) return;
        $parent.className = $parent.className.replace(/socket-error-class/g, '');
        if (isError) {
            $parent.className = $parent.className + ' socket-error-class';
        }
        // console.log('dom in cross', $this, $parent);
    }

    componentDidUpdate() {
        let isError = this.state.isError,
            $this = ReactDOM.findDOMNode(this),
            $parent = Closest($this, '.nc-bill-header-area,.socket-error-class,.u-affix-content');
        if (!$parent) return;
        $parent.className = $parent.className.replace(/socket-error-class/g, '');
        if (isError) {
            $parent.className = $parent.className + ' socket-error-class';
        }
        // console.log('dom in cross', $this, $parent);
    }

    componentWillUnmount() {
        let $this = ReactDOM.findDOMNode(this),
            $parent = Closest($this, '.socket-error-class,.nc-bill-header-area,.u-affix-content');
        if (!$parent) return;
        $parent.className = $parent.className.replace(/socket-error-class/g, '');
    }

    render() {
        let { isError, showBillTrack, json } = this.state;
        let { errorViewType } = this.props;
        return (
            <span className="billHead-errorBtn-wrapper">
                {
                    isError && (
                        <Tooltip
                            inverse
                            trigger="hover"
                            overlay={json['base-error-tip-0001'] || '点击查看错误原因'}
                            placement="top"
                            delay={1}
                            delayShow={800}
                        >
                            <span
                                className="error-flag iconfont icon icon-gantanhao"
                                onClick={this.queryBillTrack}
                            />
                        </Tooltip>
                    )}

                {
                    errorViewType !== 'none' && (
                        <BillErrorTrack
                            show={showBillTrack}
                            close={() => {
                                this.setState({ showBillTrack: false });
                            }}
                            pk={this.billpk} //单据id
                            type={this.billtype} //单据类型
                            gtxid={this.gtxid} //错误id
                            errorViewType={errorViewType}
                        // pk= "1001Z81000000001PYQH" //单据id
                        // type='5F'  //单据类型
                        // gtxid='2019110863519ada-eef2-4a47-93b5-446478d3d31b' //错误id
                        //bodyClientHeight="500" //在第二层iframe里不用配置，如果iframe层更多，需要传一下body的高度
                        />
                    )
                }
            </span>
        );

    }
}

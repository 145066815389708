import { createButton } from './create';

import {
    setButtonDisabled,
    getButtonDisabled,
    setButtonVisible,
    setButtonsVisible,
    setButtonTitle,
    hideButtonsByAreas, // 隐藏所传区域的按钮
    getButtons,
    setButtons,
    getButtonVisible,
    updateButtons,
    setMainButton,
    setPopContent,
    setUploadDocAmount,
    setOprationBtnsRenderStatus,
    setUploadConfig,
    //兼容旧代码
    setDisabled,
} from './methods';

export default {
    createButton,
    setButtonDisabled,
    getButtonDisabled,
    setButtonVisible,
    setButtonsVisible,
    setButtonTitle,
    hideButtonsByAreas, // 隐藏所传区域的按钮
    getButtons,
    setButtons,
    getButtonVisible,
    updateButtons,
    setMainButton,
    setPopContent,
    setUploadDocAmount,
    setOprationBtnsRenderStatus,
    setUploadConfig,
    //兼容旧代码
    setDisabled,
}

import React, { Component } from 'react';
import getUserId from './getUserId';
import getUserInfo from './getUserInfo';
//import BillErrorTrack from '../BillErrorTrack';
import { BillErrorTrack } from '@platform/base';
import { codes } from './closeStatus';
import { toast, ajax, ViewModel, clearToast, Cipher, gzip as Gzip, getMultiLang, getSafeRandom } from '@platform/api';
import ChannelCtrl from './uniqueChannel';
import HeartCheck from './heartCheck';

const gzip = new Gzip();

require('./index.less');

let { getGlobalStorage, setGlobalStorage } = ViewModel;

const isLocal = NODE_ENV === 'development';

const defaultUserId = isLocal ? 'renyjk' : ''; // 仅仅用于本地测试 

const ReadyState = ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'],
    Timeout = 4000,
    Count = 99999,
    MESSAGE = ChannelCtrl.ChannelAction.message;

export default class SocketConnect extends Component {
    constructor(props) {
        super(props);

        // 错误追溯使用
        this.state = {
            showBillTrack: false,
            userId: props.userId,
            ds: props.ds,
            json: {},
            LangData: null,
        };
        this.saga_popInfo = {};
        this.gtxid = '';
        this.billpk = '';
        this.billtype = props.billtype;

        // 页面连接标识，用户接收消息时，判断是不是该连接
        this.tabid = '';
        // 重连计数器
        this.count = Count;

        // soket实例
        this.CWebSocket = null;
        // 连接标识，避免重复连接
        this.lockReconnect = false;
        // 创建多页签消息机制
        this.recieve = this.recieve.bind(this);
        this.running = this.running.bind(this);

        this.ChannelCtrl = new ChannelCtrl({
            unique: '___socket_channel___',
            onRecieve: this.recieve,
        }).start();
        // 添加心跳检测
        this.HeartCheck = new HeartCheck({
            running: this.running,
            count: 99999,
            timeout: 50 * 1000, // 每50秒发送一次信息
        });

        console.log('socket connect channel ');
    }

    running() {
        console.log('send message for connect check');
        this.isConnect && this.CWebSocket && this.CWebSocket.send(String(new Date().getTime()));
    }

    // 接收多页签消息
    recieve(obj, key) {
        // 收到其他页签的socket的消息  然后提示
        if (key == MESSAGE && obj && !this.isConnect) { //TODO 可能还要判断消息是不是自己发的 避免自己提示自己
            console.log(obj);
            try {
                this.sendMessageToIframe({ data: obj });
            } catch (error) {
                console.error ? console.error(error) : console.log(error);
                // throw error;
            }

            // 这里一定要先更新内页  这样才能把一些信息参数更新掉
            // 比如把内页的参数  更新之后就可以同步到外页了
            this.handleMessage({ data: obj });

        }
    }

    sendMessageToIframe(obj) {
        if (top && top.SocketMessageFun) {
            for (let item in top.SocketMessageFun) {
                if (typeof top.SocketMessageFun[item] == 'function') {
                    top.SocketMessageFun[item](obj);
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.userId);
        if (nextProps.userId !== this.state.userId) { // 更新链接
            this.setState({
                userId: nextProps.userId,
                ds: nextProps.ds,
            }, () => {
                // 销毁之后不在重连
                this.lockReconnect = true;
                this.closeSocket();
                this.createWebSocket();
            });
        }
    }

    componentWillMount() {
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData });
        };
        getMultiLang({ moduleId: 'page_socket', callback });
    }

    componentDidMount() {
        // 界面初始化时，建立websocket连接
        this.createWebSocket();
    }

    componentWillUnmount() {
        // 销毁之后不在重连
        this.lockReconnect = true;
        this.closeSocket();
        this.isConnect && setGlobalStorage('localStorage', '__socket_connect_', 'N');
        this.ChannelCtrl.destroy();
        this.HeartCheck.stop();
    }

    closeSocket = () => {
        this.isConnect && setGlobalStorage('localStorage', '__socket_connect_', 'N');
        if (this.CWebSocket) {
            //关闭websocket连接
            this.CWebSocket.close(); // 1000 组件注销关闭
            // alert("关闭了WebSocket连接")
            this.CWebSocket = null;
        }
    }

    WebSocketConnect = () => {
        if (!this.tabid) { // 使用top来保持多层的tabid相同
            this.tabid = top && top.WebSocketTabid ? top.WebSocketTabid : this.randomNumber();
            // 挂载下 给ajax 使用
            window.WebSocketTabid = this.tabid;
            top.WebSocketTabid = this.tabid;
            console.log('页签时间戳值为：', this.tabid);
        }

        // 判断当前浏览器中是否已经建立了链接
        let isConnect = getGlobalStorage('localStorage', '__socket_connect_') === 'Y';
        if (isConnect) return;

        // 启动定时器 不停发送消息避免链接中断
        this.HeartCheck.restart();

        // 注意下用户信息的获取顺序
        let userid = this.state.userid || getUserId() || defaultUserId,
            ds = this.state.ds || getUserInfo('datasource') || '',
            serverIP = window.top.location.hostname,
            serverPort = window.location.port ? (':' + window.location.port) : window.location.port,
            wsLocation = serverIP + serverPort,
            { serverLocation: loacalWsLocation } = this.props;

        if (loacalWsLocation) wsLocation = loacalWsLocation;

        // 加密 后端没有取开关
        // let cipherFlag = getGlobalStorage('localStorage', 'rockin');
        // 将是否加密缓存到组件单例上
        // Cipher.CipherFlag = cipherFlag === 'true';
        let cruxId = String(new Date().getTime());
        let aeskey = Cipher.opaqueDecrypt(getGlobalStorage('localStorage', 'cowboy')) || '15469120627904bc557cd9d084a8fae1';
        aeskey = cruxId + aeskey.substring(0, aeskey.length - cruxId.length);
        // console.log('aeskey', aeskey);
        //  let socketParams = Cipher.CipherFlag ? Cipher.encrypt(`userid=${userid}${ds}`, '15469120627904bc557cd9d084a8fae1') : `userid=${userid}${ds}`;
        let socketParams = `userid=` + Cipher.encrypt(`${userid}${ds}`, aeskey) + `&info=${cruxId}`;

        if (!userid) return;
        this.wsUrl = `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${wsLocation}/websocket/api/messagelistener/?` + socketParams;

        try {
            this.CWebSocket = new WebSocket(this.wsUrl);
            this.init();
        } catch (e) {
            console.log('catch');
            this.reconnect();
        }
    }

    createWebSocket = () => {
        // 请求开发 判断是否是合盘环境，若为合盘环境，不使用websocket
        let connectSwitch = getGlobalStorage('localStorage', '_ncc_websocket_switch_');
        if (connectSwitch === null) {
            ajax({
                url: '/nccloud/platform/query/merge.do',
                data: {},
                async: false,
                method: 'post',
                success: res => {
                    if (res.success) {
                        if (res.data) {
                            //合盘环境，开关是“0” 不建立websocket
                            setGlobalStorage('localStorage', '_ncc_websocket_switch_', '0');
                        } else {
                            //分盘环境，开关时“1”建立webscoket
                            setGlobalStorage('localStorage', '_ncc_websocket_switch_', '1');
                            this.WebSocketConnect();

                        }
                    }
                },
            });
        } else if (connectSwitch === '1') {
            this.WebSocketConnect();
        }
    }

    //初始化，监听事件
    init = () => {
        this.CWebSocket.onclose = event => {
            console.log('websocket连接关闭', event, event.reason, codes[event.code]);
            setGlobalStorage('localStorage', '__socket_connect_', 'N'); // 记录是否已经链接了
            if (event.code != 1000) {
                this.reconnect();
            }
        };
        this.CWebSocket.onerror = event => {
            console.log('websocket连接发生异常了', event);
            setGlobalStorage('localStorage', '__socket_connect_', 'N'); // 记录是否已经链接了
            this.reconnect();
        };
        this.CWebSocket.onopen = () => {
            this.count = Count;
            setGlobalStorage('localStorage', '__socket_connect_', 'Y'); // 记录是否已经链接了
            // 记录当前socket 是否链接
            this.isConnect = true;
            // 链接是否打开
            console.log(this.CWebSocket);
            console.log(ReadyState[this.CWebSocket.readyState]);
        };
        this.CWebSocket.onmessage = event => {
            this.count = Count;
            setGlobalStorage('localStorage', '__socket_connect_', 'Y'); // 记录是否已经链接了
            // 记录当前socket 是否链接
            this.isConnect = true;
            console.log('websocket接收到消息');
            // 发消息给其他页签
            this.ChannelCtrl.send(MESSAGE, event.data);
            this.sendMessageToIframe({ data: event.data });
            this.handleMessage(event);
            //拿到任何消息都说明当前连接是正常的
        };
    }

    //接收消息 处理界面
    handleMessage = evt => {
        let userid = getUserId() || defaultUserId,
            ds = getUserInfo('datasource') || '',
            data = JSON.parse(evt.data);
        if (data && data.origin === 'websocket-server') {
            // system 是 true时，为公共系统提示
            return;
        }

        let { json } = this.state;

        let resMessage = gzip.unzip(data.message) || {};

        let {
            isShowView = true,
            isShowToast = true,
            onMessage, //注册回调
            billpkname, //列表表格主键名
        } = this.props;

        // 当前用户，激活页签下显示
        let condition = data.targetids.includes(userid + ds) && !document.hidden;

        // 为 saga 错误类型时显示
        let saga_mesg = data.origin === 'ncc-server' && resMessage.type === 'saga_error';

        // 为龙哥新增 重试、回退成功后的错误消息
        let hint_mesg = data.origin === 'ncc-server' && resMessage.type === 'saga_hint';

        if (condition) {
            // 如果返回了消息清除  重试中和回退中的提示 
            clearToast('socketReSetErrorToast');
            clearToast('socketBackErrorToast');

            // saga出错提示
            if (saga_mesg) {
                if (resMessage.error) {
                    if (resMessage.billtype) this.billtype = resMessage.billtype;
                    if (Array.isArray(resMessage.refreshData) && resMessage.refreshData.length > 0) {
                        this.gtxid = resMessage.refreshData[0].saga_gtxid;
                        this.billpk = resMessage.refreshData[0][billpkname || '_businessPk'];
                    }
                    // 内外页参数同步
                    isShowView = top && this.gtxid && top.SocketConfig && top.SocketConfig[this.gtxid] ? top.SocketConfig[this.gtxid] : isShowView;
                    isShowToast = top && this.gtxid && top.SocketConfig && top.SocketConfig[this.gtxid] ? top.SocketConfig[this.gtxid] : isShowToast;

                    if (resMessage.msg) {
                        let isdisplay = typeof resMessage.isdisplay === 'undefined' ? true : resMessage.isdisplay;
                        isShowToast && toast({
                            mark: 'socketErrorToast' + this.gtxid,
                            color: 'danger',
                            title: json['page-socket-0001'] || '发送失败',
                            customBtnBeforeClose: (isShowView && isdisplay) ? (
                                <span
                                    data-mark={this.gtxid}
                                    onClick={event => {
                                        //-- 解决CLOUD-182009
                                        let current_gtxid = event.target.getAttribute('data-mark');
                                        if (current_gtxid) {
                                            this.gtxid = current_gtxid;
                                            let current_saga = this.saga_popInfo[this.gtxid];
                                            this.billpk = current_saga && current_saga.billpk;
                                            this.billtype = current_saga && current_saga.billtype;
                                        }
                                        this.setState({ showBillTrack: true });
                                    }}
                                >{json['page-socket-0002'] || '立即查看'}
                                </span>
                            ) : <span />,
                            groupOperation: true,
                            groupOperationMsg: [resMessage.msg],
                        });

                        //存储当前页面多条saga返回数据,解决CLOUD-182009
                        this.saga_popInfo[this.gtxid] = {
                            billpk: this.billpk,
                            billtype: this.billtype,
                        }
                    }
                }
                else {
                    // 只是给测试统计跨服务事务效率用的，先单元测试，去掉
                    // isShowToast && toast({
                    //     // mark: 'socketErrorToast',
                    //     color: 'success',
                    //     title: json['page-socket-0003'] || '跨服务事务处理成功！'
                    // });
                }
            }

            // 重试或者回退成功的消息提示
            if (hint_mesg) {
                // 清除一进入页面就出现的提示
                console.log('socketErrorToast' + this.gtxid);
                clearToast('socketErrorToast');
                clearToast('socketErrorToast' + this.gtxid);
                if (!resMessage.error) { //成功的消息提示
                    // clearToast('socketErrorToast'); // 清除一进入页面就出现的提示
                    isShowToast && toast({
                        color: 'success',
                        content: resMessage.msg,
                    });
                } else { // 失败的消息提示
                    isShowToast && toast({
                        color: 'danger',
                        content: resMessage.msg,
                    });
                }
            }

        }
        // 消息和交互分開
        if (data.targetids.includes(userid + ds) && resMessage.tabid === this.tabid) {
            //调用业务组注册的回调
            if (typeof onMessage === 'function') {
                // 错误中心，重试或者回退成功的提示，无法获取到，因为重试或者回退成功，龙哥给的tabid不对(始终是第一次重试的tabid)
                onMessage({ ...this.props, ...this.output }, resMessage);
            }

            if (typeof window.top.onSocketMessage === 'function') {
                window.top.onSocketMessage(resMessage);
            }
        }
    }

    // 异常关闭时，发起重连
    reconnect = () => {
        if (this.lockReconnect) {
            return;
        }
        if (this.count <= 0) {
            toast({
                color: 'error',
                title: this.state.json['page-socket-0004'] || 'Socket重连已达次数上限,请联系管理员或者刷新页面重试！',
            });
            return;
        }
        this.lockReconnect = true;
        this.count--;
        console.log(this.count);
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(() => {
            console.log('websocket发起重连');
            this.createWebSocket();
            this.lockReconnect = false;
        }, Timeout);
    }

    // 创建连接时间戳
    randomNumber = () => {
        const now = new Date();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        let hour = now.getHours();
        let minutes = now.getMinutes();
        let seconds = now.getSeconds();
        return now.getFullYear().toString() + month.toString() + day + hour + minutes + seconds + (Math.round(getSafeRandom() * 23 + 100)).toString();
    }

    render() {
        let { errorViewType = true, // 是否显示 单据追溯
        } = this.props;
        errorViewType = top && top.SocketConfig && top.SocketConfig[this.gtxid] ? top.SocketConfig[this.gtxid] : errorViewType;

        return errorViewType === 'none' ? null : (
            <BillErrorTrack
                show={this.state.showBillTrack}
                close={() => {
                    this.setState({ showBillTrack: false });
                }}
                pk={this.billpk} //单据id
                type={this.billtype} //单据类型
                gtxid={this.gtxid} //错误id
                errorViewType={errorViewType}
            // bodyClientHeight="500" //在第二层iframe里不用配置，如果iframe层更多，需要传一下body的高度
            />
        );
    }
}
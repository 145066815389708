import React, { Component } from 'react';
import { Tooltip, Transfer, Button, Icon } from '@platform/base';
import classNames from 'classnames';
import { getMultiLang, getSysFieldid } from '@platform/api';
require('./index.less');

export default class ListToListTransfer extends Component {
    constructor() {
        super();
        this.state = {
            serachKeyword: '', //搜索框的查询关键词
            upDisabled: true, //上移和置顶按钮的禁用状态
            downDisabled: true, //下移和置底按钮的禁用状态
            targetSelectedKeys: [], //目标框中选中的元素的key集合
            // 多语
            LangData: null,
            json: {},
        };
    }
    componentWillMount() {
        // 初始化调用getPlatformLang方法获取多语
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData }, () => {
                //  在这里进行之前组件willMount的操作，比如加载缓存之类的
            })
        }
        getMultiLang({ moduleId: 'container_transfer', callback }) // moduleId为所需加载json文件夹名称前缀
    }
    // 点击向左向右穿梭按钮
    handleTransfer = (targetKeys, direction, moveKeys) => {
        if (direction === 'right') {
            // 让新移动到右边的项添加到右边已有项的底部
            targetKeys = this.props.targetKeys.concat(moveKeys);
        }
        this.props.onTargetKeysChange(targetKeys);
    };

    // 列表项选中状态切换
    onCheck = (sourceSelectedKeys, targetSelectedKeys) => {
        this.setState({ targetSelectedKeys }, this.changeDisabled);
    };

    // 上移下移和置顶置底按钮禁用状态切换
    changeDisabled = (targetKeys = this.props.targetKeys) => {
        // 找到选中的targetSelectedKeys中每一项在targetKeys 对应的索引,组成indexAry
        // 对indexAry从小到大排序, 如果相邻两项的差都为1, 说明选中的是连续的节点, 否则不不连续
        let { targetSelectedKeys } = this.state;
        let indexAry = targetSelectedKeys.map(item => targetKeys.indexOf(item)).sort((a, b) => a - b);
        let continuous = () => {
            if (indexAry.length > 1) {
                for (let i = 0; i < indexAry.length - 1; i++) {
                    if (indexAry[i + 1] - indexAry[i] != 1) {
                        return false;
                    }
                }
            } else if (indexAry.length == 0) {
                return false;
            }
            return true;
        };

        let continuousFlag = continuous();
        // console.log(indexAry, continuousFlag, 99);
        if (continuousFlag) {
            // 选中的是连续的
            // 选中的包含了目标框中的第一个元素，不能上移
            if (indexAry[0] === 0) {
                this.setState({ upDisabled: true });
            } else {
                this.setState({ upDisabled: false });
            }

            // 选中的包含了目标框中的最后一个元素，不能下移
            if (indexAry[indexAry.length - 1] === targetKeys.length - 1) {
                this.setState({ downDisabled: true });
            } else {
                this.setState({ downDisabled: false });
            }
        } else {
            this.setState({
                upDisabled: true,
                downDisabled: true,
            });
        }
    };

    // 点击上移和下移按钮
    handleMove = direction => {
        let { targetKeys } = this.props;
        let { targetSelectedKeys } = this.state;
        let temp = JSON.parse(JSON.stringify(targetKeys));
        let indexAry = targetSelectedKeys.map(item => targetKeys.indexOf(item)).sort((a, b) => a - b);
        let before = targetKeys.slice(0, indexAry[0]);
        let selected = targetSelectedKeys;
        let after = targetKeys.slice(indexAry[indexAry.length - 1] + 1, targetKeys.length);
        switch (direction) {
            case 'up':
                if (before.length > 0) {
                    let lastBeforeItem = before[before.length - 1];
                    before.splice(before.length - 1, 1);
                    temp = [...before, ...selected, lastBeforeItem, ...after];
                }
                break;
            case 'down':
                if (after.length > 0) {
                    let firstAfterItem = after[0];
                    after.splice(0, 1);
                    temp = [...before, firstAfterItem, ...selected, ...after];
                }
                break;
            case 'top':
                temp = [...selected, ...before, ...after];
                break;
            case 'bottom':
                temp = [...before, ...after, ...selected];
                break;
            default:
                break;
        }
        this.handleTransfer(temp);
        this.getRightSelectEle()
        this.changeDisabled(temp);
    };

    // 按关键词搜索过滤已有列表项展示匹配到的内容
    handleFilterOption = (inputValue, option) => {
        return option.title.indexOf(inputValue) > -1;
    };

    // 获取右侧第一个选中的元素 input聚焦 滚动到视口区域 
    getRightSelectEle = () => {
        let el = document.querySelector('li.u-transfer-list-content-item > label.is-checked > input[type="checkbox"]')
        setTimeout(() => {
            el && el.focus()
        }, 20)
    }
    render() {
        let {
            className,
            dataSource,
            targetKeys,
            showSearch,
            listRender,
            titles,
            operations,
            searchPlaceholder,
            notFoundContent,
            showMoveBtn,
        } = this.props;
        titles = titles || [this.state.json['container-transfer-0001'], this.state.json['container-transfer-0002']]
        notFoundContent = notFoundContent || this.state.json['container-transfer-0003']
        searchPlaceholder = searchPlaceholder || this.state.json['container-transfer-0004']
        let { upDisabled, downDisabled } = this.state;
        const transferClass = classNames({
            'transfer-wrap': true,
            [className]: !!className, // 接收传进来的 class 名
        });
        return (
            <div className="list-to-list">
                <div className={transferClass} fieldid={getSysFieldid('transfer_area')}>
                    <Transfer
                        lazy={this.props.lazy}
                        dataSource={dataSource}
                        targetKeys={targetKeys}
                        onChange={this.handleTransfer}
                        onSelectChange={this.onCheck}
                        filterOption={this.handleFilterOption}
                        showSearch={showSearch}
                        render={listRender}
                        titles={titles}
                        operations={operations}
                        searchPlaceholder={searchPlaceholder}
                        notFoundContent={notFoundContent}
                    />
                    {showMoveBtn && (
                        <div className="move-btns">
                            {upDisabled ? <Button
                                className="move-btn move-top"
                                disabled={upDisabled}
                                onClick={() => this.handleMove('top')}
                            >
                                <Icon type="uf uf-2arrow-up"></Icon>
                            </Button> : <Tooltip
                                placement="top"
                                overlay={this.state.json['container-transfer-up-top']}
                                delayHide={1}
                                delayShow={1000}
                            >
                                <Button
                                    className="move-btn move-top"
                                    disabled={upDisabled}
                                    onClick={() => this.handleMove('top')}
                                >
                                    <Icon type="uf uf-2arrow-up"></Icon>
                                </Button>
                            </Tooltip>}
                            {upDisabled ? <Button
                                className="move-btn move-up"
                                disabled={upDisabled}
                                onClick={() => this.handleMove('up')}
                            >
                                <Icon type="uf uf-arrow-up"></Icon>
                            </Button> : <Tooltip
                                placement="top"
                                overlay={this.state.json['container-transfer-up']}
                                delayHide={1}
                                delayShow={1000}
                            >
                                <Button
                                    className="move-btn move-up"
                                    disabled={upDisabled}
                                    onClick={() => this.handleMove('up')}
                                >
                                    <Icon type="uf uf-arrow-up"></Icon>
                                </Button>
                            </Tooltip>}
                            {downDisabled ? <Button
                                className="move-btn move-down"
                                disabled={downDisabled}
                                onClick={() => this.handleMove('down')}
                            >
                                <Icon type="uf uf-arrow-down"></Icon>
                            </Button> : <Tooltip
                                placement="top"
                                overlay={this.state.json['container-transfer-down']}
                                delayHide={1}
                                delayShow={1000}
                            >
                                <Button
                                    className="move-btn move-down"
                                    disabled={downDisabled}
                                    onClick={() => this.handleMove('down')}
                                >
                                    <Icon type="uf uf-arrow-down"></Icon>
                                </Button>
                            </Tooltip>}
                            {downDisabled ? <Button
                                className="move-btn move-bottom"
                                disabled={downDisabled}
                                onClick={() => this.handleMove('bottom')}
                            >
                                <Icon type="uf uf-2arrow-down"></Icon>
                            </Button> : <Tooltip
                                placement="top"
                                overlay={this.state.json['container-transfer-down-bottom']}
                                delayHide={1}
                                delayShow={1000}
                            >
                                <Button
                                    className="move-btn move-bottom"
                                    disabled={downDisabled}
                                    onClick={() => this.handleMove('bottom')}
                                >
                                    <Icon type="uf uf-2arrow-down"></Icon>
                                </Button>
                            </Tooltip>}
                        </div>
                    )}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

ListToListTransfer.defaultProps = {
    showSearch: false, // 是否显示搜索框
    showMoveBtn: false, //是否显示上移下移，置顶置底按钮
    // titles: [ '来源', '目标' ], // 两侧框的顶部标题
    operations: ['', ''], // 穿梭按钮的文字
    // searchPlaceholder: '请输入内容', // 搜索框的提示文字
    // notFoundContent: '暂无数据', // 列表为空时显示的内容
    dataSource: [], // 数据源
    targetKeys: [], // 目标框数据的 keys
    listRender: ({ title }) => title, // 列表渲染的字段内容
};

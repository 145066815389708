/**
 * Created by wangshhj on 2018/3/13.
 */

export function show(id, config = {}, showCallback) {
    if (!this.state.modal.hasOwnProperty(id)) {
        console.error('需要先初始化createModal');
        return false;
    }
    let modalData = this.state.modal[id];
    modalData = Object.assign(modalData, config);
    modalData.showModal = true;
    if (config.hasOwnProperty('content') || config.hasOwnProperty('customBtns')) {
        modalData.update = true;
    }
    this.setState({ modal: this.state.modal }, () => {
        if (showCallback && (typeof showCallback === 'function')) showCallback();
    });
}

export function close(id) {
    this.state.modal[id].showModal = false;
    // this.state.modal[id].userControl = false;
    this.setState({ modal: this.state.modal });
}
/*
 * @Author: hufei 
 * @PageInfo: 两种穿梭框合并后的外壳
 * @Date: 2018-04-13 15:38:49 
 * @Last Modified by: hufei
 * @Last Modified time: 2018-06-07 17:11:46
 */
import React, { Component } from 'react';
import ListToListTransfer from './ListToListTransfer';
import TreeToListTransfer from './TreeToListTransfer';
export default class Transfer extends Component {
	render() {
		let { treeType, ...others } = this.props;
		return treeType ? <TreeToListTransfer {...others} /> : <ListToListTransfer {...others} />;
	}
}

/*
 * @Author: liyxt
 * @Date: 2019-10-21 14:15:59
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-04 11:03:43
 * @Description: file content
 */
import React from 'react';
require('./index.less');
import CardPagination from './CardPag_test';

//创建卡片分页组件
export function createCardPagination(events) {
    return (
        <CardPagination
            getThis={_this => {
                this.myCardPagination = _this;
            }}
            events={events}
            output={this.output}
            rootprops={this.props}
            getUrlParam={this.getUrlParam}
        />
    );
}


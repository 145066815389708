/*
 * @Author: liyxt
 * @Date: 2019-10-21 14:15:59
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-03 14:54:19
 * @Description: file content
 */ 
/**
 * Created by zhouxini on 2018/7/19.
 */
import React from 'react';

export default function createUploader(
    id,
    {
        billId, // 单据主键
        billNo, // 单据编码，显示在左侧
        target, // 点击的目标element DOM 【可选】（配合placement使用)
        placement, // 上传控件相对弹窗的位置 String 【可选】（配合target使用）
        beforeUpload, // 上传前事件 Function 【可选】返回值Boolean
        size, // 上传质量大小限制 Number 【可选】
        accept, // 上传文件类型 String 【可选】
        upData, // 上传时用户自定义参数Object 【可选】
        multiple, // 是否支持批量上传 Boolean 【可选】 默认值为false，不支持
        beforeDelete, // 删除前事件 Function 【可选】返回值Boolean
        onHide, // 点击关闭的钩子函数 Function 【可选】
        ...others
    } = {},
) {
    if (!this.state.uploader.hasOwnProperty(id)) {
        this.state.uploader[id] = {
            billId,
            billNo,
            target,
            placement,
            beforeUpload,
            size,
            accept,
            upData,
            multiple,
            beforeDelete,
            onHide,
            showUploader: false,
        };
    }
    let uploaderData = this.state.uploader[id];

    // 上传前事件
    let beforeUploadFun = null;

    if (typeof uploaderData.beforeUpload === 'function') {
        beforeUploadFun = uploaderData.beforeUpload.bind(this);
    }

    // 删除前事件
    let beforeDeleteFun = null;
    if (typeof uploaderData.beforeDelete === 'function') {
        beforeDeleteFun = uploaderData.beforeDelete.bind(this);
    }

    let onHideFun = () => {
        uploaderData.showUploader = false;
        this.setState({ uploader: this.state.uploader })
        if (typeof uploaderData.onHide === 'function') {
            uploaderData.onHide.call(this);
        }
    }

    return uploaderData.showUploader ? (
        <Uploader
            billId={uploaderData.billId}
            billNo={uploaderData.billNo}
            target={uploaderData.target}
            placement={uploaderData.placement}
            beforeUpload={beforeUploadFun}
            size={uploaderData.size}
            accept={uploaderData.accept}
            upData={uploaderData.upData}
            multiple={uploaderData.multiple}
            beforeDelete={beforeDeleteFun}
            onHide={onHideFun}
            {...others}
        />
    ) : null;
}

/**
 * Created by wangshhj on 2018/3/13.
 */
import React, { Component } from 'react';
import {
    Modal,
    Button,
    HotKeys,
    Tooltip,
} from '@platform/base';
import { getMultiLang } from '@platform/api';

require('./createModal.less');

class NCModal extends Component {
    constructor(props) {
        super(props);
        this.state = { json: {} };
    }

    componentWillMount() {
        let callback = (json, bool) => {
            if (bool) {
                this.setState({ json }, () => {
                    //console.log('多语加载完成')
                });
            }
        };
        getMultiLang({ moduleId: 'container_toast', callback });
    }

    getTitle = (title, color) => {
        let { json } = this.state;
        if (!title && color) {
            if (color === 'success') {
                title = json['0001'];
            } else if (color === 'info') {
                title = json['0002'];
            } else if (color === 'warning') {
                title = json['0003'];
            } else if (color === 'danger') {
                title = json['0004'];
            }
        }
        return title;
    };

    render() {
        let { json } = this.state;
        let {
            modalData,
            close,
            size,
            className,
            getClassName,
            hasCloseBtn,
            backdrop,
            indexInfo,
            beSureClick,
            cancelClick,
            noFooter,
            showCustomBtns,
            customBtns,
            hotKeyboard = true,
            ...others
        } = this.props;
        let {
            leftBtnName = json['0005'],
            rightBtnName = json['0006'],
            color,
            title,
            content,
        } = modalData;
        return (
            <Modal
                show={modalData.showModal}
                onHide={close.bind(this)}
                size={size}
                className={className}
                backdrop={backdrop}
                indexInfo={indexInfo}
                // draggable={false}
                // resizable={false}
                {...others}
                ref={Modal => (this.Modal = Modal)}
            >
                {hotKeyboard && !noFooter && (
                    <HotKeys
                        keyMap={{
                            sureBtnHandler:
                                HotKeys.USUAL_KEYS.MODAL_CONFIRM,
                            cancelBtnHandler:
                                HotKeys.USUAL_KEYS.MODAL_CALCEL,
                        }}
                        handlers={{
                            sureBtnHandler: () => {
                                // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                                if (this.Modal && this.Modal.isTopModal()) {
                                    console.log(
                                        'createModal sureBtnHandler 事件回调',
                                    );
                                    beSureClick.bind(this)();
                                }
                            },
                            cancelBtnHandler: () => {
                                // 取消按钮的事件  增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                                if (this.Modal && this.Modal.isTopModal()) {
                                    console.log(
                                        'createModal cancelBtnHandler 事件回调',
                                    );
                                    cancelClick.bind(this)();
                                }
                            },
                        }}
                        className="ncmodel-hotkeys-wrapper"
                        focused={true}
                        attach={document.body}
                        display="inline"
                    />
                )}
                <Modal.Header fieldid="header-area" closeButton={!!hasCloseBtn}>
                    <span>{json['0007']}</span>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title fieldid={`${json['0007']}`}>
                        {getClassName(color)} {this.getTitle(title, color)}
                    </Modal.Title>
                    <div className="createModal-body-content">{content}</div>
                </Modal.Body>

                {
                    !noFooter ? (
                        showCustomBtns ? (
                            <Modal.Footer>
                                {showCustomBtns && customBtns ? customBtns : null}
                            </Modal.Footer>
                        ) : (
                            < Modal.Footer >
                                <Tooltip
                                    placement="top"
                                    inverse
                                    overlay={`${leftBtnName}  (${
                                        HotKeys.USUAL_KEYS.MODAL_CONFIRM
                                    })`}
                                    trigger={['hover', 'focus']}
                                    className="model-helper-overlay"
                                >
                                    <Button
                                        fieldid="confirm"
                                        onClick={beSureClick.bind(this)}
                                        colors="primary"
                                        tabIndex="0"
                                    >
                                        {leftBtnName}(<span className="text-decoration-underline">Y </span>)
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    inverse
                                    overlay={`${rightBtnName}  (${
                                        HotKeys.USUAL_KEYS.MODAL_CALCEL
                                    })`}
                                    trigger={['focus', 'hover']}
                                    className="model-helper-overlay"
                                >
                                    <Button
                                        fieldid="cancel"
                                        onClick={cancelClick.bind(this)}
                                        shape="border"
                                        tabIndex="0"
                                    >
                                        {rightBtnName}(<span className="text-decoration-underline">N</span>)
                                    </Button>
                                </Tooltip>
                            </Modal.Footer>
                        ))
                        : null
                }
            </Modal>
        );
    }
}
export default function createModal(
    id,
    {
        title, //标题
        content, //内容
        beSureBtnClick, //确定按钮事件回调
        cancelBtnClick, //取消按钮事件回调
        leftBtnName, //左侧按钮名称
        rightBtnName, //右侧按钮名称
        noFooter = false, //是否需要底部按钮
        size, //  模态框大小 sm/lg/xlg
        color = 'warning',
        hasCloseBtn = false,
        closeByClickBackDrop = false,
        zIndex = 200,
        // draggable=false,
        // resizable=false,
        ...others
    } = {},
) {
    if (!this.state.modal.hasOwnProperty(id)) {
        this.state.modal[id] = {
            color,
            showModal: false,
            title,
            content,
            beSureBtnClick,
            cancelBtnClick,
            leftBtnName,
            rightBtnName,
        };
    }
    let modalData = this.state.modal[id];

    // 按钮class
    let getClassName = color => {
        let iconName;
        //   let iconName = 'icon-wancheng';
        if (color === 'danger') {
            iconName = 'icon-shibai';
        } else if (color === 'warning') {
            iconName = 'icon-zhuyi1';
        } else if (color === 'info') {
            iconName = 'icon-tixing';
        } else if (color === 'success') {
            iconName = 'icon-wancheng';
        } else {
            iconName = 'icon-warning';
        }
        let name = `iconfont ${iconName} ${color}`;
        return <span className={name}> </span>;
    };

    //关闭模态框
    let close = () => {
        modalData.showModal = false;
        this.setState({ modal: this.state.modal });
    };

    /* 取消按钮事件 */
    let cancelClick = () => {
        close();
        if (typeof modalData.cancelBtnClick === 'function') {
            modalData.cancelBtnClick.call(this);
        }
    };

    //确认按钮事件
    let beSureClick = () => {
        close();
        if (typeof modalData.beSureBtnClick === 'function') {
            modalData.beSureBtnClick.call(this);
        }
    };
    let indexInfo = { id, zIndex };

    return (
        <NCModal
            fieldid={id}
            modalData={modalData}
            hasCloseBtn={hasCloseBtn}
            beSureClick={beSureClick}
            cancelClick={cancelClick}
            getClassName={getClassName}
            noFooter={noFooter}
            close={close}
            size={size}
            // draggable={draggable}
            // resizable={resizable}
            className={`${id} NC_confirmModal createNcModal`}
            backdrop={closeByClickBackDrop ? true : 'static'}
            indexInfo={indexInfo}
            {...others}
        />
    );
}

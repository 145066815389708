import React, { Component } from 'react';
import classNames from 'classnames';
import {
    Dropdown as Dropdown,
    Button as Button,
} from '@platform/base';
import { RenderMenu } from '../MenuUtil';

require('./index.less');

// querySelector  不能識別數字開頭 不能識別特殊字符 ·/·
const replaceSpecialStr = function (str) {
    return str.replace(/\/|\\|,/g, '') || str;
};
export default class MyDropdown extends Component {
    constructor() {
        super();
        this.state = {
            visible: false, // 下拉菜单是否显示
        };
    }

    onClick = info => {
        let { dividerBtn } = this.props;
        let key = info.key;
        let title = info.item.props.children;
        this.props.onClick({ key, title });
        this.setState({ visible: false }, () => {
            //点击下拉中的按钮，使分割按钮聚焦,解决物料集团节点，点击分割按钮弹出modal，关闭modal之后滚动到最上方问题
            if (dividerBtn) {
                let btnRight = dividerBtn.querySelector('.btn-right');
                btnRight.focus();
            }
        });
    };

    onVisibleChange = visible => {
        let { onVisibleChange } = this.props;
        this.setState({ visible });
        onVisibleChange && onVisibleChange(visible);
    };

    render() {
        let {
            list,
            title,
            disabled,
            btncolor,
            popContainer,
            area,
            dropdownType,
            tabindex = 0,
            clskey,
        } = this.props;
        // 整个下拉的可见性取决于:1.是否曾手动将下拉list的visible设为false,2.list下的分组是否都隐藏
        // 分组的可见性取决于:1.是否曾手动将分组的visible设为false,2.分组里面的按钮是否都隐藏
        list.forEach(item => {
            // 分组的可见性
            if (item.visible !== false) {
                let visible_group = !item.children.every(
                    ele => ele.visible === false,
                );
                if (item.children.length == 0) {
                    visible_group = false;
                }
                item.visible = visible_group;
            }
            // 添加一个topParentCode 解决找不到上传class的问题 bbqin
            item.children.forEach(el => {
                el.topParentCode = item.parentCode || el.parentCode;
            });
        });
        // 整个下拉的可见性
        let visible_list = list.visible;
        if (visible_list !== false) {
            visible_list = !list.every(item => item.visible === false);
        }
        // 当下拉中的按钮都隐藏时或者下拉中本来就没有按钮时,整个下拉按钮也隐藏掉
        if (list.length === 0) {
            visible_list = false;
        }
        let dropdownBtn = () => {
            if (this.props.children) {
                return <span>{this.props.children}</span>;
            } else {
                return (
                    <Button
                        fieldid={clskey}
                        btn-code={clskey}
                        disabled={disabled}
                        className={classNames({
                            'dropdown-component': title,
                            'dropdown-arrow': !title,
                            'my-dropdown-buttons-main-wrapper': dropdownType,
                            'button-primary': btncolor == 'button_main',
                        })}
                        tabindex={tabindex}
                    >
                        {title}
                        {this.state.visible ? (
                            <i
                                className={classNames(
                                    'arrow iconfont icon-hangcaozuoxiangshang1',
                                    replaceSpecialStr(` nc-${area}-${clskey}-icon `),
                                    {
                                        'button-primary':
                                            btncolor == 'button_main',
                                    },
                                )}
                            />
                        ) : (
                            <i
                                className={classNames(
                                    'arrow iconfont icon-hangcaozuoxiala1',
                                    replaceSpecialStr(` nc-${area}-${clskey}-icon `),
                                    {
                                        'button-primary':
                                                btncolor == 'button_main',
                                    },
                                )}
                            />
                        )}
                    </Button>
                );
            }
        };

        return visible_list !== false ? (
            <Dropdown
                trigger={['click']}
                // overlay={renderMenu.call(this, list, undefined, tabindex)}
                overlay={(
                    <RenderMenu
                        data={list}
                        tabindex={tabindex}
                        onClick={this.onClick}
                        onVisibleChange={this.onVisibleChange}
                        visible={this.state.visible}
                    />
                )}
                animation="slide-up"
                onVisibleChange={this.onVisibleChange}
                overlayClassName={classNames(
                    'dropdown-component-list-wrapper',
                    this.props.className,
                )}
                getPopupContainer={() => {
                    // let doc = document.querySelector(`.${area}-button-app-wrapper`)
                    // return popContainer?popContainer:doc? doc: document.body
                    return popContainer || document.body;
                }}
            >
                {dropdownBtn()}
            </Dropdown>
        ) : null;
    }
}

/**
 * 预警消息查询 树显示不正确 是由于业务根节点 isleaf是true导致
 * 账户期初余额  所有层级数据里都没有key
 * 计划周期  根节点给了 pid 没给key  子节点没有pid
 * 设置： 根节点pid=refpk  没有key   tinper用的是key 业务用的唯一标识是refpk 看tinper能否使用refpk作为唯一标识
 * 查询区---图标显示问题------------平台问题
 * 交易类型管理-无法搜索 多跟树 无pid 搜索---------平台问题
 * 单据类型 出库调整单 搜索不到 业务的pid 重复
 */
import { isArray, isObj, isFunction } from '@platform/api';
import useTree from '../store';
import * as TreeUtils from '../util';
import Tree from '../Tree';

/**
 * 检测组件对象是否存在 
 * @param {*} id 
 */
const existenceCheck = function (id) {
    let store = this.syncTreeDataSource[id]
    if (!store) {
        store = useTree();
        this.syncTreeDataSource[id] = store;
        console.warn(`所操作的表格中无ID为${id}的数据`);
        return;
    }
    return store;
}



// 创建组件
export function createSyncTree(config = {}) {
    let newConfig = Object.assign({}, config);
    let { treeId, expandWhenDoubleClick = true, onDoubleClickEve, delNodeCallBack, showModal= true, metaId, ...others } = newConfig;
    // this.register("treeTable"); // 生成 this.treeTableDataSource[id]
    let pageScope = this,
        store = pageScope.syncTreeDataSource[treeId];
    if (!store) {
        store = useTree();
        pageScope.syncTreeDataSource[treeId] = store;
    }

    if (!pageScope.formDataSource[metaId] && showModal) {
        pageScope.use.form(metaId);
    }
    return <Tree
        key={treeId}
        meta={this.state.meta}
        store={store}
        treeId={treeId}
        onDoubleClickEve={onDoubleClickEve}
        expandWhenDoubleClick={expandWhenDoubleClick}
        delNodeCallBack={(currentNode) => {
            if (isFunction(delNodeCallBack)) {
                delNodeCallBack(currentNode.refpk);
            }
        }}
        formStore={pageScope.formDataSource[metaId]}
        showModal={showModal}
        metaId={metaId}
        {...others}
        pageOutput={pageScope.output}
        treeType={'syncTree'}
    />
}

//获取搜索值
export function getSyncTreeSearchVal(id){
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    return store.getStore('searchValue')
}

// 设置树 数据
export function setSyncTreeData(id, treeData, iconBoxData,resetSearchValue = true) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.setStore('firstTime', true)
    if(resetSearchValue){
     store.setStore('searchValue', '')
    }


    if (!isArray(treeData)) {
        console.warn('setSyncTreeData方法data参数为数组');
        return false;
    }

    !store.getCache('userControlIcon') && TreeUtils.addIconBox(treeData);
    store.setStore('treeData', [...treeData]);
    store.setStore('expandedKeys', []);
    store.setStore('selectedKeys', []);
    store.setCache('deepData', [...treeData]);

    //如果有搜索值，需要过滤数据
    let searchValue = store.getStore("searchValue");
    if(searchValue){
        store.searchChange(searchValue,false);
    }

    // 对功能区按钮初始化
    if (iconBoxData) {
        if (Object.prototype.toString.call(iconBoxData) === '[object Array]') {
            setIconVisible.call(this, id, iconBoxData);
        } else {
            console.error('iconBoxData is not a Array');
        }
    }
    let selectedForInit = store.getListener('selectedForInit');
    let onSelectEve = store.getListener('onSelectEve');
    // 进行默认选中处理
    if (treeData && treeData.length != 0 && selectedForInit) {
        let dataLength = treeData.length;
        let firstRoot = treeData[0];
        let selectedNode;
        // 如果多根，选中第一个根节点
        if (dataLength > 1) {
            selectedNode = firstRoot;
        }
        // 如果单根，首先查看单根是否为实根，如果不是选中并且展开第一个子节点
        if (dataLength == 1) {
            // 有实根的情况
            if (firstRoot.needSelect) {
                selectedNode = firstRoot;
            } else {
                // 虚根情况
                if (firstRoot.children && firstRoot.children != 0) {
                    selectedNode = firstRoot.children[0];
                } else {
                    selectedNode = firstRoot;
                }
            }
            store.setStore('expandedKeys', [firstRoot.refpk]);
        }

        //当前选中的树节点
        store.setCache('selectedNode', TreeUtils.getItem(treeData, selectedNode.refpk));

        //获取选中的
        store.setStore('selectedKeys', [selectedNode.refpk]);

        if (isFunction(selectedForInit)) {
            selectedForInit(selectedNode.refpk, selectedNode, true);
        } else {
            if (isFunction(onSelectEve)) {
                onSelectEve(selectedNode.refpk, selectedNode, true);
            }
        }
    }

    store.setStateEve();
    let onSelectedChange = store.getListener('onSelectedChange');
    if (isFunction(onSelectedChange)) {
        onSelectedChange(null);
    }
}

// 只设置树 数据
export function updateTreeData(id, treeData) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    
    if (!isArray(treeData)) {
        console.warn('setSyncTreeData方法data参数为数组');
        return false;
    }

    store.setStore('treeData', [...treeData]);
    store.setCache('deepData', [...treeData]);

    store.setStateEve();
}

// 编辑成功调用方法
export function editNodeSuccess(id, newItem) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.editNodeSuccess(newItem);
}

// 编辑禁用函数
export function handleEidAddDisbale(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.setCache('disabledSearch', true)
    store.setStore('noDisableId', pk)
    store.setStore('selectedKeys', [pk])
    setNodeDisable(id, true);
    store.setStateEve();
}

// 还原状态
export function handleEidAddNoDisbale(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    setNodeDisable(id, false);
    store.setCache('disabledSearch', false)
    store.setStore('selectedKeys', [store.getStore('noDisableId')]);
    store.setStore('noDisableId', '')
    store.setStateEve();
}

// 新增节点成功调用方法
export function addNodeSuccess(id, data, isBefore) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }

    let treeData = store.getStore('treeData');
    let deepData = store.getCache('deepData');
    let userControlIcon = store.getCache('userControlIcon');

    let list = TreeUtils.treeToList(treeData);
    treeData = TreeUtils.createTreeData(list) || [];

    if (isArray(data)) {
        !userControlIcon && TreeUtils.addIconBox(data);
        data.forEach(item => {
            TreeUtils.addNodeFun(treeData, item, isBefore);

            // if (thisTree.searchValue.length != 0) {
            TreeUtils.addNodeFun(deepData, item, isBefore);
            // }
        });
    } else {
        !userControlIcon && TreeUtils.addIconBox([data]);
        TreeUtils.addNodeFun(treeData, data, isBefore);

        // if (thisTree.searchValue.length != 0) {
        TreeUtils.addNodeFun(deepData, data, isBefore);
        // }
    }

    store.setStore('treeData', [...treeData])
    store.setStore('firstTime', true)
    store.setStateEve();
}

// 获取树结构拍平数组
export function getTreeToList(id){
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let treeData = store.getStore('treeData');
    return TreeUtils.treeToList(treeData) || [];
}

// 删除节点成功调用
export function delNodeSuceess(id, pk, noneChangeLeaf = false) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.delNodeSuceess(pk, noneChangeLeaf);
}

//  获取树某个节点或整个树信息
export function getSyncTreeValue(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let deepData = store.getCache('deepData');
    // 更改获取节点数据
    return TreeUtils.getItem(deepData, pk);
}

/*
 *   设置某个节点或整个树不可点击
 *   disable:true 不可用； false:可用
 *   pk:不传，设置整个树不可用。
 * */
export function setNodeDisable(id, disable, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let treeData = store.getStore('treeData');
    let deepData = store.getCache('deepData');

    TreeUtils.setNodeDisableEve(treeData, disable, pk);
    // 同步deepData禁用状态
    if (deepData) {
        TreeUtils.setNodeDisableEve(deepData, disable, pk);
    }
    // 如果是整体禁用模式，则禁用快捷键功能
    if (!pk) {
        if (disable) {
            // 全被禁用
            store.setStore('focusable', false);
        } else {
            store.setStore('focusable', true);
        }
    }
    store.setStore('treeData', [...treeData])
    store.setStateEve();
}

/*
 *   设置树的编辑性
 *   @ status: true/false
 * */
export function setTreeEdit(id, status) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let needEdit = store.getStore('needEdit');

    if (needEdit !== status) {
        let onTreeStatusChange = store.getListener('onTreeStatusChange');
        if (onTreeStatusChange) {
            onTreeStatusChange();
        }
    }
    store.setStore('needEdit', status);
    store.setStateEve();
}

/*
 *   获取当前选中节点信息
 * */
export function getSelectNode(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    return store.getCache('selectedNode');
}
/*
 *   展开节点方法
 * */
export function openNodeByPk(id, pk, autoExpandParent = true) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let expandedKeys = store.getStore('expandedKeys');

    if (Object.prototype.toString.call(pk) === '[object Array]') {
        expandedKeys.push(...pk);
    } else if (Object.prototype.toString.call(pk) === '[object String]') {
        if (expandedKeys.indexOf(pk) === -1) {
            expandedKeys.push(pk);
        }
    } else {
        console.warn('openNodeByPk方法，参数pk,类型为数组或字符串');
        return;
    }
    store.setStore('expandedKeys', [...expandedKeys]);
    store.setStore('autoExpandParent', autoExpandParent);
    store.setStateEve();
}

/*
 *   关闭节点方法
 * */
export function closeNodeByPk(id, key) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.closeNodeByPk(key);
}

/*
 *   生成树 需要的数据结构
 *   @ data: 数组
 * */
export function createTreeData(data, resetInfo) {
    return TreeUtils.createTreeData(data, resetInfo)
}

// 获取树的数据
export function getTreeData(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let treeData = store.getStore('treeData');
    return JSON.parse(JSON.stringify(treeData || [], (key, value) => {
        if (key == 'beforeName' || key == 'afterName') {
            return undefined;
        }
        return value;
    }));
}

/*
 *   设置图标显示隐藏
 *   @ key 节点key值
 *   @ data 对象，描述图标是否显示  示例：{ editIcon:true, delIcon:false, addIcon:false }
 *   true: 显示；  false：隐藏
 * */
export function hideIcon(id, key, data) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }

    store.hideIcon(key, data);
}

/*
 *   批量设置节点图标的显隐性
 *   @ data: 数组或对象
 * */
export function setIconVisible(id, data) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }

    let treeData = store.getStore('treeData');
    if (isObj(data)) {
        TreeUtils.hideIconEve(treeData, data.key, data.value);
    } else if (isArray(data)) {
        data.map(item => TreeUtils.hideIconEve(treeData, item.key, item.value));
    }
    store.setStateEve();
}

/*
 *   新增兄弟节点
 * */
export function addBrotherNode(id, data) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.addBrotherNode(data);
}

/*
 *   设置节点选中
 *   @ pk： 目前为字符串
 */
export function setNodeSelected(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.setNodeSelected(pk);
}

/*
 *   设置节点勾选
 *   @ pk： 目前为字符串
 */
export function setNodeChecked(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.setNodeChecked(pk);
}

/**
 *获取所有被勾选的数据 pks
 *
 * @export
 * @param {*} id
 * @returns
 */
export function getCheckedKeys(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    return store.getStore('checkedKeys');
}

/* 取消当前选中所有节点 */
export function cancelSelectedNode(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.cancelSelectedNode();
}

/* 设置叶子节点不可编辑/可编辑，父节点可以编辑  */
export function setLeafNodeEdit(id, status) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let treeData = store.getStore('treeData')
    TreeUtils.setLeafEdit(treeData, status);
    store.setStateEve();
}

/*
 *  移动节点 （先获取要移动的节点对象，删除该节点，将节点放到目标节点children中）
 *  @ pk: 要移动的节点pk
 *  @ targetPk: 目标节点pk
 * */
export function moveNode(id, pk, targetPk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let treeData = store.getStore('treeData')
    let thisNode = TreeUtils.getItem(treeData, pk);
    TreeUtils.delNode(treeData, pk);
    TreeUtils.moveNodeEve(treeData, thisNode, targetPk);
    store.setStateEve();
}

/* 获取拖拽的节点key */
export function getMoveNodeKey(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    };
    let treeData = store.getStore('treeData')
    let moveKey = store.getCache('moveKey')
    return TreeUtils.getItem(treeData, moveKey);
}
/* 获取hover的节点key */
export function getHoverNodeKey(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    };
    let treeData = store.getStore('treeData')
    let isHover = store.getCache('isHover')
    return TreeUtils.getItem(treeData, isHover);
}

/* 暴露搜索功能方法 */
export function getSearchChangeEven(value, treeId) {
    let store = existenceCheck.call(this, treeId);
    if (!store) {
        return;
    };
    if (Object.prototype.toString.call(value) !== '[object String]') {
        console.warn('搜索参数为字符串类型');
    }
    // let defSource;
    // try {
    //     defSource = store.getStore('treeData');
    // } catch (e) {
    //     console.warn(e);
    // }
    store.searchChange(value);
}

/**
 * @desc 设置树节点的图标
 * @param id {string} 区域Id
 * @param items {array} [{ key: 'refpk', icons: ['uf-search'] }]
 */
export function setTreeItemIcons({ id, items }) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    };
    let treeData = store.getStore('treeData')

    items.map(item => {
        setCustomerIcons({ treeData, item });
    });

    store.setStateEve();
}

/* 根据key获取子树 */
export function getItem(treeData, key) {
    if (!key) {
        return treeData;
    }
    let length = treeData.length;
    for (let i = 0; i < length; i++) {
        let item = treeData[i];
        if (item.key == key) {
            return item;
        } else if (Array.isArray(item.children)) {
            let res = getItem(item.children, key);
            if (res) {
                return res;
            }
        }
    }
};



/**
 * @desc 根据用户传入的items 遍历树节点，寻找到具体节点后在此节点中添加 customerIcons 字段
 * @param treeData {array}
 * @param item {object}
 */
function setCustomerIcons({ treeData, item }) {
    let i = treeData.length;
    while (i--) {
        let data = treeData[i];
        if (data.key === item.key) {
            data.customerIcons = item.icons;
            return true;
        } else {
            if (Array.isArray(data.children)) {
                let res = setCustomerIcons({ treeData: data.children, item });
                if (res) {
                    return false;
                }
            }
        }
    }
}

/**
 * @desc 打开添加树节点模态框，与createSyncTree 内部的 addRender方法相同
 * @param item {object} 树节点信息
 * @param e {object} 事件信息
 * @param treeId {string}
 */

export function showAddTreeItemModal({ item, e, treeId }) {
    let store = existenceCheck.call(this, treeId);
    if (!store) {
        return;
    };

    let showModal = store.getCache('showModal');
    let metaId = store.getCache('metaId');
    let clickAddIconEve = store.getListener('clickAddIconEve')

    let { setFormStatus, EmptyAllFormValue } = this.form;
    e.stopPropagation();
    setFormStatus(metaId, 'edit');
    store.setCache('currentNode', item);

    EmptyAllFormValue(metaId);
    if (showModal) {
        store.setStore('showEditModal', true);
        store.setCache('treeNodeOperation', 'add');
    }
    if (isFunction(clickAddIconEve)) {
        clickAddIconEve(item);
    }
    store.setStateEve();
}

/**
 * @desc 打开修改树节点模态框，与createSyncTree 内部的 editRender方法相同
 * @param item {object} 树节点信息
 * @param e {object} 事件信息
 * @param treeId {string}
 */

export function showEditTreeItemModal({ item, e, treeId }) {
    let store = existenceCheck.call(this, treeId);
    if (!store) {
        return;
    };

    let showModal = store.getCache('showModal')
    let metaId = store.getCache('metaId')
    let clickEditIconEve = store.getListener('clickEditIconEve')

    let { setFormStatus } = this.form;
    e.stopPropagation();
    store.setCache('currentNode', item);

    if (showModal) {
        setFormStatus(metaId, 'edit');
        store.setStore('showEditModal', true);
        store.setCache('treeNodeOperation', 'edit');
    }
    if (isFunction(clickEditIconEve)) {
        clickEditIconEve(item);
    }
    store.setStore('selectedKeys', [item.refpk])
    store.setStateEve();
}

/**
 * @desc 打开删除树节点模态框，与createSyncTree 内部的 delRender方法相同
 * @param item {object} 树节点信息
 * @param e {object} 事件信息
 * @param treeId {string}
 */

export function showDeleteTreeItemModal({ item, e, treeId }) {
    let store = existenceCheck.call(this, treeId);
    if (!store) {
        return;
    };

    let showModal = store.getCache('showModal')
    let clickDelIconEve = store.getListener('clickDelIconEve')
    e.stopPropagation();
    store.setCache('currentNode', item);
    if (showModal) {
        store.setStore('showEditModal', true);
        store.setCache('treeNodeOperation', 'del');
    }
    if (isFunction(clickDelIconEve)) {
        clickDelIconEve(item);
    }
}

export function updataDataSource(id, callBack) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    };
    let treeData = store.getStore('treeData')
    if (callBack) {
        let newDataSource = callBack(JSON.parse(JSON.stringify(treeData)));
        if (Array.isArray(newDataSource)) {
            store.getCache('deepData', newDataSource);
            store.setStore('treeData', newDataSource);
        }
        store.setStateEve();
    } else {
        console.warn('请传回调参数，并且返回新的数据源');
    }

}

/**
 * 更新某个树节点
 * @param {*} id  树id
 * @param {*} updataNode 将要更新的节点
 */
export function updataTreeNode(id, updataNode) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    };

    let treeData = store.getStore('treeData')
    let deepData = store.getCache('deepData')

    function findEditNode(nodes) {
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            if (node.refpk == updataNode.pid) {
                node.children = node.children.map(child => {
                    if (child.refpk == updataNode.refpk) return updataNode;
                    return child;
                })
                return true;
            }
        }
        nodes.map(node => {
            if (node.children) {
                findEditNode(node.children);
            }
        })
    }
    findEditNode(treeData);
    findEditNode(deepData);
}

export function clearSearchValue(id){
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    };

    let searchInput = store.getStore('searchInputRef');
    if(searchInput){
        searchInput.current.value = "";
    }
    store.setStateEve();
}

import React, { Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import { getSafeRandom } from '@platform/api'
import { getCheckedExtendMethod } from '../../FastExtend/utils';

import {
    Button as Button,
    Dropdown as Dropdown,
    Menu as Menu,
    Popconfirm,
    Upload as Upload,
    HotKeys,
    Tooltip as Tooltip,
} from '@platform/base';

import More from './Mores';
import MyDividerButton from './MyDividerButtons';
import MyButtonGroup from './MyButtonGroup';
import MyDropdown from './MyDropDown';
import { isObj, getLangCode, isFunction, isUndefined } from '@platform/api';
import { getMultiLang, ViewModel, ajax, pageTo, toast, getSysFieldid, debounce, throttle } from '@platform/api';
import { promptBox } from '../PromptBox';
import { OPERATION_UPLOAD_CLASSNAME } from './constants';

import HelperTip from './HelperTip';
import SignIn from './SignIn';

import { firstToUpperCase, triggerOnClickWithBlurAction } from './util';

require('./create.less');

const { Item } = Menu;

let { getGlobalStorage } = ViewModel;

// const IS_TEST_ENV = NODE_ENV === 'test';

/**
 * 展示帮助
 */
// const SHOW_HELPER = false;

// get nearest parent element matching selector   origin from stackoverflow  add by bbqin
const Closest = function (el, selector) {
    if (!el) {
        return null;
    }
    var matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

    while (el) {
        if (matchesSelector.call(el, selector)) {
            break;
        }
        el = el.parentElement;
    }
    return el;
};

// querySelector  不能識別數字開頭 不能識別特殊字符 ·/·
const replaceSpecialStr = function (str) {
    return str.replace(/\/|\\|,/g, '') || str;
};

const recurToKeyboardMap = function (tree, parentVisible, obj = {}) {
    tree.forEach(item => {
        if (item.key && item.keyboard) {
            let kb = firstToUpperCase(item.keyboard);
            obj[kb] = obj[kb] ? obj[kb] + 1 : 1;
        }
        if (item.children) {
            recurToKeyboardMap(item.children, item.visible !== false && parentVisible, obj);
        }
    });
    return obj;
};

class ButtonApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: [],
            lang: {},
            intl: null,
        };
        this.bindWebsocket = false;
        this.iskeyfuncFlag = null;
    }

    UNSAFE_componentWillMount() {
        let { area, _this } = this.props;
        let buttonsLangJson = _this.myState.buttonsLangJson;
        _this.myState.buttonsComponent[area] = this;
        if (buttonsLangJson.lang) {
            this.setState({
                json: this.props.json || [],
                lang: buttonsLangJson.lang,
                intl: buttonsLangJson.intl,
            });
        } else {
            let callback = (lang, bool, intl) => {
                _this.myState.buttonsLangJson = {
                    lang,
                    intl,
                };
                this.setState({
                    json: this.props.json || [],
                    lang,
                    intl,
                });
            };
            getMultiLang({ moduleId: 'page-button', callback });
        }
    }

    UNSAFE_componentWillReceiveProps() {
        let { area, _this } = this.props;
        this.setState({ json: this.props.json || [] });
        if (!_this.myState.buttonsComponent[area]) {
            _this.myState.buttonsComponent[area] = this;
        }
    }

    /**
     * 获取当前点击的按钮的iskeyfunc属性
     * iskeyfunc属性表示按钮是否需要进行关键功能定义的校验
    */
    getCurrentBtnIsKeyFunc = ({ items, currentKey }) => {
        items.forEach(item => {
            const { children, key, iskeyfunc } = item;
            if (!isUndefined(children) && children.length) {
                if (currentKey === key) {
                    this.iskeyfuncFlag = iskeyfunc
                }
                this.getCurrentBtnIsKeyFunc({ items: children, currentKey, });
            } else {
                if (currentKey === key) {
                    this.iskeyfuncFlag = iskeyfunc
                }
            }
        })
    };

    onClick = async buttonInfo => {
        // 多语
        const { lang } = this.state;
        let { _this, _this: { billinfo = {} }, modalRelation, onClick, notInterruptCode, withAutoFocus } = this.props;
        const { key: currentKey, item } = buttonInfo;
        this.getCurrentBtnIsKeyFunc({ items: [item], currentKey });
        let iskeyfunc = this.iskeyfuncFlag
        console.log('ca认证', iskeyfunc)
        /**
       * 关键功能定义
       * 当模版上设置了按钮权限字段iskeyfunc为true后才走关键功能定义逻辑
       * 对按钮的权限进行校验
       */
        if (iskeyfunc) {
            this.iskeyfuncFlag = null //标志还原
            /**
            * 按钮权限校验
            * 根据应用编码和按钮编码返回该按钮在关键功能定义中的认证类型
            * 需要的参数为应用编码和按钮编码，分别为：appcode,appbtncode
            */
            /**
         * 获取应用编码
        */
            const appcode = billinfo.appcode || pageTo.getAppCode();
            /**
         * 获取用户编码
         */
            const { userCode } = JSON.parse(getGlobalStorage('localStorage', 'STOREFORINFODATA'));
            const checkMode = await new Promise(resolve => {
                ajax({
                    url: '/nccloud/baseapp/ncckeyfunc/authentype.do',
                    loading: false,
                    data: {
                        // 应用编码
                        appcode,
                        // 按钮编码,就是key
                        appbtncode: currentKey,
                    },
                    success: res => {
                        const { data } = res;
                        switch (data) {
                            case '0': //用户密码校验
                                promptBox({
                                    color: 'info',
                                    hotKeyboard: true,
                                    // 关键功能验证
                                    title: lang['page-button-0014'],
                                    content: <SignIn ref={self => this.signInSelf = self} lang={lang} resolve={resolve} />,
                                    noFooter: false,
                                    noCancelBtn: false,
                                    // 确定
                                    beSureBtnName: lang['page-button-0008'],
                                    // 取消
                                    cancelBtnName: lang['page-button-0009'],
                                    beSureBtnClick: () => {
                                        // 进行登录校验
                                        const { handleSignIn, state: { passWord } } = this.signInSelf;
                                        handleSignIn(userCode, passWord);
                                    },
                                    cancelBtnClick: () => {
                                        console.log('Cancel');
                                    },
                                });
                                return;
                            case '1':/**强制ca校验 **/
                                if (isFunction(window.superSign)) {
                                    const { status, msg } = window.superSign(String(getSafeRandom() * 1000 + getSafeRandom()), userCode, true, true);
                                    switch (status) {
                                        // 返回0表示通过
                                        case 0:
                                            resolve(true);
                                            break;
                                        // 返回1 表示不通过
                                        case 1:
                                            toast({
                                                content: '',
                                                color: 'danger',
                                                groupOperation: true,
                                                isNode: true,
                                                // '展开', '收起', '关闭'
                                                TextArr: [lang['page-button-0010'], lang['page-button-0011'], lang['page-button-0012']],
                                                // ca检验不成功的提示信息
                                                groupOperationMsg: [msg],
                                            });
                                            resolve(false);
                                            break;
                                    }
                                }
                                return;
                            case '2': //不进行校验
                                resolve(true);
                                return;
                        }
                    },
                    error: res => {
                        console.log('后端报错', res);
                    },
                });
            });
            if (!checkMode) {
                /**
           * 用户没有按钮权限的情况
           * 终止代码执行
           */
                return;
            }
        }

        //二开按钮点击前回调--liuxis
        if (getCheckedExtendMethod(_this, 'beforeBtnClick')(buttonInfo.key, { output: _this.output }) === false) {
            return false;
        }

        // 如果點擊事件后  當前按鈕重繪或者移除  會導致焦點丟失到其他位置  在面板内的按鈕觸發就會不連貫
        let focusedDom;
        if (modalRelation) {
            // 焦點回執
            let modalDom = document.querySelector(`.${modalRelation}`);
            focusedDom = modalDom.querySelector('.u-modal-body') || modalDom.querySelector('.u-modal-content');
        }

        modalRelation && focusedDom && focusedDom.focus();

        notInterruptCode.includes(buttonInfo.key)
            ? onClick(buttonInfo.key)
            : triggerOnClickWithBlurAction({
                callBack: onClick,
                buttonInfo,
                params: this,
                withAutoFocus,
            });

        setTimeout(() => {
            modalRelation && focusedDom && focusedDom.focus();
        }, 300);

        //二开按钮点击后回调
        getCheckedExtendMethod(_this, 'afterBtnClick')(buttonInfo.key, { output: _this.output });
    };

    // 快捷键操作与  按钮操作分离 便于管理
    keyboardHandle = async (buttonInfo, hotkey) => {
        // 多语
        const { lang } = this.state;
        let { _this, _this: { billinfo = {} }, modalRelation, onClick, notInterruptCode, withAutoFocus } = this.props;
        const { key: currentKey, item } = buttonInfo;
        this.getCurrentBtnIsKeyFunc({ items: [item], currentKey });
        let iskeyfunc = this.iskeyfuncFlag
        console.log('ca认证', iskeyfunc)
        /**
       * 关键功能定义
       * 当模版上设置了按钮权限字段iskeyfunc为true后才走关键功能定义逻辑
       * 对按钮的权限进行校验
       */
        if (iskeyfunc) {
            this.iskeyfuncFlag = null //标志还原
            /**
            * 按钮权限校验
            * 根据应用编码和按钮编码返回该按钮在关键功能定义中的认证类型
            * 需要的参数为应用编码和按钮编码，分别为：appcode,appbtncode
            */
            /**
         * 获取应用编码
        */
            const appcode = billinfo.appcode || pageTo.getAppCode();
            /**
         * 获取用户编码
         */
            const { userCode } = JSON.parse(getGlobalStorage('localStorage', 'STOREFORINFODATA'));
            const checkMode = await new Promise(resolve => {
                ajax({
                    url: '/nccloud/baseapp/ncckeyfunc/authentype.do',
                    loading: false,
                    data: {
                        // 应用编码
                        appcode,
                        // 按钮编码,就是key
                        appbtncode: currentKey,
                    },
                    success: res => {
                        const { data } = res;
                        switch (data) {
                            case '0': //用户密码校验
                                promptBox({
                                    color: 'info',
                                    hotKeyboard: true,
                                    // 关键功能验证
                                    title: lang['page-button-0014'],
                                    content: <SignIn ref={self => this.signInSelf = self} lang={lang} resolve={resolve} />,
                                    noFooter: false,
                                    noCancelBtn: false,
                                    // 确定
                                    beSureBtnName: lang['page-button-0008'],
                                    // 取消
                                    cancelBtnName: lang['page-button-0009'],
                                    beSureBtnClick: () => {
                                        // 进行登录校验
                                        const { handleSignIn, state: { passWord } } = this.signInSelf;
                                        handleSignIn(userCode, passWord);
                                    },
                                    cancelBtnClick: () => {
                                        console.log('Cancel');
                                    },
                                });
                                return;
                            case '1':/**强制ca校验 **/
                                if (isFunction(window.superSign)) {
                                    const { status, msg } = window.superSign(String(getSafeRandom() * 1000 + getSafeRandom()), userCode, true, true);
                                    switch (status) {
                                        // 返回0表示通过
                                        case 0:
                                            resolve(true);
                                            break;
                                        // 返回1 表示不通过
                                        case 1:
                                            toast({
                                                content: '',
                                                color: 'danger',
                                                groupOperation: true,
                                                isNode: true,
                                                // '展开', '收起', '关闭'
                                                TextArr: [lang['page-button-0010'], lang['page-button-0011'], lang['page-button-0012']],
                                                // ca检验不成功的提示信息
                                                groupOperationMsg: [msg],
                                            });
                                            resolve(false);
                                            break;
                                    }
                                }
                                return;
                            case '2': //不进行校验
                                resolve(true);
                                return;
                        }
                    },
                    error: res => {
                        console.log('后端报错', res);
                    },
                });
            });
            if (!checkMode) {
                /**
           * 用户没有按钮权限的情况
           * 终止代码执行
           */
                return;
            }
        }
        //二开按钮点击前回调--liuxis
        if (getCheckedExtendMethod(_this, 'beforeBtnClick')(buttonInfo.key, { output: _this.output }) === false) {
            return false;
        }
        // 先去除一下hotkey 变量  主要是业务组自己添加了变量
        ViewModel.btnHotKey = hotkey;

        notInterruptCode.includes(buttonInfo.key)
            ? onClick(buttonInfo.key)
            : triggerOnClickWithBlurAction({
                callBack: onClick,
                buttonInfo,
                params: this,
                withAutoFocus,
            });

        ViewModel.btnHotKey = '';
        //二开按钮点击后回调
        getCheckedExtendMethod(_this, 'afterBtnClick')(buttonInfo.key, { output: _this.output });
    };

    /**
     * 处理特殊按钮
     */
    handleSpecialBtn(item) {
        if (!item || !isObj(item.uploadConfig)) {
            return false;
        }
        let wrapper = findDOMNode(this);
        if (!wrapper) {
            return false;
        }
        // 如果有父级元素  就需要先展开父级  否则取不到dom
        if (item.parentCode) {
            // 弹窗容器位置
            let popContainer = this.props.popContainer || document,
                cls = replaceSpecialStr(`.nc-${item.area}-btn-${item.parentCode}-${item.key}`);
            // 目标元素
            let target = popContainer.querySelector(cls);
            // 第一次的时候弹出下元素
            let icon = wrapper.querySelector(
                replaceSpecialStr(`.nc-${item.area}-${item.topParentCode || item.parentCode}-icon`),
            );
            icon && !target && icon.click();
            // 调用目标元素的click事件
            setTimeout(() => {
                target = popContainer.querySelector(cls);
                let uploadDom = target && target.parentElement.querySelector('input');
                uploadDom && uploadDom.click();
            }, 0);
        } else {
            let target = wrapper.querySelector(replaceSpecialStr(`.nc-${item.area}-btn-${item.key}`));
            let uploadDom = target && target.parentElement.querySelector('input');
            uploadDom && uploadDom.click();
        }
    }

    /**
     * 获取事件信息  bbqin
     */
    getKeyMapsAndHandlers = data => {
        let keyMap = {},
            handlers = {},
            { hotKeysMap, hotKeysEnabled: enabled, modalRelation, _this: page, ignoreHotkeyCode = [] } = this.props;
        // console.log(page);
        // 用於去重
        let keyboardMap = recurToKeyboardMap(data);
        // console.log(keyboardMap);
        // 树深度遍历

        let process = (tree, parentVisible) => {
            return tree.forEach(item => {
                let info = hotKeysMap[item.key];

                if (item.children) {
                    process(item.children, item.visible !== false && parentVisible);
                }

                // 同组内注册两个相同快捷键的按钮 隐藏的 禁用的 就不绑定快捷键了
                if (
                    item.keyboard &&
                    keyboardMap[firstToUpperCase(item.keyboard)] > 1 &&
                    (item.visible === false || item.disabled === true || item.isenable === false || parentVisible === false)
                ) {
                    return false;
                }
                if (ignoreHotkeyCode.indexOf(item.key) !== -1) {
                    // 忽略的 移除 keyboard
                    delete item.keyboard;
                }
                if (ignoreHotkeyCode.indexOf(item.key) == -1 && (info || item.keyboard)) {
                    let keyMapItem = item.keyboard || info.key;

                    let multipleKeyboardKey = keyMapItem.split(',').length > 1;
                    keyMap[item.key] = multipleKeyboardKey ? keyMapItem.split(',') : keyMapItem;

                    // keyMap[item.key] = { sequence: keyMapItem, action: "keypress" }
                    handlers[item.key] = () => {
                        // 同组内多个按钮重复的问题处理  肩部和表体行
                        let pageareacode = page && page.output && page.output.findFocusModuleId();

                        // 针对多区域按钮相同的处理‘
                        if (
                            (pageareacode && item.pageareacode && pageareacode !== item.pageareacode) ||
                            (item.pageareacode && pageareacode == null)
                        ) {
                            return false;
                        }
                        // 弹窗背景里的按钮禁用
                        let disabledBgBtn =
                            document.querySelector('.disabled-bg-btn') || top.document.querySelector('.disabled-bg-btn');
                        if (disabledBgBtn && !modalRelation) {
                            return false;
                        }
                        // 弹窗弹出中的按钮  判断当前弹窗是不是被聚焦了
                        if (modalRelation) {
                            let modal = document.querySelector(`.${modalRelation}`);
                            let active = modal && modal.contains(document.activeElement) && modal !== document.activeElement;
                            // 弹窗不存在  或者弹窗未激活
                            if (!modal || !(modal.contains(document.activeElement) && active)) {
                                return false;
                            }
                        }
                        // 解决  如果click回调中是ajax多次调用的问题  方案是通过平台内的ajax都有mask处理
                        // 上传按钮 以及按钮的显示隐藏特性
                        if (
                            !document.querySelector('.u-loading-backdrop.full-screen') &&
                            !this.handleSpecialBtn(item) &&
                            item.visible !== false &&
                            item.disabled !== true &&
                            item.isenable !== false &&
                            parentVisible !== false
                        ) {
                            this.keyboardHandle(
                                {
                                    key: item.key,
                                    title: item.title,
                                    item,
                                },
                                keyMapItem,
                            );
                        }
                        // TODO 可以考虑做 promise 的判断
                    };
                }
                //

            });
        };
        enabled && process(data, true);

        return {
            keyMap,
            handlers,
        };
    };

    /**
     * 获取快捷键提示方式 样式
     */
    getTipKeybodard = item => {
        let { tipKeybodard } = this.props;

        if (tipKeybodard === 'underline' && item.keyboard && item.disabled !== true) {
            // 现在只针对单键 以后可能会变成多键
            let kbs = item.keyboard.split('+');
            return [
                item.title,
                '(',
                <span className="text-decoration-underline">{kbs ? kbs[kbs.length - 1].toUpperCase() : item.keyboard}</span>,
                ')',
            ];
        } else {
            return item.title;
        }
    };

    renderButtons = () => {
        // pageareacode
        // this=>ButtonApp 组件
        let { area, _this, hotKeysEnabled, tipKeybodard } = this.props;
        let { lang } = this.state;
        let allButtons = _this.myState.buttons || [];
        let json = [];
        for (let item of allButtons) {
            if (item.area) {
                let arr = item.area.split(',');
                arr.map(elem => {
                    elem = elem.trim();
                    if (elem === area) {
                        json.push(item);
                    }
                    return elem;
                });
            }
        }
        json.sort((a, b) => {
            return Number(a.order) - Number(b.order);
        });
        _this.myState.appButtons[area] = json;
        // 获取hotkeys  bbqin
        let keyHandlers = this.getKeyMapsAndHandlers(json, area);
        return (
            <span className={`button-app-wrapper ${area ? area + '-button-app-wrapper' : ''}`}>
                <HotKeys
                    display="inline-block"
                    keyMap={keyHandlers.keyMap}
                    handlers={keyHandlers.handlers}
                    enabled={hotKeysEnabled}
                    focused={true}
                >
                    {json.map(item => {
                        if (item.visible !== false) {
                            switch (item.type) {
                                // 因为需求 type将主要次要按钮分离出去 用btncolor表示 之前的单个按钮按general_btn表示
                                // case "button_main":
                                case 'general_btn':
                                    let Btn = (
                                        <HelperTip
                                            title={item.title}
                                            keyboard={item.keyboard}
                                            disabled={item.disabled}
                                            delayShow={500}
                                        >
                                            <Button
                                                disabled={item.disabled}
                                                className={item.btncolor == 'button_main' ? 'button-primary' : ''}
                                                onClick={() => this.onClick({
                                                    key: item.key,
                                                    title: item.title,
                                                    item,
                                                })
                                                }
                                                tabindex={this.props.tabindex}
                                                fieldid={item.key}
                                                btn-code={item.key}
                                            >
                                                {this.getTipKeybodard(item)}
                                            </Button>
                                        </HelperTip>
                                    );
                                    let RefshBtn = (
                                        <HelperTip
                                            title={item.title}
                                            keyboard={item.keyboard}
                                            delayShow={500}
                                        >
                                            <Button
                                                disabled={item.disabled}
                                                className={
                                                    item.btncolor == 'button_main' ? 'button-primary refresh-component' : 'refresh-component'
                                                }
                                                onClick={() => this.onClick({
                                                    key: item.key,
                                                    title: item.title,
                                                    item,
                                                })
                                                }
                                                tabindex={this.props.tabindex}
                                                fieldid={item.key}
                                                btn-code={item.key}
                                            >
                                                <i className="iconfont icon-shuaxin1" />
                                            </Button>
                                        </HelperTip>
                                    );
                                    // 没有title渲染成刷新按钮,有uploadConfig给按钮增加上传功能,没有uploadConfig渲染普通按钮
                                    return (!item.title && !item.parentCode) ? (
                                        RefshBtn
                                    ) : isObj(item.uploadConfig) && !item.disabled ? (
                                        <Upload showUploadList={false} {...item.uploadConfig}>
                                            <HelperTip
                                                title={item.title}
                                                keyboard={item.keyboard}
                                                delayShow={500}
                                            >
                                                <Button
                                                    disabled={item.disabled}
                                                    className={classNames(replaceSpecialStr(` nc-${item.area}-btn-${item.key} `), {
                                                        'button-primary upload-btn-wrapper': item.btncolor == 'button_main',
                                                        'upload-btn-wrapper': item.btncolor != 'button_main',
                                                    })}
                                                    onClick={() => this.onClick({
                                                        key: item.key,
                                                        title: item.title,
                                                        item,
                                                    })
                                                    }
                                                    tabindex={this.props.tabindex}
                                                    fieldid={item.key}
                                                    btn-code={item.key}
                                                >
                                                    {item.title}
                                                    {item.docAmount ? `(${item.docAmount})` : ''}
                                                </Button>
                                            </HelperTip>
                                        </Upload>
                                    ) : (!item.title && item.parentCode) ? '' : (Btn);
                                case 'dropdown':
                                    return (
                                        <MyDropdown
                                            list={item.children}
                                            title={item.title}
                                            disabled={item.disabled}
                                            btncolor={item.btncolor}
                                            area={area}
                                            dropdownType={item.dropdownType}
                                            popContainer={this.props.popContainer}
                                            keyboard={item.keyboard}
                                            clskey={item.key}
                                            onClick={buttonInfo => this.onClick({ ...buttonInfo, item })}
                                            tabindex={this.props.tabindex}
                                            className={`${area}-${item.key}-panel fieldid_${item.key}_btn_list`}
                                            delayShow={500}
                                        />
                                    );
                                case 'buttongroup':
                                    return (
                                        <MyButtonGroup
                                            fieldid={item.key}
                                            btn-code={item.key}
                                            list={item.children}
                                            area={area}
                                            _this={_this}
                                            disabled={item.disabled}
                                            visible={item.visible !== false}
                                            buttonLimit={this.props.buttonLimit}
                                            popContainer={this.props.popContainer}
                                            tipKeybodard={tipKeybodard}
                                            clskey={item.key}
                                            onClick={buttonInfo => this.onClick({ ...buttonInfo, item })}
                                            tabindex={this.props.tabindex}
                                        />
                                    );
                                case 'divider':
                                    return (
                                        <MyDividerButton
                                            list={item.children}
                                            dividerType={item.dividerType}
                                            _key={item.key}
                                            area={area}
                                            btncolor={item.btncolor}
                                            isenable={item.isenable} // 分割按钮的权限要特殊处理
                                            title={item.title}
                                            onClick={buttonInfo => this.onClick({ ...buttonInfo, item })}
                                            disabled={item.disabled}
                                            uploadConfig={item.uploadConfig}
                                            popContainer={this.props.popContainer}
                                            tabindex={this.props.tabindex}
                                            keyboard={item.keyboard}
                                        />
                                    );
                                case 'more':
                                    //子级按钮没有，则不限显示父级
                                    let moreBtnlist = item.children
                                    let renderMoreBtn = []
                                    moreBtnlist.map(item => {
                                        if (item.children && item.children.length) {
                                            renderMoreBtn.push(item)
                                        }
                                    })
                                    //所有子级按钮没有则不显示更多按钮
                                    if (!renderMoreBtn.length) {
                                        return null
                                    }
                                    return (
                                        <More
                                            list={renderMoreBtn}
                                            title={item.title}
                                            json={lang}
                                            btncolor={item.btncolor}
                                            disabled={item.disabled}
                                            popContainer={this.props.popContainer}
                                            onClick={buttonInfo => this.onClick({ ...buttonInfo, item })}
                                            className="moreButton-component"
                                            tabindex={this.props.tabindex}
                                            clskey={item.key}
                                            area={item.area}
                                        />
                                    );
                                default:
                                    return (
                                        <HelperTip
                                            title={item.title}
                                            keyboard={item.keyboard}
                                            delayShow={500}
                                        >
                                            <Button
                                                btncolor={item.btncolor}
                                                disabled={item.disabled}
                                                onClick={() => this.onClick({
                                                    key: item.key,
                                                    title: item.title,
                                                    item,
                                                })
                                                }
                                                tabindex={this.props.tabindex}
                                                fieldid={item.key}
                                                btn-code={item.key}
                                            >
                                                {item.title}
                                            </Button>
                                        </HelperTip>
                                    );
                            }
                        }
                    })}
                </HotKeys>
            </span>
        );
    };

    render() {
        return this.renderButtons();
    }
}

function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

class OprationButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            index: 0,
            lang: {},
            intl: null,
            json: [],
        };
        this.bindWebsocket = false;
        this.uuid = uuidv4();
        this.langCode = getLangCode();
    }

    UNSAFE_componentWillMount() {
        let { oprationIndex, _this, area } = this.props;
        let buttonsLangJson = _this.myState.buttonsLangJson;
        // 记录列表页面的组件
        _this.myState.buttonsComponent[area] = _this.myState.buttonsComponent[area] || {};
        _this.myState.buttonsComponent[area]._isOperateButton = true;
        _this.myState.buttonsComponent[area][this.uuid] = this;

        _this.myState.oprationIndexCopy = oprationIndex;
        if (buttonsLangJson.lang) {
            this.setState({
                json: this.props.json || [],
                index: oprationIndex,
                lang: buttonsLangJson.lang,
                intl: buttonsLangJson.intl,
            });
        } else {
            let callback = (lang, bool, intl) => {
                _this.myState.buttonsLangJson = {
                    lang,
                    intl,
                };

                this.setState({
                    json: this.props.json || [],
                    index: oprationIndex,
                    lang,
                    intl,
                });
            };

            getMultiLang({ moduleId: 'page-button', callback });
        }
    }

    componentWillUnmount() {
        let { _this, area } = this.props;
        if (_this.myState.buttonsComponent[area] && _this.myState.buttonsComponent[area][this.uuid]) {
            delete _this.myState.buttonsComponent[area][this.uuid];
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // let { _this } = nextProps;
        // console.log(_this)
        this.setState(
            { json: nextProps.json || [] },
            () => {
                // _this.forceUpdate()
            },
        );
    }

    onVisibleChange = () => {
        // let { _this } = this.props;
        // _this.myState.oprationBtnListVisible = !this.state.visible
        this.setState({ visible: !this.state.visible });
    };

    popContentItem = item => {
        let { onClick } = this.props;
        let popContent = (
            <span>
                <span className="iconfont icon-tixing" /> &nbsp;
                {item.popContent}
            </span>
        );
        return (
            <Popconfirm
                trigger="click"
                placement="top"
                content={popContent}
                stopbubble="1"
                onClose={debounce(() => {
                    // 做个防抖  不然快速点击确定会触发多次事件
                    onClick(item.key);
                    this.setState({ visible: false });
                }, 1000)}
                onCancel={() => {
                    this.setState({ visible: false });
                }}
                rootClose={true}
            >
                {/*<a className={`${list === 'list'?'u-menu-item-title-wrapper': ''}`}*/}
                <a
                    className={'u-menu-item-title-wrapper row-btn-' + replaceSpecialStr(item.key)}
                    // className="row-edit-option"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    key={item.key}
                    fieldid={getSysFieldid(`${item.key}_btn`)}
                    btn-code={item.key}
                >
                    {item.title}
                    {/* {index < json.length - 1 && <span className="split-line">|</span>} */}
                </a>
            </Popconfirm>
        );
    };

    popContentItem1 = (item, list) => {
        return item.popContent ? (
            this.popContentItem(item, list)
        ) : (<span
            className="u-menu-item-title-wrapper"
            btn-code={item.key}
            onClick={e => {
                this.onClick({ key: item.key, e, item });
                this.setState({ visible: false });
            }}
        //fieldid={`${item.key}_btn`}
        >
            {item.title}
        </span>);
    };

    renderMenu = list => {
        return (
            <Menu>
                {list.map(item => (
                    <Item key={item.key} disabled={item.disabled}>
                        {item.uploadConfig ? (
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <Upload showUploadList={false} {...item.uploadConfig}>
                                    {item.title}
                                </Upload>
                            </span>
                        ) : (this.popContentItem1(item, 'list'))}
                    </Item>
                ))}
            </Menu>
        );
    };

    renderMoreList = (list, rowIndex) => {
        let { popContainer } = this.props;
        let { lang } = this.state;
        if (list.length) {
            return (
                <Dropdown
                    trigger={['click']}
                    visible={this.state.visible}
                    onVisibleChange={this.onVisibleChange}
                    overlay={(
                        <Menu>
                            {list.map(item => (
                                <Item
                                    fieldid={item.key}
                                    btn-code={item.key}
                                    className={'opration-more-list-item-wrapper row-btn-' + replaceSpecialStr(item.key)}
                                    key={item.key}
                                >
                                    {item.uploadConfig ? (
                                        <span
                                            btn-code={item.key}
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            className={`${OPERATION_UPLOAD_CLASSNAME}-${rowIndex}`}
                                        >
                                            <Upload showUploadList={false} {...item.uploadConfig}>
                                                {item.title}
                                            </Upload>
                                        </span>
                                    ) : (this.popContentItem1(item, 'list'))}
                                </Item>
                            ))}
                        </Menu>
                    )}
                    animation="slide-up"
                    overlayClassName="dropdown-component-list fieldid_more_btn_list"
                    getPopupContainer={() => popContainer || document.body}
                >
                    <a
                        fieldid={getSysFieldid('more_btn')}
                        className={
                            'row-more ' +
                            list.reduce((accumulator, curent) => {
                                accumulator = accumulator + ' row-btn-' + replaceSpecialStr(curent.key);
                                return accumulator;
                            }, '')
                        }
                        onClick={this.onVisibleChange}
                    >
                        {lang['page-button-0002'] || '更多'}
                    </a>
                </Dropdown>
            );
        } else {
            return '';
        }
    };

    // 特殊模式
    renderMoreListHoverModel = (list, rowIndex, showFoldIcon) => {
        let { popContainer, renderModel } = this.props;
        let { lang } = this.state;
        return (
            <Fragment>
                {!!list.length ? <Dropdown
                    trigger={[!this.state.unfold ? 'click' : 'hover']}
                    visible={this.state.visible}
                    onVisibleChange={this.onVisibleChange}
                    overlay={(
                        <Menu>
                            {list.map(item => (
                                <Item
                                    fieldid={item.key}
                                    btn-code={item.key}
                                    className={'opration-more-list-item-wrapper row-btn-' + replaceSpecialStr(item.key)}
                                    key={item.key}
                                >
                                    {item.uploadConfig ? (
                                        <span
                                            btn-code={item.key}
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            className={`${OPERATION_UPLOAD_CLASSNAME}-${rowIndex}`}
                                        >
                                            <Upload showUploadList={false} {...item.uploadConfig}>
                                                {item.title}
                                            </Upload>
                                        </span>
                                    ) : (this.popContentItem1(item, 'list'))}
                                </Item>
                            ))}
                        </Menu>
                    )}
                    animation="slide-up"
                    overlayClassName="dropdown-component-list fieldid_more_btn_list"
                    getPopupContainer={() => popContainer || document.body}
                >
                    {!this.state.unfold ? <a
                        fieldid={getSysFieldid('more_btn')}
                        className={
                            'row-more ' +
                            list.reduce((accumulator, curent) => {
                                accumulator = accumulator + ' row-btn-' + replaceSpecialStr(curent.key);
                                return accumulator;
                            }, '')
                        }
                    // onClick={this.onVisibleChange}
                    >
                        {lang['page-button-0002'] || '更多'}
                    </a> :
                        <a className="fold-btn-more" onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ unfold: !this.state.unfold, visible: false });
                        }}>
                            <Tooltip key='fold' placement="top"
                                inverse
                                className='btn-helper-overlay'
                                overlay={lang['page-button-0017']}
                                trigger={['hover']}
                            >
                                {this.state.visible ? <i className="iconfont icon-jiantouzuo" /> : <i className="iconfont icon-jiantouxia1" />}
                            </Tooltip>
                        </a>
                    }
                </Dropdown> : ''}
                {showFoldIcon && !this.state.unfold &&
                    <Tooltip key='fold' placement="top"
                        inverse
                        className='btn-helper-overlay'
                        overlay={lang['page-button-0016']}
                        trigger={['hover']}>
                        <a className="unfold-btn-more" onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ unfold: !this.state.unfold });
                        }}>
                            <i className="iconfont icon-jiantouyou" />
                        </a>
                    </Tooltip>
                }
            </Fragment>
        );
    };

    // 外语情况下，渲染的操作列按钮
    renderLanglist = (list, rowIndex) => {
        let { popContainer } = this.props;
        if (list.length) {
            return (
                <Tooltip
                    trigger="click"
                    placement="leftTop"
                    visible={this.state.visible}
                    rootClose={true}
                    className="dropdown-component-list fieldid_more_btn_list opration-tooltip-button"
                    container={() => popContainer || document.body}
                    overlay={(
                        <ul>
                            {list.map(item => (
                                <li
                                    fieldid={getSysFieldid(item.key)}
                                    btn-code={item.key}
                                    className={'opration-more-list-item-wrapper row-btn-' + replaceSpecialStr(item.key)}
                                    key={item.key}
                                >
                                    {item.uploadConfig ? (
                                        <span
                                            btn-code={item.key}
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            className={`${OPERATION_UPLOAD_CLASSNAME}-${rowIndex}`}
                                        >
                                            <Upload showUploadList={false} {...item.uploadConfig}>
                                                {item.title}
                                            </Upload>
                                        </span>
                                    ) : (this.popContentItem1(item, 'list'))}
                                </li>
                            ))}
                        </ul>
                    )}
                //   animation="slide-up"
                //   overlayClassName="dropdown-component-list fieldid_more_btn_list"
                //   getPopupContainer={() => popContainer || document.body}
                >
                    <a
                        fieldid={getSysFieldid('more_btn')}
                        className={
                            'row-more ' +
                            list.reduce((accumulator, curent) => {
                                accumulator = accumulator + ' row-btn-' + replaceSpecialStr(curent.key);
                                return accumulator;
                            }, '') +
                            ' icon-gengduo1 iconfont'
                        }
                        onClick={this.onVisibleChange}
                    >
                        {/* {} */}
                    </a>
                </Tooltip>
            );
        } else {
            return '';
        }
    };

    /**
     * 获取快捷键的触发条件 bbqin
     * 这里对每个事件做判断 其实是不太合适的  因为每个事件都会执行这个逻辑  占用的内存还是很大的
     * TODO 限定事件的触发区域（哎 估计不太可能实现了）
     */
    getHotkeyTriggerFlag = key => {
        // console.time("getHotkeyTriggerFlag");
        let selectedRow = null;
        // 获取当前按钮的组容器
        let wrapper = findDOMNode(this);
        if (!wrapper) {
            return false;
        }
        // ajax请求中  存在的dom
        let loading = document.querySelector('.u-loading-backdrop.full-screen');
        if (loading) {
            return false;
        }
        // 获取指定目标的按钮
        let target = wrapper.querySelector('.row-btn-' + replaceSpecialStr(key));
        if (!target) {
            return false;
        }
        // 获取当前的固定列
        let parentContainer = Closest(wrapper, '.u-table-fixed-right');
        // 选中行的selector选择器
        let selector = '.tree-table-col-selected,.table-row-selected,.editTable-selected-row,.cardTable-selected-row,.simpleTable-selected-row,.nctable-selected-row';
        // 判断是否带有固定列的情况
        if (parentContainer) {
            // 获取目标选中行
            selectedRow = parentContainer.querySelector(selector);
        } else {
            let isFixed = Closest(target, '.u-table-row-fixed-columns-in-body');
            // 获取当前选中行
            if (!isFixed) {
                selectedRow = Closest(wrapper, selector);
            }
        }

        if (!selectedRow) {
            return false;
        }
        // 目标按钮是否是可见的  这句可能判断不出来显示和隐藏
        let targetVisble = getComputedStyle(target).visibility !== 'hidden';
        // 目标可见  并且是当前选中行
        let flag = selectedRow.contains(target) && targetVisble;
        // console.timeEnd("getHotkeyTriggerFlag");
        return flag ? target : false;
    };

    /**
     * 获取keyMaps  bbqin
     */
    getKeyMapsAndHandlers = ({ data, rowIndex }) => {
        let keyMap = {},
            handlers = {},
            { hotKeysMap, hotKeysEnabled: enabled, modalRelation, onClick, _this: page, ignoreHotkeyCode = [], notInterruptCode, withAutoFocus } = this.props;

        // 树深度遍历
        let process = tree => {
            return tree.forEach(item => {
                let info = hotKeysMap[item.key];
                if (
                    ignoreHotkeyCode.indexOf(item.key) == -1 &&
                    (info || item.keyboard)
                    // &&
                    // item.visible !== false &&
                    // item.disabled !== true &&
                    // item.isenable !== false
                ) {
                    let keyMapItem = item.keyboard || info.key;

                    let multipleKeyboardKey = keyMapItem.split(',').length > 1;
                    keyMap[item.key] = multipleKeyboardKey ? keyMapItem.split(',') : keyMapItem;

                    handlers[item.key] = e => {
                        let target = this.getHotkeyTriggerFlag(item.key);
                        // 针对多区域按钮相同的处理‘
                        let pageareacode = page && page.output && page.output.findFocusModuleId();
                        if (
                            (pageareacode && item.pageareacode && pageareacode !== item.pageareacode) ||
                            (item.pageareacode && pageareacode == null)
                        ) {
                            return false;
                        }
                        // 弹窗背景里的按钮禁用
                        let disabledBgBtn =
                            document.querySelector('.disabled-bg-btn') || top.document.querySelector('.disabled-bg-btn');
                        if (disabledBgBtn && !modalRelation) {
                            return false;
                        }
                        // 弹窗里弹出的情况 判断
                        if (modalRelation) {
                            let modal = document.querySelector(`.${modalRelation}`);
                            let active = modal && modal.contains(document.activeElement) && modal !== document.activeElement;
                            // 弹窗不存在  或者弹窗未激活
                            if (!modal || !(modal.contains(document.activeElement) && active)) {
                                return false;
                            }
                        }
                        if (
                            target &&
                            item.visible !== false
                            // &&
                            // item.disabled !== true &&
                            // item.isenable !== false
                        ) {
                            if (item.popContent) {
                                // 有弹窗的情况
                                target.click();
                            } else if (target.className.includes('row-more')) {
                                if (item.uploadConfig) {
                                    let uniqueClassName = `${OPERATION_UPLOAD_CLASSNAME}-${rowIndex}`;
                                    let uploadDom = document.querySelector(`.${uniqueClassName} input`);
                                    if (uploadDom) {
                                        uploadDom && uploadDom.click();
                                    } else {
                                        target.click();
                                        setTimeout(() => {
                                            uploadDom = document.querySelector(`.${uniqueClassName} input`);
                                            uploadDom && uploadDom.click();
                                            target.click();
                                        });
                                    }
                                }

                                notInterruptCode.includes(item.key)
                                    ? onClick(item.key, e, keyMapItem)
                                    : triggerOnClickWithBlurAction({
                                        callBack: onClick,
                                        buttonInfo: item,
                                        e,
                                        keyMapItem,
                                        withAutoFocus,
                                    });
                            } else {
                                notInterruptCode.includes(item.key)
                                    ? onClick(item.key, e, keyMapItem)
                                    : triggerOnClickWithBlurAction({
                                        callBack: onClick,
                                        buttonInfo: item,
                                        e,
                                        keyMapItem,
                                        withAutoFocus,
                                    });
                            }
                        }
                    };
                }
                if (item.children) {
                    process(item.children);
                }
            });
        };
        enabled && process(data);
        return {
            keyMap,
            handlers,
        };
    };

    onClick = async buttonInfo => {
        const { lang } = this.state;
        let { _this: { billinfo = {} }, onClick } = this.props;
        const { key: currentKey, e, item = {} } = buttonInfo;
        if (item.iskeyfunc) {
            /**
            * 按钮权限校验
            * 根据应用编码和按钮编码返回该按钮在关键功能定义中的认证类型
            * 需要的参数为应用编码和按钮编码，分别为：appcode,appbtncode
            */
            /**
         * 获取应用编码
        */
            const appcode = billinfo.appcode || pageTo.getAppCode();
            /**
         * 获取用户编码
         */
            const { userCode } = JSON.parse(getGlobalStorage('localStorage', 'STOREFORINFODATA'));
            const checkMode = await new Promise(resolve => {
                ajax({
                    url: '/nccloud/baseapp/ncckeyfunc/authentype.do',
                    loading: false,
                    data: {
                        // 应用编码
                        appcode,
                        // 按钮编码,就是key
                        appbtncode: currentKey,
                    },
                    success: res => {
                        const { data } = res;
                        switch (data) {
                            case '0': /** 用户密码校验 */
                                promptBox({
                                    color: 'info',
                                    hotKeyboard: true,
                                    // 关键功能验证
                                    title: lang['page-button-0014'],
                                    content: <SignIn ref={self => this.signInSelf = self} lang={lang} resolve={resolve} />,
                                    noFooter: false,
                                    noCancelBtn: false,
                                    // 确定
                                    beSureBtnName: lang['page-button-0008'],
                                    // 取消
                                    cancelBtnName: lang['page-button-0009'],
                                    beSureBtnClick: () => {
                                        // 进行登录校验
                                        const { handleSignIn, state: { passWord } } = this.signInSelf;
                                        handleSignIn(userCode, passWord);
                                    },
                                    cancelBtnClick: () => {
                                        console.log('Cancel');
                                    },
                                });
                                return;
                            case '1': /**强制ca校验 **/
                                if (isFunction(window.superSign)) {
                                    const { status, msg } = window.superSign(String(getSafeRandom() * 1000 + getSafeRandom()), userCode, true, true);
                                    switch (status) {
                                        // 返回0表示通过
                                        case 0:
                                            resolve(true);
                                            break;
                                        // 返回1 表示不通过
                                        case 1:
                                            toast({
                                                content: '',
                                                color: 'danger',
                                                groupOperation: true,
                                                isNode: true,
                                                // '展开', '收起', '关闭'
                                                TextArr: [lang['page-button-0010'], lang['page-button-0011'], lang['page-button-0012']],
                                                // ca检验不成功的提示信息
                                                groupOperationMsg: [msg],
                                            });
                                            resolve(false);
                                            break;
                                    }
                                }
                                return;
                            case '2'://不进行校验
                                resolve(true);
                                return;
                        }
                    },
                    error: res => {
                        console.log('后端报错', res);
                    },
                });
            });
            if (!checkMode) {
                /**
           * 用户没有按钮权限的情况
           * 终止代码执行
           */
                return;
            }
        }
        onClick(currentKey, e)
    }

    render() {
        let { buttonLimit, area, _this, wantedButtons = [], stopPropagation, hotKeysEnabled, rowIndex, templateOrder, renderModel } = this.props;
        let json = _this.myState.buttons || [];
        let needRenderButtons = [];
        let needCopyButtonsJson = [];
        if (Array.isArray(wantedButtons)) {
            for (let item of wantedButtons) {
                for (let ite of json) {
                    if (ite.visible === true || ite.hasOwnProperty('visible') === false) {
                        if (ite.area.indexOf(',') !== -1) {
                            let arr = ite.area.split(',');
                            let areaArr = arr.map(i => i.trim());
                            if (item === ite.key && areaArr.includes(area)) {
                                needRenderButtons.push({
                                    ...ite,
                                    isOprationBtn: true,
                                });
                            }
                        } else {
                            if (item === ite.key && ite.area === area) {
                                needRenderButtons.push({
                                    ...ite,
                                    isOprationBtn: true,
                                });
                            }
                        }
                    }
                    if (ite.area.indexOf(',') !== -1) {
                        let arr = ite.area.split(',');
                        let areaArr = arr.map(i => i.trim());
                        if (item === ite.key && areaArr.includes(area)) {
                            needCopyButtonsJson.push({
                                ...ite,
                                isOprationBtn: true,
                            });
                        }
                    } else {
                        if (item === ite.key && ite.area === area) {
                            needCopyButtonsJson.push({
                                ...ite,
                                isOprationBtn: true,
                            });
                        }
                    }
                }
            }
        }

        needCopyButtonsJson.length && needCopyButtonsJson.sort((a, b) => {
            return Number(a.order) - Number(b.order);
        });
        if (templateOrder) {
            needRenderButtons.length && needRenderButtons.sort((a, b) => { //以前操作列按钮没有排序，注释掉，如果有人提顺序问题看NCC-40383
                return Number(a.order) - Number(b.order);
            });
        }

        _this.myState.oprationButtons[area] = needCopyButtonsJson;


        let keyHandlers = needRenderButtons.length
            ? this.getKeyMapsAndHandlers({ data: needRenderButtons, area, rowIndex })
            : {};



        // 逻辑处理
        let list = [];
        let renderDropDown = null;
        let showflag = this.langCode === 'tradchn' || this.langCode === 'simpchn';

        // 悬浮在行上
        if (renderModel === 'hover') {
            let showFoldIcon = needRenderButtons.length;
            if (!this.state.unfold) {
                if (needRenderButtons.length && needRenderButtons.length > buttonLimit) {
                    list = needRenderButtons.slice(buttonLimit - 1, needRenderButtons.length);
                    needRenderButtons = needRenderButtons.slice(0, buttonLimit - 1);
                }
            } else {
                showflag = false;
                list = needRenderButtons;
            }
            renderDropDown = this.renderMoreListHoverModel(list, this.props.rowIndex, showFoldIcon);
        } else {
            // 多语和非多语模式的处理
            // let showflag = false;
            if (showflag) {
                if (needRenderButtons.length && needRenderButtons.length > buttonLimit) {
                    list = needRenderButtons.slice(buttonLimit - 1, needRenderButtons.length);
                    needRenderButtons = needRenderButtons.slice(0, buttonLimit - 1);
                }
                renderDropDown = this.renderMoreList(list, this.props.rowIndex);
            } else {
                list = needRenderButtons;
                renderDropDown = this.renderLanglist(list, this.props.rowIndex);
            }
        }

        return (needRenderButtons.length || list.length) ? (
            <span
                className={"opration-wrapper " + (renderModel === 'hover' ? ' opration-wrapper-hover-model ' : '')}
                onClick={e => {
                    stopPropagation && e.stopPropagation();
                }}
            >
                <HotKeys
                    display="inline-block"
                    className="opration-btns-hotkey-wrapper"
                    keyMap={keyHandlers.keyMap}
                    handlers={keyHandlers.handlers}
                    enabled={hotKeysEnabled}
                    focused={true}
                >
                    {showflag &&
                        needRenderButtons.map(item => {
                            if (item.popContent) {
                                return this.popContentItem(item);
                            } else {
                                return (
                                    // 不需要弹窗
                                    <a
                                        fieldid={getSysFieldid(`${item.key}_btn`)}
                                        btn-code={item.key}
                                        className={'row-edit-option row-btn-' + replaceSpecialStr(item.key)}
                                        key={item.key}
                                        onClick={e => this.onClick({ key: item.key, e, item })}
                                    >
                                        {item.title}
                                    </a>
                                );
                            }
                        })}
                    {renderDropDown}
                </HotKeys>
            </span>
        ) : (<span
            className="opration-wrapper"
            onClick={e => {
                stopPropagation && e.stopPropagation();
            }}
        />);
    }
}

export function createButtonApp(config = {}) {
    // this=>Page 组件
    let {
        area,
        onButtonClick,
        buttonLimit,
        popContainer,
        tabindex = -1,
        hotKeysMap = {},
        hotKeysEnabled = true,
        modalRelation,
        ignoreHotkeyCode = [],
        tipKeybodard = 'none',
        notInterruptCode = [],
        withAutoFocus = true,
    } = config;

    return (
        <ButtonApp
            // ref={`${area}ButtonAreaRefName`}
            ref={dom => {
                this.myState[`${area}ButtonAreaRefName`] = dom;
            }}
            _this={this}
            // json={areaButtons}
            area={area}
            onClick={(id, hotkey) => {
                onButtonClick({ ...this.props, ...this.output }, id, hotkey);
            }}
            key={area}
            buttonLimit={buttonLimit}
            popContainer={popContainer}
            tabindex={tabindex}
            hotKeysMap={hotKeysMap}
            hotKeysEnabled={hotKeysEnabled}
            modalRelation={modalRelation}
            ignoreHotkeyCode={ignoreHotkeyCode}
            tipKeybodard={tipKeybodard}
            notInterruptCode={notInterruptCode}
            withAutoFocus={withAutoFocus}
        />
    );
}

export function createOprationButton(wantedButtons = [], config = {}) {
    // this=>Page 组件
    // 后台查回来的按钮和页面传过来的按钮数组进行交集，是页面最终显示的按钮
    let {
        renderModel = getGlobalStorage('localStorage', 'btnRenderModel'), // 'hover'
        area,
        onButtonClick,
        buttonLimit,
        popContainer,
        stopPropagation = true,
        hotKeysMap = {},
        hotKeysEnabled = true,
        modalRelation,
        ignoreHotkeyCode = [],
        notInterruptCode = [],
        withAutoFocus = true,
        templateOrder = false, //按模板顺序显示按钮
    } = config;

    this.myState.oprationIndex += 1;
    // let index = this.myState.oprationIndexCopy || 0;
    buttonLimit = buttonLimit || (renderModel === 'hover' ? 5 : 3);
    return (
        <OprationButton
            ref={dom => {
                // this.myState[`${area}${this.myState.oprationIndex}ButtonAreaRefName`] = dom;
                // this.myState[`${area}${index}ButtonAreaRefName`] = dom;
                this.myState[`${area}ButtonAreaRefName`] = dom;
            }}
            // json={finalButtons}
            onClick={(id, e, hotkey) => {
                onButtonClick({ ...this.props, ...this.output }, id, e, hotkey);
            }}
            _this={this}
            area={area}
            renderModel={renderModel}
            buttonLimit={buttonLimit}
            stopPropagation={stopPropagation}
            oprationIndex={this.myState.oprationIndex}
            wantedButtons={wantedButtons}
            popContainer={popContainer}
            hotKeysMap={hotKeysMap}
            hotKeysEnabled={hotKeysEnabled}
            modalRelation={modalRelation}
            ignoreHotkeyCode={ignoreHotkeyCode}
            rowIndex={config.rowIndex}
            notInterruptCode={notInterruptCode}
            withAutoFocus={withAutoFocus}
            templateOrder={templateOrder}
        />
    );
}

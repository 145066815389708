/**
 * Created by wangshhj on 2018/3/13.
 */

export function show(id, config = {}) {
    if (!this.state.modal.hasOwnProperty(id)) {
        return false;
    }
    let modalData = this.state.modal[id];
    modalData = Object.assign(modalData, config);
    modalData.showModal = true;
    this.setState({ modal: this.state.modal });
}

export function close(id) {
    let modalData = this.state.modal[id];
    modalData.showModal = false;
    this.setState({ modal: this.state.modal });
}
/**
 * Created by wangshhj on 2018/1/11.  本地 xuyang updata
 * 说明 E8PyA2FlmQx8
 * 查询区组件的核心数据存在 this.state.searchInfo内。
 * 查询区内部存在4个状态,simple：简单查询，plan:查询方案，nomarl：普通，super:高级 ,存在 this.state.searchInfo.status。
 * 查询区用户输入的数据（主要是value和 操作符数据）存在 this.state.searchInfo.snapshotMap里面，它有四个属性对应四个状态（simple，plan，nomarl,super）
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import { moment } from '@platform/api';
import { promptBox } from '../PromptBox';
// import { setTimeout } from 'timers';
import { Row, Dropdown, Col, Tooltip as NCTooltip, HotKeys as NCHotKeys, Button as NCButton, Checkbox as NCCheckbox } from '@platform/base';
import { getAllSearchDataEve, closeSearchPlanArea, setMultiLang } from './methods';
import {
    initDefaultSnapshot,
    templateFilter,
    planExtend,
    getTimezoneOffset,
    getFunctionValueFromViewModal,
    _clone,
    allItemsFillComplete,
    setOperationOptionsMultiLang,
    beSureBtnClick,
    beSureBtnClick_Del,
    isDateFunction,
    tree2Array,
} from './_methods';
import { clearAllValueEve } from './methods';
import { getMultiLang, getSysFieldid, ViewModel, localeLang, isFunction, isArray, deepClone, isEmpty, PubSub } from '@platform/api';
import SearchAdvModal from './SearchAdvModal';
import { templateEngine } from './templateEngine';
import SearchAdvCom from './SearchAdvCom';
// import toast from '../../api/toast';
// import { getMultiLang } from '../GetMultiLang';
// import { localeLang } from '../../public/currentLocale';
// import ViewModel from '../../shell/viewmodel/viewmodel';
import { setMultiLang as getFunctionList, timeFunctionHandler } from 'platform/base/DatePickerTimeZoneConversion/dateFunction';
// import getSysFieldid from '../../api/getSysFieldid';
// 外层方案
import Item from './item';

// 查询区每一项
import SearchItem from './searchItem';

// import searchOpen from '../../../public/static/images/searchOpen.png';
// import searchClose from '../../../public/static/images/searchClose.png';

require('./createSearch.less');
// 查询区渲染完成发布订阅标识
const BEFORECOMPLETE = 'beforeAreaChangeComplete';
const COMPLETE = 'areaChangeComplete';
const INITNUM = 5;
let ConstSearchInfo = {};

let searchPlanId = 'search@plan';

export default class SearchComponent extends Component {
    constructor(props) {
        super(props);
        this.completeFlag = true;
        this.state = {
            searchInfo: {},
            json: {},
            LangData: null,
            leftTab: '1',
            isRenderAdv: false,
        };
        this.props.get_this(this); //把当前this 注册到全局this去
        this._this = this.props.getGloablThis();
        this.meta = this._this.state.meta[this.props.id];
        this.preHandlerFlag = true;
        this.searchAreaHeight = 0;
    }

    componentDidMount() {
        let meta = this.meta;
        let that = this;
        if (this.completeFlag) {
            let renderCompleteEvent = this.props.renderCompleteEvent;
            // 发布通知表格更新高度
            PubSub.publish(COMPLETE, true);
            setTimeout(() => {
                if (that.completeFlag && !isEmpty(meta)) {
                    that._this.infoExchangeController.publish('heightChange', { area: 'searchArea' });
                    renderCompleteEvent && renderCompleteEvent('');
                    that.completeFlag = false;
                }
            });
        }
    }
    // componentWillUpdate(){
    //     let meta = this.meta;
    //     let that = this;
    //     if (this.completeFlag) {
    //         let renderCompleteEvent = this.props.renderCompleteEvent;
    //         // 发布通知表格更新高度
    //         PubSub.publish(COMPLETE, true);
    //         setTimeout(() => {
    //             if (that.completeFlag && !isEmpty(meta)) {
    //                 that._this.infoExchangeController.publish('heightChange', { area: 'searchArea' });
    //                 renderCompleteEvent && renderCompleteEvent('');
    //                 that.completeFlag = false;
    //             }
    //         });
    //     }
    // }
    render() {
        return NCCreateSearch.call(this, this.props);
    }

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        // 说明切换查询区了 by liyxt
        if (this.props.id !== nextProps.id) {
            this.completeFlag = true;
            nextProps.get_this(this); //把当前this 注册到全局this去
            this.meta = deepClone(this._this.state.meta[nextProps.id]);
            // console.log(this.meta);
        } else {
            this.meta = this._this.state.meta[nextProps.id];
        }
        //根据模板先把快照初始化出来
        let info = this.state.searchInfo;
        let defaultRenderobj4web = initDefaultSnapshot(this.meta.items, nextProps.context, nextProps.isTemplateOrder);
        info.fixedConditionGroup = defaultRenderobj4web.filter(item => item.isExtend) || [];
        info.defaultPlan = {
            pk_ncc_queryscheme: '001',
            isMyPlan: true,
            name: '预置方案',
            renderobj4web: defaultRenderobj4web
        };
        info.searchPlanCache = [info.defaultPlan, ...(this.meta.queryschemes || [])];
        if (!this.state.searchInfo.showAdvModal && !nextProps.onlyShowAdvArea) {
            initSearchArea.call(this, nextProps, this.state.searchInfo);
        }
    }

    componentDidUpdate() {
        if (this.searchDom) {
            // if (this.state.searchInfo.status == 'simple') {
            //     this.state.searchInfo.currentPlan.renderobj4web_snap = this.state.searchInfo.snapshotMap.simple;
            //     this.state.searchInfo.searchPlanSnap[this.state.searchInfo.currentPlan.pk_ncc_queryscheme] = this.state.searchInfo.currentPlan;
            // }
            if (this.searchAreaHeight !== this.searchDom.offsetHeight) {
                // 发布通知表格更新高度
                PubSub.publish(COMPLETE, true);
                this.searchAreaHeight = this.searchDom.offsetHeight;

                // if (this.completeFlag) {
                //     let renderCompleteEvent = this.props.renderCompleteEvent;
                //     renderCompleteEvent && renderCompleteEvent('');
                //     this.completeFlag = false;

                // }

            }

        }

    }

    componentWillMount() {
        //根据模板先把快照初始化出来
        let info = this.state.searchInfo;
        let defaultRenderobj4web = initDefaultSnapshot(this.meta.items, this.props.context, this.props.isTemplateOrder);
        info.fixedConditionGroup = defaultRenderobj4web.filter(item => item.isExtend) || [];
        info.defaultPlan = {
            pk_ncc_queryscheme: '001',
            isMyPlan: true,
            name: '预置方案',
            renderobj4web: defaultRenderobj4web
        };
        info.searchPlanCache = [info.defaultPlan, ...(this.meta.queryschemes || [])];
        initSearchArea.call(this, this.props, info);
        // 初始化调用getMultiLang方法获取多语
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData });
        };

        getMultiLang({ moduleId: 'page_search', callback }); // moduleId为所需加载json文件夹名称前缀
        if (this.props.useCache) {
            let searchInfo = ViewModel.getData(`search_${this.meta.oid || this.props.id}`);
            if (searchInfo) {
                this.setState({ searchInfo });
            }
        }

    }

    // componentDidMount() {
    //     //解决NCC-38603问题
    //     PubSub.publish(COMPLETE, true);
    // }

    componentWillUnmount() {
        // this.props.remove_this();
        // if(this.meta&&this.meta.queryschemes)
        // 	this.meta.queryschemes.splice(0,1);
        this.completeFlag = true;
        if (this.props.useCache) {
            ViewModel.setData(`search_${this.meta.oid || this.props.id}`, this.state.searchInfo);
        }
        PubSub.publish(COMPLETE, true);
        // PubSub.publishSync(BEFORECOMPLETE, true);
        // 移除 pubsub 事件监听
        PubSub.unsubscribe('searchRequired');
        PubSub.unsubscribe('clearWarning');
    }
}

function initSearchArea(props, info) {
    let statusList = ['simple', 'normal', 'super'];
    let isEdit = false;//模板是否改变
    let flag = false;//标识是否是第一次初始化
    // info.status = props.onlyShowAdvArea ? 'normal' : 'simple';
    info.status = 'simple';
    // let preMetaMap = info.isCompleted ? { ...info.itemsMap } : {};
    info.itemsMap = {};
    let meta = this.meta;

    meta.items.forEach(item => (info.itemsMap[item.attrcode] = item));
    info.snapshotMap = {};
    statusList.forEach(item => {
        info.snapshotMap[item] = [];
    });


    let conditions;
    if (meta.queryschemes) {
        meta.queryschemes.forEach(item => {
            if (item.isquickqs) {
                conditions = item;
            }
        });
    }
    if (!info.hasOwnProperty('moduletype')) {
        info.snapshotCache = {};
        statusList.forEach(item => {
            info.snapshotCache[item] = {};
        });
        info.moduletype = 'search';
    }

    //用于存储 查询区的基本状态 用于重置
    if (!ConstSearchInfo.hasOwnProperty(props.id)) {
        // info.searchPlanSnap = {};
        //如果没有高级就一直展开，如果有高级就按照isunfold为true展开
        info.showSearchItems = !meta.isunfold;
        info.showSearchItems = props.showAdvBtn ? info.showSearchItems : false;
        info.showAdvModal = false; // 高级面板的显隐性
        info.showSearchPlan = false; // 显示查询方案
        ConstSearchInfo[props.id] = {};
    }
    if (!info.isCompleted) {
        if (conditions && !meta.isclosequickquery) {
            // 快速方案添加到 当前方案
            info.currentPlan = conditions;
        } else {
            info.currentPlan = info.defaultPlan;
        }
    }
    //这里判断模板有没有改变有问题，因为是过滤了visible的，可能只是修改了visible
    if (info.currentPlan.isMyPlan) {
        // let prev = Object.keys(preMetaMap).join();
        // let next = Object.keys(info.itemsMap).join();
        let prev = '';
        let next = '';
        //预制方案
        info.currentPlan.renderobj4web.forEach(item => prev = prev + item.attrcode);
        info.defaultPlan.renderobj4web.forEach(item => next = next + item.attrcode);
        //模板变了，可能添加了几个字段，要恢复之前的value
        if (prev !== next) {
            isEdit = true;
            info.currentPlan.renderobj4web = info.defaultPlan.renderobj4web;
            // info.currentPlan = info.defaultPlan;
        }
    } else {
        let plan = info.searchPlanCache.find(item => item.pk_ncc_queryscheme == info.currentPlan.pk_ncc_queryscheme);
        //说明查询区的查询方案变了
        if (!plan) {
            info.currentPlan = info.defaultPlan;
        }
    }


    info.isDefaultPlan = info.currentPlan.pk_ncc_queryscheme == '001';
    //初始化并恢复快照
    initSimpleSnap(props.context, info, info.currentPlan, true, isEdit);
    info.snapshotMap['simple'] = info.currentPlan.renderobj4web_snap;

    if (!info.hasOwnProperty('isCompleted')) {
        info.isCompleted = true;
        flag = true;
    }
    if (flag) {
        isFunction(props.setInitValueEvent) && (props.setInitValueEvent('simple'));
    }



}
// 展开查询方案 并处理成 快照
function spreadQueryPlan(context, planItems, temItem, preSnapMap = {}, level = 0) {
    if (Array.isArray(planItems)) {
        return planItems.map(item => {
            if (Array.isArray(item.children)) {
                item.children = spreadQueryPlan(context, item.children, temItem, preSnapMap, ++level);
                return item;
            } else {
                let initialvalue = {
                    value: '',
                    display: '',
                };
                if (preSnapMap[item.attrcode]) {
                    item.initialvalue = preSnapMap[item.attrcode];
                }
                item.initialvalue = item.initialvalue || item.value; //此处是为了兼容老版本代码的value 已经变成initialvalue了
                if (!isEmpty(item.initialvalue)) {
                    initialvalue.value = item.initialvalue.value;
                    initialvalue.display = item.initialvalue.display;
                }
                let tempItem = temItem[item.attrcode];
                if (tempItem) {
                    if (tempItem.itemtype === 'checkbox_switch') {
                        if (item.initialvalue && item.initialvalue.value === '') {
                            item.initialvalue.value = false;
                        }
                    } else if (tempItem.itemtype === 'refer' && item.initialvalue) {
                        let refervalue = getFunctionValueFromViewModal(item.initialvalue, context);
                        item.initialvalue.display = refervalue.display || item.initialvalue.display;
                    } else if (tempItem.itemtype === 'residtxt') {
                        let index = tempItem.languageMeta.filter(val => val.languageCode == localeLang)[0]?.index || 1;
                        initialvalue.index = index;
                        if (item.initialvalue && !item.initialvalue.hasOwnProperty('index')) {
                            item.initialvalue.index = index;
                        }
                    }
                    let visible = item.isDefault ? item.visible : item.visible === false ? false : true;
                    let obj = {
                        attrcode: item.attrcode,
                        initialvalue: initialvalue,

                        operationSign:
                            item.operationSign || (item.queryOperateType ? item.queryOperateType.split('@')[0] : ''),
                        visible: visible,
                        isExtend: !!tempItem.isfixedcondition,
                    };
                    if (tempItem.itemtype === 'residtxt') {
                        if (!isEmpty(item.valueMap)) {
                            obj.valueMap = item.valueMap;
                        } else if (item.initialvalue && item.initialvalue.value) {
                            obj.valueMap = {};
                            obj.valueMap[item.attrcode + item.initialvalue.index] = { value: item.initialvalue.value };
                        }
                    }
                    // if (tempItem.isfixedcondition && level < 2) {
                    //     obj.visible = false;
                    // }
                    return obj;
                }
            }
        }).filter(obj => obj && obj.visible);
    }
}



function initSimpleSnap(context, info, currentPlan, flag = true, isEdit = false) {
    if (!currentPlan) return;
    let preSnap = info.currentPlan.renderobj4web_snap;
    let preSnapValueMap = {};
    if (preSnap) {
        preSnap.forEach(item => {
            preSnapValueMap[item.attrcode] = item.initialvalue;
        })
    }
    let isDefaultPlan = currentPlan.pk_ncc_queryscheme == '001';
    if (currentPlan.renderobj4web_snap && flag) {
        if (isDefaultPlan) {
            if (isEdit) {
                currentPlan.renderobj4web_snap = spreadQueryPlan(context, currentPlan.renderobj4web, info.itemsMap, preSnapValueMap);
            }

        }
        // else {
        //     currentPlan.renderobj4web_snap = spreadQueryPlan(context, currentPlan.renderobj4web, info.itemsMap, preSnapValueMap);
        // }
    } else {
        if (isDefaultPlan) {
            currentPlan.renderobj4web_snap = spreadQueryPlan(context, info.defaultPlan.renderobj4web, info.itemsMap);
        } else {
            if (currentPlan.renderobj4web && currentPlan.renderobj4web.length && currentPlan.renderobj4web[0].refpk === 'root') {
                currentPlan.renderobj4web[0].visible = true
                let superSnap = [...info.fixedConditionGroup, ...currentPlan.renderobj4web[0].children];
                let currentPlanCopy = _clone(currentPlan);
                currentPlanCopy.renderobj4web[0].children = superSnap;
                currentPlan.renderobj4web_snap = spreadQueryPlan(context, currentPlanCopy.renderobj4web, info.itemsMap);
            } else {
                let filterFix = Array.isArray(currentPlan.renderobj4web) ? currentPlan.renderobj4web.filter(item => {
                    let fixCondition = info.fixedConditionGroup.find(a => a.attrcode == item.attrcode);
                    if (fixCondition) {
                        return false;
                    } else {
                        return true;
                    }
                }) : [];
                let normalSnap = [...info.fixedConditionGroup, ...filterFix];
                currentPlan.renderobj4web_snap = spreadQueryPlan(context, normalSnap, info.itemsMap);
            }
        }
    }

}



function NCCreateSearch(
    {
        id,
        clickSearchBtn, // 查询按钮点击事件回调，只返回公共查询条件，不包含自定义查询条件；如果有自定义查询条件，需要业务组在clickPlanEve方法中接收查询方案信息，并保存到业务组的state中，然后在 clickSearchBtn方法中自行合并公共条件和自定义条件。
        searchBtnName = '',
        onAfterEvent, // 编辑后事件
        showSearchBtn = true, // 是否显示查询按钮 ,默认显示
        showAdvBtn = true, // 是否显示高级查询按钮和查询方案按钮 ,默认显示
        showClearBtn = true, // 是否显示清空按钮
        showAdvSearchPlanBtn = true, // 高级面板中是否显示保存和另存方案按钮
        replaceAdvBtnEve, // 业务组替换高级面板 (fun)
        replaceAdvBody, // 业务组替换高级面板中的body (fun)
        addAdvTabs, // 添加高级查询区自定义页签 (fun)
        addAdvBody, // 添加高级查询区右侧自定义查询条件Dom (fun)
        replaceRightBody, // 替换高级面板右侧body区域 (fun)
        // oid, // 查询模板的oid
        hideSearchCondition = false, // 是否显示高级查询左侧的候选条件
        hideSearchPlan = false, // 是否显示高级查询左侧的查询方案
        saveSearchPlan, // 保存查询方案确定按钮事件 ，用于业务组返回自定义的查询条件
        clickPlanEve, // 点击高级面板中的查询方案事件 ,用于业务组为自定义查询区赋值
        clickAdvBtnEve, //单击高级之后的回调方法
        hideBtnArea = false, // 隐藏查询按钮区域，默认false
        advSearchClearEve, // 高级查询面板中的清空按钮 点击事件回调，用于业务组清除自定义查询条件值
        onlyShowSuperBtn = false, // 只显示高级按钮
        replaceSuperBtn = '', // 替换高级按钮
        onOperateTypeChange, // 操作符改变时的回调
        onlyShowAdvArea = false, //在查询区只显示 高级查询部分
        onlyShowModal = false, //在查询区只显示模态框
        renderCompleteEvent, //渲染完成回调函数
        statusChangeEvent, //状态切换
        context = {},
        renderItem = {},
        createScript = function () { },
        setInitValueEvent,
        isSynInitAdvSearch,
        onlyShowPlan = false, // 只显示方案列表和高级查询
        searchModalTitle = '', // 高级查询区 modal title
        searchConditionName = '', //高级查询 "查询条件" 名称自定义
        currentSearch = true,
        fieldid,
        opneBtnClick,//点击展开收起的回调
        removeQuerConditionEve,//删除查询条件的回调
        isTemplateOrder,
        hideSearchType = false,//隐藏高级擦查询的普通和高级radio
        onlyShowSuperCondition = false,
        hiddenFunList,
        modalCloseEve
    } = {},
) {
    let searchInfo = this.state.searchInfo;
    let meta = this.meta;
    //给供应链郑新测试用
    // let meta = this._this.state.meta[id];
    // let context = this.props.context;
    let oid = meta.oid;
    let multiLang = this.state.json;
    let LangData = this.state.LangData;
    setStateEve = setStateEve.bind(this);
    this.oid = oid;
    //这两句要回头看看xuyangt
    searchAdvBtnClick = searchAdvBtnClick.bind(this);
    this.state.searchAdvBtnClick = searchAdvBtnClick;

    this.state.chooseSearchPlan = (item) => { chooseSearchPlan(item) };

    // initSearchArea.call(this, searchInfo);

    if (!isEmpty(multiLang)) {
        setMultiLang(multiLang);
        setOperationOptionsMultiLang(multiLang);
        if (this.state.searchInfo.hasOwnProperty('snapshotMap') && this.preHandlerFlag) {
            this.preHandlerFlag = false;
            metaPreHandler(meta, multiLang);
        }
    }

    if (!isEmpty(multiLang) && !isEmpty(searchInfo.searchPlanCache)) {
        searchInfo.searchPlanCache.forEach(item => {
            if (item.pk_ncc_queryscheme == '001') {
                item.name = multiLang['page-search-0018'];
            }
        });
        if (searchInfo.defaultPlan) {
            searchInfo.defaultPlan.name = multiLang['page-search-0018'];
        }
        if (searchInfo.currentPlan && searchInfo.currentPlan.pk_ncc_queryscheme == '001') {
            searchInfo.currentPlan.name = multiLang['page-search-0018'];
        }

    }
    searchBtnName = searchBtnName || <i className="iconfont icon-sousuo" />;
    replaceSuperBtn = replaceSuperBtn || multiLang['page-search-0003'];

    // 查询区初始化配置相关方法



    //更新查询区数据
    function setStateEve(callBack) {
        this.setState(
            { searchInfo },
            function () {
                if (typeof callBack === 'function') {
                    callBack();
                }
            },
        );
    }
    function metaPreHandler(meta, multiLang) {
        const items = meta.items;
        const functionList = getFunctionList(multiLang);
        const select = 'select';
        const targt = ['datepicker',
            'datetimepicker',
            'rangepicker',
            'NCTZDatePickerStart',
            'NCTZDatePickerEnd',
            'NCTZDatePickClientTime',
            'NCTZDatePickClientHourTime',
            'NCTZDatePickerRangeDay',
            'datePickerNoTimeZone'];
        const handler = i => {
            if (i.children) {
                i.children.forEach(ii => handler(ii));
            } else {
                const item = items.find(ii => ii.attrcode == i.attrcode) || {};
                const mytar = [select, ...targt];
                let value = i.initialvalue.value;
                if (value && mytar.some(ii => ii == item.itemtype)) {
                    if (i.operationSign === 'between' || item.isMultiSelectedEnabled) {
                        if (typeof value == 'string') {
                            value = value.split(',');
                        }
                    }
                    if (item.itemtype == 'select') {
                        if (isEmpty(item.options)) {
                            item.options = [i.initialvalue];
                        }
                        let res = item.options.filter(ii => {
                            if (typeof value == 'string') {
                                return ii.value == value;
                            } else {
                                return value.find(iii => ii.value === iii);
                            }
                        }) || [];
                        i.initialvalue.display = res.map(ii => ii.display).join(',');

                    } else if (targt.some(ii => ii == item.itemtype)) {
                        if (Array.isArray(value)) {
                            i.initialvalue.display = value.map(ii => (timeFunctionHandler(ii) ? functionList[ii] : ii)).join('~');
                        } else {
                            i.initialvalue.display = timeFunctionHandler(value) ? functionList[value] : value;
                        }
                    }
                }
            }
        };
        if (Array.isArray(meta.queryschemes)) {
            meta.queryschemes.forEach(qs => {
                if (Array.isArray(qs.renderobj4web)) { qs.renderobj4web.forEach(i => handler(i)); }
            });
        }

    }
    /* 请求到查询方案后，如果有默认方案，则筛选显示默认方案；没有默认方案，显示visible:true的条件 */
    function afterQuerySearchPlan(res) {
        if (res.length) {
            res.forEach(item => item.renderobj4web_snap = spreadQueryPlan(item.renderobj4web));//过滤掉undefined
            let conditions = res.find(item => item.isquickqs);
            if (conditions) {
                searchInfo.status = 'simple';
                searchInfo.snapshotMap[searchInfo.status] = searchInfo.fixedConditionGroup.concat(conditions.renderobj4web_snap);
                // 快速方案添加到 当前方案
                searchInfo.currentPlan = conditions;
                searchInfo.isDefaultPlan = false;
            }
        }
    }



    /* 从模板借口获得查询方案*/
    function querySearchPlanFromTemplate(plan) {
        if (plan) {
            searchInfo.searchPlanCache = [...plan];
        } else {
            searchInfo.searchPlanCache = [];
        }
        searchInfo.searchPlanCache.unshift(searchInfo.defaultPlan);

        /* 等后台候选条件查询写好了之后，逻辑就没问题了 -- wangshhj */
        afterQuerySearchPlan(searchInfo.searchPlanCache);
        // ConstSearchInfo[id] = deepClone(searchInfo);
        //setStateEve();
    }
    //点击查询按钮事件
    const clickSearch = () => {
        setTimeout(() => {
            let type = 'simple';

            let snapshot = searchInfo.snapshotMap[searchInfo.status];
            let data = getAllSearchDataEve.call(this, id, snapshot, true);
            if (data) {
                let template = templateFilter(searchInfo.itemsMap, snapshot);

                let finalData = {
                    queryAreaCode: id,
                    oid,
                    querytype: 'tree',
                    querycondition: data,
                    template,
                    nctz: getTimezoneOffset(),
                };

                if (clickSearchBtn && typeof clickSearchBtn == 'function') {
                    clickSearchBtn({ ...this._this, ...this._this.props }, data, type, data && finalData);
                }
            }
        }, 0);
    };

    //重置按钮事件
    const resetEvent = () => {
        let oldvalue = _clone(searchInfo.snapshotMap[searchInfo.status]);
        let snapshot = searchInfo.status === 'plan' ? searchInfo.currentSnapshot : searchInfo.snapshotMap[searchInfo.status];
        clearAllValueEve.call(this, snapshot);
        this.state.searchInfo = searchInfo;
        if (advSearchClearEve && typeof advSearchClearEve == 'function') {
            advSearchClearEve(oldvalue);
        }

        setStateEve();
    };

    /* 高级查询按钮事件,控制查询面板的显影性 */
    function searchAdvBtnClick(search, type, choosePlanName) {

        if (isFunction(replaceAdvBtnEve)) {
            replaceAdvBtnEve();
        } else {
            /* 请求候选条件 */ //暂时不访问请求候选条件的接口
            document.body.style.overflow = 'hidden';
            search.showAdvModal = true;
            search.showSearchPlan = false;
            this.lastStatus = search.status;
            let currentClickPlan = search.searchPlanCache.find(item => item.name === choosePlanName);
            //如果点击的查询方案和当前方案不一致
            if (currentClickPlan && currentClickPlan.pk_ncc_queryscheme !== search.currentPlan.pk_ncc_queryscheme) {
                search.currentClickPlan = currentClickPlan;
            }
            search.isDefaultPlan = search.currentPlan.pk_ncc_queryscheme == '001';
            search.currentPlan.renderobj4web_snap || initSimpleSnap(context, search, search.currentPlan);
            search.currentClickPlan?.renderobj4web_snap || initSimpleSnap(context, search, search.currentClickPlan)
            let currentPlan = search.currentClickPlan || search.currentPlan;
            if (currentPlan && !isEmpty(currentPlan.renderobj4web_snap) && currentPlan.renderobj4web_snap[0].attrcode == 'root') {
                search.status = 'super';
            } else {
                search.status = 'normal';
            }
            if (type?.target) type = '1';
            this.state.leftTab = type || '1';
            this.state.isRenderAdv = true;
            if (choosePlanName) {
                PubSub.publish('searchChoosePlan', choosePlanName);
            }
            setStateEve(() => {
                if (isFunction(clickAdvBtnEve)) {
                    clickAdvBtnEve();
                } else if (isFunction(statusChangeEvent)) {
                    statusChangeEvent(search.status);
                }
            });
        }
    }

    //生成查询、清空、高级 按钮
    const createButton = () => {
        return (
            <div className='search-component-rowArea'>
                {!onlyShowSuperBtn &&
                    showSearchBtn && (
                        <NCTooltip
                            delayShow={800}
                            inverse
                            overlay={<div>{typeof searchBtnName == 'string' ? searchBtnName : `${multiLang['page-search-0001']}(Enter)`}</div>}
                            placement="top"
                        >
                            <span
                                fieldid={getSysFieldid('search_btn')}
                                className={
                                    showAdvBtn ? (
                                        'search-component-searchBtn'
                                    ) : (
                                        'search-component-searchBtn hide-adv-btn'
                                    )
                                }
                                onClick={clickSearch.bind(this)}
                            >
                                {searchBtnName}
                            </span>
                        </NCTooltip>

                    )}
                {!onlyShowSuperBtn &&
                    showClearBtn && (
                        <NCTooltip
                            delayShow={800}
                            inverse
                            overlay={<div style={{ padding: '0' }}>{`${multiLang['page-search-0002']}(Alt+Del)`}</div>}
                            placement="top"
                        >
                            <span
                                className="clearBtn"
                                fieldid={getSysFieldid('searchClear_btn')}
                                onClick={resetEvent.bind(this)}
                            >
                                <i className="iconfont icon-qingkong1" />
                            </span>
                        </NCTooltip>
                    )}
                {onlyShowSuperBtn && (
                    <NCTooltip
                        delayShow={800}
                        inverse
                        overlay={<div>{replaceSuperBtn}</div>}
                        placement="top"
                    >
                        <NCButton
                            style={{ maxWidth: onlyShowSuperBtn ? 'initial' : '29%' }}
                            // className="superBtn"
                            colors="primary"
                            onClick={searchAdvBtnClick.bind(this, searchInfo)}
                            fieldid="replaceSuper"
                        >
                            {replaceSuperBtn}
                        </NCButton>
                    </NCTooltip>

                )}
            </div>
        );
    };

    // 生成查询条件
    const createDom = (snapshotItem, flag = false, buttonFlag, zindex, index) => {
        if (buttonFlag && zindex === 0) {
            return (
                !searchInfo.showSearchItems && (
                    <div className="search-button">
                        {hideBtnArea ? null : createButton()}
                    </div>
                )
            );
        }
        let templetItem = searchInfo.itemsMap[snapshotItem.attrcode];
        if (!templetItem) {
            //toast({ content: '查询方案损坏！', color: 'warning' });
            return null;
        } else {
            let dom = new templateEngine({
                id,
                isAdvDom: false,
                searchInfo,
                snapshotItem,
                onAfterEvent,
                setStateEve,
                renderItem,
                context: this.props.context,
                createScript,
                multiLang,
                LangData,
                clickSearch,
                hiddenFunList
            });
            return (
                <SearchItem
                    areaId={id}
                    key={snapshotItem.attrcode + zindex + index}
                    name={snapshotItem.attrcode}
                    itemtype={templetItem.itemtype}
                    fieldid={getSysFieldid(snapshotItem.attrcode)}
                    style={{ marginTop: flag ? '8px' : '0' }}
                    className="search-item-wrap"
                    dom={dom}
                    hotkeytag={`searchArea-${snapshotItem.attrcode}`}
                />
            );
        }
    };

    // 显示查询方案
    const showSearchPlanEve = e => {
        e.stopPropagation();
        searchInfo.showSearchPlan = !searchInfo.showSearchPlan;
        setStateEve();
    };
    // 隐藏查询方案
    const hideSearchPlanEve = () => {
        searchInfo.showSearchPlan = false;
        setStateEve();
    };
    // 点击查询方案  plan => simple
    const chooseSearchPlan = (item, e) => {
        if (item.pk_ncc_queryscheme == searchInfo.currentPlan.pk_ncc_queryscheme) return;
        let attrcodeArr = [];
        // let searchInfo = this.state.searchInfo;
        searchInfo.status = 'simple';
        searchInfo.currentPlan = item;
        searchInfo.isDefaultPlan = item.pk_ncc_queryscheme == '001';
        if (!searchInfo.userControlAdvArea) {
            let advId = id;
            afterClickSearchPlan(advId);
        }
        initSimpleSnap(context, searchInfo, item, false);
        searchInfo.snapshotMap.simple = item.renderobj4web_snap;

        searchInfo.currentSnapshot = [];

        searchInfo.snapshotMap.simple.forEach(item => {
            if (item.initialvalue) {
                let VALUE = item.initialvalue;
                let tempItem = searchInfo.itemsMap[item.attrcode];
                if (tempItem.itemtype === 'refer') {
                    VALUE = getFunctionValueFromViewModal(VALUE, context);
                }
                if (isEmpty(VALUE.value) && isEmpty(VALUE.display)) {
                    searchInfo.currentSnapshot.push(item);
                }
            }
            // if (searchInfo.snapshotCache && searchInfo.snapshotCache[searchInfo.status] && searchInfo.snapshotCache[searchInfo.status][item.attrcode]) {
            //     item.initialvalue = searchInfo.snapshotCache[searchInfo.status][item.attrcode];
            // }
            attrcodeArr.push(item.attrcode);
        });

        hideSearchPlanEve();

        if (isFunction(clickPlanEve)) {
            clickPlanEve(item, this._this);
        }
        isFunction(setInitValueEvent) && setInitValueEvent('simple');
        isFunction(statusChangeEvent) && statusChangeEvent('simple');
        //暂时去掉保存选择查询方案时 查询的方法 xuyang
        if (isFunction(clickSearchBtn) && allItemsFillComplete(searchInfo.snapshotMap[searchInfo.status], searchInfo, context)) {
            clickSearch();
        }
        //选择查询方案的时候触发变化方法
        this._this.infoExchangeController.publish('heightChange', { area: 'searchArea' });
        // 发布通知表格更新高度
        PubSub.publish(COMPLETE, true);
        // 切换方案时  clearWraning
        PubSub.publish('clearWarning', attrcodeArr);
    };
    // 修改方案名称
    const editSearchPlan = (index, newValue) => {
        this.state.searchPlanName = newValue;
    };
    /* 删除查询方案 */
    const delSearchPlan = pk_ncc_queryscheme => {
        // this.props.modal.show('delSearchPlan@' + id);
        promptBox({
            color: 'warning',
            hotKeyboard: true,
            title: multiLang['page-search-0031'],
            content: <div dangerouslySetInnerHTML={{ __html: multiLang['page-search-0015'] }} />, // 提示内容,非必输
            beSureBtnName: multiLang['page-search-0009'], // 确定按钮名称, 默认为"确定",非必输
            cancelBtnName: multiLang['page-search-0010'],
            beSureBtnClick: beSureBtnClick_Del.bind(this, true, pk_ncc_queryscheme),
        });
    };
    // 创建查询方案
    const createSearchPlan = () => {
        // console.log(searchInfo)
        let searchPlan = searchInfo.searchPlanCache;
        let dom = null;
        if (!searchPlan || !searchPlan.length) {
            dom = (
                <a>
                    <p className="searchPlanList" fiedlid={`${multiLang['page-search-0019']}_sub-btn`}>
                        <span className="searchPlanName">{multiLang['page-search-0019']}</span>
                    </p>
                    <p className="searchPlanList" fiedlid={`${multiLang['page-search-0020']}_sub-btn`}>
                        <span className="addSearchPlanHref" onClick={searchAdvBtnClick.bind(this, searchInfo)}>
                            {multiLang['page-search-0020']}
                        </span>
                    </p>
                </a>
            );
        } else {
            // 外层方案查询 (已提出,可放置到内部)
            dom = searchPlan.map((key, index) => (
                <p key={key.pk_ncc_queryscheme} className="searchPlanList" fielid={`${key.name}_sub-btn`}>
                    <Item
                        item={key}
                        value={key.name}
                        searchInfo={searchInfo}
                        chooseSearchPlan={chooseSearchPlan.bind(this, key)}
                        searchAdvBtnClick={searchAdvBtnClick.bind(this, searchInfo, '2', key.name)}
                        editSearchPlan={editSearchPlan.bind(this, index)}
                        delSearchPlan={delSearchPlan.bind(this, key.pk_ncc_queryscheme)}
                        beSureBtnClick={beSureBtnClick.bind(this, 'edit', true, key.name)}
                        showFlag={searchInfo.showFlag}
                        multiLang={multiLang}
                    />
                </p>
            ));
        }
        return (
            <div
                className={`searchPlanBox  ${!searchInfo.showSearchPlan ? 'hideMore' : null}`}
            >
                {dom}
            </div>
        );
    };

    /* 关闭模态框 */
    const closeModal = (flag) => {
        searchInfo.showAdvModal = false;
        !flag && modalCloseEve && modalCloseEve()
        setStateEve();
    };

    /* 修改查询方案 */
    const searchPlanCacheChange = newSearch => {
        searchInfo.searchPlanCache = newSearch;
        //把新的方案同步到模板上
        this._this.state.meta[id].queryschemes = newSearch.filter(item => item.pk_ncc_queryscheme != '001');
        setStateEve();
    };
    // 更新界面方案(外部请求处理需要) add by wanglongx
    const upDateSearchPlan = newSearch => {
        searchInfo.snapshotMap['simple'] = newSearch.renderobj4web;
        searchInfo.currentPlan = newSearch;
        setStateEve();
    };
    /* 点击查询方案之后,需要展示未填写的查询条件 */
    const afterClickSearchPlan = dataSource => {
        searchInfo.dataSource = dataSource; // 查询方案区域的数据来源 ,高级面板中的普通区域id/高级区域的id
        setStateEve();
    };

    const getFuntionValue = (item, multiLang) => {
        const functionList = {
            '#day(-1)#': multiLang['page-search-0060'],
            '#day(0)#': multiLang['page-search-0061'],
            '#day(1)#': multiLang['page-search-0062'],
            '#month(-1)#': multiLang['page-search-0063'],
            '#month(0)#': multiLang['page-search-0064'],
            '#month(1)#': multiLang['page-search-0065'],
            '#week(-1)#': multiLang['page-search-0066'],
            '#week(0)#': multiLang['page-search-0067'],
            '#week(1)#': multiLang['page-search-0068'],
            '#quarter(-1)#': multiLang['page-search-0069'],
            '#quarter(0)#': multiLang['page-search-0070'],
            '#quarter(1)#': multiLang['page-search-0071'],
            '#year(-1)#': multiLang['page-search-0072'],
            '#year(0)#': multiLang['page-search-0073'],
            '#year(1)#': multiLang['page-search-0074'],
            '#lastDayOfMonth#': multiLang['page-search-0075'],
            '#lastDayOfWeek#': multiLang['page-search-0076'],
            '#finalDayOfLastMonth#': multiLang['page-search-0077'],
        };
        let valArr = [];
        let resultArr = [];
        let val = item.initialvalue.value;
        if (val && typeof val == 'string') {
            valArr = val.split(',');
        } else {
            valArr = val;
        }
        valArr.forEach(i => {
            if (i && i.indexOf('#') != -1) {
                resultArr.push(functionList[i]);
            }
        })
        if (resultArr.length) {
            return resultArr.join(',');
        }
        return item.initialvalue.display;
    };


    // count search conditions --- add by wanglongx
    const countQueryConditions = (item, multiLang) => {
        switch (searchInfo.itemsMap[item.attrcode].itemtype) {
            case 'refer':
                return item.initialvalue.display;
            case 'select':
                let options = searchInfo.itemsMap[item.attrcode].options;
                let option = options.find(i => i.value == item.initialvalue.value);
                if (option) {
                    return option.display;
                }
                return item.initialvalue.display;
            case 'datepicker':
                return getFuntionValue(item, multiLang);
            case 'NCTZDatePickerEnd':

                return getFuntionValue(item, multiLang);
            case 'NCTZDatePickerStart':
                return getFuntionValue(item, multiLang);
            case 'radio':
                return item.initialvalue.display;
            case 'checkbox':
                return <NCCheckbox checked={!(item.initialvalue.value === 'N' || item.initialvalue.value === false)} disabled />;
            case 'checkbox_switch':
                return <NCCheckbox checked={!(item.initialvalue.value === 'N' || item.initialvalue.value === false)} disabled />;
            case 'rangepicker':
                return getFuntionValue(item, multiLang);
            case 'datetimepicker':
                return getFuntionValue(item, multiLang);
            default:
                return item.initialvalue.value;
        }
    };

    // add by wanglongx 

    const createTitle = () => {

        let res = null;

        res = <span>{multiLang['page-search-0025']}: <span className="search-conditions-number">({tree2Array(searchInfo.snapshotMap['simple']).filter(item => checkIsVisible(item)).length})</span></span>;
        return res;
    };

    // 防抖——触发高频事件后 n 秒后函数只会执行一次，如果n毫秒内高频事件再次被触发，则重新计算时间；
    const debounce = (fn, interval) => {
        let timeout = null; // 创建一个标记用来存放定时器的返回值
        return function () {
            if (timeout) {
                clearTimeout(timeout); // 每当事件触发的时候把前一个 setTimeout clear 掉
            }
            timeout = setTimeout(() => { // 然后又创建一个新的 setTimeout, 这样就能保证interval间隔时间触发的话，就不会执行 fn 函数
                fn.apply(this, arguments);
            }, interval);
        };
    }
    // const searchEnter = debounce.call(this, searchEnter, 1500);
    const searchEnter = () => {
        document.activeElement.blur();
        clickSearch();
    }
    //查询区目前展示方式 ：1、只显示高级按钮 ；2、都显示
    const createSearchDom = () => {
        let { status, snapshotMap, itemsMap, showAdvModal } = searchInfo;
        if (onlyShowSuperBtn) {
            return <div className="lightapp-component-search">{hideBtnArea ? null : createButton()}</div>;
        } else {
            // 每五个一行 第一行后面放 button
            let allItem = [];
            let temp = [];
            // let items1 = [];
            // let items2 = [];
            let snapshot_list = tree2Array(snapshotMap['simple']);
            let snapshot = snapshot_list.filter(item => item.visible !== false);
            // let template  = templateFilter(itemsMap,snapshot);
            snapshot.map((item, index) => {
                let val = itemsMap[item.attrcode];
                if (val) {
                    temp.push(item);
                    if (temp.length === INITNUM) {
                        allItem.push([...temp]);
                        temp = [];
                    }
                }
            });
            allItem.push(temp);

            return (
                // 1909 search add by wanglongx
                // 内部快捷键 enter
                <NCHotKeys
                    className="lightapp-component-search"
                    keyMap={{
                        query: [
                            'enter',
                        ],
                        delete: ['Alt+Del'],
                    }}
                    handlers={{
                        delete: () => {
                            // blur 触发保存 强制让 body 获取焦点
                            document.body.focus();
                            resetEvent.call(this);
                        },
                        query: debounce.call(this, searchEnter, 300),
                    }}
                    attach={false}
                >
                    {/* 注册全局 F3 */}
                    <NCHotKeys
                        keyMap={{
                            high: [
                                'f3',
                            ],
                        }}
                        handlers={{
                            high: () => {
                                // f3 部分情况下禁用.
                                currentSearch && showAdvBtn && !showAdvModal && searchAdvBtnClick(searchInfo);
                            },
                        }}
                        display="inline"
                        attach={document.body}
                        focused={true}
                    />
                    {showAdvBtn && (
                        <div className={`${searchInfo.showSearchItems ? 'search-open-close' : ' '} search-top`}>
                            <div className="search-edit">
                                <Dropdown
                                    trigger={[
                                        'click',
                                    ]}
                                    placement="bottomLeft"
                                    overlay={(
                                        <div className="query_condo">
                                            <ul className="query_content">
                                                {createSearchPlan.call(this)}
                                            </ul>
                                            <div
                                                className="create_new"
                                                onClick={() => {
                                                    searchAdvBtnClick(searchInfo);
                                                }}
                                                fieldid={getSysFieldid('create_new_btn')}
                                            >
                                                <sapn className="new_content">{` + ${multiLang['page-search-0080']}`}</sapn>
                                            </div>
                                        </div>
                                    )}
                                    onVisibleChange={a => {
                                        if (a) {
                                            searchInfo.showFlag = true;
                                            setStateEve();
                                        }
                                    }}
                                    fieldid="search-plan"
                                >
                                    <div className="search-name-wrapper" fieldid={getSysFieldid('search-plan_btn')}>
                                        <span className="search-plan-name">{(searchInfo.currentPlan && searchInfo.currentPlan.name) || searchInfo.defaultPlan.name}</span>
                                        <i className="iconfont icon-bottom" />
                                    </div>
                                </Dropdown>
                                <NCTooltip
                                    delayShow={800}
                                    inverse
                                    overlay={<div style={{ padding: '10px' }}>F3</div>}
                                    placement="top"
                                >
                                    <span
                                        className="search-super"
                                        onClick={e => {
                                            searchAdvBtnClick(searchInfo);
                                        }}
                                    >
                                        {multiLang['page-search-0003']}
                                    </span>
                                </NCTooltip>

                            </div>

                            {searchInfo.showSearchItems && (
                                <Dropdown
                                    trigger={[
                                        'hover',
                                    ]}
                                    overlayClassName="search-dropdown"
                                    minOverlayWidthMatchTrigger={false}
                                    delayShow={0.8}
                                    // overlayClassName="multi-lang-text-collapse"
                                    placement="bottomLeft"
                                    overlay={
                                        snapshot_list.filter(item => item.initialvalue.value).length && (
                                            <div className="search-condition">
                                                {snapshot_list.map(item => {
                                                    if (checkIsVisible(item)) {
                                                        return (
                                                            <p className="search-condition-item">
                                                                <span className="item-title">{`${searchInfo.itemsMap[item.attrcode].label}: `}</span>
                                                                <span className="item-content">{countQueryConditions(item, multiLang)}</span>
                                                            </p>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        )
                                    }
                                >
                                    <div
                                        className="search-conditions-value"
                                        onClick={() => {
                                            searchInfo.showSearchItems = false;
                                            setStateEve();
                                        }}
                                    >
                                        <span>
                                            {searchInfo.showSearchItems && createTitle()}&nbsp;&nbsp;
                                                 {snapshot_list.map(item => {
                                                if (checkIsVisible(item)) {

                                                    return searchInfo.itemsMap[item.attrcode].label + ';';
                                                }
                                            })}
                                        </span>
                                    </div >
                                </Dropdown>
                            )}
                            {(!onlyShowPlan && !onlyShowSuperBtn && showAdvBtn) && (
                                <div
                                    className="search-open"
                                    onClick={() => {
                                        searchInfo.showSearchItems = !searchInfo.showSearchItems;
                                        PubSub.publishSync(BEFORECOMPLETE, true);
                                        setStateEve(() => {
                                            this._this.infoExchangeController.publishSync('heightChange', { area: 'searchArea' });
                                            // 发布通知表格更新高度
                                            opneBtnClick && opneBtnClick();
                                            // PubSub.publish(COMPLETE, true);
                                        });
                                    }}
                                >
                                    {searchInfo.showSearchItems ? <span className="search-open-icon" ><i className="label">{multiLang['page-search-0087']}</i><i className="iconfont icon-chaxunmoren" /></span> : <span className="search-open-icon" ><i className="label">{multiLang['page-search-0088']}</i><i className="down iconfont icon-chaxunmoren" /></span>}
                                </div>
                            )}
                        </div>
                    )}
                    {!onlyShowPlan && !searchInfo.showSearchItems && (
                        <div className="item-contant">
                            {allItem.map((item, zindex) => {
                                if (zindex === 0) item.push('button');
                                return (
                                    <div className={`item-rows ${searchInfo.hideSearch && zindex != 0 ? 'hideMore' : ''}`}>
                                        {item.map((data, index) => createDom(data, zindex > 0, index === item.length - 1, zindex, index))}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </NCHotKeys>
            );
        }
    };
    function checkIsVisible(item) {
        if (item.operationSign == 'is null' || item.operationSign == 'is not null') {
            return true;
        } else if (!isEmpty(item.initialvalue.value) && item.type != 'refer') {
            return true;
        } else if (!isEmpty(item.initialvalue.value) && item.type == 'refer' && !isDateFunction(item.initialvalue.value)) {
            return true;
        } else {
            return false;
        }

    }
    const createEmptySearch = snapshot => {
        return <div className="NC_searchPlanMain">{snapshot.map(item => createDom(item))}</div>;
    };
    /* 关闭查询方案区 */
    const closeSearchPlan = () => {
        closeSearchPlanArea.call(this._this, id);
    };
    /* 更多按钮点击事件，展示隐藏项 */
    const moreSearchPlanClick = () => {
        searchInfo.showHideSearchPlan = !searchInfo.showHideSearchPlan;
        setStateEve();
    };
    /* 创建查询方案未填写项区域 及查询按钮 */
    const createSearchPlanArea = () => {
        if (!isEmpty(searchInfo.currentSnapshot) && searchInfo.currentSnapshot.length > 12) {
            searchInfo.showMoreBtn = true;
        }
        return (

            <Col className={`lightapp-component-search ${searchInfo.showHideSearchPlan && 'lightapp-component-search-absolute'}`}>
                <Col
                    md={10}
                    sm={8}
                    xs={6}
                    id={id}
                    className={`NC_searchPlanBox ${searchInfo.showHideSearchPlan ? null : 'searchPlanBoxHeight'}`}
                >
                    <p className="NC_searchPlanName">{searchInfo.currentPlan.name}</p>
                    {createEmptySearch(searchInfo.currentSnapshot)}
                    <div className="NC_searchPlanBtn">
                        <NCTooltip
                            delayShow={800}
                            inverse
                            overlay={multiLang['page-search-0021']}
                            placement="top"
                        >
                            <span className="closeSearchPlan" onClick={closeSearchPlan.bind(this)}>
                                ×
                                 </span>
                        </NCTooltip>

                        {searchInfo.showMoreBtn ? (
                            <span className="moreSearchPlan" onClick={moreSearchPlanClick.bind(this)}>
                                {searchInfo.showHideSearchPlan ? multiLang['page-search-0022'] : multiLang['page-search-0023']}
                            </span>
                        ) : null}
                    </div>
                </Col>
                {createButton()}
            </Col>
        );
    };

    /* 当前高级查询面板中的状态 （普通/高级） and 业务组是否自定义高级查询区 true/false  */
    const advSearchStatus = (status, userControl) => {
        searchInfo.advSearchStatus = status;
        searchInfo.userControlAdvArea = userControl;
    };

    /* 如果修改了默认方案，则需要更新查询区展示 */
    const afterEditSearchPlan = res => {
        // searchInfo.items = [];
        // filterData(res);
        setStateEve();
    };

    return (isEmpty(multiLang) ? null : (
        <div
            // 给快捷键用
            id={`hot-key-${id}`}
            ref={dom => { this.searchDom = ReactDOM.findDOMNode(dom); }}
            className={`search-area-contant ${showAdvBtn ? '' : 'search-area-contant-margin'}`}
            fieldid={getSysFieldid(fieldid ? `${fieldid}_search-area` : 'search-area')}>
            {!onlyShowAdvArea ? (
                !onlyShowModal ? (
                    <div>
                        <Row
                            // 这里作为id不能直接用querSelector 选中，因为id可能是数字开头的
                            id={id}
                            style={{ minHeight: searchInfo.showHideSearchPlan ? '90px' : '0px' }}
                            className={` NC_CreateSearch ${searchInfo.status === 'plan' ? 'NC_searchAreaHeight' : null}`}
                        >
                            {(searchInfo.status === 'simple' || searchInfo.showAdvModal || onlyShowSuperBtn) && createSearchDom()}
                            {(searchInfo.status === 'plan') && !onlyShowSuperBtn && createSearchPlanArea()}

                            {showAdvBtn && (isSynInitAdvSearch || this.state.isRenderAdv) ? (
                                <SearchAdvModal
                                    moduleId={id}
                                    searchBtnName={searchBtnName}
                                    searchModalTitle={searchModalTitle}
                                    searchConditionName={searchConditionName}
                                    onlyShowSuperCondition={onlyShowSuperCondition}
                                    hideSearchType={hideSearchType}
                                    className={`${searchInfo.showAdvModal ? 'showAdvModal' : 'hideAdvModal'}`}
                                    closeModal={closeModal}
                                    searchBtnClick={clickSearchBtn}
                                    onAfterEvent={onAfterEvent} // 编辑后事件
                                    showAdvSearchPlanBtn={showAdvSearchPlanBtn} // 是否显示保存方案按钮
                                    replaceAdvBody={replaceAdvBody} // 替换整个body
                                    addAdvTabs={addAdvTabs} // 添加自定义页签
                                    addAdvBody={addAdvBody} // 在body区域添加自定义条件
                                    searchPlanCache={searchInfo.searchPlanCache} // 查询方案变量缓存记录
                                    searchPlanCacheChange={i => searchPlanCacheChange(i)} // 查询方案修改回调
                                    upDateSearchPlan={e => upDateSearchPlan(e)} //更新当前界面的查询方案
                                    oid={oid}
                                    hideSearchCondition={hideSearchCondition} // 隐藏候选条件
                                    hideSearchPlan={hideSearchPlan}
                                    saveSearchPlan={saveSearchPlan} // 业务组自定义查询条件当前数据
                                    replaceRightBody={replaceRightBody} // 替换右侧body区域
                                    afterClickSearchPlan={afterClickSearchPlan} // 点击查询方案后，需要展示未填写的查询方案
                                    clickPlanEve={clickPlanEve} // 查询方案点击事件回调函数，用于业务组设置自定义查询区数据
                                    statusChange={advSearchStatus} // 获取当前高级面板中的状态
                                    advSearchClearEve={advSearchClearEve} // 高级面板中的清空按钮点击事件回调
                                    afterEditSearchPlan={afterEditSearchPlan} // 如果修改了默认方案，则需要更新查询展示
                                    onOperateTypeChange={onOperateTypeChange}
                                    searchInfo={searchInfo}
                                    _this={this}
                                    changeCompleteFlag={flag => (this.completeFlag = flag)}
                                    statusChangeEvent={statusChangeEvent}
                                    onlyShowSuperBtn={onlyShowSuperBtn}
                                    createScript={createScript}
                                    renderItem={renderItem}
                                    setInitValueEvent={setInitValueEvent}
                                    meta={this.meta}
                                    multiLang={this.state.json}
                                    LangData={LangData}
                                    lastStatus={this.lastStatus}
                                    leftTab={this.state.leftTab} // 控制页签
                                    fieldid={fieldid}
                                    removeQuerConditionEve={removeQuerConditionEve}
                                    isTemplateOrder={isTemplateOrder}
                                    hiddenFunList={hiddenFunList}
                                    {...this.props}
                                />
                            ) : null}
                        </Row>

                    </div>
                ) : (
                    showAdvBtn && (isSynInitAdvSearch || this.state.isRenderAdv) ? (
                        <SearchAdvModal
                            moduleId={id}
                            searchBtnName={searchBtnName}
                            searchModalTitle={searchModalTitle}
                            searchConditionName={searchConditionName}
                            hideSearchType={hideSearchType}
                            onlyShowSuperCondition={onlyShowSuperCondition}
                            className={`${searchInfo.showAdvModal ? 'showAdvModal' : 'hideAdvModal'}`}
                            closeModal={closeModal}
                            searchBtnClick={clickSearchBtn}
                            onAfterEvent={onAfterEvent} // 编辑后事件
                            showAdvSearchPlanBtn={showAdvSearchPlanBtn} // 是否显示保存方案按钮
                            replaceAdvBody={replaceAdvBody} // 替换整个body
                            addAdvTabs={addAdvTabs} // 添加自定义页签
                            addAdvBody={addAdvBody} // 在body区域添加自定义条件
                            searchPlanCache={searchInfo.searchPlanCache} // 查询方案变量缓存记录
                            searchPlanCacheChange={i => searchPlanCacheChange(i)} // 查询方案修改回调
                            upDateSearchPlan={e => upDateSearchPlan(e)} //更新当前界面的查询方案
                            oid={oid}
                            hideSearchCondition={hideSearchCondition} // 隐藏候选条件
                            hideSearchPlan={hideSearchPlan}
                            saveSearchPlan={saveSearchPlan} // 业务组自定义查询条件当前数据
                            replaceRightBody={replaceRightBody} // 替换右侧body区域
                            afterClickSearchPlan={afterClickSearchPlan} // 点击查询方案后，需要展示未填写的查询方案
                            clickPlanEve={clickPlanEve} // 查询方案点击事件回调函数，用于业务组设置自定义查询区数据
                            statusChange={advSearchStatus} // 获取当前高级面板中的状态
                            advSearchClearEve={advSearchClearEve} // 高级面板中的清空按钮点击事件回调
                            afterEditSearchPlan={afterEditSearchPlan} // 如果修改了默认方案，则需要更新查询展示
                            onOperateTypeChange={onOperateTypeChange}
                            searchInfo={searchInfo}
                            _this={this}
                            changeCompleteFlag={flag => (this.completeFlag = flag)}
                            statusChangeEvent={statusChangeEvent}
                            onlyShowSuperBtn={onlyShowSuperBtn}
                            createScript={createScript}
                            renderItem={renderItem}
                            setInitValueEvent={setInitValueEvent}
                            meta={this.meta}
                            multiLang={this.state.json}
                            LangData={LangData}
                            lastStatus={this.lastStatus}
                            leftTab={this.state.leftTab} // 控制页签
                            removeQuerConditionEve={removeQuerConditionEve}
                            isTemplateOrder={isTemplateOrder}
                            hiddenFunList={hiddenFunList}
                            {...this.props}
                        />
                    ) : null)
            ) : (
                <div className="NC_searchAdvModal" fieldid={getSysFieldid('search_modal-area')} style={{ width: '100%' }}>
                    <SearchAdvCom
                        className={`${searchInfo.showAdvModal ? 'showAdvModal' : 'hideAdvModal'}`}
                        moduleId={id}
                        searchBtnName={searchBtnName}
                        closeModal={closeModal}
                        searchBtnClick={clickSearchBtn}
                        onAfterEvent={onAfterEvent} // 编辑后事件
                        showAdvSearchPlanBtn={showAdvSearchPlanBtn} // 是否显示保存方案按钮
                        replaceAdvBody={replaceAdvBody} // 替换整个body
                        addAdvTabs={addAdvTabs} // 添加自定义页签
                        searchConditionName={searchConditionName}
                        hideSearchType={hideSearchType}
                        onlyShowSuperCondition={onlyShowSuperCondition}
                        addAdvBody={addAdvBody} // 在body区域添加自定义条件
                        searchPlanCache={searchInfo.searchPlanCache} // 查询方案变量缓存记录
                        searchPlanCacheChange={i => searchPlanCacheChange(i)} // 查询方案修改回调
                        upDateSearchPlan={e => upDateSearchPlan(e)} //更新当前界面的查询方案
                        oid={oid}
                        hideSearchCondition={hideSearchCondition} // 隐藏候选条件
                        hideSearchPlan={hideSearchPlan}
                        saveSearchPlan={saveSearchPlan} // 业务组自定义查询条件当前数据
                        replaceRightBody={replaceRightBody} // 替换右侧body区域
                        afterClickSearchPlan={afterClickSearchPlan} // 点击查询方案后，需要展示未填写的查询方案
                        clickPlanEve={clickPlanEve} // 查询方案点击事件回调函数，用于业务组设置自定义查询区数据
                        statusChange={advSearchStatus} // 获取当前高级面板中的状态
                        advSearchClearEve={advSearchClearEve} // 高级面板中的清空按钮点击事件回调
                        afterEditSearchPlan={afterEditSearchPlan} // 如果修改了默认方案，则需要更新查询展示
                        onOperateTypeChange={onOperateTypeChange}
                        searchInfo={searchInfo}
                        _this={this}
                        changeCompleteFlag={flag => (this.completeFlag = flag)}
                        statusChangeEvent={statusChangeEvent}
                        onlyShowSuperBtn={onlyShowSuperBtn}
                        createScript={createScript}
                        renderItem={renderItem}
                        meta={this.meta}
                        setInitValueEvent={setInitValueEvent}
                        multiLang={this.state.json}
                        LangData={LangData}
                        lastStatus={this.lastStatus}
                        isTemplateOrder={isTemplateOrder}
                        removeQuerConditionEve={removeQuerConditionEve}
                        hiddenFunList={hiddenFunList}
                        {...this.props}
                    />
                </div>
            )}
        </div>
    ));
}

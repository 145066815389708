/**
 * zhanghengh
 * 18/5/31
 * 公式编辑器的表和数据部分，用的包装后的钉耙的组件，如有问题，可以参考钉耙的的例子
 * 后端是汪建伟
 */
import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Checkbox,
    Input,
} from '@platform/base';
import { ajax, toast, isString, getMultiLang } from '@platform/api';

require('./surface.less');

export default class Surface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false, //控制checkbox的状态
            surfaceData: [], //表的数据
            fieldData: [], //字段的数据
            inputValue: '', // input 的值
            json: {},
        };
    }

    componentWillMount() {
        const { surfaceUrl } = this.props;
        // 初始化调用getPlatformLang方法获取多语
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData });
        };
        getMultiLang({ moduleId: 'container_formulaEditor', callback }); // moduleId为所需加载json文件夹名称前缀
        ajax({
            //初始加载数据
            url: isString(surfaceUrl) ? surfaceUrl : '/nccloud/formula/web/formulatab.do',
            loading: false,
            success: res => {
                this.setState({
                    surfaceData: res.data, //当前数据
                    cacheData: res.data, //初始数据
                });
            },
            error: e => {
                toast({ color: 'danger', content: e.message });
            },
        });
    }

    // componentWillReceiveProps(nextProps) {  //暂时注释不要删除，可能会有用
    //   const { surfaceUrl } = nextProps;  //最新的props
    //   const { surfaceUrl: oldSurfaceUrl } = this.props;  //上一次的props
    //   if(surfaceUrl !== oldSurfaceUrl) {  //当传入的路径变化时才重新请求，做到优化作用
    //     ajax({
    //       url: isString(surfaceUrl) ? surfaceUrl : '/nccloud/formula/web/formulatab.do',
    //       loading: false,
    //       success: (res) => {
    //         this.setState({
    //           surfaceData: res.data,//当前数据
    //           cacheData: res.data//初始数据
    //         });
    //       },
    //       error: (e) => {
    //         toast({ color: 'danger', content: e.message });
    //       }
    //     });
    //   }
    // }
    /**
     * zhanghengh
     * 18/5/30
     * 点击刷新回调
     */
    handleRefresh = () => {
        const { cacheData } = this.state;
        if (cacheData) {
            this.setState(
                {
                    surfaceData: cacheData,
                    inputValue: '',
                },
                () => {
                    this._getFieldData(cacheData[0].displayName);
                },
            );
        }
    };

    /**
     * zhanghengh
     * 18/5/30
     * checkbox变化回调
     */
    handleCheckbox = () => {
        this.setState({ checked: !this.state.checked });
    };

    /**
     * zhanghengh
     * 18/5/30
     * input变化回调
     */
    handleInput = value => {
        const { cacheData } = this.state;
        if (cacheData) {
            const newData = cacheData.filter(eve => {
                if (eve.displayName.includes(value)) {
                    return true;
                }
            });
            this.setState(
                {
                    surfaceData: newData,
                    inputValue: value,
                },
                () => {
                    if (newData.length > 0) {
                        this._getFieldData(newData[0].displayName);
                    } else {
                        this.setState({ fieldData: [] });
                    }
                },
            );
        }
    };

    /**
     * zhanghengh
     * 18/5/30
     * 创建列表内容
     */
    _createContent(data) {
        return data.map((eve, index) => {
            const { displayName, inputSig, hintMsg } = eve;
            return (
                <li key={index} data-name={inputSig} data-explain={hintMsg} data-title={displayName} onDoubleClick={this.handleDbList}>
                    {displayName}
                </li>
            );
        });
    }

    /**
     * 获取字段的数据
     * zhanghengh
     */
    _getFieldData = title => {
        let json = this.state.json;
        ajax({
            url: '/nccloud/formula/web/formulatabfield.do',
            loading: false,
            data: { tablename: title },
            success: res => {
                this.setState({ fieldData: res.data });
            },
            error: () => {
                toast({ color: 'danger', content: `${json['formula0011']}` });
            },
        });
    };

    /**
     * zhanghengh
     * 18/5/30
     * 单击表格列表的回调
     */
    handleSurfaceList = e => {
        const { surfaceData } = this.state;
        const { setExplain } = this.props;
        const { explain, title } = e.target.dataset;
        if (surfaceData.length && title) {
            setExplain(explain);
            this._getFieldData(title);
        }
    };

    /**
     * zhanghengh
     * 18/5/30
     * 单击字段列表的回调
     */
    handleFieldList = e => {
        this.props.setExplain(e.target.dataset.explain);
    };

    /**
     * zhanghengh
     * 18/5/30
     * 双击列表的回调
     */
    handleDbList = e => {
        const { surfaceData } = this.state;
        if (surfaceData.length) {
            const { name } = e.target.dataset;
            this.props.setName(name);
        }
    };

    /**
     * zhanghengh
     * 18/5/30
     * 创建内容
     */
    _createAll = () => {
        const { tabPaneHeight } = this.props
        const { checked, surfaceData, fieldData, inputValue, json } = this.state;
        return (
            <Row>
                <Col md={6} xs={6} sm={6}>
                    <div>
                        <div className="surface-left-title">{json['formula0018']}</div>
                        <ul className="surface-left" onClick={this.handleSurfaceList}
                            style={{ height: tabPaneHeight - 100 }}
                        >
                            {this._createContent(surfaceData)}
                        </ul>
                        <div className="input-area">
                            <Checkbox className="checkbox" colors="info" checked={checked} onChange={this.handleCheckbox}>
                                {json['formula001']}
                            </Checkbox>
                            <Input
                                value={inputValue}
                                disabled={!checked}
                                style={{
                                    width: '100%',
                                    display: 'block',
                                }}
                                placeholder="Search"
                                onChange={this.handleInput}
                            />
                        </div>
                        <Button
                            disabled={!checked}
                            style={{
                                width: '100%',
                                margin: 0,
                                display: 'block',
                                background: '#1e88e5',
                            }}
                            onClick={this.handleRefresh}
                        >
                            {json['formula002']}
                        </Button>
                    </div>
                </Col>
                <Col md={6} xs={6} sm={6}>
                    <div className="surface-right-title">{json['formula0019']}</div>
                    <ul className="surface-right" onClick={this.handleFieldList}>
                        {this._createContent(fieldData)}
                    </ul>
                </Col>
            </Row>
        );
    };

    render() {
        return <div className="surface-area">{this._createAll()}</div>;
    }
}

/**
 * Created by zhouxini on 2018/7/19.
 */

export function show(id, config = {}) {
	if (!this.state.uploader.hasOwnProperty(id)) {
		return false;
	}
	let uploaderData = this.state.uploader[id];
	uploaderData = Object.assign(uploaderData, config);
	uploaderData.showUploader = true;
	this.setState({
		uploader: this.state.uploader
	});
}

export function close(id) {
	if (!this.state.uploader.hasOwnProperty(id)) {
		return false;
	}
	let uploaderData = this.state.uploader[id];
	uploaderData.showUploader = false;
	this.setState({
		uploader: this.state.uploader
	});
}

import React, { Component } from "react";
import ReactDOM from "react-dom";
require("./index.less");
import { Modal, Button, HotKeys, Tooltip } from '@platform/base'
import { getMultiLang, getSysFieldid, getSafeRandom } from '@platform/api';
const { Header, Title, Body, Footer } = Modal;
/**
 * 备注：在业务组传的确认取消回调报错的情况下，当前的PromptBox的确定和取消事件里的close不走，会导致n递增，走的回调取到的有误。
 */
let n = -1;
export class PromptBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.show || true,
            json: {},
        };
    }

    UNSAFE_componentWillMount() {
        let callback = (json, bool) => {
            if (bool) {
                this.setState({ json }, () => {
                    //console.log('多语加载完成')
                });
            }
        };
        getMultiLang({ moduleId: "container_toast", callback });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ showModal: nextProps.show || this.props.show || false });
    }

    callBackErrorHandle = (func, msg) => {
        try {
            func();
        } catch (err) {
            this.close();
            console.error(`${msg}:${err}`);
        }
    };

    /* 取消按钮事件 */
    cancelClick = () => {
        n++;
        let { bcFn } = this.props;
        if (bcFn[n] && typeof bcFn[n] === "function") {
            // this.props.cancelBtnClick();
            // bcFn[n]();
            this.callBackErrorHandle(bcFn[n], "PromptBox的取消回调报错");
            this.clear();
        }
        this.close();
    };

    //确认按钮事件
    beSureClick = () => {
        n++;
        let { bsFn } = this.props;
        if (bsFn[n] && typeof bsFn[n] === "function") {
            // this.props.beSureBtnClick();
            // bsFn[n]();
            this.callBackErrorHandle(bsFn[n], "PromptBox的确认回调报错");
            this.clear();
        }
        this.close();
    };

    //删除弹框
    close = () => {
        this.setState({ showModal: false });
    };

    onExited = () => {
        let element = document.getElementsByClassName('prompt-box-project')[0];
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
            clearTimeout(this.closeTimer);
            this.closeTimer = null;
            // element 都不在app上，会报错的，这种内部组件，不要这样写
            // document.getElementById("app").removeChild(element);
            let $parentElement = element && element.parentElement;
            $parentElement && $parentElement.removeChild(element);
        }
    }

    closeBtnClick = () => {
        n++;
        let { bCloseFn } = this.props;
        if (bCloseFn[n] && typeof bCloseFn[n] === "function") {
            bCloseFn[n]();
            this.clear();
        }
        this.close();
    };

    clear = () => {
        let { bsFn, bcFn, bCloseFn } = this.props;
        bsFn[n] = null;
        bcFn[n] = null;
        bCloseFn[n] = null;
    }

    render() {
        let { json } = this.state;
        let {
            content,
            title,
            color = "success",
            noFooter = false,
            closeByClickBackDrop = false,
            noCancelBtn = false,
            hasCloseBtn = false,
            beSureBtnName = json["0005"],
            cancelBtnName = json["0006"],
            zIndex = 200,
            hotKeyboard = true,
            ...other
        } = this.props;
        if (!title) {
            if (color === "success") {
                title = json["0001"];
            } else if (color === "info") {
                title = json["0002"];
            } else if (color === "warning") {
                title = json["0003"];
            } else if (color === "danger") {
                title = json["0004"];
            }
        }

        let toastIcon = color => {
            let iconName = "icon-wancheng";
            if (color === "danger") {
                iconName = "icon-shibai";
            } else if (color === "warning") {
                iconName = "icon-zhuyi1";
            } else if (color === "info") {
                iconName = "icon-tixing";
            }
            let name = `iconfont ${iconName} ${color}`;
            return <span className={name} />;
        };
        let id = Date.now() + getSafeRandom();
        let indexInfo = { id, zIndex };
        return (
            <Modal
                show={this.state.showModal}
                onHide={this.closeBtnClick.bind(this)}
                className={`prompt-box ${id}`}
                backdrop={closeByClickBackDrop ? true : "static"}
                indexInfo={indexInfo}
                ref={Modal => (this.Modal = Modal)}
                onExited={this.onExited}
                {...other}
                fieldid={this.props.fieldid ? this.props.fieldid : "simpleConfirm"}
            >
                {hotKeyboard && !noFooter && (
                    <HotKeys
                        keyMap={{
                            sureBtnHandler:
                                [HotKeys.USUAL_KEYS.MODAL_CONFIRM[0], 'enter'],
                            cancelBtnHandler:
                                HotKeys.USUAL_KEYS.MODAL_CALCEL,
                        }}
                        handlers={{
                            sureBtnHandler: () => {
                                // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                                if (this.Modal && this.Modal.isTopModal()) {
                                    console.log(
                                        "promptbox sureBtnHandler 事件回调",
                                    );
                                    this.beSureClick();
                                }
                            },
                            cancelBtnHandler: () => {
                                // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                                if (this.Modal && this.Modal.isTopModal()) {
                                    console.log(
                                        "promptbox cancelBtnHandler 事件回调",
                                    );
                                    this.cancelClick();
                                }
                            },
                        }}
                        className="promptbox-hotkeys-wrapper"
                        focused={true}
                        attach={document.body}
                        display="inline"
                    />
                )}
                <Header closeButton={hasCloseBtn ? true : false}>
                    <span fieldid={getSysFieldid(`${json["0007"]}_title`)}>{json["0007"]}</span>
                </Header>

                <Body>
                    <Title className='prompt-box-body-title'>
                        {toastIcon(color)} <div className={'title-text'}>{title}</div>
                    </Title>
                    <div className="prompt-box-body-content">{content}</div>
                </Body>

                {!noFooter && (
                    <Footer>
                        <Tooltip
                            placement="top"
                            inverse
                            overlay={`${beSureBtnName}  (${HotKeys.USUAL_KEYS.MODAL_CONFIRM})`}
                            trigger={["hover", "focus"]}
                            className="model-helper-overlay"
                        >
                            <Button
                                onClick={this.beSureClick}
                                colors="primary"
                                fieldid="confirm"
                            >
                                {beSureBtnName}(
                                <span className="text-decoration-underline">
                                    Y
                                </span>
                                )
                            </Button>
                        </Tooltip>
                        {!noCancelBtn && (
                            <Tooltip
                                placement="top"
                                inverse
                                overlay={`${cancelBtnName}  (${HotKeys.USUAL_KEYS.MODAL_CALCEL})`}
                                trigger={["focus", "hover"]}
                                className="model-helper-overlay"
                            >
                                <Button
                                    onClick={this.cancelClick}
                                    shape="border"
                                    fieldid="cancel"
                                >
                                    {cancelBtnName}(
                                    <span className="text-decoration-underline">
                                        N
                                    </span>
                                    )
                                </Button>
                            </Tooltip>
                        )}
                    </Footer>
                )}
            </Modal>
        );
    }
}

let beSureArr = [];
let cancelArr = [];
let closeArr = [];
export const promptBox = props => {
    props = props || {};
    //let app = document.getElementById('app');
    let container;
    if (props.container) {
        if (props.container.body) {
            container = props.container.body;
        }
    } else {
        container = document.body;
    }
    let div = document.createElement('section');
    div.className = 'prompt-box-project';
    // div.style.position='absolute';
    // div.style.top='0';
    // div.style.right='0';
    beSureArr.push(props.beSureBtnClick);
    cancelArr.push(props.cancelBtnClick);
    closeArr.push(props.closeBtnClick);
    // container.style.position = 'absolute';
    // container.style.top='0';
    // container.style.right='0';
    container.appendChild(div);
    ReactDOM.render(<PromptBox {...props} bsFn={beSureArr} bcFn={cancelArr} bCloseFn={closeArr} container={div} />, div);
}

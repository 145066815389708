/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-04 11:11:55
 * @Description: file content
 */
import React, { Component } from 'react';
import { createPageIcon, getSysFieldid, getGlobalStorage} from '@platform/api';

require('./index.less');
import { BackBtn } from '@platform/base';


export default class BillInfo extends Component {
    constructor(props) {
        super(props);
        this.props.getThis(this);
        this.state = {
            showBackBtn: true,
            showBillCode: true,
            billCode: this.props.events.billCode,
            title: this.props.events.title,
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.events.title !== this.props.events.title){
            this.setState({
                title: nextProps.events.title
            })
        }

    }

    render() {
        const { events } = this.props;
        let { showBackBtn, showBillCode, billCode, title = '' } = this.state;
        let {  initShowBackBtn = true, backBtnClick, titleClick } = events;
        // let pageIcon = !showBackBtn && (typeof createPageIcon === 'function') ? createPageIcon() : null;
        let pageIcon = typeof createPageIcon === 'function' ? createPageIcon() : null;
        let fontClass = (window.nccFontSize) || (window.top.nccFontSize) || getGlobalStorage('localStorage', 'nccFontSize') || '';
        let titleFontSize='';
        if(fontClass){
            switch (fontClass) {
                case 'large':
                    titleFontSize = 'title-font-size-large';
                    break;
                case 'medium':
                    titleFontSize = 'title-font-size-medium';
                    break;
                default:
                    break;
            }
        }
        return (
            <div className={`BillHeadInfoWrap ${showBackBtn && initShowBackBtn ? 'BillHeadInfoWrap-showBackBtn' : ''}`}>
                {
                    showBackBtn && initShowBackBtn &&
                    <BackBtn
                        onClick={backBtnClick && backBtnClick}
                    />
                }
                {pageIcon}
                <span
                    className={`bill-info-title ${titleFontSize}`}
                    fieldid={getSysFieldid(`${title}_title`)}
                    onClick={() => {
                        if (typeof titleClick === "function") {
                            titleClick();
                        }
                    }}
                >
                    {title}
                </span>
                {
                    showBillCode &&
                    <span className="bill-info-code">{billCode && `：${billCode}`}</span>
                }
            </div>
        )

    }
}

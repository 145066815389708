/*
*   查询区高级查询弹出框组件  --wangshhj
* */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import Ajax from 'src/api/ajax';
// import { promptBox } from 'src';
import { PubSub } from '@platform/api';
import { getSysFieldid, toast, isFunction, isArray, isEmpty } from '@platform/api';
import { Select as NCSelect, Tabs as NCTabs, Input as NCFormControl, Radio as NCRadio, Tooltip as NCTooltip, HotKeys as NCHotKeys, Tree as NCTree, Icon as NCIcon } from '@platform/base';
import { getTimezoneOffset, _clone as deepClone, snapshotFilter, tree2Array, beSureBtnClick, beSureBtnClick_Del, plan2snapshotTranslate } from './_methods';
import {
    _getAllSearchData,
    delNode,
    setSearchValByFieldHigh as setSearchValByField,
    getCurrentTemplate,
    _addData,
    clearAllValue,
    replaceData,
    createOperation,
    setMultiLangHigh as setMultiLang,
} from './methods';
import { templateEngine } from './templateEngine';
import { promptBox } from '../PromptBox';

// import toast from '../../api/toast';
// import getSysFieldid from '../../api/getSysFieldid';

require('./searchAdvModal.less');

const NCTabPane = NCTabs.TabPane;
const NCTreeNode = NCTree.TreeNode;
const NCOption = NCSelect.Option;
const NCRadioGroup = NCRadio.RadioGroup;
// 查询区渲染完成发布订阅标识
const BEFORECOMPLETE = 'beforeAreaChangeComplete'
const COMPLETE = 'areaChangeComplete';

/*
*   SearchAdvModal组件，props中的参数解释：
*
*   moduleId ,                   //查询区组件id
*	advMeta ,                    //查询区模板
*	closeModal ,                 //关闭组件方法
*	searchBtnClick ,             //查询按钮回调
*	onAfterEvent ,               //编辑后事件
*   showAdvSearchPlanBtn ,       //是否显示保存方案按钮 ，默认显示
*   replaceAdvBody ,             //替换高级查询body区域
*   addAdvTabs ,                 //添加自定义tab页签
*   addAdvBody ,                 //添加自定义查询条件
*   replaceRightBody ,           //添加高级查询区右侧区域自定义查询条件Dom (fun)
*   searchPlanCache ,            //查询方案数据
*   searchPlanCacheChange ,      //查询方案修改后，回调
*   oid ,                        // 查询模板的oid
*   hideSearchCondition = false, // 隐藏候选条件
*   hideSearchPlan = false       // 隐藏高级查询的查询方案
*   saveSearchPlan,              // 保存查询方案确定按钮事件 ，用于业务组返回自定义的查询条件
*   clickPlanEve,                // 点击高级面板中的查询方案事件 ,用于业务组为自定义查询区赋值
*   advSearchClearEve,           // 高级面板中的清空按钮点击事件回调，用于业务组清除自定义查询条件的值
* */
export default class SearchAdvCom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true, // 组件显影性
            moveNode: null, // 拖拽节点
            leftTab: props.hideSearchCondition ? '2' : (props.leftTab || '1'), // 左侧tab的key值
            // planTabKey: props.hideSearchCondition ? '2' : '1', // 查询方案的tabkey, 默认2，业务组不需要候选条件时改为1
            // preparedPlanName: '', // 预制查询方案名称
            // needReveal: false, // 是否需要展示查询方案的未填写项；
            userControlAll: false, // 业务组自定义整个高级查询区,
            // advMeta: '',
            // searchInfo: deepClone(props.searchInfo),
            searchInfo: {
                snapshotMap: {
                    simple: [],
                    normal: [],
                    super: []
                },
                itemsMap: {}
            },
            onlyShowSuperBtn: props.onlyShowSuperBtn,
        };
        this.lastStatus = props.lastStatus;
        // 业务组自定义查询区域 ，点查询方案时，不用展示方案中的未填写项
        if (isFunction(props.replaceRightBody)) {
            this.state.userControlAll = true;
            props.statusChange(this.state.status, this.state.userControlAll);
        }
        this.firstInit = true;
        this.multiLang = props.multiLang;
        this.LangData = props.LangData;
        setMultiLang(this.multiLang);
        this.initByProps(props, true);
        // this.getSnapshotCache(this.state.searchInfo);
    }

    componentDidMount() {
        if (!isFunction(this.props.replaceAdvBody) && !this.props.hideSearchCondition) {
            //每次更新高级查询都更新左侧的内容
            this.createSearchCondition.call(this, this.items);
        }
        // 没办法了 只能通过全局订阅发布做了 
        this.searchChoosePlan = PubSub.subscribe('searchChoosePlan', (msg, data) => {
            if (data) {
                let currentPlan = this.props.searchPlanCache.find(item => item.name == data);
                if (currentPlan) {
                    this.setState({ currentPlan });
                    this.clickSearchPlan(currentPlan);
                }
            }
        });

    }

    componentWillUnmount() {
        this.searchChoosePlan && PubSub.unsubscribe(this.searchChoosePlan);
    }

    componentWillReceiveProps(props) {

        // this.state.searchInfo = deepClone(props.searchInfo)
        // this.state.searchPlan = props.searchPlanCache;
        // this.state.currentPlan = props.searchInfo.currentPlan;
        // this.getSnapshotCache(this.state.searchInfo);
        this.lastStatus = props.lastStatus;
        // 当从方案里面切进 super时 选中"2" 并选中当前方案
        // if ('leftTab' in props && props.leftTab == '2') {
        //     this.setState({ leftTab: props.leftTab });
        // }
        if (props.onlyShowAdvArea || props.searchInfo.showAdvModal) {
            this.initByProps(props, false);
        }
        // if (this.state.currentPlan.name !== props.searchInfo.currentPlan.name) {
        // 	this.clickSearchPlan(this.state.currentPlan);
        // }

        // 繁琐判断 需重新思考
        // if('choosePlanName' in props && props.choosePlanName != undefined && !props.choosePlanFlag){
        // 	let currentPlan = props.searchPlanCache.find(item => item.name == props.choosePlanName)
        // 	if(currentPlan){
        // 		this.setState({
        // 			currentPlan: currentPlan
        // 		})
        // 		this.clickSearchPlan(currentPlan)
        // 	}
        // }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.onlyShowAdvArea && !nextProps.searchInfo.showAdvModal) {
            return false
        }
        return true
    }
    componentDidUpdate(props, state) {
        props.searchInfo.snapshotMap.normal = state.searchInfo.snapshotMap.normal;
        props.searchInfo.snapshotMap.super = state.searchInfo.snapshotMap.super;
    }
    initByProps = (props, flag) => {
        //这些再打开高级之后就是自己的状态了
        let showModal = props.onlyShowAdvArea ? true : this.state.showModal;
        if (flag || !showModal) {
            // this.state.status = props.searchInfo.status || 'normal'; // 当前操作区域
            this.state.normalAdvId = props.moduleId + '@' + 'normal'; // 普通查询区id
            this.state.superAdvId = props.moduleId + '@' + 'super'; // 高级查询区id
            // this.state.leftTab = props.hideSearchCondition ? '2' : (props.leftTab || '1'); // 左侧tab的key值
            this.state.currentPlan = props.searchInfo.currentClickPlan || props.searchInfo.currentPlan; // 当前正在操作的查询方案
            this.state.searchPlanName = this.state.currentPlan.name || ''; // 修改查询方案名称  
            if (this.state.currentPlan.renderobj4web_snap[0] && this.state.currentPlan.renderobj4web_snap[0].refpk === 'root') {
                this.state.status = 'super';
            } else {
                this.state.status = 'normal';
            }
            if (props.onlyShowSuperCondition) {
                this.state.status = 'super';
            }
            this.state.searchInfo.status = this.state.status;
        }
        props.searchInfo.currentClickPlan = null;
        //每次更新都需要同步的数据，主要是模板的内容
        this.state.searchInfo.searchPlanCache = props.searchPlanCache || [];// 查询方案数据
        this.state.searchPlan = props.searchPlanCache || [];// 查询方案数据
        this.state.searchInfo.defaultPlan = props.searchInfo.defaultPlan;
        this.state.searchInfo.currentPlan = props.searchInfo.currentPlan;
        let items = props.meta.items || [];
        this.items = items;
        this.state.searchInfo.fixedConditionGroup = props.searchInfo.fixedConditionGroup;
        items.forEach(item => (this.state.searchInfo.itemsMap[item.attrcode] = item));

        //根据currentPlan初始化普通和高级区域的快照数据
        //如果是预制方案就按照simple的数据去初始化normal和super，如果不是预制方案就根据方案的类型初始化数据
        this.defaultOpen = true;

        //let items = deepClone(this.props.advMeta)||[];

        /* 添加树组件需要的字段 */
        items.forEach(item => {
            if (!item.hasOwnProperty('attrcode')) {
                console.error('查询模板中有字段缺少attrcode字段，请检查');
            }
            if (!item.hasOwnProperty('label')) {
                console.error('查询模板中有字段缺少label字段，请检查');
            }
            item.refpk = item.attrcode;
            item.refname = item.advLable || item.label;
            item.isleaf = true;
        });
        //items = items.filter(item=>item.isCondition===true);

        // 当业务组要替换整个body区域或者不需要候选条件时，不加载候选条件
        if (!isFunction(props.replaceAdvBody) && !props.hideSearchCondition && !flag) {
            //每次更新高级查询都更新左侧的内容
            this.createSearchCondition.call(this, items);
        }

        // 当业务组需要替换整个body区或者替换右侧body区域时，不默认加载右侧 必输条件
        if (!isFunction(props.replaceAdvBody) && !isFunction(props.replaceRightBody)) {
            //为了处理非首次初始化高级弹窗导致的 根据模板更新右侧区域
            // if (isEmpty(searchInfo.snapshotMap.normal)) { this.addRequiredItems.call(this, items); }
            let searchInfo = this.state.searchInfo;
            let currentPlan = this.state.currentPlan;
            let isDefaultPlan = currentPlan.pk_ncc_queryscheme == '001';
            searchInfo.isDefaultPlan = isDefaultPlan;
            let currentSnap = deepClone(currentPlan.renderobj4web_snap);
            let defaultSnap = deepClone(props.searchInfo.defaultPlan.renderobj4web);

            if (flag || !showModal) {
                this.tabIndex = this.tabIndex || '0';
                if (isFunction(props.addAdvTabs)) {
                    let def = [...props.addAdvTabs()];
                    for (let index = 0; index < def.length; index++) {
                        const element = def[index];
                        if (element.defaultOpen === true) {
                            if (this.props.isShowDefalutPage) {
                                this.tabIndex = String(index + 1);
                            } else {
                                this.tabIndex = String(index)
                            }

                            break;
                        }

                    }
                }
                if (isDefaultPlan) {
                    if (this.state.status == 'super' && !props.onlyShowSuperCondition) {
                        this.state.searchInfo.snapshotMap.normal = plan2snapshotTranslate(searchInfo, defaultSnap, props.context, createOperation);
                        this.state.searchInfo.snapshotMap.super = currentSnap;
                    } else if (this.state.status == 'super' && props.onlyShowSuperCondition) {
                        if (currentSnap && currentSnap.length && currentSnap[0].attrcode == 'root') {
                            this.state.searchInfo.snapshotMap.super = currentSnap;
                        } else {
                            this.state.searchInfo.snapshotMap.super = [{
                                attrcode: 'root',
                                refpk: 'root',
                                key: 'root',
                                operationSign: 'and',
                                noRemove: true, //不可移除
                                children: plan2snapshotTranslate(searchInfo, currentSnap, props.context, createOperation),
                                visible: true,
                            }]
                        }
                    } else {
                        this.state.searchInfo.snapshotMap.normal = plan2snapshotTranslate(searchInfo, currentSnap, props.context, createOperation);
                        this.state.searchInfo.snapshotMap.super = [
                            {
                                attrcode: 'root',
                                refpk: 'root',
                                key: 'root',
                                operationSign: 'and',
                                noRemove: true, //不可移除
                                children: deepClone(this.state.searchInfo.snapshotMap.normal),
                                visible: true,
                            }
                        ];
                    }

                } else {
                    if (searchInfo.status == 'super') {
                        if (currentSnap && currentSnap.length && currentSnap[0]) {
                            this.state.searchInfo.snapshotMap.super = plan2snapshotTranslate(searchInfo, currentSnap, props.context, createOperation);
                        } else {
                            this.state.searchInfo.snapshotMap.super = [{
                                attrcode: 'root',
                                refpk: 'root',
                                key: 'root',
                                operationSign: 'and',
                                noRemove: true, //不可移除
                                children: plan2snapshotTranslate(searchInfo, currentSnap, props.context, createOperation),
                                visible: true,
                            }]
                        }
                        this.state.searchInfo.snapshotMap.normal = plan2snapshotTranslate(searchInfo, defaultSnap, props.context, createOperation);
                    } else {
                        this.state.searchInfo.snapshotMap.normal = plan2snapshotTranslate(searchInfo, currentSnap, props.context, createOperation);
                        this.state.searchInfo.snapshotMap.super = [{
                            attrcode: 'root',
                            refpk: 'root',
                            key: 'root',
                            operationSign: 'and',
                            noRemove: true, //不可移除
                            children: plan2snapshotTranslate(searchInfo, defaultSnap, props.context, createOperation),
                            visible: true,
                        }]
                    }
                }
            } else {
                console.log('gaoji已经打开');
                // this.state.searchInfo.snapshotMap[this.state.status] = props.searchInfo.snapshotMap[this.state.status];
            }
        }
        //同步高级弹窗的状态
        this.state.showModal = props.searchInfo.showAdvModal;
        if (props.onlyShowAdvArea) {
            props.searchInfo.status = this.state.status;
        }
        props.searchInfo.snapshotMap.normal = this.state.searchInfo.snapshotMap.normal;
        props.searchInfo.snapshotMap.super = this.state.searchInfo.snapshotMap.super;
        // if (!searchInfo.currentPlan.isMyPlan) {
        // 	this.clickSearchPlan(searchInfo.currentPlan);
        // }

        this.firstInit = false;
    };

    /* setState */
    setStateEve = callback => {
        this.setState(this.state, callback);
    };

    getSnapshotCache = searchInfo => {
        function setvalue(list, cache) {
            list.forEach(item => {
                if (item.children) {
                    setvalue(item.children, cache);
                } else {
                    if (cache[item.attrcode]) {
                        item.initialvalue = cache[item.attrcode];

                    }
                }
            });

        }
        Object.keys(searchInfo.snapshotMap).forEach(status => {
            let snapshot = searchInfo.snapshotMap[status];
            let cache = searchInfo.snapshotCache[status];
            if (!isEmpty(cache)) {
                setvalue(snapshot, cache);
                searchInfo.snapshotCache[status] = {};
            }

        });
    }

    // 从meta里面获取 候选条件树
    getConditionTreeFromItems = items => {
        items.forEach(item => {
            if (!item.hasOwnProperty('attrcode')) {
                console.error(this.multiLang['page-search-0026'] + 'attrcode' + this.multiLang['page-search-0027'] + '，' + this.multiLang['page-search-0028']);
            }
            if (!item.hasOwnProperty('label')) {
                console.error(this.multiLang['page-search-0026'] + 'label' + this.multiLang['page-search-0027'] + '，' + this.multiLang['page-search-0028']);
            }
            item.refpk = item.attrcode || '';
            item.refname = item.label || '';
            !item.pid && delete item.pid;
        });
        return this.props.syncTree.createTreeData(items);
    };

    /* 查询按钮点击事件 */
    searchBtnClickEve = async () => {
        let data = null;
        let id = null;
        let template = [];
        const snapshot = this.state.searchInfo.snapshotMap[this.state.searchInfo.status];
        // let simple_snapshot = tree2Array(this.state.searchInfo.snapshotMap[this.state.searchInfo.status]);

        // 如果业务组替换整个body或者右侧body区域，则需要业务组自己组织自定义查询条件
        if (isFunction(this.props.replaceRightBody) || isFunction(this.props.replaceAdvBody)) {
            /* 此时查询按钮的回调方法中，data为 'userControl'，表示需要业务组自己处理自定义的查询条件 */
            data = 'userControl';
            if (isFunction(this.props.searchBtnClick)) {
                let f = await this.props.searchBtnClick({ ...this.props._this._this, ...this.props._this._this.props },
                    data, 'super');
                if (!f) {
                    this.props.searchInfo.status = this.lastStatus;
                    this.close(true);
                }
            }

        } else {
            data = _getAllSearchData.call(this, this.props.id, this.state.searchInfo, true);
            template = getCurrentTemplate.call(this.props._this, this.props.moduleId);

            if (data !== false) {
                let finalData = {
                    queryAreaCode: this.props.id,
                    oid: this.props.oid || this.props.meta.oid, //这块是一个暂时的处理，因为有些场景this.props.oid ==undefined 原因还没查出来
                    querytype: 'tree',
                    querycondition: data,
                    template,
                    nctz: getTimezoneOffset(),

                };
                // 外层样式 plan => simple  add by wanglongx 不过滤有值无值
                if (isFunction(this.props.searchBtnClick)) {
                    let clickResult = await this.props.searchBtnClick({ ...this.props._this._this, ...this.props._this._this.props }, data, 'super', data && finalData);
                    if (!clickResult) {
                        // this.state.searchInfo.currentSnapshot = snapshotFilter(snapshot,this.state.searchInfo.itemsMap);
                        if (this.props.syncAdvCondition) {
                            // this.state.searchInfo.currentSnapshot = snapshot;
                            this.props.searchInfo.snapshotMap['simple'] = snapshot;
                            this.state.currentPlan.renderobj4web_snap = snapshot;
                            // this.props.searchInfo.currentPlan.renderobj4web_snap = snapshot;
                            this.props.searchInfo.currentPlan = this.state.currentPlan || {};
                        }
                        this.props.searchInfo.status = 'simple';
                        this.close(true);
                    }
                } else {
                    // this.state.searchInfo.currentSnapshot = snapshotFilter(snapshot,this.state.searchInfo.itemsMap);
                    if (this.props.syncAdvCondition) {
                        // this.state.searchInfo.currentSnapshot = snapshot;
                        this.props.searchInfo.snapshotMap['simple'] = snapshot;
                        this.state.currentPlan.renderobj4web_snap = snapshot;
                        // this.props.searchInfo.currentPlan.renderobj4web_snap = snapshot;
                        this.props.searchInfo.currentPlan = this.state.currentPlan || {};
                    }
                    this.props.searchInfo.status = 'simple';
                    this.close(true);
                }
            }
        }
    };

    /* 取消 */
    cancelClick = e => {
        if (e) e.stopPropagation();
        const { backPosition } = this.props;
        isFunction(backPosition) && backPosition();
        if (!this.state.onlyShowSuperBtn) {
            this.props.searchInfo.status = this.lastStatus;
        }
        if (this.props._this._this.state.modal['addSearchPlan@' + this.props.moduleId].showModal) {
            this.props.modal.close('addSearchPlan@' + this.props.moduleId)
        } else if (this.props._this._this.state.modal['editSearchPlanName@' + this.props.moduleId].showModal) {
            this.props.modal.close('editSearchPlanName@' + this.props.moduleId)
        } else {
            if (!this.props.hideSearchCondition) {
                this.props.syncTree.clearSearchValue(this.props.moduleId);
            }
            this.close();
        }
    };

    /* 保存方案按钮 */
    saveSearchPlanClick = () => {
        // this.props.modal.show('saveSearchPlan@' + this.props.moduleId);
        beSureBtnClick.call(this, 'save');
    };

    /* 新增方案按钮 */
    addSearchPlanClick = () => {
        this.props.modal.show('addSearchPlan@' + this.props.moduleId);
    };

    /* 关闭模态框 */
    close = flag => {
        const { onlyShowModalCancelCallback, onlyShowModal } = this.props;
        let statusChangeEvent = this.props.statusChangeEvent;
        statusChangeEvent && statusChangeEvent(this.state.searchInfo.status);
        !flag && onlyShowModal && isFunction(onlyShowModalCancelCallback) && onlyShowModalCancelCallback();
        // 由于卸载导致  state 回调不触发
        // PubSub.publish(COMPLETE, true);
        // let { renderobj4web_snap, renderobj4web } = this.props.searchInfo.currentPlan;
        // if (flag === 0 && renderobj4web_snap.length > 0) {
        //     if (renderobj4web && renderobj4web.length > 0 && renderobj4web[0].attrcode != renderobj4web_snap.attrcode)
        //         delete this.props.searchInfo.currentPlan.renderobj4web_snap;
        // }

        PubSub.publishSync(BEFORECOMPLETE, true);
        this.props._this._this.infoExchangeController.publishSync('heightChange', { area: 'searchArea' });
        this.setState({ showModal: false }, () => {
            this.props._this._this.infoExchangeController.publishSync('heightChange', { area: 'searchArea' });
            // 发布通知表格更新高度
            PubSub.publish(COMPLETE, true);
        });
        document.body.style.overflow = 'auto';
        this.props.closeModal(flag);
    };

    /* 字段操作符change事件 */
    mathSignChange = (data, val, oldValue) => {
        let advancedData = this.state.searchInfo.snapshotMap[this.state.searchInfo.status];
        if (val !== 'between' && oldValue === 'between') {
            data.initialvalue.value = '';
            data.initialvalue.display = '';
        } else if (val === 'between' && oldValue !== 'between') {
            data.initialvalue.display = [];
            data.initialvalue.value = [];
        }
        data.operationSign = val;
        //this.mathSignChangeEve(advancedData, data.refpk, val);
        this.setStateEve(() => {
            typeof this.props.onOperateTypeChange === 'function' && this.props.onOperateTypeChange(data.attrcode, val);
        });
    };

    /* 拖拽到节点 */
    onDropChildren = e => {
        e.preventDefault();

        const keys = JSON.parse(e.currentTarget.getAttribute('keys'));
        this.state.searchInfo.targetKey = keys.attrcode;
        this.state.searchInfo.targetIsExtend = keys.isExtend;
    };

    /* 拖拽经过节点 */
    onDragOver = e => {
        e.preventDefault();
        return true;
    };

    /* 父节点操作符change事件 */
    logicSelectChange = (data, val, oldval) => {
        setSearchValByField.call(this.props._this._this, this.props.moduleId, data.refpk, data, val);
    };

    /* 左侧tab ，change事件 */
    tabChange = val => {
        if (val === '2') {
            //如果还没查询过查询方案，去查询  暂时注释掉
            //this.querySearchPlan();
        }
        this.setState({ leftTab: val });
    };

    /* 创建左侧查询条件区域 */
    createSearchCondition = items => {
        //去掉固定条件和模板不显示的项
        let _item = items.filter(item => item.isfixedcondition != true);
        //_item.forEach(v=>v);
        let tree = this.props.syncTree.createTreeData(deepClone(_item));
        this.props.syncTree.setSyncTreeData(this.props.moduleId, tree);
    };

    /* 默认将visible:true放到普通和高级区域中 */
    addRequiredItems = items => {
        if (!items) return;
        let newItems = [];
        /* 过滤默认显示项 */
        let filterMeta = data => {
            data.map(item => {
                if (Array.isArray(item.children)) {
                    filterMeta(item.children);
                }
                if (item.visible || item.isfixedcondition) {
                    //item.advrequired = !!item.required;
                    newItems.push(item);
                }
            });
        };
        filterMeta(items);
        //排序这个 先放一下 ，看看在哪里比较合适
        //newItems.sort((v1, v2) => v1.visibleposition - v2.visibleposition);
        if (newItems.length) {
            _addData.call(this, newItems, 'normal', this.firstInit && this.props.setInitValueEvent, this.props.isTemplateOrder);
            _addData.call(this, newItems, 'super', this.firstInit && this.props.setInitValueEvent, this.props.isTemplateOrder);
        }

    };

    /* 切换普通和高级 */
    advOrNormal = val => {
        this.state.searchInfo.status = val;
        let statusChangeEvent = this.props.statusChangeEvent;
        statusChangeEvent && statusChangeEvent(this.state.searchInfo.status);
        /* 返回当前页签是普通还是高级 + 业务组是否自定义查询区 */
        this.props.statusChange(val, this.state.userControlAll);
        this.setStateEve();
    };

    /*拖拽元素在目标元素头上移动的时候*/
    onDragOver = e => {
        e.preventDefault();
        return true;
    };

    //单击箭头添加查询条件
    clickRight = () => {
        this.onDrop('clickRight');
    };

    /* 拖到普通查询的区域 */
    onDrop = flag => {
        if (flag === 'clickRight') {
            //hover 候选条件的时候 出现的右侧单击箭头，点击的时候获得node
            this.state.moveNode = deepClone(this.props.syncTree.getHoverNodeKey(this.props.moduleId));
        } else {
            this.state.moveNode = deepClone(this.props.syncTree.getMoveNodeKey(this.props.moduleId));
        }

        //如果拖拽的节点是数组并且第一项的isdrag为false或者 节点为对象并且isdrag 为false，不允许拖拽
        if (
            (isArray(this.state.moveNode) && this.state.moveNode[0].isdrag === false) ||
            this.state.moveNode.isdrag === false
        ) {
            return;
        }

        if (this.state.moveNode.hasOwnProperty('children')) {
            delete this.state.moveNode.children;
        }

        _addData.call(this, this.state.moveNode, this.state.searchInfo.status);
        //this.scrollBottom('normal');
    };

    //让制定ref的容器滚动条滚到最下方
    scrollBottom = ref => {
        let target = this.refs[ref];
        const clientHeight = target.clientHeight;
        const scrollHeight = target.scrollHeight;
        target.scrollTop = clientHeight + scrollHeight;
    };

    /* 清空值 */
    clearAdvValue = () => {
        isFunction(this.props.advSearchClearEve) && this.props.advSearchClearEve();
        //同步内外的status
        this.props.searchInfo.status = this.state.searchInfo.status;
        clearAllValue.call(this.props._this._this, this.props.moduleId);
    };

    /* 修改查询方案 */
    // editSearchPlan = (item) => {
    // 	this.state.currentPlan = item;
    // 	this.state.searchPlanName = item.name;
    // 	this.setStateEve();
    // 	this.props.modal.show('editSearchPlan@' + this.props.moduleId);
    // };
    /* 修改查询方案名称 */
    // 修改名称项 不一定是当前项  editPlanName
    editSearchPlanName = (item, e) => {
        // e.stopPropagation()  
        this.clickSearchPlan(item);
        // this.state.currentPlan = item;
        // this.state.editPlanName = item.name
        // this.state.searchPlanName = item.name;
        // this.setStateEve();
        this.props.modal.show('editSearchPlanName@' + this.props.moduleId);
    };

    /* 删除查询方案 */
    delSearchPlan = item => {
        this.clickSearchPlan(item);
        // this.state.currentPlan = item;
        promptBox({
            color: 'warning',
            hotKeyboard: true,
            title: this.multiLang['page-search-0031'],
            content: <div dangerouslySetInnerHTML={{ __html: this.multiLang['page-search-0015'] }} />, // 提示内容,非必输
            beSureBtnName: this.multiLang['page-search-0009'], // 确定按钮名称, 默认为"确定",非必输
            cancelBtnName: this.multiLang['page-search-0010'],
            beSureBtnClick: beSureBtnClick_Del.bind(this),
        });
    };

    /* 为查询方案中,未填写项添加标识。用于点击方案后，在页面展示 */
    addMark = data => {
        data.map(item => {
            if (
                item.initialvalue &&
                (item.initialvalue.value === '' ||
                    item.initialvalue.value === null ||
                    (isArray(item.initialvalue.value) && !item.initialvalue.value.length) ||
                    (isArray(item.initialvalue.value) && item.initialvalue.value[0] === null))
            ) {
                item.isEmpty = true;
            } else {
                item.isEmpty = false;
            }
            if (Array.isArray(item.children)) {
                this.addMark(item.children);
            }
        });
    };

    /* 点击查询方案 */
    clickSearchPlan = (item, e) => {
        if (item.pk_ncc_queryscheme == this.state.currentPlan.pk_ncc_queryscheme) return;
        item.renderobj4web && item.renderobj4web.forEach(val => (val.advrequired = !!val.required));
        this.state.currentPlan = item;
        this.state.searchInfo.isDefaultPlan = item.pk_ncc_queryscheme == '001';
        this.state.searchPlanName = item.name;
        const extendsList = this.state.searchInfo.fixedConditionGroup;
        // const extendsList = item.pk_ncc_queryscheme == '001' ? [] : this.state.searchInfo.fixedConditionGroup;
        isFunction(this.props.clickPlanEve) && this.props.clickPlanEve(item, this.props._this._this);
        if (item.renderobj4web && item.renderobj4web.length && item.renderobj4web[0].refpk === 'root') {
            //高级
            this.state.searchInfo.status = 'super';
            this.props.searchInfo.status = 'super';
            item.renderobj4web[0].children = extendsList.concat(item.renderobj4web[0].children);
            let plan = item.renderobj4web;
            this.state.searchInfo.snapshotMap.super = plan2snapshotTranslate(this.state.searchInfo, plan, this.props.context, createOperation);
        } else {
            //普通
            this.state.searchInfo.status = 'normal';
            this.props.searchInfo.status = 'normal';
            let plan = item.renderobj4web;
            if (item.pk_ncc_queryscheme == '001') {
                this.state.searchInfo.snapshotMap.normal = plan2snapshotTranslate(this.state.searchInfo, plan, this.props.context, createOperation);
            } else {
                let filterFix = Array.isArray(plan) ? plan.filter(item => {
                    let fixCondition = extendsList.find(a => a.attrcode == item.attrcode);
                    if (fixCondition) {
                        return false;
                    } else {
                        return true;
                    }
                }) : [];
                this.state.searchInfo.snapshotMap.normal = plan2snapshotTranslate(this.state.searchInfo, [...extendsList, ...filterFix], this.props.context, createOperation);
            }


            // if (item.pk_ncc_queryscheme !== '001') {
            //     plan = extendsList.concat(plan);
            // }
        }
        //同步内外状态
        this.props.searchInfo.snapshotMap.normal = this.state.searchInfo.snapshotMap.normal;
        this.props.searchInfo.snapshotMap.super = this.state.searchInfo.snapshotMap.super;
        //this.props.changeCompleteFlag && this.props.changeCompleteFlag.call(this, true);
        // replaceData.call(this.props._this._this, this.props.moduleId, plan, item.name);
        isFunction(this.props.setInitValueEvent) && this.props.setInitValueEvent(this.state.searchInfo.status);
        let statusChangeEvent = this.props.statusChangeEvent;
        statusChangeEvent && statusChangeEvent(this.state.searchInfo.status);
        this.setStateEve();
    };

    /* 创建查询方案dom */
    createSearchPlan = () => {
        /*ajax*/
        if (!this.props.searchPlanCache || !this.props.searchPlanCache.length) {
            return (
                <li className="searchPlanList">
                    <span className="searchPlanName">{this.multiLang['page-search-0029']}</span>
                </li>
            );
        }
        let newCache = deepClone(this.props.searchPlanCache);
        return newCache.map(item => {
            // if (item.isquickqs) {
            //     this.state.preparedPlanName = item.name;
            // }
            return (
                <li className={`searchPlanList ${this.state.currentPlan.name == item.name ? 'active' : ''}`} key={item.pk_ncc_queryscheme} >
                    <span className="searchPlanName" onClick={this.clickSearchPlan.bind(this, item)}>

                        <NCTooltip
                            delayShow={800}
                            inverse
                            overlay={<div>{item.name}</div>}
                            placement="top"
                        >
                            <i>{item.name}</i>
                        </NCTooltip>
                    </span>

                    {!item.isquickqs && !item.isMyPlan && (
                        <NCTooltip
                            placement="top"
                            overlay={this.multiLang['page-search-icon-modify']}
                            delayHide={1}
                            delayShow={1000}
                        >
                            <span className="searchPlanEdit" onClick={this.editSearchPlanName.bind(this, item)}>
                                <i className='iconfont icon-gerenpeizhi' />

                            </span>
                        </NCTooltip>
                    )}

                    {!item.isquickqs && !item.isMyPlan && (
                        <NCTooltip
                            placement="top"
                            overlay={this.multiLang['page-search-icon-delete']}
                            delayHide={1}
                            delayShow={1000}
                        >
                            <span className="searchPlanDel" onClick={this.delSearchPlan.bind(this, item)}>
                                <i className='iconfont icon-shanchu' />
                            </span>
                        </NCTooltip>
                    )}
                </li>
            );
        });
    };

    /* 方案名称change事件 */
    searchPlanNameChange = val => {
        this.setState({ searchPlanName: val });
    };

    /* 运算符change事件 */
    mathSignChangeEve = (treeData, pk, val) => {
        let len = treeData.length;
        while (len--) {
            let item = treeData[len];
            if (item.refpk === pk) {
                /* 处理between和非between类型切换时，数据格式处理。between数据类型为数组，起始和结束的值都为切换前的value值。非between类型时，数据格式为字符串，取between的起始值 */
                if (val !== 'between' && item.operationSign === 'between') {
                    item.initialvalue.value = '';
                    item.initialvalue.display = '';
                } else if (val === 'between' && item.operationSign !== 'between') {
                    item.initialvalue.display = [];
                    item.initialvalue.value = [];
                }
                item.operationSign = val;
                return true;
            } else if (Array.isArray(item.children)) {
                let res = this.mathSignChangeEve(item.children, pk, val);
                if (res) {
                    return false;
                }
            }
        }
    };

    /* 修改方案模态框 */
    modalContent = status => {
        return (
            <div className="plan-content">
                <span className="label">{this.multiLang['page-search-0012'] + ':'}</span>
                <span className="content">
                    <NCHotKeys
                        keyMap={{
                            high: [
                                'enter',
                            ],
                        }}
                        handlers={{ high: beSureBtnClick.bind(this, status, 'enter') }}
                    >
                        <NCFormControl
                            id="NC_searchPlanModalInput"
                            autoFocus
                            className="searchPlanModal"
                            value={this.state.searchPlanName}
                            onChange={this.searchPlanNameChange.bind(this)}
                            maxlength={100}
                        />
                    </NCHotKeys>
                </span>

            </div>
        );
    };

    // 生成查询条件单元
    createDomItem = snapshotItem => {
        let templetItem = this.state.searchInfo.itemsMap[snapshotItem.attrcode];
        let renderItem = this.props.renderItem;
        if (!templetItem) {
            //
            return null;
        } else {
            return new templateEngine({
                id: this.props.moduleId,
                searchInfo: this.state.searchInfo,
                snapshotItem,
                isAdvDom: true,
                onAfterEvent: this.props.onAfterEvent,
                setStateEve: this.setStateEve,
                renderItem,
                context: this.props.context,
                showRequire: false,
                createScript: this.props.createScript,
                showPlaceholder: false,
                hiddenFunList: this.props.hiddenFunList,
                multiLang: this.multiLang,
                LangData: this.LangData,
                // parentRef:this.refs.NCSearchAdvComBox
            });
        }
    };

    /* 生成查询条件 */
    createDom = status => {
        // const fixedConditionGroup = this.state.searchInfo.fixedConditionGroup;
        if (typeof status === 'string') {

            var snapshot = this.state.searchInfo.snapshotMap[status].filter(item => item.visible !== false);

        } else if (typeof status === 'object') {
            var snapshot = status.filter(item => item.visible);
        }
        if (!snapshot.length) {
            return;
        }
        return snapshot.map((data, ind) => {
            //  必输项标识
            let item = this.state.searchInfo.itemsMap[data.attrcode];
            if (!item && !data.attrcode.includes('root')) {
                return null;
            }
            let mustFillIn = null;
            if (item && item.required) {
                mustFillIn = <div className="mustFillIn">*</div>;
            }
            let searchChildDom = this.createDomItem(data);

            /* 树节点dom */
            const treeTitle = () => {
                let logicOptions = {
                    and: this.multiLang['page-search-0035'],
                    or: this.multiLang['page-search-0036'],
                };

                if (data.children && data.children.length) {
                    const hasRequire = data.children.some(item => this.state.searchInfo.itemsMap[item.attrcode] && this.state.searchInfo.itemsMap[item.attrcode].required, this);
                    return (
                        <div className="advancedSearchSign">
                            <div
                                key={data.refpk} // 疑问：key为什么没设置上？？？_targetInit中是null
                                keys={JSON.stringify({ attrcode: data.refpk, isExtend: !!data.isExtend })}
                                // field={data.refpk}
                                className="advancedSearchChild"
                                onDrop={this.onDropChildren}
                                onDragOver={this.onDragOver}
                            >
                                {data.attrcode === 'root' && hasRequire ? (
                                    <div className="advancedSearch_root">{logicOptions[data.operationSign]}</div>
                                ) : (
                                    <div className="select-containter">
                                        <NCSelect
                                            className="NC_logicSign"
                                            showClear={false}
                                            value={String(data.operationSign || 'and')}
                                            onChange={this.logicSelectChange.bind(this, data)}
                                            fieldid={data.attrcode}
                                        >
                                            {Object.keys(logicOptions).map((val, index) => {
                                                return (
                                                    <NCOption key={index} value={String(val)}>
                                                        {logicOptions[val]}
                                                    </NCOption>
                                                );
                                            })}
                                        </NCSelect>
                                    </div>

                                )}
                            </div>
                            {!data.noRemove &&
                                !data.isfixedcondition && (
                                    <div className="searchAdvCom_remove" onClick={this.removeDom.bind(this, data)}>
                                        {this.multiLang['page-search-0037']}
                                    </div>
                                )}
                        </div>
                    );
                } else {
                    if (data.operationOptions) {
                        let selectDom = (
                            <div
                                className="NC_searchAdvCom"
                                key={data.refpk}
                                // field={data.refpk}
                                //keys={data.refpk}
                                keys={JSON.stringify({ attrcode: data.refpk, isExtend: !!data.isExtend })}
                                onDrop={this.onDropChildren}
                                onDragOver={this.onDragOver}
                            >
                                {/* {
                                    this.state.searchInfo.status === 'super' ? (
                                        <span className='remove' >
                                            <div className="link" />
                                        </span>
                                    ) : null
                                } */}

                                <span className='remove'>{data.remove ? (
                                    <div className="searchAdvCom_remove" onClick={this.removeDom.bind(this, data)}>
                                        <i className='iconfont icon-qingkong' />
                                    </div>
                                ) : <div className="searchAdvCom_remove">{this.state.searchInfo.status === 'super' ? <div className="link" /> : null}</div>}
                                </span>
                                <span className='content'>
                                    {mustFillIn}
                                    <NCTooltip
                                        delayShow={800}
                                        inverse
                                        overlay={<div>{item.advLable || item.label}</div>}
                                        placement="top"
                                    >
                                        <div className="searchAdvCom_name">{item.advLable || item.label}</div>
                                    </NCTooltip>

                                    <div className="searchAdvCom_select">
                                        {data.operationOptions.length > 1 ? (

                                            <NCSelect
                                                className="NC_operateSign"
                                                showClear={false}
                                                disabled={!!item.disabled}
                                                value={String(data.operationSign || this.multiLang['page-search-0035'])}
                                                onChange={this.mathSignChange.bind(this, data)}
                                                fieldid="operateSign"
                                            >
                                                {data.operationOptions.map((val, index) => {
                                                    return (
                                                        <NCOption key={index} value={String(val.value)}>
                                                            {val.display}
                                                        </NCOption>
                                                    );
                                                })}
                                            </NCSelect>

                                        ) : (
                                            <span className="option-label">{(data.operationOptions[0] || {}).display}</span>
                                        )}
                                    </div>
                                    <div className="searchAdvCom_com">{searchChildDom}</div>
                                </span>

                            </div>

                        );

                        return selectDom;
                    } else {
                        console.log('the data is', data);
                    }
                }
            };

            if (data.children && data.children.length) {
                let logicOptions = {
                    and: this.multiLang['page-search-0035'],
                    or: this.multiLang['page-search-0036'],
                };
                const children = data.children;
                return (
                    <NCTreeNode
                        key={data.refpk + ind}
                        title={treeTitle.call(this, data)}
                        className="search-right-area-tree-node"
                        liAttr={{ fieldid: getSysFieldid(logicOptions[data.operationSign] + '_node') }}
                    >
                        {this.createDom(children)}
                    </NCTreeNode>
                );
            }

            return (
                <NCTreeNode
                    key={data.attrcode + ind}
                    title={treeTitle.call(this, data)}
                    liAttr={{ fieldid: getSysFieldid(data.attrcode + '_node') }}
                    className={`search-right-area-tree-node${this.state.searchInfo.status === 'super' ? ' child-node' : ''}`}
                />
            );
        }).filter(item => item);

    };

    /* 移除控件 */
    removeDom = data => {
        const { status, snapshotMap } = this.state.searchInfo;
        const queryconditionCount = snapshotMap[status].filter(item => item.attrcode === data.attrcode).length;
        if (!this.props.removeQuerConditionEve || !this.props.removeQuerConditionEve.call(this.props._this._this, this.props.moduleId, data.attrcode, queryconditionCount)) {
            this.state.searchInfo.targetKey = null;
            delNode.call(this.props._this._this, this.props.moduleId, data.refpk);
        }

        // isFunction(advData.remove) && advData.remove(this.comId)
    };

    onDragEnterBox = () => {
        this.state.searchInfo.targetKey = null;
    };

    creatConditionUIDom = data => {
        return (
            <div
                id={this.props.moduleId}
                ref="NCSearchAdvComBox"
                className="NC_searchAdvComBox"
                // onDrop={ onDrop.bind(this)}
                onDragEnter={this.onDragEnterBox.bind(this)}
                fieldid={getSysFieldid('advSearch_tree-area')}
            >
                {/*console.log("refs is",this.refs)*/}
                <div fieldid={getSysFieldid('advSearch_tree')} className='advSearch_tree'>
                    <NCTree
                        // showLine = {showLine}
                        // selectedKeys = { thisTree.selectedValue }
                        // onSelect = { onSelect.bind(this) }
                        // onExpand={ onExpand.bind(this) }    //  节点展开事件
                        // expandedKeys={ [] }    //搜索节点关键字后要展开的父节点
                        // autoExpandParent={ thisTree.autoExpandParent }    //是否展开所有节点
                        // onMouseLeave={ onMouseLeave.bind(this) }    //鼠标离开节点事件
                        // onMouseEnter={ onMouseEnter.bind(this) }    // 鼠标进入节点事件
                        // multiple = {true} //是否允许选择多个节点
                        defaultExpandedKeys="root0"

                        closeIcon={(
                            <p className="tree-icon-content">
                                <NCIcon className="first" type="uf-add-s-o" />
                                <NCIcon className="secend" type="uf-minus" />
                                <NCIcon className="third" type="uf-treefolderopen" />
                            </p>
                        )}
                        openIcon={(
                            <p className="tree-icon-content">
                                <NCIcon className="first" type="uf-reduce-s-o" />
                                <NCIcon className="secend" type="uf-minus" />
                                <NCIcon className="third" type="uf-treefolderopen" />
                            </p>
                        )}
                    // draggable
                    // onDragEnter={ onDragEnter.bind(this) }
                    // onDragOver={ onDragOverBox.bind(this) }
                    // onDragStart={ onDragStart.bind(this) }
                    // onDrop = { onDrop.bind(this) }
                    >
                        {this.createDom(data)}
                    </NCTree>
                </div>

            </div>
        );
    };

    handleChange(value) {
        this.state.searchInfo.status = value;
        this.state.status = value;
        this.props.searchInfo.status = value;
        let statusChangeEvent = this.props.statusChangeEvent;
        statusChangeEvent && statusChangeEvent(this.state.searchInfo.status);
        this.setStateEve();
    }

    /* 默认右侧区域dom */
    defRightDom = () => {
        let searchStatus = this.state.searchInfo.status;
        if (this.props.onlyShowSuperCondition) {
            searchStatus = 'super';
        }
        return (
            <div className="adv-right-area" id="adv-right-area">
                {isFunction(this.props.addAdvBody) && this.props.addAdvBody()}
                {!this.props.hideSearchType && <NCRadioGroup selectedValue={searchStatus} onChange={this.handleChange.bind(this)}>
                    <NCRadio value='normal'>{this.multiLang['page-search-0006']}</NCRadio>
                    <NCRadio value='super'>{this.multiLang['page-search-0007']}</NCRadio>
                </NCRadioGroup>}
                { searchStatus === 'normal' && (
                    <div
                        ref="normal"
                        className="normalArea"
                        id="advArea"
                        style={{ height: isFunction(this.props.addAdvBody) || this.props.hideSearchType ? 'auto' : 'calc(100% - 40px)' }}
                        onDragOver={this.onDragOver}
                        onDrop={this.onDrop.bind(this)}
                    >
                        {this.creatConditionUIDom('normal')}
                    </div>
                )}
                {searchStatus === 'super' && (
                    <div id="advArea" className="superArea" style={{ height: isFunction(this.props.addAdvBody) ? 'auto' : 'calc(100% - 40px)' }} onDragOver={this.onDragOver} onDrop={this.onDrop.bind(this)}>
                        {this.creatConditionUIDom('super')}
                    </div>
                )
                }
            </div>
        );
    };

    tabOnChange = e => {
        this.tabIndex = e;
        e = Number(e);
        if (e > 0 && isFunction(this.props.addAdvTabs)) {
            let callback = this.props.addAdvTabs()[e - 1].callback;
            isFunction(callback) && callback();
        }
        this.setStateEve();
    }

    /* 创建右侧区域dom */
    createRightDom = () => {
        let def = [
            {
                name: this.props.searchConditionName || this.multiLang['page-search-0025'],
                content: this.defRightDom(),
            },
        ];
        if (isFunction(this.props.addAdvTabs)) {
            if (this.props.isShowDefalutPage) {
                def = [...def, ...this.props.addAdvTabs()];
            }
            else {
                def = [...this.props.addAdvTabs()];
            }
        }
        return def;
    };
    onInputFocus = () => {
        const input = document.getElementById('NC_searchPlanModalInput').focus()
    }
    render() {
        return (
            <div className="modalBody" ref="modalBody">

                {/* 业务组替换高级面板的整个body区域时，不加载公共查询条件，只加载自定义条件 */}
                {isFunction(this.props.replaceAdvBody) && this.props.replaceAdvBody()}

                {!isFunction(this.props.replaceAdvBody) && (
                    <div className="modalBodyBox">
                        <div className="leftArea" fieldid={getSysFieldid('left_area')}>
                            <NCTabs
                                defaultActiveKey="1"
                                onChange={this.tabChange.bind(this)}
                                activeKey={this.state.leftTab}
                                tabBarStyle="noborder"
                                className="searchTabStyle"
                            >
                                {!this.props.hideSearchCondition && (
                                    // <NCTabPane tab={this.multiLang['page-search-0004']} key="1">
                                    <NCTabPane
                                        tab={(
                                            <div className={this.state.leftTab == '1' && 'tab-label-in'}>{this.multiLang['page-search-0004']}</div>
                                        )}
                                        key="1"
                                    >
                                        {this.props.syncTree.createSyncTree({
                                            treeId: this.props.moduleId,
                                            showLine: false,
                                            neverDisable: true,
                                            expandedKeys: ['root'],
                                            onDoubleClickEve: this.clickRight,
                                            // metaId:'treeForm',  // 树模板的id
                                            needEdit: false, //是否需要编辑节点功能，默认为true,可编辑；false：不可编辑
                                            draggable: true,
                                            searchType: 'filtration',
                                            nodeRightOpt:
                                                this.state.searchInfo.status === 'normal'
                                                    ? {
                                                        icon: 'uf-triangle-right',
                                                        callback: this.clickRight,
                                                    }
                                                    : false,
                                        })}
                                    </NCTabPane>
                                )}

                                {!this.props.hideSearchPlan && (<NCTabPane
                                    tab={(
                                        <div className={this.state.leftTab == '2' && 'tab-label-in'}>{this.multiLang['page-search-0005']}</div>
                                    )}
                                    key='2'
                                // key={this.state.planTabKey}
                                >
                                    <ul className="searchPlanUl">{this.createSearchPlan()}</ul>
                                </NCTabPane>)}
                            </NCTabs>
                        </div>

                        <div className="rightArea" fieldid={getSysFieldid('rigit_area')} style={{ overflow: isFunction(this.props.replaceRightBody) ? 'auto' : 'hidden' }}>
                            {isFunction(this.props.replaceRightBody) && this.props.replaceRightBody()}
                            {!isFunction(this.props.replaceRightBody) && (
                                <span className="clearData" onClick={this.clearAdvValue}>
                                    {this.multiLang['page-search-0017']}
                                </span>
                            )}
                            {!isFunction(this.props.replaceRightBody) && (
                                <NCTabs style={{ height: '100%' }} className="lucia" onChange={this.tabOnChange.bind(this)} activeKey={this.tabIndex || '0'}>
                                    {this.createRightDom().map((e, i) => {
                                        if (e.defaultOpen === true && this.defaultOpen) {
                                            // this.tabIndex = String(i);
                                            this.defaultOpen = false;
                                        }
                                        return (
                                            <NCTabPane tab={e.name} key={i}>
                                                {e.content}
                                            </NCTabPane>
                                        );
                                    }, this)}
                                </NCTabs>
                            )}
                        </div>
                    </div>
                )}
                {!isEmpty(this.multiLang) && this.props.modal.createModal('editSearchPlanName@' + this.props.moduleId, {
                    title: this.multiLang['page-search-0082'],
                    content: this.modalContent.call(this, 'edit'),
                    beSureBtnClick: beSureBtnClick.bind(this, 'edit'),
                    leftBtnName: this.multiLang['page-search-0009'],
                    rightBtnName: this.multiLang['page-search-0010'],
                    zIndex: 310,
                    onEntered: this.onInputFocus,
                    size: 'sm',
                    className: 'search-modal',
                })}

                {!isEmpty(this.multiLang) && this.props.modal.createModal('addSearchPlan@' + this.props.moduleId, {
                    title: this.multiLang['page-search-0081'],
                    content: this.modalContent.call(this, 'add'),
                    size: 'sm',
                    backdrop: 'static',
                    beSureBtnClick: beSureBtnClick.bind(this, 'add'),
                    leftBtnName: this.multiLang['page-search-0009'],
                    rightBtnName: this.multiLang['page-search-0010'],
                    zIndex: 310,
                    onEntered: this.onInputFocus,
                    className: 'search-modal',

                })}
            </div>
        );
    }
}

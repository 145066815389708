import React, { Component } from "react";
import HotkeyHelperPanel from "./panel";
import { HotKeys, Modal, Tooltip,Icon } from "@platform/base";
import { getMultiLang } from '@platform/api';

require("./index.less");

class HotkeyHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            langJson: {},
        };
    }

    componentWillMount() {
        let callback = (langJson, bool) => {
            if (bool) {
                this.setState({ langJson });
            }
        };
        getMultiLang({ moduleId: "hotkey_helper", callback });
    }

    static defaultProps = {
        className: "",
        backdrop: true,
        enabled: true,
    };

    hideHandle() {
        // TODO  关闭时  聚焦回原来的元素位置
        this.setState({
            show: false,
        });
    }

    render() {
        let { langJson } = this.state;
        let {
            size,
            className,
            backdrop,
            indexInfo,
            enabled,
            data,
            panelType,
            ...others
        } = this.props;
        let overlay = (
            <div className="hkq-helper-content">{langJson["hk-0003"]}</div>
        );
        return enabled ? (
            <div className="hkq-helper-wrapper">
                <HotKeys
                    display={"inline-block"}
                    keyMap={{ open: ["f3"] }}
                    handlers={{
                        open: () => {
                            this.setState({
                                show: true,
                            });
                        },
                    }}
                    focused={true}
                />
                <Tooltip
                    placement="left"
                    inverse
                    overlay={overlay}
                    trigger={["focus", "hover"]}
                    className="hkq-helper-overlay"
                >
                    <i className="hkq-helper-btn" />
                </Tooltip>
                <Modal
                    ref={Modal => (this.Modal = Modal)}
                    show={this.state.show}
                    onHide={this.hideHandle.bind(this)}
                    size={size}
                    className={"combine  hkq-helper-modal  " + className}
                    backdrop={backdrop}
                    indexInfo={indexInfo}
                    {...others}
                    fieldid = "hkq-helper"
                >
                    <Modal.Header closeButton={true}>
                        <span className="hkq-helper-title">
                            {langJson["hk-0001"]}
                        </span>
                        <span className="hkq-helper-toast">
                            {langJson["hk-0002"]}
                        </span>
                        <i
                            className="hkq-helper-esc"
                            onClick={this.hideHandle.bind(this)}
                        >
                            <Icon type="uf-close" />
                            &nbsp;(esc)
                        </i>
                    </Modal.Header>
                    <Modal.Body>
                        <HotkeyHelperPanel
                            data={data}
                            panelType={panelType}
                            langJson = {langJson}
                            {...others}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        ) : null;
    }
}

export default HotkeyHelper;

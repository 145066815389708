import React, { Component } from 'react';
// import PropTypes from 'prop-types';

require('./dragWidth.less');

export default class DragWidthCom extends Component {
    constructor(props) {
        super(props);
        this.mouseDown = false;
    }

    bindLeftDOM = dom => {
        this.divDom = dom;
    }

    componentDidMount() {
        if (this.divDom) {
            this.clientWid = this.divDom.clientWidth;
        }
        this.onMouseDown.call(this);
    }

    onMouseDown() {
        document.addEventListener('mousemove', e => {
            //e.stopPropagation();
            if (this.mouseDown && this.divDom) {
                // fix bug  IE  e.x !== e.clientx  ie  
                let wid = this.clientWid + (e.clientX - this.beginX);
                if (wid >= this.box.clientWidth - 20 || wid <= 50) {
                    this.divDom.style.width = this.divDom.clientWidth + 'px';
                } else {
                    this.divDom.style.width = wid + 'px';
                }
            }
        });
        /*document.documentElement.onmousemove = e => {
      // e.preventDefault();

    };*/

        document.addEventListener('mouseup', () => {
            if (this.mouseDown) {
                this.mouseDown = false;
                if (this.divDom) {
                    this.clientWid = this.divDom.clientWidth;
                }
                this.maskRightDom && (this.maskRightDom.style.display = 'none');
                this.maskLeftDom && (this.maskLeftDom.style.display = 'none');
    
                typeof this.props.onDragStop === 'function' && this.props.onDragStop(this.divDom?.style.width);
            }
        });
        /*document.documentElement.onmouseup = e => {

    };*/

        this.spanDom.addEventListener('mousedown', e => {
            this.beginX = e.clientX;
            this.mouseDown = true;
            // 加个遮罩 应对  iframe的情况
            this.maskRightDom && (this.maskRightDom.style.display = 'block');
            this.maskLeftDom && (this.maskLeftDom.style.display = 'block');
        });
    /*this.spanDom.onmousedown = e => {

    };*/
    }

    render() {
        let lefWid = this.props.defLeftWid || '150px';
        let leftMinWid = this.props.leftMinWid || '50px',
            rightOverflow = this.props.rightOverflow || "auto";
        return (
            <div
                className="dragWidthCom"
                ref={box => {
                    this.box = box;
                }}
            >
                <div
                    className="dragWidthCom_left"
                    style={{ width: lefWid, minWidth: leftMinWid }}
                    ref={this.bindLeftDOM}
                >
                    <div className="dragWidthCom_mask" ref={mask => { this.maskLeftDom = mask; }} />
                    {this.props.leftDom}
                </div>
                <div
                    ref={span => {
                        this.spanDom = span;
                    }}
                    className="dragWidthCom_line"
                >
                    <div className="dragWidthCom_lines" />
                </div>
                <div className="dragWidthCom_right" id="scroll_container">
                    <div className="dragWidthCom_mask" ref={mask => { this.maskRightDom = mask; }} />
                    {this.props.rightDom}
                </div>
            </div>
        );
    }
}

/*
 * @Author: wanglongx
 * @Date: 2019-05-13 14:30:27
 * @LastEditors: bbq
 * @desc 处理 查询时必填项获取光标
 * @LastEditTime: 2021-03-05 15:25:25
 */

import React, { Component } from 'react';
import { WithAutoFocus } from '@platform/base';
import classnames from 'classnames';
const { SearchWithAutoFocus } = WithAutoFocus;

@SearchWithAutoFocus
class SearchItem extends Component {
    render() {
        const { dom, isPass, className, ...others } = this.props;
        let verify = classnames({ un_pass: !isPass })
        return (
            <div
                {...others}
                className={`${className} ${verify}`}
            >
                {dom}
            </div>
        )
    }
}
export default SearchItem;


import { BaseStore, hookFactory } from "@platform/template";
import Form from '@platform/form';
import { isArray, isString, isObj, isFunction } from '@platform/api';
import * as TreeUtils from './util';
let parentPk = null;

class Store extends BaseStore {
    constructor(props) {
        super(props);
    }

    // 影响视图更新的数据
    store = {
        formStore:null,
        //state 修改会影响视图
        searchValue: '', //搜索框录入值
        // 树组件属性
        focusable: true,//是否开启快捷键功能，使用Tab 键导航获取焦点↓↑选中下、上一个节点，→←展开或者收起一个节点,enter键为节点双击事件
        selectedKeys: [], // 设置选中的树节点 selectedKeys
        expandedKeys: [],//展开指定的树节点
        autoExpandParent: true,//是否自动展开父节点
        checkedKeys: [],//选中复选框的树节点:父子节点有关联，如果传入父节点 key，则子节点自动选中；相应当子节点 key 都传入，父节点也自动选中。当设置checkable和checkStrictly，它是一个有checked和halfChecked属性的对象，并且父子节点的选中与否不再关联


        treeData: [],//表格数据
        firstTime: false,//触发dataList计算
        needEdit: true,
        noDisableId: '',

        //addNodeName: '', //新增节点名

        disabledSearch: false,

        showEditModal: false

    }

    //记录的属性和操作数据
    cache = {
        deepData: [],//搜索前暂存数据
        saveExpandedKeys: [],//搜索前暂存展开状态
        saveCheckedKeys: [],//搜索前暂存复选框选中状态

        currentNode: null,//记录当前编辑行
        moveKey: '',//记录拖拽节点key
        isHover: '',//记录鼠标hover节点key
        selectedNode: null,//记录选中子树
        userControlIcon: false,//业务组控制图标按钮是否显示

        showModal: true,
        metaId: '',//记录业务传的表单metaId
        treeNodeOperation: '',
        searchType: 'location',
        treeType: "syncTree",
        noCheckWhenSelect: false //当选中文本时不勾选

    }

    //监听函数
    listeners = {
        // onTreeStatusChange,
        // selectedForInit,
        // onSelectEve,
        // onSelectedChange,
        // clickAddIconEve,
        // clickEditIconEve,
        // clickDelIconEve,
    }

    getListener = (listener) => {
        return this.listeners[listener];
    }

    setListener = (listener, func) => {
        this.listeners[listener] = func;
    }

    getStore(paths) {
        return super.get(paths);
    }

    setStore(paths, value) {
        let res = super.set(paths, value);
        return res;
    }

    setStateEve = () => {
        this.forceUpdate();
    }

    getCache(path) {
        return this.cache[path];
    }


    setCache(path, value) {
        this.cache[path] = value;
    }

    // 搜索功能处理展开节点
    handleExpand = (value, expandedKeys) => {
        // let uniqueExpandedKeys = [];
        // expandedKeys.forEach(item => {
        //     if (item && uniqueExpandedKeys.indexOf(item) === -1) {
        //         uniqueExpandedKeys.push(item);
        //     }
        // });

        let uniqueExpandedKeys = [...new Set(expandedKeys)];
        this.setStore("expandedKeys", uniqueExpandedKeys);
        this.setStore("searchValue", value);
        this.setStore("autoExpandParent", true);

        if ((!value || value == '') && value !== '0') {
            this.setStore("expandedKeys", this.getCache('saveExpandedKeys'));
        }
    };

    //设置选中节点
    setNodeChecked = (pk) => {
        if (isArray(pk)) {
            this.setStore('checkedKeys', pk);
            this.setCache('saveCheckedKeys',pk);
        } else if (isString(pk)) {
            this.setStore('checkedKeys', [pk]);
            this.setCache('saveCheckedKeys',[pk]);
        } else {
            console.error(
                'setNodeChecked方法传参格式不正确，第二个参数应该是数组或字符串',
            );
            return false;
        }
        this.setStateEve();
    }

    //删除成功
    delNodeSuceess = (pk, noneChangeLeaf = false) => {
        let parentNode = {};
        if (typeof pk === 'undefined') {
            console.error('delNodeSuceess方法第二个参数不能为空');
            return false;
        }

        let treeData = this.getStore('treeData');
        TreeUtils.delNode(treeData, pk,parentNode);

        let treeType = this.getCache('treeType');
        if (treeType === "syncTree") {
            let deepData = this.getCache('deepData');
            // 删除备份的节点
            TreeUtils.delNode(deepData, pk,parentNode);
        }

        this.setCache('selectedNode', null)

        if (!noneChangeLeaf) {
            TreeUtils.checkHasChildren(treeData, parentNode.parentPk);
        }
        this.setStore('treeData', [...treeData]);
        this.setStore('showEditModal', false);
        this.setStateEve();
    }

    //修改成功
    editNodeSuccess = (newItem) => {
        let treeData = this.getStore('treeData');
        let treeType = this.getCache('treeType');
        let deepData = [];
        if (treeType === "syncTree") {
            deepData = this.getCache('deepData');
        }
        if (isArray(newItem)) {
            newItem.forEach(item => {
                if (treeData.length != 0) {
                    let newTreeData = TreeUtils.editNodeFun(treeData, item);
                    this.setStore('treeData', [...newTreeData]);
                    treeData =  [...newTreeData];
                }
                if (treeType === "syncTree") {
                    let newDeepData = TreeUtils.editNodeFun(deepData, item);
                    this.setCache('deepData', newDeepData);
                    deepData = newDeepData;
                }
               
            });
            // TODO: 异步树没有这个逻辑
        } else {
            if (treeData.length != 0) {
                let newTreeData = TreeUtils.editNodeFun(treeData, newItem);
                this.setStore('treeData', [...newTreeData]);
            }
            if (treeType === "syncTree") {
                let newDeepData = TreeUtils.editNodeFun(deepData, newItem);
                this.setCache('deepData', newDeepData);
            }
        }
        this.setStateEve();

    }

    closeNodeByPk = (key) => {
        let expandedKeys = this.getStore('expandedKeys');
        if (key || key == 0) {
            if (isArray(key)) {
                key.forEach(item => {
                    expandedKeys.splice(expandedKeys.indexOf(item), 1);
                });
            } else if (isString(key)) {
                if (expandedKeys.indexOf(key) !== -1) {
                    expandedKeys.splice(expandedKeys.indexOf(key), 1);
                }
            }
        } else {
            expandedKeys = [];
        }
        this.setStore('expandedKeys', expandedKeys);
        this.setStateEve();
    }

    hideIcon = (key, data) => {
        if (!isObj(data)) {
            console.warn(
                '同步组件hideIcon方法，第三个参数应为对象，数据格式参考同步树API文档',
            );
            return false;
        }
        let treeData = this.getStore('treeData');
        TreeUtils.hideIconEve(treeData, key, data);
        this.setStateEve();
    }

    addBrotherNode = (data) => {
        let treeData = this.getStore('treeData');
        let userControlIcon = this.getCache('userControlIcon');
        if (isArray(data)) {
            !userControlIcon && TreeUtils.addIconBox(data);
            data.forEach(item => TreeUtils.addBrotherFun(treeData, item));
        } else {
            !userControlIcon && TreeUtils.addIconBox([data]);
            TreeUtils.addBrotherFun(treeData, data);
        }
        this.setStore('firstTime', true);  //新增节点成功时，需要重新分割当前树，用于查询定位树节点
        this.setStore('treeData', [...treeData]);
        this.setStateEve();
    }

    setNodeSelected = (pk) => {
        let treeData = this.getStore('treeData');
        if (isArray(pk)) {
            this.setStore('selectedKeys', pk);
        } else if (isString(pk)) {
            this.setStore('selectedKeys', [pk]);
            this.setCache('selectedNode', TreeUtils.getItem(treeData, pk));
        } else {
            console.error(
                'setNodeSelected方法传参格式不正确，第二个参数应该是数组或字符串',
            );
            return false;
        }
        this.setStateEve();
    }

    cancelSelectedNode = () => {
        this.setStore('selectedKeys', []);
        this.setCache('selectedNode', null);
        this.setStateEve();
    }

    searchChange = (value,refresh = true) => {
        let deepData = this.getCache('deepData');
        let treeData = this.getStore('treeData');
        let searchType = this.getCache('searchType');
        let dataList = this.getCache('dataList');

        // let expandedKeys = [dataList[0].key];
        let expandedKeys = [];

        let ySrc = [...deepData];

        if (searchType === 'filtration') {
            // 这里还有有一个神坑的 deepData 中不一定拥有树所有状态的数据 treeData
            // TODO deepData 与 treeData 数据状态的同步  主要包括 item内的设置项的同步 by bbqin 
            // need fixed 王洋
            let res = this.filterTree(value, ySrc);
            //   let newDataKeys = res.parentKeys;
            dataList.forEach(item => {
                if (this.strToLocaleLowerCase(item.title).indexOf(this.strToLocaleLowerCase(value)) > -1) {
                    expandedKeys.push(item.key);
                }
            });

            if (res.stack && res.stack.length > 0) {
                //data = res.stack;
                this.setStore('treeData', res.stack)

            } else {
                res.stack = [];
                //data = [];
                this.setStore('treeData', [])
            }

            let list = TreeUtils.treeToList(res.stack);
            list.map(item => {
                expandedKeys.push(item.refpk);
            });
            this.handleExpand(value, expandedKeys, ySrc);
            if ((!value || value == '') && value !== '0') {
                this.setStore("expandedKeys", this.getCache('saveExpandedKeys'));
                this.setStore("selectedKeys",this.getCache("saveCheckedKeys"));
                //data = ySrc;
                this.setStore('treeData', ySrc)
            }
        } else {
            expandedKeys = this.getStore('expandedKeys');
            dataList.forEach(item => {
                if (this.strToLocaleLowerCase(item.title).indexOf(this.strToLocaleLowerCase(value)) > -1) {
                    expandedKeys = expandedKeys.concat(TreeUtils.getAllParentKey((item.key || item.id), treeData));
                }
            });
            expandedKeys = [...new Set(expandedKeys)];
            this.handleExpand(value, expandedKeys);
        }

        refresh && this.setStateEve();
    }

    filterTree = (value, treeData) => {
        let overWriteFilterTree = this.getListener('overWriteFilterTree');
        let selectedKeys = this.getStore('selectedKeys');
        let res = [],
            keys = [];
        if (isFunction(overWriteFilterTree)) {
            let overWriteTreeData = overWriteFilterTree(value, treeData);
            let overWriteList = TreeUtils.treeToList(overWriteTreeData);

            let isHasSelectedValue = overWriteList.some(node => {
                if (node) {
                    return node.key === selectedKeys[0];
                }
            });

            res = TreeUtils.createTreeData(overWriteList);

            return {
                stack: res,
                parentKeys: [...keys],
                isHasSelectedValue,
            };
        }

        // 拍平数据
        let list = TreeUtils.treeToList(treeData);

        // 过滤出带有关键字的节点
        let filterNodes = list.filter(v => {

            let beforeName = v.beforeName ? v.beforeName : '';
            let afterName = v.afterName ? v.afterName : '';
            let name = v.refname ? v.refname : '';

            // 如果beforeName afterName 是字符串参与搜索

            if (typeof beforeName === 'string') {
                name = beforeName + name;
            }

            if (typeof afterName === 'string') {
                name = name + afterName;
            }
            name = name.toLocaleLowerCase();

            value = value.toLocaleLowerCase();

            return name.includes(value);
        });

        //........ 筛选出所有相关节点
        let nodesMap = new Map();

        let findAllParents = child => {
            // 递归优化处理，已经走过的路径不再做递归操作
            if (nodesMap.has(child.refpk)) return;
            // 存入路径相关的树节点
            nodesMap.set(child.refpk, child);

            list.map(node => {
                // 如果当前节点child 有父节点  把当且节点父节点放进nodesMap集合
                if (child.pid === node.refpk) {
                    findAllParents(node);
                } else {
                    // 出口
                    return true;
                }
            });
        };

        // 递归函数入口
        filterNodes.map(node => {
            // 每一个含有搜索关键字的节点都会成为递归函数的入口
            findAllParents(node);
        });

        // Map结构转换为map结构
        let finalLists = [];

        for (let node of nodesMap.values()) finalLists.push(node);

        let isHasSelectedValue = finalLists.some(node => {
            if (node) {
                return node.key === selectedKeys[0];
            }
        });

        // 判断当前筛选数据是否有被选择的节点

        // 进行树结构重组处理
        res = TreeUtils.createTreeData(finalLists, { resetIcon: false });

        return {
            stack: res,
            parentKeys: [...keys],
            isHasSelectedValue,
        };
    };

    strToLocaleLowerCase = str => {
        return Object.prototype.toString.call(str) === '[object String]' ? str.toLocaleLowerCase() : String(str).toLocaleLowerCase();
    }


    getHooks = () => {
        return {
            getCache: this.getCache.bind(this),
            setCache: this.setCache.bind(this),
            handleExpand: this.handleExpand.bind(this),
            setStateEve: this.setStateEve.bind(this),
            setStore: this.setStore.bind(this),
            getStore: this.getStore.bind(this),
            getListener: this.getListener.bind(this),
            setListener: this.setListener.bind(this),
            setNodeChecked: this.setNodeChecked.bind(this),
            delNodeSuceess: this.delNodeSuceess.bind(this),
            editNodeSuccess: this.editNodeSuccess.bind(this),
            closeNodeByPk: this.closeNodeByPk.bind(this),
            hideIcon: this.hideIcon.bind(this),
            addBrotherNode: this.addBrotherNode.bind(this),
            setNodeSelected: this.setNodeSelected.bind(this),
            cancelSelectedNode: this.cancelSelectedNode.bind(this),
            searchChange: this.searchChange.bind(this),
            filterTree: this.filterTree.bind(this),
            strToLocaleLowerCase: this.strToLocaleLowerCase.bind(this),
        }
    }
}

export default hookFactory(Store);
import { moment } from '@platform/api';
import { PubSub } from '@platform/api';
import { isFunction, isArray, isEmpty, timeFunctionTranslater, DongbaToLocalTime, LocalToDongbaTime, toast, localeLang, isObj } from '@platform/api';
// import { timeFunctionTranslater, DongbaToLocalTime, LocalToDongbaTime } from '../../public/timer';
import {
    getTimezoneOffset,
    _clone as deepClone,
    getFunctionValueFromViewModal,
    snapshotChangeByTemplate,
    CompareDate,
    isDateFunction,
    naturalTimeHandler,
    plan2snapshotTranslate,
} from './_methods';
// import { _addData } from '../CreateAdvSearch/methods';
// import toast from '../../api/toast';

let multiLang = {};

let OperationOptions = [];

/* 检测组件id是否存在 */
function checkModuleId(id) {
    if (!this.state.meta.hasOwnProperty(id)) {
        toast({ color: 'danger', content: multiLang['page-search-0043'] });
        return false;
    }
    return true;
}
function checkSearchAreaRenderComplete(id) {
    if (!this.state.meta[id].isCompleted) {
        toast({ color: 'danger', content: '请先初始化查询区,再调用查询区方法' });
        return false;
    }
    return true;
}
/* 获取具体某个field的值 */
function getValueByField(searchId, field, status) {
    let searchInfo = this.state.searchInfo;
    let id = this.props.id;
    let snapshot = searchInfo.snapshotMap[status || searchInfo.status];
    let data = getAllSearchDataEve.call(this, id, snapshot, false);
    if (!data) {
        return { value: { firstvalue: '', secondvalue: '' }, display: '', oprtype: '' };
    }
    // 下面这段不走吧。。
    let len = data.conditions.length;
    if (len <= 0) {
        // {
        // 	toast({ content: `当前查询区没有查询条件`, color: 'warning' });
        // }
        return { value: { firstvalue: '', secondvalue: '' }, display: '', oprtype: '' };
    }
    // ——————
    let res = { value: { firstvalue: '', secondvalue: '' }, display: '', oprtype: '' };

    function getvalue(list, field) {
        list.forEach(item => {
            if (item.conditions) {
                getvalue(item.conditions, field);
            } else {
                if (item.field === field) {
                    res.value = item.value;
                    res.display = item.display;
                    res.oprtype = item.oprtype;
                }
            }
        });
    }
    getvalue(data.conditions, field);
    return res;
}
// loop set value 
function setValue(list, field, data) {
    list.forEach(val => {
        if (val.children && Array.isArray(val.children)) {
            setValue(val.children);
        } else if (val.attrcode === field) {
            val.initialvalue = data;
        }
    });
}
/* 设值查询区某个字段的值 */
function setSearchValByFieldEve(searchId, field, data, status) {
    // // 新查询用方案区分 status 其实没有用了 simple narmal super 都处理了
    // let arr =['simple', 'normal', 'super'];
    // if (this.state.searchInfo) {
    // 	let searchInfo = this.state.searchInfo;
    // 	// let snapshot = searchInfo.snapshotMap[status || searchInfo.status];
    // 	let searchPlanList = searchInfo.searchPlanCache;
    // 	let currentPk = searchInfo.currentPlan ? searchInfo.currentPlan.pk_ncc_queryscheme : searchInfo.defaultPlan.pk_ncc_queryscheme;
    // 	let currentPlan = searchPlanList.find(item => item.pk_ncc_queryscheme == currentPk)['renderobj4web']
    // 	if (!data) {
    // 		data = {
    // 			value: '',
    // 			display: ''
    // 		};
    // 	}
    // 	arr.map(item => {
    // 		let current = searchInfo.snapshotMap[item]
    // 		if (isEmpty(current)) {
    // 			searchInfo.snapshotCache[item || searchInfo.status][field] = data;
    // 		} else {
    // 			setValue(current, field, data);
    // 		}
    // 	})
    // 	// if (isEmpty(snapshot)) {
    // 	// 	searchInfo.snapshotCache[status || searchInfo.status][field] = data;
    // 	// } else {
    // 	// 	setValue(snapshot, field, data);
    // 	// }
    // 	// 放到当前方案的 cach 当中
    // 	if (!isEmpty(currentPlan)) {
    // 		setValue(currentPlan, field, data);
    // 	}
    // 	this.setState({
    // 		searchInfo: searchInfo
    // 	});
    // } else {
    // 	console.error('setSearchValByField方法没有找到searchId');
    // 	return false;
    // }
    if (this.state.searchInfo) {
        let searchInfo = this.state.searchInfo;
        // let snapshot = searchInfo?.currentPlan?.renderobj4web_snap || searchInfo.snapshotMap[status || searchInfo.status];
        let snapshot = searchInfo.snapshotMap[status || searchInfo.status];

        if (!data) {
            data = {
                value: '',
                display: '',
            };
        }
        if (isEmpty(snapshot)) {
            searchInfo.snapshotCache[status || searchInfo.status][field] = data;
        } else {
            function setValue(list) {
                list.forEach(val => {
                    if (val.children && Array.isArray(val.children)) {
                        setValue(val.children);
                    } else if (val.attrcode === field) {
                        val.initialvalue = data;
                    }
                });
            }
            setValue(snapshot);
            this.setState({ searchInfo });
        }

    } else {
        console.error('setSearchValByField方法没有找到searchId');
        return false;
    }
}
function ArrayHas2Value(value) {
    let result = true;
    if (!Array.isArray(value) || value.length != 2 || isEmpty(value[0]) || isEmpty(value[1])) result = false;
    return result;
}
export function setMultiLang(_multiLang) {
    multiLang = _multiLang;
}

export function setMultiLangHigh(_multiLang) {
    multiLang = _multiLang;
    if (isEmpty(OperationOptions)) {
        OperationOptions = [
            {
                display: multiLang['page-search-0047'],
                value: '=',
            },
            {
                display: multiLang['page-search-0047'],
                value: '==',
            },
            {
                display: multiLang['page-search-0048'],
                value: '>',
            },
            {
                display: multiLang['page-search-0049'],
                value: '<',
            },
            {
                display: multiLang['page-search-0050'],
                value: 'between',
            },
            {
                display: multiLang['page-search-0051'],
                value: '>=',
            },
            {
                display: multiLang['page-search-0052'],
                value: '<=',
            },
            {
                display: multiLang['page-search-0053'],
                value: '<>',
            },
            {
                display: multiLang['page-search-0054'],
                value: 'like',
            },
            {
                display: multiLang['page-search-0055'],
                value: 'left like',
            },
            {
                display: multiLang['page-search-0056'],
                value: 'right like',
            },
            {
                display: multiLang['page-search-0057'],
                value: 'is null',
            },
            {
                display: multiLang['page-search-0084'],
                value: 'not like',
            },
            {
                display: multiLang['page-search-0085'],
                value: 'is not null',
            },
        ];
    }
}

/* 获取查询区 所有数据 */
export function getAllSearchDataEve(id, snapshot, flag, logic = 'and') {
    let callBackMeta = [];
    let searchInfo = this.state.searchInfo;

    // 必填校验时 把所有必填校验未通过都标红 	
    let requiredArr = [];

    // 这个for 循环的逻辑太复杂了 没办法 必须把 校验逻辑抽出来
    for (let i = 0; i < snapshot.length; i++) {
        if (snapshot[i].children && Array.isArray(snapshot[i].children)) {

            if (i == 0 && snapshot[i].attrcode == 'root') {
                return getAllSearchDataEve.call(this, id, snapshot[i].children, flag, snapshot[i].operationSign);
            } else {
                callBackMeta.push(getAllSearchDataEve.call(this, id, snapshot[i].children, flag, snapshot[i].operationSign));
                continue;
            }

        }
        let attrcode = snapshot[i].attrcode;
        let val = searchInfo.itemsMap[attrcode];
        let VALUE = snapshot[i].initialvalue;
        let displayVal = VALUE.display || '';
        let optSign = snapshot[i].operationSign;
        let isIncludeSub = snapshot[i].runWithChildren || false;
        let refurl = snapshot[i].refurl || '';
        let timeFunction = '';

        if (displayVal && snapshot[i].runWithChildren) {
            displayVal = displayVal + multiLang['page-search-0044'];
        }

        let data = {
            firstvalue: '',
            secondvalue: '',
        };

        if (val.required && flag && snapshot[i].visible) {
            let isKong = false;
            if (isEmpty(VALUE.value) || (Array.isArray(VALUE.value) && !ArrayHas2Value(VALUE.value))) {
                if (optSign != 'is null' && optSign != 'is not null') { isKong = true; }

            }
            if (isKong) {
                callBackMeta = [];
                requiredArr.push(val.attrcode);
            }
        }
        if ((val.itemtype === 'rangepicker' || ((val.itemtype === 'datepicker' || val.itemtype === 'NCTZDatePickerEnd' || val.itemtype === 'NCTZDatePickerStart' || val.itemtype === 'datetimepicker') && optSign === 'between')) && VALUE) {
            let vals = VALUE.value;

            if (vals && !isArray(vals)) {
                vals = vals.split(',');
            }
            //给报表平台加的逻辑，如果是函数，则将函数直接传给后台
            if (isDateFunction(vals[0])) {
                timeFunction = vals.join(',');
            }
            if (vals && isArray(vals)) {
                if (vals.length > 0 && vals[0] && vals[0].format) {
                    // 多时区转化
                    let start = vals[0].format('YYYY-MM-DD');
                    let end = vals[1].format('YYYY-MM-DD');
                    // 1 开始时间
                    let currentZoneStartTime = moment(start).startOf('day').toDate();
                    let currentZoneStartHours = currentZoneStartTime.getHours();
                    let offsetZone = currentZoneStartTime.getTimezoneOffset() / 60;
                    // 2 结束时间
                    let currentZoneEndTime = moment(end).endOf('day').toDate();
                    let currentZoneEndHours = currentZoneEndTime.getHours();
                    // 当地时间转换为东八区时间
                    offsetZone = offsetZone + 8;
                    currentZoneStartTime.setHours(currentZoneStartHours + offsetZone);
                    currentZoneEndTime.setHours(currentZoneEndHours + offsetZone);
                    //   newData.firstvalue = moment(currentZoneStartTime).format('YYYY-MM-DD HH:mm:ss');
                    //   newData.secondvalue = moment(currentZoneEndTime).format('YYYY-MM-DD HH:mm:ss');

                    data.firstvalue = moment(currentZoneStartTime).format('YYYY-MM-DD HH:mm:ss');
                    data.secondvalue = moment(currentZoneEndTime).format('YYYY-MM-DD HH:mm:ss');

                    // newVal.push(value.format('YYYY-MM-DD'));
                } else if (vals.length > 0 && vals[0] && !vals[0].format) {
                    data.firstvalue = vals[0];
                    data.secondvalue = vals[1];
                } else if (vals.length === 0) {
                    data.firstvalue = '';
                    data.secondvalue = '';
                }
            }
        } else if (val.itemtype === 'refer') {
            VALUE = getFunctionValueFromViewModal(VALUE, this.props.context);
            //参照函数翻译完成之后 有可能 出现翻译不出来的情况 ，翻译不出来 再次做一次必填校验吧
            if (val.required && flag && snapshot[i].visible) {
                if (!VALUE.value && VALUE.value !== 0) {
                    callBackMeta = [];
                    if (!requiredArr.includes(val.attrcode)) requiredArr.push(val.attrcode);
                }
            }
            if (optSign === 'between') {
                data.firstvalue = (VALUE.value || [])[0] || '';
                data.secondvalue = (VALUE.value || [])[1] || '';
                displayVal = (VALUE.display || []).join(',');
            } else {
                displayVal = VALUE.display;
                data.firstvalue = VALUE.value;
            }
        } else if (
            (val.itemtype === 'select' ||
                val.itemtype === 'number' ||
                val.itemtype === 'datetimepicker' ||
                val.itemtype === 'NCTZDatePickerEnd' ||
                val.itemtype === 'NCTZDatePickerStart') &&
            optSign === 'between'
        ) {
            data.firstvalue = (VALUE.value || [])[0];
            data.secondvalue = (VALUE.value || [])[1];
            displayVal = (VALUE.display || [])[0] + '~' + (VALUE.display || [])[1];
        } else {
            data.firstvalue = VALUE.value;
        }
        let timeItemList = [
            'datepicker',
            'datetimepicker',
            'rangepicker',
            'NCTZDatePickerStart',
            'NCTZDatePickerEnd',
            'NCTZDatePickClientTime',
            'NCTZDatePickClientHourTime',
            'NCTZDatePickerRangeDay',
            'datePickerNoTimeZone',
        ];
        // 需要从东八区时间处理当地时间，然后计算0点和24点，按照当地时间得0点和24点分别转为东八区时间
        const localToEast = ['datepicker', 'datetimepicker', 'NCTZDatePickClientTime', 'NCTZDatePickClientHourTime'];
        // 开始 和结束时间是时间戳概念  操作符为 = 时不需要进行扩充为日期范围
        const timeStampType = ['NCTZDatePickerStart', 'NCTZDatePickerEnd', 'datetimepicker'];
        if (timeItemList.some(item => item === val.itemtype) && !isEmpty(VALUE.value)) {
            //xuyangt 需要优化此处逻辑 很混乱
            let firstvalue = '',
                secondvalue = '';
            if (isDateFunction(data.firstvalue) && !data.secondvalue) {
                timeFunction = data.firstvalue;
            }
            if (isDateFunction(data.firstvalue) || isDateFunction(data.secondvalue)) {
                if (optSign == '=' || optSign == '<>') {
                    // start 取开始时间戳 end时 取结束时间戳
                    if (timeStampType.includes(val.itemtype)) {
                        if (val.itemtype === 'NCTZDatePickerEnd') {
                            firstvalue = timeFunctionTranslater(data.firstvalue, { flag: false });
                        } else {
                            firstvalue = timeFunctionTranslater(data.firstvalue);
                        }
                    } else {
                        firstvalue = timeFunctionTranslater(data.firstvalue);
                        secondvalue = timeFunctionTranslater(data.firstvalue, { flag: false });
                    }
                } else if (optSign == '<' || optSign == '>=') {
                    firstvalue = timeFunctionTranslater(data.firstvalue);
                } else if (optSign == '>' || optSign == '<=') {
                    firstvalue = timeFunctionTranslater(data.firstvalue, { flag: false });
                } else if (optSign == 'between') {
                    if (!isEmpty(data.firstvalue)) {
                        firstvalue = timeFunctionTranslater(data.firstvalue);
                    }
                    if (!isEmpty(data.secondvalue)) {
                        secondvalue = timeFunctionTranslater(data.secondvalue, { flag: false });
                    }
                }

            } else {
                if (optSign == '=' || optSign == '<>') {
                    if (!timeStampType.includes(val.itemtype)) {
                        // datepicker datepicker 需要处理时区从东八区时间转换为当地时间 计算当天时间[00:00:00, 23:59:59]在转换为东八区时间
                        if (localToEast.includes(val.itemtype) && !isDateFunction(data.firstvalue)) {
                            firstvalue = DongbaToLocalTime(moment(data.firstvalue)).format('YYYY-MM-DD 00:00:00');
                            secondvalue = moment(firstvalue).format('YYYY-MM-DD 23:59:59');

                            // 转为东八区时间 
                            firstvalue = LocalToDongbaTime(moment(firstvalue)).format('YYYY-MM-DD HH:mm:ss');
                            secondvalue = LocalToDongbaTime(moment(secondvalue)).format('YYYY-MM-DD HH:mm:ss');
                        } else {
                            // start end 类型暂未处理
                            firstvalue = moment(data.firstvalue).format('YYYY-MM-DD 00:00:00');
                            secondvalue = timeFunctionTranslater(moment(data.firstvalue).format('YYYY-MM-DD 23:59:59'));
                        }
                    } else {
                        firstvalue = data.firstvalue;
                        secondvalue = firstvalue;
                    }

                } else if (optSign == '<' || optSign == '>=') {
                    firstvalue = naturalTimeHandler(data.firstvalue, 'start');
                } else if (optSign == '>' || optSign == '<=') {
                    firstvalue = naturalTimeHandler(data.firstvalue, 'end');
                } else if (optSign == 'between') {
                    if (!isEmpty(data.firstvalue)) {
                        firstvalue = naturalTimeHandler(data.firstvalue, 'start');
                    }
                    if (!isEmpty(data.secondvalue)) {
                        secondvalue = naturalTimeHandler(data.secondvalue, 'end');
                    }
                }
                //如果存在两个值，做一下端点值大小的判断和调整
                if (firstvalue && secondvalue && CompareDate(firstvalue, secondvalue)) {
                    firstvalue = timeFunctionTranslater(secondvalue);
                    secondvalue = timeFunctionTranslater(firstvalue, { flag: false });
                }
            }
            if (optSign == '=') {
                optSign = 'between';
            }
            data.firstvalue = firstvalue;
            data.secondvalue = secondvalue;

        }
        if (!val.hasOwnProperty('queryOperateType')) {
            val.queryOperateType = '';
            console.error(`查询模板中必须要有queryOperateType字段，请检查模板中${val.attrcode}字段`);
        }
        //增加去除前后空格的逻辑 目前 ‘ ’作为例外情况处理
        if (data.firstvalue && typeof data.firstvalue === 'string' && data.firstvalue !== ' ') {
            data.firstvalue = data.firstvalue.trim();
        }
        if (data.secondvalue && typeof data.firstvalue === 'string' && data.secondvalue !== ' ') {
            data.secondvalue = data.secondvalue.trim();
        }
        let Obj = {
            field: attrcode,
            value: data,
            oprtype: optSign,
            display: String(displayVal || '').replace(/~/, ','),
            isIncludeSub,
            refurl,
            datatype: val.datatype || '',
            timeFunction,
            def5: val.label,
        };
        if (val.itemtype === 'residtxt') {
            Obj.langSeq = VALUE.index;
        }
        if (!isEmpty(Obj.value.firstvalue) || !isEmpty(Obj.value.secondvalue) || optSign == 'is null' || optSign == 'is not null') {
            snapshot[i].visible && callBackMeta.push(Obj);
        }
    }

    if (requiredArr.length !== 0) {
        {
            toast({ content: multiLang['page-search-0045'], color: 'warning' });
        }
        PubSub.publish('searchRequired', requiredArr);
        return false;
    }
    // if (!callBackMeta.length) return;
    return {
        logic,
        // conditions: callBackMeta.filter(item => item),
        conditions: callBackMeta,
    };
}

/*设置查询区数据*/
function setSearchValueEve(id, data, cb) {
    let searchInfo = this.state.searchInfo;
    let snapshot = searchInfo.snapshotMap[searchInfo.status];
    // let snapshot = searchInfo?.currentPlan?.renderobj4web_snap || searchInfo.snapshotMap[searchInfo.status];
    let items = snapshot;
    let datas = data;
    if (datas.conditions) {
        datas = datas.conditions;
    }
    if (!isArray(datas)) {
        console.error('setSearchValue方法第二个参数不正确');
        return false;
    }
    const findItem = item => {
        let len = items.length;
        while (len--) {
            if (items[len].attrcode === item.field) {
                items[len].initialvalue = items[len].initialvalue || {};
                if (item.oprtype === 'between') {
                    items[len].initialvalue.value = [item.value.firstvalue, item.value.secondvalue];
                    //items[len].initialvalue.display = [item.value.firstvalue, item.value.secondvalue];
                } else {
                    items[len].initialvalue.value = item.value.firstvalue;
                    items[len].initialvalue.display = item.display;
                }
                //下面的逻辑是为了处理 反写日期函数时转为具体时间的bug
                if (item.timeFunction) {
                    if (item.timeFunction.includes(',')) {
                        items[len].initialvalue.value = item.timeFunction.split(',');
                    } else {
                        items[len].initialvalue.value = item.timeFunction;
                        items[len].initialvalue.display = '';
                    }
                }
                items[len].operationSign = item.oprtype;
                items[len].runWithChildren = item.isIncludeSub || false;
                items[len].refurl = item.refurl || '';
                break;
            }
        }
    };
    datas.map(item => {
        findItem(item);
    });
    // if (searchInfo?.currentPlan?.renderobj4web_snap) {
    //     searchInfo.snapshotMap[searchInfo.status] = searchInfo.currentPlan.renderobj4web_snap;
    // }
    this.setState(
        { searchInfo },
        () => {
            typeof cb === 'function' && cb();
        },
    );
}

/* 清空查询区 */
function clearSearchAreaEve(data) {
    let len = data.length;
    while (len--) {
        if (data[len].children && Array.isArray(data[len].children)) {
            clearSearchAreaEve(data[len].children);
        } else {
            if (data[len].initialvalue.index !== undefined) {
                data[len].initialvalue = {
                    value: '',
                    display: '',
                    index: data[len].initialvalue.index
                }
                let valMap = data[len].valueMap
                if (valMap) {
                    for (const key in valMap) {
                        let current = valMap.current;
                        let index = valMap.index;
                        valMap[key] = {
                            current: current,
                            index: index,
                            value: ''
                        }
                    }
                }

            } else {
                data[len].initialvalue = {
                    value: '',
                    display: '',
                }
            }
        }

    }
}

/* 设置某个字段是否可用 */
function setDisabledByFieldEve(moduleId, field, status) {
    let meta = this._this.meta.getMeta() || {};

    let data = meta[moduleId].items;
    let len = data.length;
    while (len--) {
        let item = data[len];
        if (item.attrcode === field) {
            item.disabled = status;
            break;
        }
    }
    this._this.meta.setMeta(meta);
}
/* 设置某个字段是否必填*/
function setRequiredByFieldEve(moduleId, field, status) {
    let meta = this._this.meta.getMeta() || {};

    let data = meta[moduleId].items;
    let len = data.length;
    while (len--) {
        let item = data[len];
        if (item.attrcode === field) {
            item.required = !!status;
            break;
        }
    }
    this._this.meta.setMeta(meta);
}
function _setTemlateByField(moduleId, field, property, value) {
    let meta = this._this.meta.getMeta() || {};

    let data = meta[moduleId].items;
    let len = data.length;
    while (len--) {
        let item = data[len];
        if (item.attrcode === field) {
            item[property] = value;
            break;
        }
    }
    this._this.meta.setMeta(meta);

}
function getOprtypeByFieldEve(moduleId, field) {
    let searchInfo = this.state.searchInfo;
    let data = searchInfo.snapshotMap[searchInfo.status];
    function getValue(list, result = []) {
        list.forEach(item => {
            if (item.children) {
                getValue(item.children, result);
            } else {
                if (item.attrcode === field) {
                    result.push(item.operationSign);
                }
            }
        });
        return result;
    }

    return getValue(data);
}

/*=======================================以下为输出方法=============================*/
/*
*	因为查询区有3种展示状态，所以需要根据当前状态判断业务组操作的是在哪种状态下进行的。
*
*	@ 规则：
*	1、当高级面板展开 或者 展示查询方案时,取高级面板中的 普通或高级中的数据
*	2、当高级面板收起 并且 不展示查询方案时，取外面简单查询区的值
*
*	@ 状态：
*	高级面板显影性：  			searchInfo.showAdvModal   ( true/false )
*	高级面板当前是普通或高级：  searchInfo.advSearchStatus	（normal/super）
*
* */

/* 获取查询区所有数据 */
export function getAllSearchData(moduleId, flag = true) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    if (!_this) return null;
    let searchInfo = _this.state.searchInfo;
    let snapshot = searchInfo.snapshotMap[searchInfo.status];
    return getAllSearchDataEve.call(_this, moduleId, snapshot, flag);
}

export function getQueryInfo(moduleId, flag = true) {
    // let _this = this.myState.search[`searchArea_${moduleId}`];
    if (isEmpty(this.state.meta[moduleId])) {
        // toast({ content: multiLang['page-search-0046'], color: 'warning' });
        return {};
    } else {
        let data = getAllSearchData.call(this, moduleId, flag);
        if (data) {
            return {
                queryAreaCode: moduleId,
                oid: this.state.meta[moduleId].oid,
                querytype: 'tree',
                querycondition: data,
                nctz: getTimezoneOffset(),
            };
        } else {
            return {};
        }

    }
}

/* 设置查询区具体某个字段数据 */
export function setSearchValByField(moduleId, field, data, status) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) {
        return;
    }
    let statusList = ['simple', 'plan', 'normal', 'super'];

    if (status && !statusList.some(item => item === status)) {
        status = '';
    }
    let _this = this.myState.search[`searchArea_${moduleId}`];
    setSearchValByFieldEve.call(_this, moduleId, field, data, status);
}

/* 设置查询区数据 */
export function setSearchValue(moduleId, data, cb) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    setSearchValueEve.call(_this, moduleId, data, cb);
}

export function getSearchSnap(moduleId, status) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    let searchInfo = _this.state.searchInfo;
    let snapshot = searchInfo.snapshotMap[status || searchInfo.status];
    return snapshot;
}

export function setSearchSnap(moduleId, data, status) {
    if (!Array.isArray(data)) {
        return;
    }
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    let searchInfo = _this.state.searchInfo;
    searchInfo.snapshotMap[status || searchInfo.status] = data;
    searchInfo.currentPlan.renderobj4web_snap = data;
    _this.setState({ searchInfo });
}

/*
*	获取表格某个字段的数据
* */
export function getSearchValByField(moduleId, field, status) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let statusList = ['simple', 'plan', 'normal', 'super'];
    if (status && !statusList.some(item => item === status)) {
        status = '';
    }
    let _this = this.myState.search[`searchArea_${moduleId}`];
    return getValueByField.call(_this, moduleId, field, status);
}

/*
* 	设置某个查询条件是否可用
* 	@ field：控件的attrcode
*  	@ status: true可用；false不可用
* */
export function setDisabledByField(moduleId, field, status) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    setDisabledByFieldEve.call(_this, moduleId, field, status);
}

/* 关闭查询方案区域 ，内部使用*/
export function closeSearchPlanArea(moduleId) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    _this.state.searchInfo.status = 'simple';
    _this.setState({ searchInfo: _this.state.searchInfo });
}

/* 打开/关闭 高级查询面板 */
export function openAdvSearch(moduleId, status, callback) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    //this.state.meta[moduleId].showAdvModal = status;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    if (status) {
        _this.state.searchAdvBtnClick(_this.state.searchInfo);
    }
    _this.setState({ searchInfo: _this.state.searchInfo }, callback);
}

/* 设置查询区不可编辑 */
export function setDisabled(moduleId, status) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let data = this.state.meta[moduleId].items;
    let len = data.length;
    while (len--) {
        let item = data[len];
        item.disabled = status;
    }
    this.setState({ meta: this.state.meta });
}

/* 清空查询区方法 */
export function clearSearchArea(moduleId) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    let searchInfo = _this.state.searchInfo;
    let data = searchInfo.snapshotMap[searchInfo.status];
    clearSearchAreaEve(data);
    this.setState({ searchInfo });
}
/* 获取查询条件的操作符 */
export function getOprtypeByField(moduleId, field) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    return getOprtypeByFieldEve.call(_this, moduleId, field);
}
/* 修改条件可见性 */
export function changeItemVisibleByField(moduleId, field, _status = false) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    let searchInfo = _this.state.searchInfo;
    let { isDefaultPlan, snapshotMap, status } = searchInfo;

    let items = this.state.meta[moduleId].items;
    if (isDefaultPlan && status == 'simple') {
        // let context = _this.props.context;
        items.find(item => item.attrcode === field).visible = _status;
        // Object.keys(snapshotMap).forEach(key => {
        //     if (status == key) {
        //         let snapshot = snapshotMap[key];
        //         if (key == 'super') {
        //             snapshotMap[key] = _addData.call(_this, items, 'super');
        //         } else {
        //             snapshotMap[key] = snapshotChangeByTemplate(snapshot, items, context);
        //         }
        //     }
        // });

    } else {
        if (status != 'super') {
            //首先从自己的方案里面找，如果有的话就直接修改方案里面的visible
            // let currentSnap = currentPlan.renderobj4web_snap;
            let currentSnap = snapshotMap[status];
            let ITEM = currentSnap.find(item => item.attrcode === field);
            if (ITEM) {
                ITEM.visible = _status;
            } else {
                let itemObj = items.find(item => item.attrcode === field);
                // let initialvalue = { display: "", value: "" };
                if (itemObj) {
                    const obj = {
                        key: field,
                        attrcode: field,
                        refpk: field,
                        initialvalue: { display: "", value: "" },
                        isExtend: false,
                        isfixedcondition: false,
                        label: itemObj.label,
                        operationSign: itemObj.queryOperateType ? itemObj.queryOperateType.split('@')[0] : '',
                        operationOptions: createOperation(itemObj.queryOperateType),
                        remove: true,
                        visible: _status,
                    };
                    currentSnap.push(obj)
                }
            }
        } else {
            // snapshotMap[status] = snapshotMap[status].filter(item => item.attrcode != field);
        }

    }
    // _this.setState({ searchInfo });
    this.setState({ meta: this.state.meta });

}
/*修改查询模板必输性 */
export function setRequiredByField(moduleId, field, status) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    setRequiredByFieldEve.call(_this, moduleId, field, status);
}
/*修改查询模板属性 */
export function setTemlateByField(moduleId, field, property, value) {
    let f = checkModuleId.call(this, moduleId);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${moduleId}`];
    _setTemlateByField.call(_this, moduleId, field, property, value);
}

export function _addData(_items, status, callback, isTemplateOrder) {
    let searchInfo = this.state.searchInfo;
    let advancedData = searchInfo.snapshotMap[status];
    let targetPk = searchInfo.targetKey;
    let result = [];
    if (status === 'normal') {
        targetPk = null;
    }
    if (isArray(_items)) {
        //数组，代表初始化区域
        let items = _items.filter((item, index) => {
            if (!(Number(item.visibleposition) > 0)) {
                item.visibleposition = 99999 + index;
            }
            return (item.visible || item.isfixedcondition) && item.isdrag;
        });
        const requireList = [];
        const fiexedList = [];
        const otherList = [];

        items.forEach((item, index) => {
            if (!(Number(item.visibleposition) > 0)) {
                item.visibleposition = 99999 + index;
            }
            if (item.isfixedcondition) {
                fiexedList.push(item);
            } else if (item.required) {
                requireList.push(item);
            } else {
                otherList.push(item);
            }
        });

        fiexedList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
        if (isTemplateOrder) {
            const notFixed = requireList.concat(otherList).sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
            items = fiexedList.concat(notFixed);

        } else {

            requireList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
            otherList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
            items = fiexedList.concat(requireList).concat(otherList);
        }

        // fiexedList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
        // requireList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
        // otherList.sort((v1, v2) => Number(v1.visibleposition) - Number(v2.visibleposition));
        // items = fiexedList.concat(requireList).concat(otherList);

        let data = items.map(item => {
            let remove = item.required != true && item.isfixedcondition != true;
            if (searchInfo.snapshotCache && searchInfo.snapshotCache[searchInfo.status] && searchInfo.snapshotCache[searchInfo.status][item.attrcode]) {
                item.initialvalue = searchInfo.snapshotCache[searchInfo.status][item.attrcode];
            }
            return initData(item, remove);
        });

        if (status === 'normal') {
            // 普通区域
            searchInfo.snapshotMap[status] = data;
        } else if (data.length > 0 && data[0].refpk !== 'root') {
            //高级区域
            // 如果新增的数据是数组，并且是高级区域,外层没有根节点时，需要在外层添加一个逻辑运算符根节点，且不可移除。
            searchInfo.snapshotMap[status] = [
                {
                    attrcode: 'root',
                    refpk: 'root',
                    key: 'root',
                    operationSign: 'and',
                    noRemove: true, //不可移除
                    children: data,
                    visible: true,
                },
            ];
        }
        isFunction(callback) && callback(status);
        result = searchInfo.snapshotMap[status];

    } else if (isObj(_items)) {
        // 对象代表拖拽添加元素
        //if (_items.isfixedcondition) return;
        let data = initData(_items, true);
        data.isExtend = false;
        if (!advancedData.length) {
            // 数据为空，拖拽到高级区域时需要初始化数据
            if (status === 'normal') {
                searchInfo.snapshotMap[status] = [data];
            } else {
                // 如果新增的数据是数组，并且是高级区域,外层没有根节点时，需要在外层添加一个逻辑运算符根节点，且不可移除。
                searchInfo.snapshotMap[status] = [
                    {
                        attrcode: 'root',
                        refpk: 'root',
                        operationSign: 'and',
                        noRemove: true, //不可移除
                        children: [data],
                        visible: true,
                    },
                ];
            }
            result = searchInfo.snapshotMap[status];
        } else {
            if (status === 'super' && searchInfo.targetKey) {
                let tempItem = searchInfo.itemsMap[searchInfo.targetKey];
                // let targSnopItem  = 
                if (tempItem) {

                    if (tempItem.visible && (searchInfo.targetIsExtend)) {
                        toast({ content: multiLang['page-search-0091'], color: 'warning' });
                        return null;
                    } else if (tempItem.visible && (tempItem.required)) {
                        toast({ content: multiLang['page-search-0058'], color: 'warning' });
                        return null;
                    }
                }
            }
            addDataEve.call(this, advancedData, data, targetPk, status);
        }
    }
    setStateEve.call(this);
    return result;
}

export function _getAllSearchData(id, searchInfo, flag) {
    let snapshot = searchInfo.snapshotMap[searchInfo.status];
    //searchInfo.currentData = JSON.parse(JSON.stringify(snapshot));
    searchInfo.currentData = createCurrentData.call(this, id, snapshot, flag);
    if (searchInfo.currentData === false) {
        return false;
    }
    checkResult(searchInfo.currentData);
    checkConditions(searchInfo.currentData);

    if (isArray(searchInfo.currentData) && !searchInfo.currentData.length) {
        return null;
    }
    if (searchInfo.status === 'normal' || searchInfo.status === 'plan') {
        return {
            logic: 'and',
            conditions: searchInfo.currentData,
        };
    } else {
        return searchInfo.currentData[0];
    }
}

/* 设置searchPlanName */
export function setSearchPlanName(id, name) {
    let f = checkTreeId.call(this, id);
    if (!f) return;
    let searchInfo = this.state.meta[id];
    searchInfo.searchPlanName = name;
    setStateEve.call(this);
}

/* setState */
function setStateEve() {
    this.setState({ searchAdv: this.state.searchAdv });
}

/* 检查组件id是否存在 */
function checkTreeId(treeId) {
    if (!this.state.meta.hasOwnProperty(treeId)) {
        console.error('没有找到高级查询组件的id,请检查传参是否正确');
        return false;
    } else {
        return true;
    }
}

/* 初始化数据 */
function initData(item, remove = false) {
    let snapshotItem = {
        attrcode: item.attrcode,
        remove,
        visible: true,
        isExtend: !!item.isfixedcondition,
    };
    if (item.queryOperateType) {
        // 查询条件，设置操作符，初始化数据
        snapshotItem.operationSign = item.queryOperateType.split('@')[0];
        snapshotItem.refpk = snapshotItem.key = item.attrcode;
        if (snapshotItem.operationSign === 'between') {
            //似乎 between是 不设置默认值的  xuyang 此处得问问
            if (item.itemtype === 'refer') {
                snapshotItem.initialvalue = item.initialvalue || { value: [], display: [] };
            } else {
                snapshotItem.initialvalue = item.initialvalue || { value: [], display: '' };
            }
        } else {
            if (item.itemtype === 'checkbox_switch') {
                snapshotItem.initialvalue = {
                    value: (item.initialvalue && item.initialvalue.value) || false,
                    display: '',
                };
            } else {
                snapshotItem.initialvalue = item.initialvalue || { value: '', display: '' };
                if (item.itemtype === 'residtxt') {
                    snapshotItem.initialvalue.index = item.languageMeta.filter(val => val.languageCode == localeLang)[0]?.index || 1;
                    snapshotItem.valueMap = {};
                    snapshotItem.valueMap[snapshotItem.attrcode + snapshotItem.initialvalue.index] = { value: snapshotItem.initialvalue.value };
                }
            }
        }
        snapshotItem.operationOptions = createOperation(item.queryOperateType);
    } else {
        //逻辑关系，默认‘并且’
        snapshotItem.operationOptions = createOperation('=');
        snapshotItem.operationSign = '=';
        snapshotItem.initialvalue = item.initialvalue || { value: '', display: '' };
    }
    return snapshotItem;
}

/* 生成查询数据 */
function createCurrentData(id, snapshot, flag) {
    let searchInfo = this.state.searchInfo;
    let allRequire = checkRequired.call(this, id, snapshot, flag);
    if (!allRequire) return false;
    else {
        return snapshot.map(snapshotItem => {
            if (Array.isArray(snapshotItem.children)) {
                return {
                    logic: snapshotItem.operationSign,
                    conditions: createCurrentData.call(this, id, snapshotItem.children),
                };
            } else {
                let attrcode = snapshotItem.attrcode;
                let item = searchInfo.itemsMap[attrcode];
                let data = {
                    firstvalue: null,
                    secondvalue: null,
                };

                let initialvalue = snapshotItem.initialvalue || {};
                let displayVal = initialvalue.display || null;
                let optSign = snapshotItem.operationSign;
                let isIncludeSub = snapshotItem.runWithChildren || false;
                let refurl = snapshotItem.refurl || '';
                let timeFunction = '';
                if (displayVal && snapshotItem.runWithChildren) {
                    displayVal = displayVal + multiLang['page-search-0044'];
                }

                if (
                    item.itemtype === 'rangepicker' ||
                    ((item.itemtype === 'datepicker' ||
                        item.itemtype === 'NCTZDatePickerEnd' ||
                        item.itemtype === 'NCTZDatePickerStart' ||
                        item.itemtype === 'datetimepicker') &&
                        snapshotItem.operationSign === 'between')
                ) {
                    let vals = initialvalue.value;
                    if (vals && !isArray(vals) && typeof vals === 'string') {
                        vals = vals.split(',');
                    }
                    //给报表平台加的逻辑，如果是函数，则将函数直接传给后台
                    if (isDateFunction(vals[0])) {
                        timeFunction = vals.join(',');
                    }
                    if (vals && isArray(vals)) {
                        if (vals.length > 0 && vals[0] && vals[0].format) {
                            // 多时区转化
                            let start = vals[0].format('YYYY-MM-DD');
                            let end = vals[1].format('YYYY-MM-DD');
                            // 1 开始时间
                            let currentZoneStartTime = moment(start).startOf('day').toDate();
                            let currentZoneStartHours = currentZoneStartTime.getHours();
                            let offsetZone = currentZoneStartTime.getTimezoneOffset() / 60;
                            // 2 结束时间
                            let currentZoneEndTime = moment(end).endOf('day').toDate();
                            let currentZoneEndHours = currentZoneEndTime.getHours();
                            // 当地时间转换为东八区时间
                            offsetZone = offsetZone + 8;
                            currentZoneStartTime.setHours(currentZoneStartHours + offsetZone);
                            currentZoneEndTime.setHours(currentZoneEndHours + offsetZone);
                            //   newData.firstvalue = moment(currentZoneStartTime).format('YYYY-MM-DD HH:mm:ss');
                            //   newData.secondvalue = moment(currentZoneEndTime).format('YYYY-MM-DD HH:mm:ss');

                            data.firstvalue = moment(currentZoneStartTime).format('YYYY-MM-DD HH:mm:ss');
                            data.secondvalue = moment(currentZoneEndTime).format('YYYY-MM-DD HH:mm:ss');

                            // newVal.push(value.format('YYYY-MM-DD'));
                        } else if (vals.length > 0 && vals[0] && !vals[0].format) {
                            data.firstvalue = vals[0];
                            data.secondvalue = vals[1];
                        } else {
                            data.firstvalue = '';
                            data.secondvalue = '';
                        }
                    }
                } else if (item.itemtype === 'refer') {
                    if (snapshotItem.operationSign === 'between') {
                        displayVal = (initialvalue.display || []).join(',');
                        data.firstvalue = initialvalue.value[0];
                        data.secondvalue = initialvalue.value[1];
                    } else {
                        let VALUE = getFunctionValueFromViewModal(initialvalue, this.props.context);
                        displayVal = VALUE.display;
                        data.firstvalue = VALUE.value;
                    }
                } else if (
                    (item.itemtype === 'select' ||
                        item.itemtype === 'number' ||
                        item.itemtype === 'datetimepicker' ||
                        item.itemtype === 'NCTZDatePickerEnd' ||
                        item.itemtype === 'NCTZDatePickerStart') &&
                    snapshotItem.operationSign === 'between'
                ) {
                    data.firstvalue = (initialvalue.value || [])[0];
                    data.secondvalue = (initialvalue.value || [])[1];
                    displayVal = (initialvalue.display || [])[0] + '~' + (initialvalue.display || [])[1];
                } else {
                    data.firstvalue = initialvalue.value;
                }
                let timeItemList = [
                    'datepicker',
                    'datetimepicker',
                    'rangepicker',
                    'NCTZDatePickerStart',
                    'NCTZDatePickerEnd',
                    'NCTZDatePickClientTime',
                    'NCTZDatePickClientHourTime',
                    'NCTZDatePickerRangeDay',
                    'datePickerNoTimeZone',
                ];
                // 需要从东八区时间处理当地时间，然后计算0点和24点，按照当地时间得0点和24点分别转为东八区时间
                const localToEast = ['datepicker', 'datetimepicker', 'NCTZDatePickClientTime', 'NCTZDatePickClientHourTime'];
                // 开始 和结束时间是时间戳概念  操作符为 = 时不需要进行扩充为日期范围
                const timeStampType = ['NCTZDatePickerStart', 'NCTZDatePickerEnd', 'datetimepicker'];
                if (timeItemList.some(val => val === item.itemtype) && !isEmpty(initialvalue.value)) {
                    //xuyangt 需要优化此处逻辑 很混乱
                    let firstvalue = '',
                        secondvalue = '';
                    if (isDateFunction(data.firstvalue) && !data.secondvalue) {
                        timeFunction = data.firstvalue;
                    }
                    if (isDateFunction(data.firstvalue) || isDateFunction(data.secondvalue)) {
                        if (optSign == '=' || optSign == '<>') {
                            // start 取开始时间戳 end时 取结束时间戳
                            if (timeStampType.includes(item.itemtype)) {
                                if (item.itemtype === 'NCTZDatePickerEnd') {
                                    firstvalue = timeFunctionTranslater(data.firstvalue, { flag: false });
                                } else {
                                    firstvalue = timeFunctionTranslater(data.firstvalue);
                                }
                            } else {
                                firstvalue = timeFunctionTranslater(data.firstvalue);
                                secondvalue = timeFunctionTranslater(data.firstvalue, { flag: false });
                            }
                        } else if (optSign == '<' || optSign == '>=') {
                            firstvalue = timeFunctionTranslater(data.firstvalue);
                        } else if (optSign == '>' || optSign == '<=') {
                            firstvalue = timeFunctionTranslater(data.firstvalue, { flag: false });
                        } else if (optSign == 'between') {
                            if (!isEmpty(data.firstvalue)) {
                                firstvalue = timeFunctionTranslater(data.firstvalue);
                            }
                            if (!isEmpty(data.secondvalue)) {
                                secondvalue = timeFunctionTranslater(data.secondvalue, { flag: false });
                            }
                        }

                    } else {
                        if (optSign == '=' || optSign == '<>') {
                            if (!timeStampType.includes(item.itemtype)) {
                                // datepicker datepicker 需要处理时区从东八区时间转换为当地时间 计算当天时间[00:00:00, 23:59:59]在转换为东八区时间
                                if (localToEast.includes(item.itemtype) && !isDateFunction(data.firstvalue)) {
                                    firstvalue = DongbaToLocalTime(moment(data.firstvalue)).format('YYYY-MM-DD 00:00:00');
                                    secondvalue = moment(firstvalue).format('YYYY-MM-DD 23:59:59');

                                    // 转为东八区时间 
                                    firstvalue = LocalToDongbaTime(moment(firstvalue)).format('YYYY-MM-DD HH:mm:ss');
                                    secondvalue = LocalToDongbaTime(moment(secondvalue)).format('YYYY-MM-DD HH:mm:ss');
                                } else {
                                    // start end 类型暂未处理
                                    firstvalue = moment(data.firstvalue).format('YYYY-MM-DD 00:00:00');
                                    secondvalue = timeFunctionTranslater(moment(data.firstvalue).format('YYYY-MM-DD 23:59:59'));
                                }
                            } else {
                                firstvalue = data.firstvalue;
                                secondvalue = firstvalue;
                            }

                        } else if (optSign == '<' || optSign == '>=') {
                            firstvalue = naturalTimeHandler(data.firstvalue, 'start');
                        } else if (optSign == '>' || optSign == '<=') {
                            firstvalue = naturalTimeHandler(data.firstvalue, 'end');
                        } else if (optSign == 'between') {
                            if (!isEmpty(data.firstvalue)) {
                                firstvalue = naturalTimeHandler(data.firstvalue, 'start');
                            }
                            if (!isEmpty(data.secondvalue)) {
                                secondvalue = naturalTimeHandler(data.secondvalue, 'end');
                            }
                        }

                        if (firstvalue && secondvalue && CompareDate(firstvalue, secondvalue)) {
                            firstvalue = timeFunctionTranslater(secondvalue);
                            secondvalue = timeFunctionTranslater(firstvalue, { flag: false });
                        }
                    }
                    if (optSign == '=') {
                        optSign = 'between';
                    }
                    data.firstvalue = firstvalue;
                    data.secondvalue = secondvalue;
                }
                //增加去除前后空格的逻辑 目前 ‘ ’作为例外情况处理
                if (data.firstvalue && typeof data.firstvalue === 'string' && data.firstvalue !== ' ') {
                    data.firstvalue = data.firstvalue.trim();
                }
                if (data.secondvalue && typeof data.firstvalue === 'string' && data.secondvalue !== ' ') {
                    data.secondvalue = data.secondvalue.trim();
                }

                let Obj = {
                    field: attrcode,
                    value: data,
                    oprtype: optSign,
                    display: String(displayVal || '').replace(/~/, ','),
                    isIncludeSub,
                    refurl,
                    datatype: item.datatype || '',
                    timeFunction,
                    def5: item.label,
                };
                if (item.itemtype === 'residtxt') {
                    Obj.langSeq = initialvalue.index;
                }
                if (!isEmpty(Obj.value.firstvalue) || !isEmpty(Obj.value.secondvalue) || optSign == 'is null' || optSign == 'is not null') {
                    return snapshotItem.visible ? Obj : null;
                } else {
                    return null;
                }
            }
        });
    }
}

//校验必填
function checkRequired(id, snapshot, flag, result = true) {
    let searchInfo = this.state.searchInfo;
    snapshot.forEach(item => {
        if (item.children) {
            result = checkRequired.call(this, id, item.children, flag, result);
        } else {
            let templateItem = searchInfo.itemsMap[item.attrcode];
            let initialvalue =
                templateItem.itemtype === 'refer'
                    ? getFunctionValueFromViewModal(item.initialvalue, this.props.context)
                    : item.initialvalue;
            if (templateItem && templateItem.required && flag && result && item.visible) {
                let isKong = false;
                // 因为 为空或者不为空的条件不可编辑 
                //但是后台需要校验，所以这两种类型的条件可以没有值就传给后台
                //所以必填校验也不用做了
                if (
                    isEmpty(initialvalue.value) ||
                    (Array.isArray(initialvalue.value) && !ArrayHas2Value(initialvalue.value))
                ) {
                    if (item.operationSign != 'is null' && item.operationSign != 'is not null') { isKong = true; }
                }
                if (isKong) {
                    {
                        toast({ content: multiLang['page-search-0045'], color: 'warning' });
                    }
                    result = false;
                }
            }
        }
    });
    return result;
}

/*
*   添加拖拽数据
*   @data: 要添加的数据
*   @targetPk: 拖拽的目标节点pk
*   @flag: 高级/普通  （super/normal）
*
* */
function addDataEve(treeData, data, targetPk, flag) {
    if (typeof targetPk === 'string' && targetPk !== 'root') {
        //有目标节点pk，并且不是根节点时
        let len = treeData.length;
        while (len--) {
            let item = treeData[len];

            // 目标节点如果是逻辑符节点，添加到逻辑符节点的children内
            if (targetPk.split('@')[1] === 'root') {
                if (item.refpk === targetPk) {
                    if (Array.isArray(item.children)) {
                        let newTime = new Date().getTime();
                        data.refpk = newTime + data.refpk;
                        // !item.children.find(v => v.attrcode === data.attrcode) &&
                        item.children.push(data);
                    } else {
                        item.children = [data];
                    }
                    return true;
                }
            } else {
                // 目标节点是查询条件节点时， 创建一个逻辑符根节点 ，节点的子节点为拖拽的节点和目标节点，并删除原目标节点
                if (item.refpk === targetPk && data.refpk !== item.refpk) {
                    //必填字段不能合并？ xuyang
                    if (item.advrequired) {
                        return;
                    }
                    // 生成新节点，拖拽节点和目标节点的refpk都需要重新赋值，以免重复
                    let newTime = new Date().getTime();
                    let newPk = newTime + '@' + 'root';
                    item.refpk = newTime + item.refpk;
                    data.refpk = newTime + data.refpk;
                    let newTree = {
                        attrcode: newPk,
                        refpk: newPk,
                        operationSign: 'and',
                        children: [item, data],
                        visible: true,
                    };
                    //删除原节点 把新的节点插入到原有结构队尾
                    // treeData.splice(len, 1);
                    // treeData.push(newTree);
                    //替换源节点
                    treeData[len] = newTree;
                    return true;
                }
            }
            if (Array.isArray(item.children)) {
                let res = addDataEve.call(this, item.children, data, targetPk);
                if (res) {
                    return false;
                }
            }
        }
    } else {
        if (flag === 'super') {
            //高级区域需要加入到根节点的children中
            /* 疑问：逻辑符为并且时，是否可以在同一级中拖拽多个相同的查询条件？？？——wangshhj */
            // !treeData[0].children.find(item => item.refpk === data.refpk) &&
            let newTime = new Date().getTime();
            data.refpk = newTime + data.refpk;
            treeData[0].children.push(data);
        } else {
            //普通区域直接放到数组中
            //暂时去掉 普通查询中不能拖入重复条件的限制
            //!treeData.find((item) => item.refpk === data.refpk) && treeData.push(data);
            treeData.push(data);
        }
    }
}

/* 检测返回的数据，去掉null */
function checkResult(data) {
    if (!data.length) {
        return null;
    }
    data.map((item, index) => {
        if (!item || (item.value && isArray(item.value.firstvalue) && !item.value.firstvalue.length)) {
            data.splice(index, 1);
            checkResult(data);
        } else if (Array.isArray(item.conditions)) {
            checkResult(item.conditions);
        }
    });
}

/* 检测数据的conditions是否有值，没有值，删除数据 */
function checkConditions(treeData) {
    let len = treeData.length;
    while (len--) {
        let item = treeData[len];
        if (Array.isArray(item.conditions) && item.conditions.length) {
            checkConditions(item.conditions);
        } else if (Array.isArray(item.conditions) && !item.conditions.length) {
            treeData.splice(len, 1);
        }
    }
    if (treeData.length && Array.isArray(treeData[0].conditions) && !treeData[0].conditions.length) {
        checkConditions(treeData);
    }
}

/* 处理运算符 */
export function createOperation(data) {
    data = data || '';
    data = data.replace(/^@+|@$/g, '');
    let o = data.split('@');
    let newArr = [];
    o.forEach(item => {
        for (let i = 0; i < OperationOptions.length; i++) {
            if (item === OperationOptions[i].value) {
                newArr.push(OperationOptions[i]);
                break;
            }
        }
    });
    return newArr;
}

/* 清空当前所有数据 */
export function clearAllValueEve(treeData) {
    let len = treeData.length;
    let itemsMap = this.state.searchInfo.itemsMap;
    while (len--) {
        let item = treeData[len];
        if (item.children && Array.isArray(item.children)) {
            clearAllValueEve.call(this, item.children);
        } else {
            let tempItem = itemsMap[item.attrcode];
            if (!tempItem.disabled) {
                if (tempItem.itemtype === 'residtxt') {
                    item.initialvalue.value = '';
                    item.initialvalue.display = '';
                    if (item.valueMap) {
                        Object.keys(item.valueMap).forEach(key => {
                            item.valueMap[key].value = '';
                        });
                    }
                } else {
                    item.initialvalue = { value: '', display: '' };
                }
            }
        }
    }
}

/*
*  删除节点
*  @ nodePk:删除的节点refpk
* */
export function delNode(id, nodePk) {
    let f = checkTreeId.call(this, id);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${id}`];
    let searchInfo = _this.state.searchInfo;
    let status = searchInfo.status;
    let snapshot = searchInfo.snapshotMap[status];
    delNodeEve(snapshot, nodePk);
    checkChildren(snapshot);
    setStateEve.call(_this);
}

/* 检测节点的children是否有值，没有值，删除该节点 */
function checkChildren(treeData) {
    let len = treeData.length;
    while (len--) {
        let item = treeData[len];
        if (Array.isArray(item.children) && !item.children.length) {
            treeData.splice(len, 1);
        } else if (Array.isArray(item.children)) {
            checkChildren(item.children);
        }
    }
}

/* 删除节点事件 */
function delNodeEve(treeData, nodePk) {
    let len = treeData.length;
    while (len--) {
        let item = treeData[len];
        if (item.refpk === nodePk) {
            treeData.splice(len, 1);
            return true;
        } else if (Array.isArray(item.children)) {
            let res = delNodeEve(item.children, nodePk);
            if (res) {
                return false;
            }
        }
    }
}

/*
*   设置查询区具体某个字段数据   ps:内部使用字段的refpk设值， 业务组使用field设值
*   @ field: 字段
*   @ data: { value, display }
*   @ operationSign: 操作符
* */
export function setSearchValByFieldHigh(searchId, refpk, data, operationSign) {
    // 业务组调用的
    let _this = this.myState.search[`searchArea_${searchId}`];
    let searchInfo = _this.state.searchInfo;
    let snapshot = searchInfo.snapshotMap[searchInfo.status];
    setValueByField(snapshot, refpk, data, operationSign);
    setStateEve.call(_this);
}

/* 根据attrcode设置某个查询条件的值 */
function setValueByField(treeData, field, value, operationSign) {
    let len = treeData.length;
    while (len--) {
        let item = treeData[len];
        if (item.attrcode === field) {
            //此处防止循环引用，导致保存方案报错
            if (item.attrcode.indexOf('@root') === -1) {
                item.initialvalue = value;
            }

            if (operationSign) {
                item.operationSign = operationSign;
            }
        } else if (Array.isArray(item.children)) {
            setValueByField(item.children, field, value, operationSign);
        }
    }
}

/* 获取当前普通区或高级区域的state，用于保存查询方案 */
export function getCurrentTemplate(id) {
    let searchInfo = this.state.searchInfo;
    return searchInfo.snapshotMap[searchInfo.status].map(item => searchInfo.itemsMap[item.attrcode]);
}

/* 清空所有数据 */
export function clearAllValue(id) {
    let f = checkTreeId.call(this, id);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${id}`];
    let searchInfo = _this.state.searchInfo;
    let snapshot = searchInfo.snapshotMap[searchInfo.status];
    clearAllValueEve.call(_this, snapshot);
    setStateEve.call(_this);
}

/* 整体替换数据，用于查询方案。数据结构完整，不需要初始化 */
export function replaceData(id, data, planName) {
    let f = checkTreeId.call(this, id);
    if (!f) return;
    let _this = this.myState.search[`searchArea_${id}`];
    let searchInfo = _this.state.searchInfo;
    const status = searchInfo.status;
    const plan = plan2snapshotTranslate(
        searchInfo,
        data,
        this.app_context,
        createOperation,
    );
    searchInfo.snapshotMap[status] = plan;

    searchInfo.searchPlanName = planName;
    setStateEve.call(_this);
}
/**
 * Created by wangshhj on 2018/1/11.
 */
import NCCreateSearch from './createSearch';
import {
    setSearchValue,
    setSearchValByField,
    getAllSearchData,
    getSearchValByField,
    setDisabledByField,
    closeSearchPlanArea,
    openAdvSearch,
    setDisabled,
    clearSearchArea,
    getQueryInfo,
    getOprtypeByField,
    changeItemVisibleByField,
    setRequiredByField,
    setTemlateByField,
    getSearchSnap,
    setSearchSnap
} from './methods';
let search = {
    NCCreateSearch,
    setSearchValue,
    setSearchValByField,
    getAllSearchData,
    getSearchValByField,
    setDisabledByField,
    closeSearchPlanArea,
    openAdvSearch,
    setDisabled,
    clearSearchArea,
    getQueryInfo,
    getOprtypeByField,
    changeItemVisibleByField,
    setRequiredByField,
    setTemlateByField,
    getSearchSnap,
    setSearchSnap
};

export default search;

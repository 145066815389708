import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import {
    Button,
    Upload as Upload,
} from '@platform/base';
import MyDropdown from '../MyDropDown';
import { isObj } from '@platform/api';

import HelperTip from '../HelperTip';

require('./index.less');

// querySelector  不能識別數字開頭 不能識別特殊字符 ·/·
const replaceSpecialStr = function (str) {
    return str.replace(/\/|\\|,/g, '') || str;
};

export default class MyDividerButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false, // 正常批复弹框显示状态
            visible: false, // 正常批复弹框显示状态
        };
    }

    onSwitchMenu = visible => {
        this.setState({ visible });
    };

    render() {
        const {
            _key,
            title,
            list,
            btncolor,
            disabled,
            area,
            isenable,
            uploadConfig,
            visible,
            dividerType,
            tabindex = 0,
            keyboard,
        } = this.props;
        // 整个下拉的可见性取决于:1.是否曾手动将下拉list的visible设为false,2.list下的分组是否都隐藏
        // 分组的可见性取决于:1.是否曾手动将分组的visible设为false,2.分组里面的按钮是否都隐藏
        list.forEach(item => {
            // 分组的可见性
            if (item.visible !== false) {
                let visible_group = !item.children.every(
                    ele => ele.visible === false,
                );
                if (item.children.length == 0) {
                    visible_group = false;
                }
                item.visible = visible_group;
            }
        });
        // 整个下拉的可见性
        let visible_list = list.visible;
        if (visible_list !== false) {
            visible_list = !list.every(item => item.visible === false);
        }
        // 当下拉中的按钮都隐藏时或者下拉中本来就没有按钮时,整个下拉按钮也隐藏掉
        if (list.length === 0) {
            visible_list = false;
        }

        let btnCls = classNames(replaceSpecialStr(` nc-${area}-btn-${_key} `));

        let vlBtnCls = classNames({
            ' empty btn-left ': !visible_list,
            ' btn-left divider-main-button ': visible_list,
        });

        let mainBtnCls = classNames({
            ' my-divider-buttons-main-wrapper ':
                dividerType || btncolor == 'button_main',
        });

        let uploadBtnCls = classNames({ [` upload-btn-${_key} `]: isObj(uploadConfig) });

        let Btn = (
            <Button
                fieldid={_key}
                btn-code={_key}
                disabled={!isenable || disabled} // 分割按钮要根据请求回来的权限和手动设置的disabled值来设置可用性
                className={classNames(
                    btnCls,
                    vlBtnCls,
                    mainBtnCls,
                    uploadBtnCls,
                )} // 下拉中没有按钮不显示下拉,父按钮四个角都圆角
                onClick={() => {
                    // e.stopPropagation()
                    this.props.onClick({ key: _key, title });
                }}
                tabindex={tabindex}
            >
                {title}
            </Button>
        );

        let bb = dividerBtn => {
            if (visible && visible_list) {
                return (
                    <div className="sawadika">
                        <div className="hover-divider-btn-left-wrapper">
                            <div className="my-divider-icon-main-wrapper">
                                <HelperTip
                                    title={title}
                                    keyboard={keyboard}
                                    disabled={!isenable || disabled}
                                    delayShow={500}
                                >
                                    {isObj(uploadConfig) ? (
                                        <span
                                            className={classNames(vlBtnCls)} // 下拉中没有按钮不显示下拉,父按钮四个角都圆角
                                            onClick={e => {
                                                this.setState({ visible: false });
                                                e.stopPropagation();
                                            }}
                                        >
                                            <Upload
                                                showUploadList={false}
                                                {...uploadConfig}
                                            >
                                                {Btn}
                                            </Upload>
                                        </span>
                                    ) : (<Button
                                        fieldid={_key}
                                        btn-code={_key}
                                        disabled={!isenable || disabled} // 分割按钮要根据请求回来的权限和手动设置的disabled值来设置可用性
                                        className={classNames(
                                            btnCls,
                                            vlBtnCls,
                                            mainBtnCls,
                                        )} // 下拉中没有按钮不显示下拉,父按钮四个角都圆角
                                        onClick={() => {
                                            this.setState({ visible: false });
                                            if (isenable || !disabled) {
                                                this.props.onClick({
                                                    key: _key,
                                                    title,
                                                });
                                            }
                                        }}
                                        tabindex={tabindex}
                                    >
                                        {title}
                                    </Button>)
                                    }
                                </HelperTip>
                            </div>
                        </div>

                        <MyDropdown
                            list={list}
                            area={area}
                            btncolor={btncolor}
                            popContainer={this.props.popContainer}
                            onVisibleChange={this.onSwitchMenu}
                            onClick={this.props.onClick}
                            tabindex={tabindex}
                            className={classNames(` ${area}-${_key}-panel fieldid_${_key}_btn_list`)}
                            dividerBtn={dividerBtn}
                        >
                            {/*<div className="divider-button-btn-wrapper">*/}

                            {isObj(uploadConfig) ? (
                                <span
                                    className={classNames(vlBtnCls)} // 下拉中没有按钮不显示下拉,父按钮四个角都圆角
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Upload
                                        showUploadList={false}
                                        {...uploadConfig}
                                    >
                                        {Btn}
                                    </Upload>
                                </span>
                            ) : (<Button
                                fieldid={_key}
                                btn-code={_key}
                                disabled={!isenable || disabled} // 分割按钮要根据请求回来的权限和手动设置的disabled值来设置可用性
                                className={classNames(vlBtnCls, mainBtnCls)} // 下拉中没有按钮不显示下拉,父按钮四个角都圆角
                                onClick={e => {
                                    e.stopPropagation();
                                    if (isenable || !disabled) {
                                        this.props.onClick({
                                            key: _key,
                                            title,
                                        });
                                    }
                                }}
                            >
                                {title}
                            </Button>)
                            }
                            <Button
                                fieldid={`${_key}-arrow`}
                                className={classNames('btn-right', {
                                    'my-divider-buttons-main-wrapper':
                                        dividerType || btncolor == 'button_main',
                                })}
                                style={{ borderRadius: '0px 3px 3px 0px' }}
                                onClick={this.onSwitchMenu}
                                tabindex={tabindex}
                            >
                                {!this.state.visible ? (<i
                                    className={classNames(
                                        'arrow iconfont icon-hangcaozuoxiala1',
                                        replaceSpecialStr(` nc-${area}-${_key}-icon `),
                                        {
                                            'my-divider-buttons-main-wrapper':
                                                btncolor == 'button_main',
                                        },
                                    )}
                                />
                                ) : (<i
                                    className={classNames(
                                        'arrow iconfont icon-hangcaozuoxiangshang1',
                                        replaceSpecialStr(` nc-${area}-${_key}-icon `),
                                        {
                                            'my-divider-buttons-main-wrapper':
                                                btncolor == 'button_main',
                                        },
                                    )}
                                />)
                                }
                            </Button>
                            {/*</div>*/}
                        </MyDropdown>
                    </div>
                );
            } else if (visible && !visible_list) {
                return (
                    isObj(uploadConfig) ? (
                        <Upload
                            showUploadList={false}
                            {...uploadConfig}
                        >
                            {Btn}
                        </Upload>) :
                        <HelperTip
                            title={title}
                            keyboard={keyboard}
                            disabled={!isenable || disabled}
                            delayShow={500}
                        >

                            {Btn}
                        </HelperTip>
                );
            } else {
                return '';
            }
        };
        return (
            <div
                className="divider-button-wrapper"
                ref={dom => {
                    this.dividerBtn = ReactDOM.findDOMNode(dom);
                }}
            >{bb(this.dividerBtn)}
            </div>
        );
    }
}

MyDividerButton.defaultProps = {
    title: '分割线按钮',
    visible: true,
    list: [],
};


import { isArray } from '@platform/api';
const PID = '__root__';
let parentPk = null;

/* 根据key获取子树 */
export function getItem(treeData, key) {
    if (!key) {
        return treeData;
    }
    let length = treeData.length;
    for (let i = 0; i < length; i++) {
        let item = treeData[i];
        if (item.key == key || item.refpk == key) {
            return item;
        } else if (Array.isArray(item.children)) {
            let res = getItem(item.children, key);
            if (res) {
                return res;
            }
        }
    }
};

/** 将树拉平为数据 */
export function treeToList(treeData, list = []) {
    if (Array.isArray(treeData)) {
        treeData.forEach(e => {
            // 获取除 children 外的其他属性，并且放进list数组
            let { children, ...others } = e;
            list.push({ ...others });
            if (Array.isArray(e.children)) {
                treeToList(e.children, list);
            }
        });
        // 返回list集合
        return list;
    } else {
        return false;
    }
}

/**获取所有主键*/
export function getAllTreeKeys(treeData,list=[]){
    if (Array.isArray(treeData)) {
        treeData.forEach(e => {
            // 获取除 children 外的其他属性，并且放进list数组
            let { refpk,key,id } = e;
            let pk =  refpk || key || id;
            list.push(pk);
            if (Array.isArray(e.children)) {
                getAllTreeKeys(e.children, list);
            }
        });
        // 返回list集合
        return list;
    } else {
        return false;
    }
}

/** 添加isleaf标识 ？？？？？*/
/// 外部未引用
export function addIsLeaf(data, { resetIcon } = {}) {
    (data || []).forEach(item => {
        // 不应该一股脑
        let ib = item.iconBox || {};
        // 需求要求的是取  默认true
        item.iconBox = resetIcon === false ? ib : {
            addIcon: true,
            delIcon: true,
            editIcon: true,
            ...ib,
        };
        if (!item.hasOwnProperty('isleaf')) {
            if (Array.isArray(item.children) && item.children.length) {
                item.isleaf = false;
                addIsLeaf(item.children);
            } else {
                item.isleaf = true;
            }
        }
    });
}


export function addNodeFun(treeData, child, isBefore) {
    if (!child.pid) {
        treeData.push(child);
        return;
    }
    const loop = data => {
        data.forEach(item => {
            if (item.refpk === child.pid) {
                if (Array.isArray(item.children)) {
                    if (isBefore) {
                        item.children.unshift(child);
                    } else {
                        item.children.push(child);
                    }
                } else {
                    item.children = [child];
                }
                item.isleaf = false;
                item.isLeaf = false;
                return true;
            } else if (Array.isArray(item.children)) {
                let res = loop(item.children);
                if (res) {
                    return false;
                }
            }
        });
    };
    loop(treeData);
}


//外部未引用
//  获取符合查询关键字的节点的父节点
export function getParentKey(key, tree) {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => (item.key || item.id) === key)) {
                parentKey = node.key || node.id;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }

    return parentKey;
};

//外部未引用
//  获取符合查询关键字的节点的父节点
export function getAllParentKey(key, tree, parentKeys = []) {
    let parentKey;
    while(parentKey=getParentKey(key,tree)){
        parentKeys.push(parentKey);
        key = parentKey;
    }
    return parentKeys;
};


export function moveNodeEve(tree, node, targetpk) {
    let i = tree.length;
    while (i--) {
        let data = tree[i];
        if (data.refpk === targetpk) {
            if (Array.isArray(data.children)) {
                data.children.push(node);
            } else {
                data.children = [node];
            }
            return true;
        } else {
            if (Array.isArray(data.children)) {
                let res = moveNodeEve(data.children, node, targetpk);
                if (res) {
                    return false;
                }
            }
        }
    }
}

export function setLeafEdit(data, status) {
    data.forEach(item => {
        if (item.isleaf) {
            item.iconBox = {
                addIcon: status,
                delIcon: status,
                editIcon: status,
            };
        } else if (Array.isArray(item.children)) {
            setLeafEdit(item.children, status);
        }
    });
}

export function hideIconEve(tree, key, iconObj) {
    let i = tree.length;
    while (i--) {
        let data = tree[i];
        if (data.key === key) {
            data.iconBox = Object.assign(data.iconBox || {}, iconObj);
            return true;
        } else {
            if (Array.isArray(data.children)) {
                let res = hideIconEve(data.children, key, iconObj);
                if (res) {
                    return false;
                }
            }
        }
    }
}

export function addBrotherFun(treeData, child) {
    if (!child.pid) {
        treeData.push(child);
        return;
    }
    const loop = data => {
        let len = data.length;
        for (let i = 0; i < len; i++) {
            let item = data[i];
            if (item.pid === child.pid) {
                data.push(child);
                return true;
            } else if (Array.isArray(item.children)) {
                let res = loop(item.children);
                if (res) {
                    return false;
                }
            }
        }
    };
    loop(treeData);
}

export function editNodeFun(treeData, child) {
    if (!child.refpk) {
        console.error('树，编辑节点方法，传参错误，没找到refpk');
        return false;
    }
    let list = treeToList(treeData),
        old = list.find(e => e.refpk === child.refpk);
    old && Object.assign(old, child);
    return createTreeData(list);
}

export function delNode(tree, key,parentNode = {}) {
    if (!tree) return;
    let i = tree.length;
    while (i--) {
        let data = tree[i];
        if (data.refpk === key) {
            parentNode.parentPk = data.pid;
            tree.splice(i, 1);
            return true;
        } else {
            if (Array.isArray(data.children)) {
                let res = delNode(data.children, key,parentNode);
                if (res) {
                    return false;
                }
            }
        }
    }
}

export function addIconBox(data) {
    if (data && data instanceof Array && data.length > 0) {
        data.forEach(item => {
            let ib = item.iconBox || {};
            item.iconBox = {
                addIcon: true,
                delIcon: true,
                editIcon: true,
                ...ib,
            };
            if (Array.isArray(item.children)) {
                addIconBox(item.children);
            }
        });
    }
}

export function setNodeDisableEve(treeData, disable, pk) {
    let findYou = false;
    let len = treeData.length;

    for (let i = 0; i < len; i++) {
        let item = treeData[i];
        if (pk) {
            if (item.refpk === pk) {
                treeData[i].disabled = disable;
                findYou = true;
                return;
            }
        } else {
            treeData[i].disabled = disable;
        }
        if (Array.isArray(item.children)) {
            setNodeDisableEve(item.children, disable, pk);
            if (findYou) {
                return false;
            }
        }
    }
}

export function checkHasChildren(tree, pk) {
    let i = tree.length;
    while (i--) {
        let data = tree[i];
        if (data.refpk === pk) {
            if (Array.isArray(data.children) && data.children.length === 0) {
                delete data.children;
                data.isleaf = true;
            }
            return true;
        } else {
            if (Array.isArray(data.children)) {
                let res = checkHasChildren(data.children, pk);
                if (res) {
                    return false;
                }
            }
        }
    }
}

export function createTreeData(data, { resetIcon } = {}) {
    if (!isArray(data)) {
        console.warn('createTreeData方法，参数应该为数组');
        return false;
    }
    if(!data.length){
        return [];
    }

    // 创建Map集合
    let group = new Map();
    data.forEach(e => {
        // 如果没有pid的节点标记为 __root__ 祖节点
        e.pid = e.pid || PID;
        // 处理查询区树没有key的问题
        e.key || (e.key = e.refpk);

        // 把相同pid的节点放在一起 结构：以pid为键 以相同pid节点组成的数组为值
        group.get(e.pid) ? group.get(e.pid).push(e) : group.set(e.pid, [e]);
    });

    function makeDOM(pid) {
        // 如果map 里面 pid 有值，就给每个节点追加children属性
        if (group.get(pid)) {
            // 返回的是map生成的数组,数组成员是e
            return group.get(pid).map(e => {
                // 处理查询区树没有key的问题
                e.key || (e.key = e.refpk);
                e.children = makeDOM(e.refpk);
                return e;
            });
        } else {
            return null;
        }
    }
    let newData = makeDOM(PID);

    addIsLeaf(newData, { resetIcon });
    return newData;
}

import React, { Component } from 'react';
import { cacheTools, clearToast, ViewModel } from '@platform/api';
import { HotKeys, Tooltip } from '@platform/base';
import { getMultiLang } from '@platform/api';

require('./index.less');

export default class CardPagination extends Component {
    constructor(props) {
        super(props);
        //传出this
        this.props.getThis(this);

        this.pkname = 'id';
        if (this.props.events && this.props.events.urlPkname) {
            this.pkname = this.props.events.urlPkname;
        }

        //初始化state
        this.state = {
            allpks: this.getAllpks(true),
            visible: true, //分页组件的显示隐藏
            activeId: this.props.getUrlParam(this.pkname),
            langJson: {},
        };
    }

    UNSAFE_componentWillMount() {
        let callback = langJson => {
            this.setState({ langJson });
        };
        // moduleId为所需加载langJson文件夹名称前缀
        getMultiLang({ moduleId: 'common_icon_title', callback });
    }

    getAllpks = init => {
        let { events } = this.props;
        let allpks = [];
        if (events && events.dataSource) {
            let cacheData = ViewModel.getData(events.dataSource);
            if (
                cacheData &&
                cacheData.simpleTable &&
                cacheData.simpleTable.allpks
            ) {
                allpks = cacheData.simpleTable.allpks;
            }
        } else {
            //init时，在CacheTools中取，否则，在组件内部维护的state中获取
            if (init) {
                allpks = cacheTools.get('allpks');
            } else {
                allpks = this.state.allpks;
            }
        }
        return Array.isArray(allpks) ? allpks : [];
    };

    //获取点击按钮的 id
    getClickItemId = type => {
        let { events } = this.props;
        let { activeId } = this.state;
        if (!activeId) {
            if (events && events.dataSource) {
                let cacheData = ViewModel.getData(events.dataSource);
                if (cacheData && cacheData.currentId) {
                    activeId = cacheData.currentId;
                }
            }
        }

        let allpks = this.getAllpks();
        //删除的场景
        if (!allpks.includes(activeId)) {
            if (events && events.dataSource) {
                let cacheData = ViewModel.getData(events.dataSource);
                if (cacheData && cacheData.currentId) {
                    activeId = cacheData.currentId;
                }
            }
        }

        let currentId = activeId;
        if (Array.isArray(allpks) && allpks.length) {
            let id;
            let index;
            switch (type) {
                case 'firstItem':
                    id = allpks[0];
                    break;
                case 'prevItem':
                    index = allpks.indexOf(currentId);
                    if (index != -1 && index > 0) {
                        id = allpks[index - 1];
                    }
                    break;
                case 'nextItem':
                    index = allpks.indexOf(currentId);
                    if (index != -1 && index < allpks.length - 1) {
                        id = allpks[index + 1];
                    }
                    break;
                case 'finalItem':
                    id = allpks[allpks.length - 1];
                    break;
            }
            if (id) {
                this.setState({ activeId: id });
            }
            return id;
        }
    };

    pageIndexChange = val => {
        let { events, output, rootprops } = this.props;
        if (events && events.handlePageInfoChange) {
            // 清除特殊弹出提示 by bbqin
            clearToast('socketErrorToast');
            events.handlePageInfoChange(
                { ...rootprops, ...output }, //其他
                this.getClickItemId(val),
            );
        }
    };

    getBtnStatus = () => {
        let { events } = this.props;
        let { activeId } = this.state;
        if (!activeId) {
            if (events && events.dataSource) {
                let cacheData = ViewModel.getData(events.dataSource);
                if (cacheData && cacheData.currentId) {
                    activeId = cacheData.currentId;
                }
            }
        }
        let allpks = this.getAllpks();
        //删除的场景
        if (!allpks.includes(activeId)) {
            if (events && events.dataSource) {
                let cacheData = ViewModel.getData(events.dataSource);
                if (cacheData && cacheData.currentId) {
                    activeId = cacheData.currentId;
                }
            }
        }
        let currentId = activeId;
        //currentId存在时
        let currentIndex, endDisabled, firstDisabled;
        currentIndex = allpks.indexOf(currentId);
        if (currentIndex == -1) {
            //allpks里不存在currentId
            endDisabled = true;
            firstDisabled = true;
        } else {
            if (!allpks.length) {
                //没有allpks
                endDisabled = true;
                firstDisabled = true;
            } else if (allpks.length === 1) {
                //只有一个pk
                endDisabled = true;
                firstDisabled = true;
            } else {
                //多个pk
                if (currentIndex === 0) {
                    //第一个
                    endDisabled = false;
                    firstDisabled = true;
                } else if (currentIndex === allpks.length - 1) {
                    endDisabled = true;
                    firstDisabled = false;
                } else {
                    endDisabled = false;
                    firstDisabled = false;
                }
            }
        }
        return {
            isFirst: firstDisabled,
            isEnd: endDisabled,
        };
    };

    render() {
        let { langJson } = this.state;
        let { isFirst, isEnd } = this.getBtnStatus();
        let { hotKeyboard = true, tooltips = [langJson['icon-page-first'], langJson["icon-page-prev"], langJson["icon-page-next"], langJson["icon-page-end"]] } = this.props;
        return (
            <div
                className={`${this.state.visible ? 'show' : 'hide'}  cardPagination-lightapp-component`}
            >
                {hotKeyboard && (
                    <HotKeys
                        keyMap={{
                            endBtnHandler: HotKeys.USUAL_KEYS.CARDPAGE_END,
                            upBtnHandler: HotKeys.USUAL_KEYS.CARDPAGE_UP,
                            downBtnHandler:
                                HotKeys.USUAL_KEYS.CARDPAGE_DOWN,
                            homeBtnHandler:
                                HotKeys.USUAL_KEYS.CARDPAGE_HOME,
                        }}
                        handlers={{
                            endBtnHandler: () => {
                                this.pageIndexChange.bind(this, 'finalItem')();
                            },
                            upBtnHandler: () => {
                                if (isFirst) {
                                    return false;
                                }
                                this.pageIndexChange.bind(this, 'prevItem')();
                            },
                            downBtnHandler: () => {
                                if (isEnd) {
                                    return false;
                                }
                                this.pageIndexChange.bind(this, 'nextItem')();
                            },
                            homeBtnHandler: () => {
                                this.pageIndexChange.bind(this, 'firstItem')();
                            },
                        }}
                        className="cardPage-hotkeys-wrapper"
                        focused={true}
                        attach={document.body}
                        display="inline-block"
                    />
                )}
                <div className="cardPagination-lightapp-component-wrap">
                    <Tooltip
                        placement="top"
                        overlay={tooltips[0]}
                        delayHide={1}
                        delayShow={1000}
                    >
                        <span
                            disabled={isFirst ? true : false}
                            className={`${isFirst ? 'disable' : ''} first-item cardPaginationBtn`}
                            onClick={() => {
                                if (isFirst) {
                                    return false;
                                }
                                this.pageIndexChange.call(this, 'firstItem')
                            }}
                        >
                            <span className="icon iconfont icon-shangyiye" />
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        overlay={tooltips[1]}
                        delayHide={1}
                        delayShow={1000}
                    >
                        <span
                            disabled={isFirst ? true : false}
                            className={`${isFirst ? 'disable' : ''} item second-item cardPaginationBtn`}
                            onClick={() => {
                                if (isFirst) {
                                    return false;
                                }
                                this.pageIndexChange.call(this, 'prevItem')
                            }}
                        >
                            <span className="icon iconfont icon-jiantouzuo" />
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        overlay={tooltips[2]}
                        delayHide={1}
                        delayShow={1000}
                    >
                        <span
                            disabled={isEnd ? true : false}
                            className={`${isEnd ? 'disable' : ''} item third-item cardPaginationBtn`}
                            onClick={() => {
                                if (isEnd) {
                                    return false;
                                }
                                this.pageIndexChange.call(this, 'nextItem')
                            }}
                        >
                            <span className="icon iconfont icon-jiantouyou" />
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        overlay={tooltips[3]}
                        delayHide={1}
                        delayShow={1000}
                    >
                        <span
                            disabled={isEnd ? true : false}
                            className={`${isEnd ? 'disable' : ''} last-item cardPaginationBtn`}
                            onClick={() => {
                                if (isEnd) {
                                    return false;
                                }
                                this.pageIndexChange.call(this, 'finalItem')
                            }}
                        >
                            <span className="icon iconfont icon-xiayiye" />
                        </span>
                    </Tooltip>
                </div>

            </div>
        );
    }
}

/**
 * 使用异步树的节点：数据权限 节点， 使用权限页签-授权规则(选择新增规则，资源实体选'客户基本信息')
 * 
 * 异步树使用了同步树API    hideIcon
 */


import { isArray, isString, isFunction } from '@platform/api';
import useTree from '../store';
import Tree from '../Tree';
import * as TreeUtils from '../util';

const existenceCheck = function (id) {
    let store = this.asyncTreeDataSource[id]
    if (!store) {
        store = useTree();
        this.asyncTreeDataSource[id] = store;
        console.warn(`所操作的表格中无ID为${id}的数据`);
        return;
    }
    return store;
}


// 创建组件
export function createAsyncTree(config = {}) {
    let newConfig = Object.assign({}, config);
    let { 
        treeId, 
        expandWhenDoubleClick=true, 
        editType, 
        doubleClickEve,
        showModal=true,
        metaId,
        ...others 
    } = newConfig;
    // this.register("treeTable"); // 生成 this.treeTableDataSource[id]
    let pageScope = this,
        store = pageScope.asyncTreeDataSource[treeId];
    if (!store) {
        store = useTree();
        pageScope.asyncTreeDataSource[treeId] = store;
    }
    if (!pageScope.formDataSource[metaId] && showModal) {
        pageScope.use.form(metaId);
    }

    return <Tree
        meta={this.state.meta}
        store={store}
        treeId={treeId}
        needSearch={false}
        expandWhenDoubleClick={expandWhenDoubleClick}
        onDoubleClickEve={doubleClickEve}
        needEdit={editType}
        {...others}
        showModal={showModal}
        metaId={metaId}
        formStore={pageScope.formDataSource[metaId]}
        pageOutput={pageScope.output}
        treeType={'asyncTree'}
    />
}

/*
*   生成树 需要的数据结构
*   @ data: 数组
* */
export function createTreeData(data) {
    TreeUtils.createTreeData(data);
}

/*====================================================================================*/
// 设置树 数据
export function setTreeData(id, newData, treeNode) {
    if(!isArray(newData)){
        return;
    }
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
   
    if (!treeNode) {
        //判断是否是根节点; 不是根节点要去找对应的父节点插入child
        let treeData = createNewData(newData);
        store.setStore('treeData', treeData);
        store.setStore('selectedKeys', []); // 清空之前的选择项
    } else {
        let key = '';
        if(treeNode.props && treeNode.props.eventKey){
            key = treeNode.props.eventKey;
        }else if(treeNode.key){
            key = treeNode.key;
        }
        if(key){
            let data = createNewData(newData);
            let treeData = store.getStore('treeData');
            getNewTreeData(treeData, key, data);
            store.setStore('treeData', [...treeData]);
        }else{
            console.warn('异步树setTreeData方法，treeNode参数不正确');
            return;
        }
    }
    store.setStateEve();
    let onSelectedChange = store.getListener('onSelectedChange');
    if(isFunction(onSelectedChange && !treeNode)){
        onSelectedChange(null);
    }
}

/*
 *   设置节点勾选
 *   @ pk： 目前为字符串
 */
export function setNodeChecked(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.setNodeChecked(pk);
}


//  添加树节点
export function addTreeData(id, datas) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let data = createNewData(datas);
    let treeData = store.getStore('treeData');
    if (isArray(data)) {
        TreeUtils.addIconBox(data);
        data.forEach(item => addTreeNode(treeData, item));
    } else {
        TreeUtils.addIconBox([data]);
        addTreeNode(treeData, data);
    }
    store.setStore('treeData', [...treeData])
    store.setStateEve();
}

//  获取树某个节点或整个树信息
export function getAsyncTreeValue(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let treeData = store.getStore('treeData');
    return TreeUtils.getItem(treeData, pk);
}

// 删除树节点
export function delTreeData(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.delNodeSuceess(pk)
}

// 编辑树节点
export function editTreeData(id, newItem) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.editNodeSuccess(newItem);
}

//  设置节点不可用
export function setAsyncNodeDisable(id, disable, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }

    let treeData = store.getStore('treeData'); 
    //let deepData = store.getCache('deepData'); 

    TreeUtils.setNodeDisableEve(treeData, disable, pk);
    // // 同步deepData禁用状态
    // if (deepData) {
    //     TreeUtil.setNodeDisableEve(deepData, disable, pk);
    // }
    // 如果是整体禁用模式，则禁用快捷键功能
    if (!pk) {
        if (disable) {
            // 全被禁用
            store.setStore('focusable', false);
        } else {
            store.setStore('focusable', true);
        }
    }
    store.setStore('treeData', [...treeData])
    store.setStateEve();
}

/*
 *   设置树的编辑性
 *   @ status: true/false
 * */
export function setTreeEdit(id, status) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let needEdit = store.getStore('needEdit'); 
   
    if (needEdit !== status) {
        let onTreeStatusChange = store.getListener('onTreeStatusChange');
        if (onTreeStatusChange) {
            onTreeStatusChange();
        }
    }
    store.setStore('needEdit', status); 
    store.setStateEve();
}

/*
*   获取当前选中节点信息
* */
export function getSelectNodeAsync(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }

   return store.getCache('selectedNode'); 
}

/*
*   展开节点方法
* */
export function openNodeByPkAsync(id, pk, reset) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    let expandedKeys = store.getStore('expandedKeys');

    if (reset) {
        expandedKeys = Object.prototype.toString.call(pk) === '[object Array]' ? pk : (pk ? [pk] : []);
    } else if (Object.prototype.toString.call(pk) === '[object Array]') {
        expandedKeys.push(...pk);
    } else if (Object.prototype.toString.call(pk) === '[object String]') {
        if (expandedKeys.indexOf(pk) === -1) {
            expandedKeys.push(pk);
        }
    } else {
        console.warn("openNodeByPk方法，参数pk,类型为数组或字符串");
        return;
    }

    store.setStore('autoExpandParent', true); 
    store.setStore('expandedKeys', expandedKeys); 
    store.setStateEve();

    let loadTreeData = store.getListener('loadTreeData');
    let treeData = store.getStore('treeData');
    if (isFunction(loadTreeData)) {
        if (isString(pk)) {
            loadTreeData(pk, TreeUtils.getItem(treeData, pk));
        } else if (isArray(pk)) {
            pk.forEach(item => {
                loadTreeData(item, TreeUtils.getItem(treeData, item));
            });
        }
    }
}

/*
*   关闭节点方法
* */
export function closeNodeByPkAsync(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.closeNodeByPk(pk);
}

/*
*   设置图标显示隐藏
*   @ key 节点key值
*   @data 对象，描述图标是否显示  示例：{ editIcon:true, delIcon:false, addIcon:false }
*   true: 显示；  false：隐藏
* */
export function hideIconAsync(id, key, data) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.hideIcon(key, data);
}

/*
*   新增兄弟节点
* */
export function addBrotherNodeAsync(id, data) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
   store.addBrotherNode(data);
}

/*
*   设置节点选中
*   @pk： 目前为字符串
*/
export function setNodeSelectedAsync(id, pk) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.setNodeSelected(pk);
}

/* 取消当前选中所有节点 */
export function cancelSelectedNodeAsync(id) {
    let store = existenceCheck.call(this, id);
    if (!store) {
        return;
    }
    store.cancelSelectedNode();
}



// 组装树组件需要的结构
function createNewData(val){
    if (isArray(val)) {
        val.map((item, index) => {
            item.key = item.refpk;
            if (!item.iconBox) {
                if (item.key) {
                    item.iconBox = {
                        addIcon: true,
                        delIcon: true,
                        editIcon: true,
                    };
                }
            }
            if (item.key === undefined) {
                console.error('createNewData方法报错，异步树的数据中缺少refpk字段，请检查数据格式');
                return false;
            }
        });
    } else if (val.refpk) {
        val.key = val.refpk;
        if (!val.iconBox) {
            val.iconBox = {
                addIcon: true,
                delIcon: true,
                editIcon: true,
            };
        }
    } else {
        console.error('createNewData, 异步树，数据格式不正确');
    }
    return val;
};

//  插入对应的 树 子节点数据
function getNewTreeData(treeData, parentKey, child) {
    if (!child || !isArray(child) || child.length === 0) {
        return false;
    }
    const loop = data => {
        if (!data || data.length === 0) {
            return false;
        }
        for(let item of data){
            if (item.key === child[0].pid) {
                item.children = child;
                return;
            } 
             if (item.children) {
               loop(item.children);
            }
        }
    };
    loop(treeData);
    return treeData;
}



// 新增节点，插入节点
function addTreeNode(treeData, data) {
    let pid = data.pid;
    if (pid) {
        let len = treeData.length;
        for (let i = 0; i < len; i++) {
            let node = treeData[i];
            if (node.refpk === pid) {
                if (node.children) {
                    node.children.push(data);
                } else {
                    node.children = [data];
                }
                return true;
            } else if (Array.isArray(node.children)) {
                let res = addTreeNode(node.children, data);
                if (res) {
                    return false;
                }
            }
        }
    } else {
        if (data.refpk) {
            treeData.push(data);
        } else {
            console.warn('addTreeNode方法，参数data格式不正确');
        }
    }
}


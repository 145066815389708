/**
 * Created by wangshhj on 2018/1/18.
 */

import { warningOnce, isFunction, isWrong, formatAcuracy, isArray } from '@platform/api';
import { CONST_IS_MULTILINGUAL } from './constant';
// 总配置文件
const timeTypes = ['datepicker', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'timepicker', 'datetimepicker', 'rangepicker', 'datePickerNoTimeZone']
const displayTypes = ['select', 'radio', 'checkbox', 'refer']
import { utils } from '@platform/table-core';
const { getPageSizeStorage } = utils;

export function getInsertTableValue(id) {
    return this.state.insertTable[id];
}

/*
*   获取当前表格勾选所有数据
*   @ mianCord  主表主键字段
*   @childCord  子表主键字段
* */
export function getInsertTableSelectedValue(id, mainCord, childCord) {
    let table = this.state.insertTable[id];
    let newArr = [];
    let bodyKey = null;
    table.mainCheckObj.checkedArray.map((item, index) => {

        //先判断主表是否勾选，如果勾选，只返回主表主键即可，如果没有勾选，查看子表是否有数据，是否有勾选项，如果有，返回主表主键+子表主键
        let newObj = {};
        if (table.mainCheckObj.checkedArray[index]) {
            if (table.outerData[index].values.hasOwnProperty(mainCord)) {
                newObj[mainCord] = table.outerData[index].values[mainCord].value;
                newArr.push(newObj);
            } else {
                alert('没有找到主表主键');
            }
        } else {
            bodyKey = table.outerData[index].values.key;
            if (table.childCheckObj.hasOwnProperty(bodyKey)) {
                newObj[childCord] = [];
                table.childCheckObj[bodyKey].checkedArrayChild.map((val, index2) => {
                    if (table.childCheckObj[bodyKey].checkedArrayChild[index2]) {
                        newObj[mainCord] = table.outerData[index].values[mainCord].value;
                        newObj[childCord].push(table.bodyData.data[bodyKey].rows[index2].values[childCord].value);
                    }
                });
                if (newObj[mainCord]) {
                    newArr.push(newObj);
                }
            }
        }
    });
    return newArr;
}

//获取pageInfo信息
export function getPageInfo(id) {
    return this.state.insertTable[id].pageInfo;
}

/*
"refreshData": [{
  "saga_status": "1",
  "mny": "123",
  "cbillid": "123124",
  "ts": "2019-10-28 12:23:23"
 }, {
  "saga_status": "1",
  "mny": "444",
  "cbillid": "44444",
  "ts": "2019-10-28 12:23:23"
 }]
 */

/**34
 *  根据wensocket推送值，更新表格数据
 * refreshData
 */

export function updateDataByRefresh(tableId, pkname, refreshData, saga_errormesg) {
    let myTable = this.state.insertTable[tableId];
    if (typeof tableId == 'string' && myTable && Array.isArray(refreshData)) {
        let outerData = myTable.outerData,
            bodyData = myTable.bodyData,
            oLen = outerData.length,
            refreshLen = refreshData.length,
            bLen = bodyData && bodyData.data && Object.keys(bodyData.data),
            RD = {};

        if (!oLen || !refreshLen) return;
        // 数据转型
        refreshData.forEach((item, index) => {
            let obj = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const val = item[key];
                    obj[key] = {
                        value: val.value || val,
                        display: val.display || val
                    };
                }
            }
            RD[item[pkname]] = obj;
        });
        // 外层数据 过滤
        outerData.forEach((item, index) => {
            let values = item.values,
                pkn = values[pkname],
                pkv = pkn && pkn.value,
                rd = RD[pkv];
            if (rd && pkn) {
                // 合并數據
                values = Object.assign(values, rd);
                //有错误信息时，将错误信息数据放表格行
                rd['saga_status'] === '1' && (values.saga_errormesg = saga_errormesg);
            }
        });
        // 内存数据过滤
        if (bLen) {
            bodyData = bodyData.data;
            for (const key in bodyData) {
                if (bodyData.hasOwnProperty(key)) {
                    const element = bodyData[key];
                    element.rows.forEach((item, index) => {
                        let values = item.values,
                            pkn = values[pkname],
                            pkv = pkn && pkn.value,
                            rd = RD[pkv];
                        if (rd && pkn) {
                            // 合并數據
                            values = Object.assign(values, rd);
                            //有错误信息时，将错误信息数据放表格行
                            rd['saga_status'] === '1' && (values.saga_errormesg = saga_errormesg);
                        }
                    });
                }
            }
        }
        this.setState({ insertTable: this.state.insertTable });
    }
}

/*
*   更新外层表格数据
*   @ newData  外层表格数据
*   @ rowId  主表主键字段
* */
export function setInsertTableValue(id, newData, rowId, reset) {
    CONST_IS_MULTILINGUAL.isMul = false;
    let currentTable = this.state.insertTable[id];

    if (newData.hasOwnProperty('pageInfo') && !isWrong(newData.pageInfo)) {
        //前端重置后端返回的页码，初次为0，应该改为1，有问题在看看
        newData.pageInfo.pageIndex === '0' ? (newData.pageInfo.pageIndex = '1') : '';
        //this.myTable[moduleId].state.table.pageInfo = data.pageInfo;
    } else {
        let prePageSize = getPageSizeStorage(this.state.meta, id);
        if (newData.rows.length > 0) {
            newData.pageInfo = currentTable
                ? currentTable.pageInfo
                : {
                    pageSize: prePageSize,
                    pageIndex: '1'
                };
        } else {
            newData.pageInfo = {
                pageSize: currentTable ? currentTable.pageInfo.pageSize : prePageSize,
                pageIndex: '1'
            };
        }
    }

    if (reset) {
        newData.pageInfo.pageIndex = '1';
    }

    if (newData.hasOwnProperty('allpks')) {
        //this.myTable[moduleId].state.table.allpks = data.allpks;
        // 存储到本地缓存中，卡片翻页需要用
        // CacheTools.set("allpks", data.allpks);
    } else {
        if (newData.rows.length > 0) {
            newData.allpks = currentTable
                ? currentTable.allpks
                : [];
        } else {
            newData.allpks = [];
        }
    }

    // expandedRowKeys
    newData.rows.map((val, index) => {
        if (!val.rowId && val.rowId !== 0) {
            // val.rowId = new Date().getTime() + index;
            if (!val.values[rowId]) {
                console.error('setInsertTableValue方法中，rowId没找到');
                return false;
            }
            val.rowId = val.values[rowId].value;
        }
        val.values.key = val.rowId;
        val.values.rowIndex = index;
    });

    this.state.insertTable[id] = {
        outerData: newData.rows,
        pageInfo: newData.pageInfo,
        allpks: newData.allpks,
        firstTime: true,
        expandedRowKeys: []
    };

    this.setState({
        insertTable: this.state.insertTable
    });
}

/*
*   设置展开的子表数据
*   @ record  主表当前行信息
*   @ column  子表列模板信息
*   @ datas  子表数据
*   @ rowId  子表行 主键字段
* */
export function setChildTableData(id, record, datas, rowId) {
    let table = this.state.insertTable[id];
    let checkedArrayChild = [];//子表每行勾选状态
    let key = record.key;
    let rowIndex = record.rowIndex;
    datas.rows.map((val, index) => {
        if (!val.rowId && val.rowId !== 0) {
            // val.rowId = new Date().getTime() + index;
            if (!val.values[rowId]) {
                console.error('setChildTableData方法，没有找到rowId');
                return false;
            }
            val.rowId = val.values[rowId].value;
        }
        val.values.key = val.rowId;
        if (table.mainCheckObj.checkedArray[rowIndex]) {
            checkedArrayChild.push(true);
        } else {
            checkedArrayChild.push(false);
        }
    });

    table.bodyData.data[key] = datas;

    // 根据主表当前行是否勾选，设置子表复选框 勾选
    if (!table.childCheckObj.hasOwnProperty(key)) {
        table.childCheckObj[key] = {
            checkedAllChild: table.mainCheckObj.checkedArray[rowIndex],
            checkedArrayChild
        };
    }

    this.setState({
        insertTable: this.state.insertTable
    });
}

/**
 * 获取一列合计
 *
 * @export
 * @param {*} column 
 * @param {*} source
 * @returns  res  为需要合计的列和值k v 集合
 */
export function getTotal(column, source) {
    if (Object.prototype.toString.call(source) !== '[object Array]') {
        console.warn('源数据类型不为数组');
        return false;
    }
    if (Object.prototype.toString.call(column) !== '[object Array]') {
        console.warn('模板配置列数据类型不为数组');
        return false;
    }
    // 获取需要合计的属性 并储存
    const fileds = new Set();
    // col.hasOwnProperty('istotal')
    column.map(col => {
        if (col.visible && col.istotal) {
            fileds.add(col.key);
        }
    });

    // 获取合计属性值
    const values = new Map();
    fileds.forEach((filed) => {
        if (!values.has(filed)) {
            values.set(filed, []);
        }
        source.map(node => {
            if (node.hasOwnProperty(filed) && node[filed].renderValue) {
                values.get(filed).push(node[filed].renderValue.replace(/,/g, ''));
            };
        });
    });

    const res = {};
    // 进行加和计算
    values.forEach((counts, filed) => {

        let avg = 0;
        // 计算counts数组最长精度，如果位数不够需要补零
        const countPre = precision(counts);
        counts.map(v => {
            avg += Number(v);
        });
        // toString 可以解决 0.1+0.2=0.30000000000000004的问题
        // let stringTypeOfAvg = String(avg);
        // toFixed 可以解决 0.01+0.06=0.06999999999999999的问题
        let stringTypeOfAvg = avg.toFixed(countPre);

        res[filed] = formatAcuracy(stringTypeOfAvg, countPre);
    });

    return res;

}

function precision(count) {
    // 如果是数组的话就获取最长精度
    if (Object.prototype.toString.call(count) === '[object Array]') {
        const precs = count.map(c => {
            if (String(c).split('.')[1] === undefined) {
                return 0;
            } else {
                return String(c).split('.')[1].length;
            }

        });

        return Math.max.apply(null, precs);

    } else {
        if (String(count).split('.')[1]) {
            return String(count).split('.')[1].length;
        } else {
            return 0;
        }
    }
}

/**
 * liumings 获取选中的行
 * @param {*} moduleId 表格id
 * mofify
 */
export function getCheckedRows(moduleId) {
    let insetTable = this.state.insertTable[moduleId];
    if (typeof moduleId == 'string' && insetTable) {
        let consArr = [];
        if (insetTable.mainCheckObj && isArray(insetTable.mainCheckObj.checkedArray)) {
            insetTable.mainCheckObj.checkedArray.map((item, index) => {
                if (item) {
                    consArr.push({
                        data: insetTable.outerData[index],
                        index
                    });
                }
            });
        }
        return consArr;
    }
    warningOnce(false, `所操作的表格中无ID为${moduleId}的数据`);
    return false;

}

export function deleteTableRowsByIndex(moduleId, index) {
    let insertTable = this.state.insertTable[moduleId];
    if (typeof moduleId == 'string' && insertTable) {
        if (typeof index == 'number' || isArray(index)) {
            if (typeof index == 'number') {
                index = [index];
            }
            let arr = JSON.parse(JSON.stringify(insertTable.outerData));
            let newdata = [];
            newdata = arr.filter((item, i) => {
                return !index.includes(i);
            });
            // debugger
            insertTable.outerData = newdata;
            insertTable.mainCheckObj.data = newdata;
            let checkarr = insertTable.mainCheckObj.checkedArray.concat();
            for (let i = 0; i < checkarr.length; i++) {
                if (index.includes(i)) {
                    checkarr.splice(i, 1);
                    i--;
                }
            }
            insertTable.mainCheckObj.checkedArray = checkarr;

            this.setState({
                insertTable: this.state.insertTable
            });
            console.log(insertTable);
            return false;
        } else {
            warningOnce(typeof rowId == 'string', '传入的第二个参数为数字，或数字的数组');
            return false;
        }
    }
    warningOnce(false, `所操作的表格中无ID为${tableId}的数据`);
    return false;
}


export function getMultiChildChangeWidth(width, isMultiChild) {
    if (isMultiChild && width) {
        this.setState({
            fixedWidth: width
        });
    }
}

/**
 * 点击表头部排序图标排序对列进行排序
 * @param {*} val 被排序的每一行的详细数据
 * @param {*Object} sortType: 
 *         { mode:'single', backSource:false ,sortFun:(a)=> {console.log(a)}}
*                   mode：值为multiple'(多列排序) 或'single'(单列排序)
                   backSource：false是前端排序，值为true为后端排序,默认false
                   sortFun：后端排序的回调函数
 * @param {*Function} customSortRule 自定义排序规则
* 备注：转单内部使用
 */
export function sortColumn(val, sortType, customSortRule) {
    let attrcode = val.attrcode,
        valType = val.itemtype;
    if (!isFunction(customSortRule)) {
        switch (true) {
            case valType === 'number':
                val.sorter = (front, behind) => {
                    const frontValue = front[attrcode] ? front[attrcode].value || '0' : '0';
                    const behindValue = behind[attrcode] ? behind[attrcode].value || '0' : '0';
                    return Number(frontValue) - Number(behindValue);
                };
                break;
            case timeTypes.includes(valType):
                val.sorter = (front, behind) => {
                    const frontValue = front[attrcode] ? front[attrcode].value || '0' : '0';
                    const behindValue = behind[attrcode] ? behind[attrcode].value || '0' : '0';
                    return Number(new Date(frontValue).getTime()) - Number(new Date(behindValue).getTime());
                };
                break;
            case displayTypes.includes(valType):
                val.sorter = (front, behind) => {
                    const frontValue = String(front[attrcode] ? front[attrcode].display || '' : '');
                    const behindValue = String(behind[attrcode] ? behind[attrcode].display || '' : '');
                    // if (behindValue > frontValue) {
                    //     return -1;
                    // } else if (behindValue < frontValue) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case valType === 'label':
                val.sorter = (front, behind) => {
                    const fvalue = front[attrcode] ? front[attrcode].value || '' : '';
                    const fdisplay = front[attrcode] ? front[attrcode].display : '';
                    const bvalue = behind[attrcode] ? behind[attrcode].value || '' : '';
                    const bdisplay = behind[attrcode] ? behind[attrcode].display : '';
                    const frontValue = String(isNullOrVoid(fdisplay) ? fvalue : fdisplay);
                    const behindValue = String(isNullOrVoid(bdisplay) ? bvalue : bdisplay);
                    // if (behindValue > frontValue) {
                    //     return -1;
                    // } else if (behindValue < frontValue) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case attrcode !== 'numberindex' && attrcode !== 'opr':
                val.sorter = (front, behind) => {
                    const frontValue = String(front[attrcode] ? front[attrcode].value || '' : '');
                    const behindValue = String(behind[attrcode] ? behind[attrcode].value || '' : '');
                    // if (behindValue > frontValue) {
                    //     return -1;
                    // } else if (behindValue < frontValue) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }
                    return frontValue.localeCompare(behindValue);
                };
                break;
        }
    } else {
        customSortRule(val);
    }
    /**
     * columnInfo:排序的列的模板信息
     * type:排序的类型
     */
    val.sorterClick = (columnInfo, type) => { //排序的回调函数
        if (sortType && sortType.backSource === true && isFunction(sortType.sortFun)) { //后端排序
            sortType.sortFun(columnInfo, type);
        }
    };
}

//设置行勾选状态 
//id string
//index是要设置的行的数组 array
//ischeck是设置是否勾选 bool
export function changeCheck(id, index, ischeck) {
    let currentTable = this.state.insertTable[id];
    let allFlag = false;
    let mainCheckObj = currentTable.mainCheckObj;

    index.map(i => {
        mainCheckObj.checkedArray[i] = ischeck;
    });


    for (var i = 0; i < mainCheckObj.checkedArray.length; i++) {
        if (!mainCheckObj.checkedArray[i]) {
            allFlag = false;
            break;
        } else {
            allFlag = true;
        }
    }

    mainCheckObj.checkedAll = allFlag;

    let data = mainCheckObj.data;
    index.map(i => {
        let thisKey = data[i].values.key;
        //设置子表勾选
        if (
            JSON.stringify(currentTable.childCheckObj) !== '{}' &&
            currentTable.childCheckObj.hasOwnProperty(thisKey)
        ) {
            currentTable.childCheckObj[thisKey].checkedAllChild = ischeck;
            currentTable.childCheckObj[thisKey].checkedArrayChild.map((val, ind) => {
                currentTable.childCheckObj[thisKey].checkedArrayChild[ind] = ischeck;
            });
        }
    });

    this.setState({ insertTable: this.state.insertTable });
}
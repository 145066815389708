/**
 * 按钮权限,关键功能定义, 用户名密码登录组件
 */

import React, { Component } from 'react';
import { Input as FormControl } from '@platform/base';
import { ajax, toast, RSAUtils } from '@platform/api';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /**
       * 密码
       */
            passWord: '',
        };
    }

  /**
   * @param val 变化的值
   * 密码变化回调
   */
  handlePassWordChange = val => {
      this.setState({ passWord: val });
  };

  /**
   * 登录回调
   * @param userCode 用户
   * @param passWord 密码
   */
  handleSignIn = async (userCode, passWord) => {
      const { resolve, lang } = this.props;
      /**
     * 获取加密所需的公钥
     */
      const { exponent, modulus } = await new Promise(resolve => {
          ajax({
              url: '/nccloud/baseapp/ncckeyfunc/getpubkey.do',
              loading: false,
              success: res => {
                  const { data } = res;
                  resolve(data);
              },
              error: res => {
                  console.log('后端报错', res);
              },
          });
      });
      /**
     * 密码加密
     */
      const key = RSAUtils.getKeyPair(exponent, '', modulus);
      passWord = RSAUtils.encryptedString(key, passWord);
      /**
     * 用户名和密码后台校验
     */
      const result = await new Promise(resolve => {
          ajax({
              url: '/nccloud/baseapp/ncckeyfunc/pwdverify.do',
              loading: false,
              data: {
                  encodePwd: passWord,
                  usercode: userCode,
              },
              success: res => {
                  const { data } = res;
                  resolve(data);
              },
              error: res => {
                  console.log('后端报错', res);
              },
          });
      });
      if (!result) {
          toast({
              content: '',
              color: 'danger',
              groupOperation: true,
              isNode: true,
              // '展开', '收起', '关闭'
              TextArr: [
                  lang['page-button-0010'],
                  lang['page-button-0011'],
                  lang['page-button-0012'],
              ],
              // 密码不正确
              groupOperationMsg: [lang['page-button-0013']],
          });
      }
      resolve(result);
  };

  render() {
      const { passWord } = this.state;
      const { lang } = this.props;
      return (
          <div>
              {/* <div>{lang['page-button-0015']}</div> */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* 密码 */}
                  <span
                      style={{ width: '52px' }}
                  >{`${lang['page-button-0006']}:`}
                  </span>
                  <FormControl
                      style={{ width: '200px' }}
                      value={passWord}
                      onChange={this.handlePassWordChange}
                      // 请输入密码
                      placeholder={lang['page-button-0007']}
                      type="password"
                  />
              </div>
          </div>
      );
  }
}
export default SignIn;

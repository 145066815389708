import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Tooltip as NCTooltip } from '@platform/base';

class ToolCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipStatus: false
        };
    }

    componentWillUnmount() {
        this.cellDom = null;
    }

    handleMouseOver = () => {
        if (this.cellDom) {
            let { tipStatus } = this.state;
            if (this.cellDom.scrollWidth > this.cellDom.clientWidth && tipStatus === false) {
                this.setState({ tipStatus: true });
            }
            if (this.cellDom.scrollWidth <= this.cellDom.clientWidth && tipStatus === true) {
                this.setState({ tipStatus: false });
            }
        }
    };

    render() {
        let { context, multipleRowCell, isLineAll } = this.props;

        return (
            <NCTooltip
                onMouseOver={this.handleMouseOver}
                placement="top"
                overlay={this.state.tipStatus ? context : ''}
                className="tooltip-word-color"
                delay={1}
                inverse
            >
                <span
                    className={classnames(
                        { textLineAll: !multipleRowCell },
                        { noTextLineAll: !isLineAll }
                    )}
                    ref={dom => {
                        this.cellDom = ReactDOM.findDOMNode(dom);
                    }}
                >
                    {context ? context : <span>&nbsp;</span>}
                </span>
            </NCTooltip>
        );
    }
}

export default ToolCell;

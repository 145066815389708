import React, { Component } from "react";
import { staticData } from "./staticData";
import { HotKeys } from "@platform/base";

require("./index.less");

class HotkeyHelperPanel extends Component {
    constructor(props) {
        super(props);
        // 多语装载
        staticData.setLangJson(this.props.langJson);

        this.state = {
            data: this.getInitData(),
            currentIndex: 0,
        };
        // 搜索关键字的缓存
        this.caheKey = "";
    }

    // static defaultProps = {};

    // 更新数据
    componentWillReceiveProps(nextProps) {
        if (nextProps.data != this.props.data) {
            this.setState({
                data: this.getInitData(newData),
                currentIndex: 0,
            });
        }
        // 更新多语
        if (nextProps.langJson != this.props.langJson) {
            // console.log(nextProps.langJson);
            staticData.setLangJson(nextProps.langJson);
            this.setState({
                data: this.getInitData(),
                currentIndex: 0,
            });
        }
    }

    /**
     * 获取初始化结果
     */
    getInitData(newData) {
        let {
            panelType = "common",
            data = [],
            limit,
            isMultiSelectedEnabled,
        } = this.props;
        data = newData || data;
        this.initData = this.currentData = [].concat(
            staticData.getData(panelType, isMultiSelectedEnabled),
            data,
        );
        // 建立缓存区
        this.bufferData = this.currentData.slice(limit);
        this.currentData = this.currentData.slice(0, limit);

        return this.currentData;
    }
    /**
     * 获取查询结果
     * @param {*} key
     */
    getSearchData(key) {
        let { panelType = "common", limit } = this.props,
            data = [];
        this.initData.map(item => {
            let { area, name, desc } = item;
            // 不区分大小写
            let nameLow = (name + "").toLocaleLowerCase();
            let keyLow = (key + "").toLocaleLowerCase();
            let descLow = `${desc}`.toLocaleLowerCase();
            // 检索的规则 // area.indexOf(key) != "-1" ||
            if (
                nameLow.indexOf(keyLow) != "-1" ||
                descLow.indexOf(keyLow) != "-1"
            ) {
                data.push(item);
            }
        });
        // 建立缓存区
        this.bufferData = data.slice(limit);
        this.currentData = data.slice(0, limit);
        // 缓存当前的搜索关键字
        this.caheKey = key;
        return this.currentData;
    }

    /**
     * 处理字符串 添加高亮提示
     */
    highLight(str = "", key) {
        if (key === "" || key === null || key === undefined) {
            return [str];
        }
        // 不区分大小写
        let keyLow = `${key}`.toLocaleLowerCase();
        let strLow = `${str}`.toLocaleLowerCase();
        if (strLow.indexOf(keyLow) === -1) {
            return [str];
        }
        // 截取
        let remianStr = str;
        let spans = [];
        let len = key.length;
        // 执行计数器
        let count = 0;
        while (remianStr.length > 0 && len > 0 && count < 300) {
            // while 很容易引起崩溃  注意这个问题
            count++;
            let i = strLow.indexOf(keyLow);
            if (i !== -1) {
                spans.push(remianStr.substring(0, i));
                spans.push(
                    <span className="search-high">
                        {remianStr.substring(i, i + len)}
                    </span>,
                );
                remianStr = remianStr.substring(i + len);
                strLow = strLow.substring(i + len);
            } else {
                spans.push(remianStr);
                remianStr = "";
            }
        }
        // let arrStr = str.split(key),
        //     spans = [];
        // arrStr.map((item, i) => {
        //     if (i != 0) {
        //         spans.push(<span className="search-high">{key}</span>);
        //     }
        //     spans.push(item);
        // });
        return spans;
    }

    /**
     * 区域是否存在判断
     */
    hasArea() {
        let { panelType, isMultiSelectedEnabled } = this.props;
        return (
            isMultiSelectedEnabled &&
            ["tree", "grid", "gridTree"].indexOf(panelType) != -1
        );
    }

    /**
     * 数据分组
     */
    dataGrouping(data) {
        let md = new Map();

        data.map(({ area = "unknown", name = "", desc = "" }, i) => {
            let child = md.get(area) || [];
            child.push({
                name,
                desc,
            });
            md.set(area, child);
        });

        return md;
    }
    /**
     * 渲染表格行
     */
    renderItems() {
        let data = this.state.data,
            currentIndex = this.state.currentIndex,
            key = this.caheKey,
            dg = this.dataGrouping(data),
            result = [];
        // 数据分组
        // console.log(data, dg);
        dg.forEach((items, area) => {
            let len = items.length;
            items.map(({ name = "", desc = "" }, i) => {
                result.push(
                    <tr tabIndex={currentIndex === i ? "0" : "-1"}>
                        {this.hasArea() || i !== 0 ? (
                            ""
                        ) : (
                            <td rowSpan={len} className="hkq-group">
                                {this.highLight(area, key)}
                            </td>
                        )}
                        <td>{this.highLight(name, key)}</td>
                        <td>{this.highLight(desc, key)}</td>
                    </tr>,
                );
            });
        });

        return result;
    }
    /**
     * 查询
     * @param {*} e
     */
    searchHandle(e) {
        if (e.target) {
            e.target.value && (e.target.nextElementSibling.style.display = "");
            this.setState({
                data: this.getSearchData(e.target.value),
                currentIndex: 0,
            });
        }
    }
    /**
     * move to tabindex
     */
    moveHandle(currentIndex) {
        let prevIndex = this.state.currentIndex,
            panelDOM = this.panel;
        this.setState(
            {
                currentIndex: currentIndex,
                data: this.currentData,
            },
            () => {
                // 聚焦到下一个行
                let next =
                    panelDOM &&
                    panelDOM.querySelector(
                        `tbody tr:nth-child(${currentIndex + 1})`,
                    );
                // next && next.focus();
            },
        );
    }

    /**
     * 文本框事件
     * // TODO 这里可以考虑把input作为单独的组件抽取  自己管理自己的状态
     */
    focusInputHandle(e) {
        if (e.target && e.target.value) {
            e.target.nextElementSibling.style.display = "";
        }
    }

    /**
     * 文本框事件
     * // TODO 这里可以考虑把input作为单独的组件抽取  自己管理自己的状态
     */
    blurInputHandle(e) {
        if (e.target && !this.enterClose) {
            e.target.nextElementSibling.style.display = "none";
        }
    }

    /**
     * 清除按钮
     */
    clearHandle(e) {
        let i = (this.panel && this.panel.querySelector("input")) || { value: "" };
        // 清空当前的搜索关键字
        this.caheKey = "";
        i.value = "";
        this.setState({
            data: this.getSearchData(""),
            currentIndex: 0,
        });
        this.enterClose = false;
        e.target && (e.target.style.display = "none");
    }
    /**
     * 文本框查询按钮
     * @param {*} e
     */
    searchBtnHandle(e) {
        let i = (this.panel && this.panel.querySelector("input")) || { value: "" };
        this.setState({
            data: this.getSearchData(i.value),
            currentIndex: 0,
        });
        e.target &&
            e.target.value &&
            (e.target.nextElementSibling.style.display = "");
    }

    /**
     * 展示更多
     */
    moreShow() {
        let { limit } = this.props;
        if (this.bufferData.length > 0) {
            this.currentData = this.currentData.concat(this.bufferData);
            this.bufferData = [];
            this.moveHandle.bind(this)(limit - 1);
        }
    }

    render() {
        let bufferLen = this.bufferData.length;
        let langJson = this.props.langJson;
        return [
            <div
                className="hkq-helper-panel"
                ref={dom => {
                    this.panel = dom;
                }}
            >
                <HotKeys
                    keyMap={{ more: ["fn+up", "fn+down", "up", "down"] }}
                    handlers={{
                        more: () => {
                            this.moreShow();
                        },
                    }}
                    attach={false}
                >
                    <div className="hkq-helper-input">
                        <input
                            placeholder={langJson["hk-0004"]}
                            onChange={this.searchHandle.bind(this)}
                            onFocus={this.focusInputHandle.bind(this)}
                            onBlur={this.blurInputHandle.bind(this)}
                            type="text"
                        />
                        <i
                            className="icon iconfont hkq-close"
                            style={{ display: "none" }}
                            onClick={this.clearHandle.bind(this)}
                            onMouseEnter={() => {
                                this.enterClose = true;
                            }}
                            onMouseLeave={() => {
                                this.enterClose = false;
                            }}
                        >
                            &#xe68d;
                        </i>
                        <i
                            className="icon iconfont hkq-search"
                            onClick={this.searchBtnHandle.bind(this)}
                        >
                            &#xe611;
                        </i>
                    </div>

                    <div className="hkq-scroll-header">
                        <table>
                            <colgroup>
                                {this.hasArea() ? (
                                    ""
                                ) : (
                                    <col
                                        style={{
                                            "width": "140px",
                                            "min-width": "140px",
                                        }}
                                    />
                                )}
                                <col
                                    style={{
                                        "width": "140px",
                                        "min-width": "140px",
                                    }}
                                />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    {this.hasArea() ? (
                                        ""
                                    ) : (
                                        <th>{langJson["hk-0005"]}</th>
                                    )}
                                    <th>{langJson["hk-0006"]}</th>
                                    <th>{langJson["hk-0007"]}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div
                        className="hkq-scroll-table"
                        onScroll={() => {
                            this.moreShow();
                        }}
                    >
                        <table>
                            <colgroup>
                                {this.hasArea() ? (
                                    ""
                                ) : (
                                    <col
                                        style={{
                                            "width": "140px",
                                            "min-width": "140px",
                                        }}
                                    />
                                )}
                                <col
                                    style={{
                                        "width": "140px",
                                        "min-width": "140px",
                                    }}
                                />
                                <col />
                            </colgroup>

                            <tbody>{this.renderItems()}</tbody>
                        </table>
                    </div>
                </HotKeys>
            </div>,
            <button
                className="hk-show-more"
                tabIndex="-1"
                onClick={this.moreShow.bind(this)}
            >
                {bufferLen !== 0 ? langJson["hk-0008"] : langJson["hk-0009"]}
            </button>,
        ];
    }
}

HotkeyHelperPanel.defaultProps = {
    limit: 14,
    bufferSize: 4,
    data: [],
};

export default HotkeyHelperPanel;

// socket 全局消息控制器

import { ViewModel, getSafeRandom } from '@platform/api'

const { setGlobalStorage, getGlobalStorage, removeGlobalStorage, getDecryptData } = ViewModel;

/**
 * 生成唯一标 识
 */
function uuidv4() {
    return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}


class ChannelCtrl {

    // 动作标识
    static ChannelAction = {
        check: 'checkUnique',
        message: 'sendMessage',
        reply: 'uniqueReply'
    }

    constructor(props = {}) {
        this.props = props;
        this.recieve = this.recieve.bind(this);
        this.uuidv4 = uuidv4();
        this.unique = props.unique || this.uuidv4;
        this.re = new RegExp('^' + this.unique);
    }

    recieve(e) {
        let { onRecieve } = this.props;
        if (String(e.key).match(this.re)) { // localStorage.setItem時 如   ___socket_channel___ 前缀  localStorage.setItem('___socket_channel___XXXX')
            let key = e.key.replace(this.re, '');
            let value = getDecryptData(e.newValue) || getGlobalStorage('localStorage', e.key);
            let obj = value ? JSON.parse(value) : '';
            onRecieve && onRecieve(obj, key);
        }
    }

    setRecieve(f) {
        this.props.onRecieve = f;
    }

    start() {
        window.addEventListener('storage', this.recieve, false);
        return this;
    }

    send(event, message) {
        setGlobalStorage('localStorage', this.unique + event, JSON.stringify(message));
        // clean up localstorage
        setTimeout(() => {
            removeGlobalStorage('localStorage', this.unique + event);
        }, 10);
    }

    destroy() {
        this.props.onRecieve = null;
        window.removeEventListener('storage', this.recieve);
    }
}

export default ChannelCtrl;
/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-03 14:25:43
 * @Description: file content
 */
import { config as CONFIG } from '@platform/api';
import { findParentTd } from '@platform/api';
import { ViewModel } from '@platform/api'

import {
    CARD_ROW_SELECTED_CLASSNAME,
    EDIT_ROW_SELECTED_CLASSNAME,
    SIMPLE_ROW_SELECTED_CLASSNAME,
    _ROW_SELECTED_CLASSNAME,
} from './constants';

/**
 * 首字母大写  add by bbqin 暂时不考虑多键的情况
 * @param {*} str
 */
export function firstToUpperCase(str) {
    // 把加号前后的字符全转译
    let strTransform = str.split(',');

    let proccessedStr = strTransform.map(str => {
        return str
            .toLowerCase()
            .replace(/ /g, '')
            .replace(/( |^)[a-z]|\+[a-z]/g, L => L.toUpperCase());
    }).join(',');

    return proccessedStr;
}

/**
 * @desc: 现在表格的快捷键是selected-row样式加事件源去判断是否触发快捷键事件的，导致页面存在多个表格且每个表格都有选中状态的时候总会触发第一个表格的快捷键事件。
 * @resolve: 点击某个表格的时候把清除其他表格row的选中状态
 * @param pageScope {object} 页面级数据；
 * @param moduleId {string} 区域id
 */
export function clearOtherTableRowSelectedStatus({ pageScope, moduleId }) {
    let { orderOfHotKey } = pageScope;

    orderOfHotKey.forEach(item => {
        if (item !== moduleId) {
            let dom = document.querySelector(`#${CONFIG.hotKeyModuleIdPrefix}-${item}`);
            let selectedRows = dom && dom.querySelectorAll(`.${CARD_ROW_SELECTED_CLASSNAME},.${EDIT_ROW_SELECTED_CLASSNAME},.${SIMPLE_ROW_SELECTED_CLASSNAME},.${_ROW_SELECTED_CLASSNAME}`);

            if (selectedRows && selectedRows.length) {
                pageScope.myTable[item].state.table.currentIndex = -1;

                pageScope.myTable[item].setState({
                    table: pageScope.myTable[item].state.table,
                });
            }
        }
    });
}

/**
 * @desc 在表格处于编辑状态下触发保存，暂存等快捷键的时候由于没有触发表格的编辑后(onblur)导致输入的值没有更新到states上
 * @resolve 在触发快捷键按钮的时候默认让body获取焦点
 * @param callBack {function} 回调函数
 * @param buttonInfo {object} 按钮信息
 * @param e {object}
 * @param keyMapItem {object}
 */
export function triggerOnClickWithBlurAction({ callBack, buttonInfo, e, keyMapItem }) {
    // 如果页面超出一屏时按快捷键会导致页面滚动
    // document.body.focus();
    if (keyMapItem) {
        document.activeElement && document.activeElement.blur();
    }

    // let $target = window.event && window.event.target;

    let timer = setTimeout(() => {
        // 采购发票维护 附件按钮点击报错  业务使用了window 上的event对象
        // window.event = {//修复ie按钮不可用bug
        //     target: $target,
        // };

        keyMapItem
            ? callBack(buttonInfo.key, e, keyMapItem)
            : callBack(buttonInfo.key);
        clearTimeout(timer);
    }, 50);
    // 设置一个时长 避免编辑事件的setTimeout后执行
}


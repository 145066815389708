/**
 * Created by wangshhj on 2018/3/13.
 */
import React, { Component } from 'react';
import {
    Modal,
    Button,
    HotKeys,
    Tooltip,
} from '@platform/base';
import { getMultiLang } from '@platform/api';

require('./createModal.less');

class Modal2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            json: {},
        };
    }

    UNSAFE_componentWillMount() {
        let callback = (json, bool) => {
            if (bool) {
                this.setState({ json });
            }
        };
        getMultiLang({ moduleId: 'container_toast', callback });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let { modalData, argusObj } = nextProps;
        let nowData = {};
        for (let attr in modalData) {
            for (let attr1 in argusObj) {
                if (attr === attr1) {
                    if (modalData[attr] && argusObj[attr1]) {
                        nowData[attr] = modalData[attr];
                    } else {
                        if (modalData[attr]) {
                            nowData[attr] = modalData[attr];
                        } else {
                            nowData[attr] = argusObj[attr1];
                        }
                    }
                }
            }
        }
        this.setState({ data: nowData });
    }

    render() {
        let { json } = this.state;
        let {
            id,
            size,
            className,
            backdropClassName,
            title,
            content,
            noFooter,
            leftBtnName = json['0005'],
            rightBtnName = json['0006'],
            showCustomBtns,
            customBtns,
            hideRightBtn,
            hideLeftBtn,
            container,
        } = this.state.data;
        let {
            onHide,
            backdrop,
            indexInfo,
            beSureClick,
            cancelClick,
            show,
            size: propSize,
            hotKeyboard = true,
            onEscapeKeyUp,
            draggable,
            resizable,
            ...others
        } = this.props;
        let mSize = size || propSize || '';

        let maxModalCls = mSize === 'max' ? 'nc-modal-max' : '';
        return (
            <Modal
                fieldid={id}
                show={show}
                onHide={onHide.bind(this)}
                size={mSize}
                backdrop={backdrop}
                indexInfo={indexInfo}
                className={`${className} simpleModal ${maxModalCls}`}
                backdropClassName={backdropClassName}
                container={container}
                ref={Modal => (this.Modal = Modal)}
                onEscapeKeyUp={onEscapeKeyUp}
                draggable={draggable}
                resizable={resizable}
                id={id}
                noFooter={noFooter}
                {...others}
            >
                {!showCustomBtns && hotKeyboard && !noFooter && (
                    <HotKeys
                        keyMap={{
                            sureBtnHandler:
                                HotKeys.USUAL_KEYS.MODAL_CONFIRM,
                            cancelBtnHandler:
                                HotKeys.USUAL_KEYS.MODAL_CALCEL,
                        }}
                        handlers={{
                            sureBtnHandler: () => {
                                // 确定按钮的事件 增加top的判断避免所有弹窗逻辑都被触发 by bbqin
                                if (this.Modal && this.Modal.isTopModal()) {
                                    console.log(
                                        'createModal  sureBtnHandler 事件回调',
                                        this.Modal.isTopModal(),
                                    );
                                    beSureClick.bind(this)();
                                }
                            },
                            cancelBtnHandler: () => {
                                // 取消按钮的事件 增加top的判断避免所有弹窗逻辑都被触发  by bbqin
                                if (this.Modal && this.Modal.isTopModal()) {
                                    console.log(
                                        'createModal cancelBtnHandler 事件回调',
                                    );
                                    cancelClick.bind(this)();
                                }
                            },
                        }}
                        className="simpleModal-hotkeys-wrapper"
                        focused={true}
                        attach={document.body}
                        display="inline"
                    />
                )}
                <Modal.Header fieldid="header-area" closeButton>
                    <Modal.Title fieldid={title}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={noFooter ? 'has-resize-icon' : ''}>{content}</Modal.Body>

                {!noFooter ? (
                    <Modal.Footer>
                        {!showCustomBtns &&
                            hideLeftBtn === false &&
                            leftBtnName &&
                            (hotKeyboard ?
                                (<Tooltip
                                    placement="top"
                                    inverse
                                    overlay={`${leftBtnName}  (${HotKeys.USUAL_KEYS.MODAL_CONFIRM})`}
                                    trigger={['hover', 'focus']}
                                    className="model-helper-overlay"
                                >
                                    <Button
                                        onClick={beSureClick.bind(this)}
                                        colors="primary"
                                        tabIndex="0"
                                        fieldid="confirm"
                                    >
                                        {leftBtnName}(<span className="text-decoration-underline">Y</span>)
                                    </Button>
                                </Tooltip>
                                ) : (<Button
                                    onClick={beSureClick.bind(this)}
                                    colors="primary"
                                    tabIndex="0"
                                    fieldid="confirm"
                                >
                                    {leftBtnName}
                                </Button>))
                        }

                        {!showCustomBtns &&
                            hideRightBtn === false &&
                            rightBtnName &&
                            (hotKeyboard ?
                                (<Tooltip
                                    placement="top"
                                    inverse
                                    overlay={`${rightBtnName}  (${HotKeys.USUAL_KEYS.MODAL_CALCEL})`}
                                    trigger={['focus', 'hover']}
                                    className="model-helper-overlay"
                                >
                                    <Button
                                        onClick={cancelClick.bind(this)}
                                        shape="border"
                                        tabIndex="0"
                                        fieldid="cancel"
                                    >
                                        {rightBtnName}(<span className="text-decoration-underline">N</span>)
                                    </Button>
                                </Tooltip>
                                ) : (
                                    <Button
                                        onClick={cancelClick.bind(this)}
                                        shape="border"
                                        tabIndex="0"
                                        fieldid="cancel"
                                    >
                                        {rightBtnName}
                                    </Button>
                                )
                            )
                        }
                        {showCustomBtns && customBtns ? customBtns : null}
                    </Modal.Footer>
                ) : null}
            </Modal>
        );
    }
}

export default function createModal(
    id,
    {
        title, //标题
        content, //内容
        beSureBtnClick, //确定按钮事件回调
        cancelBtnClick, //取消按钮事件回调
        closeModalEve, //关闭按钮事件回调
        rightBtnName, //左侧按钮名称
        leftBtnName, //右侧按钮名称
        noFooter = false, //是否需要底部按钮
        size = 'lg', //  模态框大小 sm/lg/xlg
        userControl = false, // 点 确定/取消 按钮后，是否自动关闭弹出框
        className = '',
        backdropClassName = '',
        zIndex = 200,
        hasBackDrop = true, //显示遮罩层
        closeByClickBackDrop = false, //点击遮罩关闭提示框，true是点击关闭，false是阻止关闭,默认是false --UE让禁用这个功能
        showCustomBtns = false, //允许自定义底部按钮
        customBtns, //自定义按钮
        validateCloseBtn = false, //点击关闭X按钮的校验
        hotKeyboard = true, // 热键的配置项
        hideRightBtn = false, //隐藏底部右边的按钮
        hideLeftBtn = false, //隐藏底部左边的按钮
        container = document.body,
        onEscapeKeyUp,
        draggable = true, //可拖拽移动位置
        resizable = true, //可拖拽变化宽高
        ...others
    } = {},
) {
    let custom = (className.indexOf('junior') !== -1) || (className.indexOf('senior') !== -1) || (className.indexOf('combine') !== -1);
    let mSize = `${custom ? '' : size}`;
    let argusObj = {
        id,
        title,
        content,
        beSureBtnClick,
        cancelBtnClick,
        closeModalEve,
        rightBtnName,
        leftBtnName,
        noFooter,
        size: mSize, //传定制大小的类名的时候，size不配置生效，解决配置拖拽的时候的问题
        userControl,
        className,
        backdropClassName,
        zIndex,
        hasBackDrop,
        closeByClickBackDrop,
        showCustomBtns,
        customBtns,
        validateCloseBtn,
        hideRightBtn,
        hideLeftBtn,
        container,
        onEscapeKeyUp,
    };
    if (!this.state.modal.hasOwnProperty(id)) {
        this.state.modal[id] = argusObj;
    }
    let modalData = this.state.modal[id] || {};
    if (!modalData.update) {
        modalData.content = content;
        modalData.customBtns = customBtns;
    }
    //关闭模态框
    const close = () => {
        modalData.showModal = false;
        this.setState({ modal: this.state.modal });
    };
    /* 点击关闭按钮 */
    const onHide = () => {
        if (!modalData.validateCloseBtn) {
            close();
        }
        if (typeof modalData.closeModalEve === 'function') {
            modalData.closeModalEve();
        }
    };

    /* 取消按钮事件 */
    const cancelClick = () => {
        if (!modalData.userControl) {
            close();
        }
        if (typeof modalData.cancelBtnClick === 'function') {
            modalData.cancelBtnClick();
        }
    };

    //确认按钮事件
    const beSureClick = () => {
        if (!modalData.userControl) {
            close();
        }
        if (typeof modalData.beSureBtnClick === 'function') {
            modalData.beSureBtnClick();
        }
    };

    let backdrop;
    if (hasBackDrop) {
        if (closeByClickBackDrop) {
            backdrop = closeByClickBackDrop;
        } else {
            backdrop = 'static';
        }
    } else {
        backdrop = hasBackDrop;
    }
    let finalIndex = modalData.zIndex || zIndex || 200
    let indexInfo = { id, zIndex: finalIndex };
    return (
        <Modal2
            {...others}
            onHide={onHide}
            backdrop={backdrop}
            indexInfo={indexInfo}
            beSureClick={beSureClick}
            cancelClick={cancelClick}
            modalData={modalData}
            show={modalData.showModal}
            argusObj={argusObj}
            hotKeyboard={hotKeyboard}
            custom={custom}
            onEscapeKeyUp={onEscapeKeyUp}
            draggable={draggable}
            resizable={resizable}
            size={mSize}
        />
    );
}

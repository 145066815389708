/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: bbq
 * @LastEditTime: 2020-12-07 10:31:26
 * @Description: file content
 */
import React, { Component } from 'react';
import { Tooltip } from '@platform/base';
import { firstToUpperCase } from './util';

export default class HelperTip extends Component {
    render() {
        let { keyboard, title, children, disabled, delayShow } = this.props;
        // let processedKeyboard = keyboard && keyboard.replace(/(\+|,|，|\s)/g, '_');
        return keyboard && disabled !== true ? (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <Tooltip
                key='ss'
                placement="top"
                inverse
                overlay={`${title || ''}  (${firstToUpperCase(keyboard)})`}
                trigger={['hover']} // 去除  , "focus"
                className='btn-helper-overlay'
                onMouseOver={e => {
                    if (e.target.className && e.target.className.includes('u-loading-backdrop')) {
                        e.stopPropagation();
                        // 单词写错啦
                        e.preventDefault();
                    }
                }}
                onMouseOut={() => {
                    let dom = document.querySelector('.btn-helper-overlay');
                    if (dom) {
                        dom.style.display = 'none';
                    }
                }}
                delayShow={delayShow}
            >
                {children}
            </Tooltip>
        ) : (
                children
            );
    }
}

/**
 * Created by wangshhj on 2018/1/11.  本地 xuyang updata
 */
import React, { Component } from 'react';
import SearchComponent from './searchComponent';
import { isEmpty, createScript, createScripts, ViewModel } from '@platform/api';
// import { timeFunctionTranslater } from '../../public/timer';
// import ViewModel from '../../shell/viewmodel/viewmodel';

/*
*   @ 查询区交互说明：
*   1、如果查询区需要查询方案，并且不是只显示高级按钮时，则先请求查询方案 ；如果查询方案中有默认查询方案，则页面永远展示默认查询方案中的查询条件；否则，展示查询模板中的visible:true的查询条件；
*   2、当用户修改了默认查询方案 ，需要实时更新同步查询区展示
*   3、当用户点击查询方案去查询时（无论是外面的查询方案还是高级面板中的查询方案），外面查询区需要展示该方案中的未填写项（该未填写项只按照保存方案时标记，修改了查询方案中的查询条件但是未保存的不算）
*   4、高级面板中，普通查询区不能拖拽多个相同的查询条件；
*   5、高级面板中，高级区域可以拖拽多个相同的查询条件，但是，默认展示的查询条件不能移除，也不能拖到默认展示的查询条件之上形成一组新的查询条件。非默认显示的查询条件可以和拖拽项组成新的、带逻辑运算的查询条件
*   6、默认查询方案只能修改不能删除
*   7、clickSearchBtn方法，在点击查询方案触发查询时，只返回方案中的公共查询条件，不包含自定义查询条件；如果有自定义查询条件，需要业务组在clickPlanEve方法中接收查询方案信息，并保存到业务组的state中，然后在 clickSearchBtn方法中自行合并公共条件和自定义条件，再去请求查询。
*   8、需要查询方案功能时，必须传参数oid（查询模板中的oid），可以通过createUIDom(data, callback)  回调拿到模板信息；
*   9、当只显示高级按钮时，不加载简单查询区的查询条件和查询按钮，点方案进行查询也不加载方案中的未填写项。
*
* */
export default function NCCreateSearch(
    id,
    {
        clickSearchBtn, // 查询按钮点击事件回调，只返回公共查询条件，不包含自定义查询条件；如果有自定义查询条件，需要业务组在clickPlanEve方法中接收查询方案信息，并保存到业务组的state中，然后在 clickSearchBtn方法中自行合并公共条件和自定义条件。
        searchBtnName = '',
        onAfterEvent, // 编辑后事件
        showSearchBtn = true, // 是否显示查询按钮 ,默认显示
        showAdvBtn = true, // 是否显示高级查询按钮和查询方案按钮 ,默认显示
        showClearBtn = true, // 是否显示清空按钮
        showAdvSearchPlanBtn = true, // 高级面板中是否显示保存方案按钮
        replaceAdvBtnEve, // 业务组替换高级面板 (fun)
        replaceAdvBody, // 业务组替换高级面板中的body (fun)
        addAdvTabs, // 添加高级查询区自定义页签 (fun)
        addAdvBody, // 添加高级查询区自定义查询条件Dom (fun)
        replaceRightBody, // 添加高级查询区右侧区域自定义查询条件Dom (fun)
        // oid, // 查询模板的oid
        hideSearchCondition = false, // 隐藏候选条件
        hideSearchPlan = false, // 隐藏高级弹窗的查询计划
        saveSearchPlan, // 保存查询方案确定按钮事件 ，用于业务组返回自定义的查询条件
        clickPlanEve, // 点击高级面板中的查询方案事件 ,用于业务组为自定义查询区赋值
        clickAdvBtnEve, //单击高级之后的回调方法
        hideBtnArea = false, // 隐藏查询按钮区域，默认false
        advSearchClearEve, // 高级查询面板中的清空按钮 点击事件回调，用于业务组清除自定义查询条件值
        onlyShowSuperBtn = false, // 只显示高级按钮
        replaceSuperBtn = '', // 替换高级按钮
        onOperateTypeChange, // 操作符改变时的回调
        onlyShowAdvArea = false, //在查询区只显示 高级查询部分,
        onlyShowModal = false, //在查询区只显示 模态框,
        renderCompleteEvent, //查询区渲染完成之后的回调事件
        statusChangeEvent, //切换查询区状态时的回调
        context = '', //如果业务组传了上下文进来，就用业务组的数据
        setInitValueEvent, //初始化四状态默认值的时候的回调函数
        useCache = true, //是否启用查询区缓存，默认启用
        isSynInitAdvSearch = false, //是否同步渲染高级查询
        onlyShowModalCancelCallback, // 只渲染modal时 关闭modal 触发的回调
        onlyShowPlan = false, // 只显示方案列表和高级查询
        searchModalTitle = '', // 高级查询 modal title
        searchConditionName = '', //高级查询 "查询条件" 名称自定义
        currentSearch = true,
        opneBtnClick, //展开收起按钮的单击事件，触发是在面板展开或关闭之后进行的
        isTemplateOrder = false, //默认不是模板顺序，如果是模板顺序，则取消必填项优先的逻辑
        removeQuerConditionEve, //移除查询条件的触发条件，返回ture 则 不能移除
        fieldid,
        hideSearchType = false,//隐藏高级和普通的radio
        onlyShowSuperCondition = false,//只显示高级查询，和hideSearchType配合使用
        hiddenFunList = {},//在日期组件中隐藏一些日期函数
        modalCloseEve,
        isShowDefalutPage = true,
        syncAdvCondition = true
    } = {},
) {
    //模板没有值 就不渲染
    if (!(!isEmpty(this.state.meta[id]) && !isEmpty(this.state.meta[id].items))) {
        return null;
    } else {
        // let id = arguments[0];
        // let props = { id, ...arguments[1] };

        function get_this(_this) {
            this.myState.search[`searchArea_${id}`] = _this;
        }
        function remove_this() {
            this.myState.search[`searchArea_${id}`] = {};
            delete this.myState.search[`searchArea_${id}`];
        }
        function getGloablThis() {
            return this;
        }
        //渲染监控=======
        // if (!this.hasOwnProperty('searchAreaRenderCount')) {
        //     this.searchAreaRenderCount = 1;
        // } else {
        //     this.searchAreaRenderCount++;
        // }
        // console.log('查询区组件渲染了' + this.searchAreaRenderCount + '次');

        //===============================
        // let refers = this.state.meta[id].items.filter((item) => item.itemtype === 'refer');

        // createScripts.call(this, 'search', id, refers);
        let contextMixin = { ...ViewModel.getContext(), ...(context || this.app_context) };
        // console.log('contextMixin', contextMixin);

        return (
            <SearchComponent
                renderItem={
                    this.state.renderItem.search && this.state.renderItem.search.hasOwnProperty(id) ? (
                        this.state.renderItem.search[id]
                    ) : (
                        {}
                    )
                } //全局参照列表
                context={contextMixin} //上下文数据 用于 函数翻译
                syncTree={this.syncTree} //树
                modal={this.modal}
                get_this={get_this.bind(this)}
                remove_this={remove_this.bind(this)}
                createScript={createScript.bind(this)}
                search={this.search}
                getGloablThis={getGloablThis.bind(this)}
                id={id}
                clickSearchBtn={clickSearchBtn}
                searchBtnName={searchBtnName}
                onAfterEvent={onAfterEvent}
                showSearchBtn={showSearchBtn}
                showAdvBtn={showAdvBtn}
                showClearBtn={showClearBtn}
                showAdvSearchPlanBtn={showAdvSearchPlanBtn}
                replaceAdvBtnEve={replaceAdvBtnEve}
                replaceAdvBody={replaceAdvBody}
                addAdvTabs={addAdvTabs}
                addAdvBody={addAdvBody}
                replaceRightBody={replaceRightBody}

                hideSearchCondition={hideSearchCondition}
                hideSearchPlan={hideSearchPlan}
                saveSearchPlan={saveSearchPlan}
                clickPlanEve={clickPlanEve}
                clickAdvBtnEve={clickAdvBtnEve}
                hideBtnArea={hideBtnArea}
                advSearchClearEve={advSearchClearEve}
                onlyShowSuperBtn={onlyShowSuperBtn}
                replaceSuperBtn={replaceSuperBtn}
                onOperateTypeChange={onOperateTypeChange}
                onlyShowAdvArea={onlyShowAdvArea}
                onlyShowModal={onlyShowModal}
                renderCompleteEvent={renderCompleteEvent}
                statusChangeEvent={statusChangeEvent}
                setInitValueEvent={setInitValueEvent}
                useCache={useCache}
                isSynInitAdvSearch={isSynInitAdvSearch}
                onlyShowModalCancelCallback={onlyShowModalCancelCallback}
                onlyShowPlan={onlyShowPlan}
                searchModalTitle={searchModalTitle}
                searchConditionName={searchConditionName}
                onlyShowSuperCondition={onlyShowSuperCondition}
                currentSearch={currentSearch}
                fieldid={fieldid}
                opneBtnClick={opneBtnClick}
                isTemplateOrder={isTemplateOrder}
                removeQuerConditionEve={removeQuerConditionEve}
                hideSearchType={hideSearchType}
                hiddenFunList={hiddenFunList}
                modalCloseEve={modalCloseEve}
                isShowDefalutPage={isShowDefalutPage}
                syncAdvCondition={syncAdvCondition}
            />
        );
    }
}

import { createCardPagination } from './create_test';
import { 
    setCardPaginationVisible, 
    setCardPaginationId, 
    getNextCardPaginationId,
    setCardPaginationAllpks,
} from './methods_test';

export default {
    createCardPagination,
    setCardPaginationVisible, 
    setCardPaginationId, 
    getNextCardPaginationId,
    setCardPaginationAllpks,
}
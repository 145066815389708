import BillInfo from './BillInfo';
//创建单据号
/**
 * 
 * @param {*} events : {showBackBtn:true,billCodeText:''}
 */
export function createBillHeadInfo(events) {
    return (
        <BillInfo
            getThis={(_this) => {
                this.myBillInfo = _this;
            }}
            events={events}
            output={this.output}
            rootprops={this.props}
        />
    );
}
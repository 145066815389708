//import intl from 'react-intl-universal';
import axios from 'axios';
import { getLangCode, isObj }  from '@platform/api';
let localeLang = getLangCode() || 'simpchn';

export function init(moduleId, callback, domainName) {
    let domainPath = domainName ? `../../../../${domainName}/public/lang/` : '../../../public/lang/';
    let _dir = NODE_ENV == 'development' ? '../src/platform/public/lang/' : domainPath;

    if (moduleId) {
        // axios.get(`${_dir}customer/${localeLang}/${moduleId}.json`)
        axios.get(`${_dir}standard/${localeLang}/${moduleId}.json`)
            .then(res => {
                if (res) {
                    setRes.call(this, moduleId, res.data, callback);
                }
            }).catch((error) => {
                //  加载industry
                axios
                    .get(`${_dir}industry/${localeLang}/${moduleId}.json`)
                    .then(res => {
                        if (res) {
                            setRes.call(this, moduleId, res.data, callback);
                        }
                    }).catch((error) => {
                        //  加载standard
                        // axios.get(`${_dir}standard/${localeLang}/${moduleId}.json`)
                        axios.get(`${_dir}customer/${localeLang}/${moduleId}.json`)
                            .then(res => {
                                // console.log(moduleId, '------')
                                if (res) {
                                    setRes.call(this, moduleId, res.data, callback);
                                }
                            }).catch((res) => {
                                callback && callback(res.data, false, {})
                            })

                    })

            })
    }
}

function setRes(moduleId, data, callback){
    if (this.state.intl) {
        this.state.intl = {};
    }
    if(!this.state.intl[moduleId]) this.state.intl[moduleId]={}
    this.state.intl[moduleId][localeLang] = data

    this.setState({ intl: this.state.intl }, () => {
        callback && callback(data, true, {})
    })
}

// 获取多语插件 
export function getIntl(moduleId) {
    if (!moduleId){
		return;
	}
    return {
        get:(code, valMap)=>{
            if(this.state.intl[moduleId] && this.state.intl[moduleId][localeLang]){
                let langJson = this.state.intl[moduleId][localeLang][code];
                if(isObj(valMap)){
                    for(let pop in valMap){
                        if(langJson.includes(`{${pop}}`)){
                            let reg = new RegExp('{\\s*'+ pop +'\\s*}','g');
                            langJson = langJson.replace(reg, valMap[pop]);
                        }
                    }
                }
                return langJson;
            }  
        } 
    } 
    
}


import React, { Component } from 'react';
import { PubSub } from '@platform/api';
import { HotKeys as OriginHotKeys } from 'react-hotkeys';

require('./index.less');
/**
 * 通用的组合键
 */
const USUAL_KEYS = {
    // 新增
    ADD: ['Ctrl+/'],
    // 刪除
    DELETE: ['Del'],
    // 保存
    SAVE: ['Ctrl+S'],
    // 保存新增
    SAVE_ADD: ['Alt+S'],
    // 編輯
    EDIT: ['Ctrl+E'],
    // 保存提交
    SAVE_SUBMIT: ['Ctrl+T'],
    // 取消
    CAEL: ['Alt+Q'],
    // 暂存
    STORAGE: ['Ctrl+Alt+S'],
    // 刪除行
    DELETE_ROW: ['Alt+Del'],

    // 窗口取消按钮
    MODAL_CALCEL: ['Alt+N'],
    // 窗口确定按钮
    MODAL_CONFIRM: ['Alt+Y'],

    // CardPagination
    // 卡片分页末页按钮
    CARDPAGE_END: ['Alt+End'],
    // 卡片分页上一页按钮
    CARDPAGE_UP: ['Alt+Pageup'],
    // 卡片分页下一页按钮
    CARDPAGE_DOWN: ['Alt+Pagedown'],
    // 卡片分页首页按钮
    CARDPAGE_HOME: ['Alt+Home'],

    // 常用按钮
    BTN_PRINT: ['Ctrl+P'],

    // 窗口的x
    ICON_CLOSE: ['Esc'],
};

const defaultProps = {
    // 事件key集合  {addData: ['ctrl+s']}
    keyMap: {},
    // 处理方法集合  {addData: ()=> {}}  注意键的配置和处理逻辑的配置对应
    handlers: {},
    // 是否启用
    enabled: true,
    // 触发事件的目标
    attach: document.body, // 如果为flase 则聚焦到当前包裹区域时才触发
    // focused: false, // 是否默认聚焦  设置true 不聚焦到区域也可以触发
    display: 'block',
    // 是否清理 弹窗
    clearOpenPanel: true, // 可以是方法回调
};

/**
 * 首字母大写
 * @param {*} str
 */
function firstToUpperCase(str) {
    str = str.replace(/ /g, '');
    return str.toLowerCase().replace(/( |^)[a-z]/g, L => L.toUpperCase());
}

/**
 * 转换为小写
 * @param {*} str
 */
function toLowerCase(str) {
    str = str.replace(/ /g, '');
    return str.toLowerCase();
}

function _typeof(obj) {
    if (obj == null) {
        return String(obj);
    }
    return typeof obj === 'object' || typeof obj === 'function'
        ? Object.prototype.toString.call(obj).replace(/object|\[|\]| /g, '') ||
        'object'
        : typeof obj;
}

/**
 * 二次封装了  hotkeys 组件
 */
class HotKeys extends Component {
    static USUAL_KEYS = USUAL_KEYS;

    constructor(props) {
        super(props);
        this.state = { enabled: this.props.enabled };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.enabled && nextProps.enabled !== this.state.enabled) {
            this.setState({ enabled: nextProps.enabled });
        }
    }

    UNSAFE_componentWillMount() {
        // 在iframe中使用时会导致  聚焦不到iframe内部
        let attach = this.props.attach;
        // if (attach === document.body) {
        //     document.body.tabindex = "0";
        //     document.body.setAttribute('tabindex', 0);
        // }
    }

    componentDidMount() {
        // HotKeys.USUAL_KEYS.EDIT = ['ctrl+g'];
        // TODO 未来可以请求数据  然后设置数据
    }

    /**
     * 获取翻译之后的keys
     * @param {*} keys
     */
    getTranslateKeys(keys) {
        let type = _typeof(keys);
        if (type === 'string') {
            keys = toLowerCase(USUAL_KEYS[keys] ? USUAL_KEYS[keys][0] : keys);
        } else if (type === 'Array') {
            // 翻译
            keys = keys.map(function (item, index) {
                return toLowerCase(
                    USUAL_KEYS[item] ? USUAL_KEYS[item][0] : item,
                );
            });
            // 去重
            keys = [...new Set(keys)];
        } else if (type === 'Object' && keys.sequence && keys.action) {
            keys = { sequence: keys.sequence, action: keys.action };
        } else {
            keys = ['up+down+left+right+b+a+b+a'];
        }
        return keys;
    }

    /**
     * 清理頁面的彈出面板
     * TODO: 后续要考虑下其他方案  目前这个方案太low了
     */
    clearOpenPanel(keyCode) {
        // 特殊键处理
        if (keyCode === 'enter' || keyCode[0] === 'enter') {
            return false;
        }
        // 日期和时间太复杂  特殊处理下
        let d =
            document.querySelector('.rc-calendar-picker') ||
            document.querySelector('.u-time-picker-panel');
        if (d) {
            PubSub.publish('clearOpenPanel', { type: 'picker' });
        }
        // 这里没有考虑多个展开的问题 而且 有多个展开时应该如何处理
        // 也没有考虑到 组件本身用了 hotkeys 操作的问题
        if (
            document &&
            (document.querySelector('.u-dropdown:not(.u-dropdown-hidden)') ||
                document.querySelector('.u-select-dropdown:not(.u-select-dropdown-hidden)') ||
                document.querySelector('.mores-list-wrapper:not(.mores-list-hide-wrapper)'))
        ) { // 这一坨我也是醉了  要判断  非隐藏的情况下显示了
            console.log('use hotkeys and clear panel');
            document.activeElement.click();
            console.log(document.activeElement);
        }
    }

    triggerEventHandle(k, e, sequence) {
        let {
            handlers = {},
            clearOpenPanel = true,
        } = this.props;
        // console.log(k, e, sequence);
       
        e.preventDefault();
        handlers[k] && handlers[k](e, sequence);
        
        if (Object.prototype.toString.call(clearOpenPanel) === '[object Function]') {
            clearOpenPanel();
        }
        if (clearOpenPanel === true) {
            this.clearOpenPanel(sequence);
        }
    }

    /**
     * 獲取KeyMapsAndHandlers
     */
    getKeyMapsAndHandlers() {
        let {
            keyMap = {},
            handlers = {},
            keyMapX = {},
            handlersX = {},
        } = this.props;

        for (let k in keyMap) {
            keyMapX[k] = this.getTranslateKeys(keyMap[k]);

            // handlersX[k] = function (e) {
            //     this.clearOpenPanel(keyMapX[k]);
            //     e.preventDefault();
            //     handlers[k] && handlers[k](e, keyMapX[k]);
            // };

            // handlersX[k] = ((k, handlers, keyMapX, self) => {
            //     // 包装
            //     return function (e) {
            //         self.clearOpenPanel(keyMapX[k]);
            //         e.preventDefault();
            //         handlers[k] && handlers[k](e, keyMapX[k]);
            //     };
            // })(k, handlers, keyMapX, this);

            handlersX[k] = this.triggerEventHandle.bind(this, k);
        }
        keyMapX = Object.assign({}, keyMapX);
        handlersX = Object.assign({}, handlersX);
        // 过滤一些空的hotkeys
        if (
            Object.keys(keyMapX).length === 0 ||
            Object.keys(handlersX).length === 0
        ) {
            return false;
        }
        return {
            keyMapX,
            handlersX,
        };
    }

    render() {
        let {
            enabled,
            keyMap,
            handlers,
            children = null,
            className = '',
            display,
            clearOpenPanel,
            ...others
        } = this.props;

        let assignResult = this.getKeyMapsAndHandlers(); // 處理一下 合并結果
        return assignResult && enabled ? (
            <OriginHotKeys
                keyMap={assignResult.keyMapX}
                handlers={assignResult.handlersX}
                ref={dom => (this.hotkeyWrapped = dom)}
                className={'nc-hotkeys-wrapper ' + className}
                style={{ display }}
                {...others}
            >
                {children}
            </OriginHotKeys>
        ) : (<div className={className} style={{ display }}>
            {children}
        </div>);
    }
}

HotKeys.defaultProps = defaultProps;

export default HotKeys;

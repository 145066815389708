import { Col, Row } from 'bee-layout';
require('bee-layout/build/Layout.css');
import { isFunction } from '@platform/api';
import HotKeys from '../HotKeys';

let _multiLang = {};
let functionList = {};
export const setMultiLang = (multiLang = {}) => {
    functionList = {
        '#day(-1)#': multiLang['page-search-0060'],
        '#day(0)#': multiLang['page-search-0061'],
        '#day(1)#': multiLang['page-search-0062'],
        '#month(-1)#': multiLang['page-search-0063'],
        '#month(0)#': multiLang['page-search-0064'],
        '#month(1)#': multiLang['page-search-0065'],
        '#week(-1)#': multiLang['page-search-0066'],
        '#week(0)#': multiLang['page-search-0067'],
        '#week(1)#': multiLang['page-search-0068'],
        '#quarter(-1)#': multiLang['page-search-0069'],
        '#quarter(0)#': multiLang['page-search-0070'],
        '#quarter(1)#': multiLang['page-search-0071'],
        '#year(-1)#': multiLang['page-search-0072'],
        '#year(0)#': multiLang['page-search-0073'],
        '#year(1)#': multiLang['page-search-0074'],
        '#lastDayOfMonth#': multiLang['page-search-0075'],
        '#lastDayOfWeek#': multiLang['page-search-0076'],
        '#finalDayOfLastMonth#': multiLang['page-search-0077'],
    };
    _multiLang = multiLang;
    return functionList;
};
// 用来判断和处理时间函数
export function timeFunctionHandler(val) {
    let result = false;
    if (typeof val === 'string' && val.indexOf('#') !== -1) {
        result = true;
    }
    return result;
}
function selectDateFunction(e, callback, item) {
    console.log("触发click事件", item)

    pickerState(e, false);
    callback(item);
}
function pickerState(e, flag) {
    let pickerDOM = document.getElementsByClassName('rc-calendar-picker')[0];
    if (pickerDOM) {
        pickerDOM.style.display = flag ? 'block' : 'none';
    }
}

export const getFuntionValue = value => {
    return functionList[value] || value;
};
export const getTimeFunctionLabel = (value, show, openCallBack) => {
    let label = functionList[value] || '';
    return show && label ? (
        <div className="date-picker-mask" onClick={() => openCallBack(true)}>
            {label}
        </div>
    ) : null;
};
// value = 选择的时间 callback = 单击回调 show  = 是否显示时间函数
export const getDateFunNode = (value, callback, show, hiddenFunList) => {
    if (!show) return;
    let spen = 4;
    let newFunList = { ...functionList };
    if (Array.isArray(hiddenFunList)) {
        hiddenFunList.forEach(element => {
            delete newFunList[element];
        });
    }
    return (
        <HotKeys
            keyMap={{
                rightHandler: ["right"],
                leftHandler: ["left"],
                enterHander: ["enter"],
            }}
            handlers={{
                rightHandler: e => {
                    if (document.activeElement.nextElementSibling) {
                        document.activeElement.nextElementSibling.focus();
                    }
                },
                leftHandler: () => {
                    if (document.activeElement.previousElementSibling) {
                        document.activeElement.previousElementSibling.focus();
                    }
                },
                enterHander: () => {
                    if (document.activeElement) {
                        document.activeElement.click();
                    }

                },
            }}
            clearOpenPanel={false}
        >
            <div className='function-area'>
                {Object.keys(newFunList).map((item, index) => (
                    <div
                        tabIndex={index === 0 ? "0" : "-1"}
                        className="fun-plan-area function-area-item"
                        lg={spen}
                        md={spen}
                        sm={spen}
                        xs={spen}
                        onClick={e => selectDateFunction(e, callback, item)}
                        onKeyDown={e => {
                            e.stopPropagation();
                        }}
                    >
                        <span className={value.toString().includes(item) && 'select'}>{newFunList[item]}</span>
                    </div>
                ))}
            </div>
        </HotKeys>
    );
};

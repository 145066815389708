/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-12 10:09:01
 * @LastEditTime: 2021-03-05 17:17:49
 * @LastEditors: bbq
 */
/**
 * Created by wangshhj on 2018/1/16.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Col, Table as NCTable, Pagination, Checkbox, Select as NCSelect, ErrorPop } from '@platform/base'
import { isFunction, changeTime, formatAcuracy, getMultiLang, getSysFieldid, formatDatetime, formatNumber, ViewModel } from '@platform/api'
import { lib, utils } from '@platform/table-core';
import { getTotal, sortColumn } from './methods';
const { setPageSizeStorage, getPageSizeStorage } = utils;

import { CONST_IS_MULTILINGUAL } from './constant';
import ToolCell from './toolCell';
const { total } = lib;
const defaultColor = ['#111111', '#555555', '#111', '#555'];
require('./insertTable.less');

const { getGlobalStorage } = ViewModel;
const MetaTable = NCTable.SortTable;
const ComplexTable = total(MetaTable)
//缓存全局多语
let varMultil;

// 计算主表表体高度
let tableHeight = 0;
const screenHeight = window.screen.width;

if (screenHeight <= 1919 > 1440) {
    tableHeight = 48 * 10 + 'px';
} else if (screenHeight > 1919) {
    tableHeight = 56 * 10 + 'px';
} else {
    tableHeight = 40 * 10 + 'px';
}

let inserttableDom;

// currentTable.tableHeight = 0;
let NCOption = NCSelect.Option;
let bodyKey = null; //当前行的key

const defaultProps = {
    //复选框
    prefixCls: 'bee-table',
    multiSelect: {
        type: 'checkbox',
        param: 'key',
    },
};

//  检测所有子表是否有选中项
const checkChildSelected = data => {
    let res = false;
    for (let key in data) {
        let item = data[key];
        if (item.checkedAllChild) {
            res = true;
            break;
        } else {
            let child = item.checkedArrayChild;
            let len = child.length;
            for (let i = 0; i < len; i++) {
                if (child[i]) {
                    res = true;
                    break;
                }
            }
        }
    }
    return res;
};

//  检测主表是否有选中项
const checkOutTableSelected = data => {
    let i = data.length;
    let res = false;
    while (i--) {
        if (data[i]) {
            res = true;
            break;
        }
    }
    return res;
};

//  检测主表是否全选
const checkOutTableAllSelected = Obj => {
    let out = Obj.mainCheckObj;
    let len = out.checkedArray.length;
    out.checkedAll = true;
    while (len--) {
        if (!out.checkedArray[len]) {
            out.checkedAll = false;
            break;
        }
    }
};


const getHoverContent = columns => {
    let oprColumn = null;
    columns = [...columns];
    columns.forEach((item, index) => {
        if (
            item.attrcode === "opr" &&
            item.itemtype === "customer" &&
            item.render
        ) {
            oprColumn = item;
            // 移除下  这里直接移除可能有问题
            columns.splice(index, 1);
        }
    });
    return { oprColumn, columns };
}

export function createInsertTable({
    headTableId, // 外层主表表格的组件id
    bodyTableId, // 子表表格的组件id
    setTableBodyData, // 设置子表表格数据
    pageIndexChange,
    pageSizeChange,
    rowCheckChildChange,
    onAllCheckChangeEve, // 全选事件
    rowCheckChange, // 行勾选事件
    comType, // 嵌套表格类型。'normal':普通，不带复选框。hasCheckBox:只有外层表格带复选框。hasCheckBox_child:内外表格都带复选框
    needIndex = true, // 默认显示序号，如不需要，传false
    childNeedIndex = false,
    mockRadio = false,
    isDrag = true,
    multipleRowCell = false,
    adaptionHeight = false,
    inModal = false,
    customSortRule, //自定义排序规则
    // cancelCustomRightMenu = false,
    fieldid,
    insertTableHeight = false,
    hoverContent = getGlobalStorage("localStorage", "btnRenderModel"), // true
}) {
    if (!this.state.meta) {
        return false;
    }
    if (
        !this.state.meta.hasOwnProperty(headTableId) ||
        !this.state.meta[headTableId]
    ) {
        return false;
    }
    if (!this.state.insertTable[headTableId]) {
        return false;
    }
    //默认分页信息
    this.defPageInfo = {
        pageSize: getPageSizeStorage(this.state.meta, headTableId),
        pageIndex: 1,
        totalPageIndex: 5,
    };

    const meta = this.state.meta;

    function adddDataIndex(colums) {
        colums.map(col => {
            col.dataIndex = col.attrcode;
        });
    }

    adddDataIndex(meta[headTableId].items);

    const sortTypeHead = { //排序的类型 Object
        mode: 'single', //'multiple'(多列排序) 或'single'(单列排序)(如果前端排序的时候想多列排序，对象里就穿这个字段就可以了),
        backSource: false, //false是前端排序，值为true为后端排序,默认false
        sortFun: (sortParam, sortData) => {
            let sortObj = {};
            let mainCheckObj = this.state.insertTable[headTableId].mainCheckObj || {}
            let rows = this.state.insertTable[headTableId].outerData || []
            let sortRows = []
            let colums = meta[headTableId].items;

            sortParam.forEach(item => {
                sortObj[item.field] = item;
            });

            colums.forEach(item => {
                //保存返回的column状态，没有则终止order状态
                if (sortObj[item.attrcode]) {
                    item.order = sortObj[item.attrcode].order;
                    item.orderNum = sortObj[item.attrcode].orderNum;
                } else {
                    item.order = 'flatscend';
                    item.orderNum = '';
                }
            });

            sortData.forEach(el => {
                rows.forEach(row => {
                    if (el.key === row.values.key) {
                        sortRows.push(row);
                    }
                });
            });
            this.state.insertTable[headTableId].outerData = sortRows
            mainCheckObj.data = sortRows
        },
    };

    // const getColSetConfig = () => {
    //   //const appcode = pageTo.getAppCode();
    //   //const pagecode = pageTo.getPageCode();
    //   const areaCode = headTableId;
    //   let areaCodeMeta = this.state.meta[areaCode] || {};
    //   const appcode = areaCodeMeta.appcode || pageTo.getAppCode();
    //   const pagecode = areaCodeMeta.pagecode || pageTo.getPageCode();
    //   const areaId = areaCodeMeta.oid;
    //   const code = areaCodeMeta.templateCode || this.state.meta.code;
    //   const pageId = areaCodeMeta.pageid || this.state.meta.pageid;
    //   return { appcode, pagecode, areaCode, areaId, code, pageId };
    // };

    let currentTable = this.state.insertTable[headTableId];

    currentTable.tableHeight = tableHeight;
    console.log(
        '子表表格的组件id:',
        headTableId,
        ';子表表格的组件id:',
        bodyTableId,
    );

    // 如果是自适应的就进行高度计算
    // if (adaptionHeight) {

    //   if (inserttableDom) {
    //     // 普通情况
    //     let tableBody = inserttableDom.querySelectorAll('.u-table-body')[0];
    //     if (tableBody) {
    //       const tableHeight =
    //         document.body.offsetHeight - inserttableDom.getBoundingClientRect().bottom + tableBody.offsetHeight;
    //       tableBody = null;
    //       currentTable.tableHeight = tableHeight;

    //     } else {
    //       const tableHeight = document.body.offsetHeight - inserttableDom.getBoundingClientRect().bottom;
    //       tableBody = null;
    //       currentTable.tableHeight = tableHeight;
    //     }
    //   }
    // }

    function getExpandTableHeight(data, isTotal, wrapper, tableBody = false) {
        wrapper = wrapper || document;
        let expandTableHeight = 0;
        if (data && data.length) {
            let tableHead = wrapper.querySelector('.u-table-thead');
            let tbody = wrapper.querySelector('.u-table-tbody');
            let tableRow = tbody.querySelectorAll('.u-table-row')[0];
            let theadHeight = tableHead && tableHead.getBoundingClientRect().height;
            let trowHeight = tableRow && tableRow.getBoundingClientRect().height;
            let TotalRowHeight = isTotal ? 45 : 0;//合计行高度
            let BodyMinHeight = 30;//UE定的表体最小高度为55
            let paddingHeight = 10;//上下padding为6
            if (tableRow && theadHeight) {
                let baseHeight = theadHeight + paddingHeight + TotalRowHeight;
                // let baseHeight = theadHeight + TotalRowHeight;
                if (data.length < 11) { //最多展示10行
                    if (data.length * trowHeight < BodyMinHeight) {
                        expandTableHeight = BodyMinHeight + baseHeight;
                    } else {
                        expandTableHeight = data.length * trowHeight + baseHeight;
                    }
                } else {
                    expandTableHeight = 10 * trowHeight + baseHeight;
                }
                if (tableBody) {
                    return data.length > 9 ? (expandTableHeight - baseHeight) : '';
                }
                return expandTableHeight;
            } else {
                return 0;
            }
        }
        if (data && data.length == 0 && isTotal) {
            return 110;
        }

        if (data && data.length == 0 && !isTotal) {
            return 65;
        }
    }

    // 订阅查询区高度回调
    // function handlePubSub(msg, data) {
    //   if (data) {
    //     if (inserttableDom) {
    //       let tableBody = inserttableDom.querySelectorAll('.u-table-body')[0];
    //       if (tableBody) {
    //         const tableHeight =
    //           document.body.offsetHeight - inserttableDom.getBoundingClientRect().bottom + tableBody.offsetHeight;
    //         tableBody = null;
    //         currentTable.tableHeight = tableHeight;
    //       } else {
    //         const tableHeight = document.body.offsetHeight - inserttableDom.getBoundingClientRect().bottom;
    //         tableBody = null;
    //         currentTable.tableHeight = tableHeight;
    //       }
    //     }
    //   }
    //   setTimeout(() => {
    //     PubSub.unsubscribe(subscribeToken);
    //   }, 5000);
    // };

    currentTable.multilingual = varMultil;

    // const _that = this;

    //调用多语
    const callback = json => {
        if (CONST_IS_MULTILINGUAL.isMul) return;
        currentTable.multilingual = json;
        varMultil = json;
        this.setState(
            { insertTable: this.state.insertTable },
            () => {
                CONST_IS_MULTILINGUAL.isMul = true;
            },
        );
    };

    getMultiLang({ moduleId: 'containers_insert_tree_table', callback });

    currentTable.fatherTable = {};
    Object.defineProperty(currentTable.fatherTable, 'top', {
        set(newVal) {
            currentTable.fatherTable.height =
                document.body.clientHeight - newVal - 100;
        },
    });

    let data = currentTable.outerData;
    let pageInfo = Object.assign({}, this.defPageInfo, currentTable.pageInfo);
    currentTable.pageInfo = pageInfo;
    let pageSize = pageInfo.pageSize;
    let pageIndex = pageInfo.pageIndex;
    // let totalPage = Math.ceil(pageInfo.total / pageSize);

    this.setTableBodyData = setTableBodyData; //设置内嵌表格数据
    this.pageIndexChange = pageIndexChange; //切换分页
    this.pageSizeChange = pageSizeChange; //切换每页显示条数

    let columns = this.state.meta[headTableId].items.filter(e => e.visible);
    let bodyColumn = this.state.meta[bodyTableId].items.filter(e => e.visible);
    let headPaginationInfo = this.state.meta[headTableId].pagination;
    // let bodyPagination = this.state.meta[bodyTableId].pagination;

    // 分割分页id
    const splitPks = (data, pageIndex, pageSize) => {
        let pks = [];
        let index = Number(pageIndex);
        let size = Number(pageSize);
        let start = (index - 1) * size;
        for (let i = start; i < start + size; i++) {
            if (data[i]) {
                pks.push(data[i]);
            } else {
                break;
            }
        }
        return pks;
    };

    //切换分页
    const pageIndexChanges = eventKey => {
        let pks;
        currentTable.pageInfo.pageIndex = eventKey;
        if (isFunction(this.pageIndexChange)) {
            if (currentTable.allpks) {
                pks = splitPks(
                    currentTable.allpks,
                    currentTable.pageInfo.pageIndex,
                    currentTable.pageInfo.pageSize,
                );
            }
            data = this.pageIndexChange(eventKey, pks);
        }

        pageIndex = eventKey;
        this.setState(this.state);
    };

    //每页显示条数
    const pageSizeSelect = val => {
        let total = currentTable.pageInfo.total;
        currentTable.pageInfo.pageSize = val;
        setPageSizeStorage(this.state.meta, headTableId, val);
        currentTable.pageInfo.pageIndex = 1;
        currentTable.pageInfo.totalPage = Math.ceil(
            total / currentTable.pageInfo.pageSize,
        );


        if (isFunction(pageSizeChange)) {
            let pks;
            if (currentTable.allpks) {
                pks = splitPks(
                    currentTable.allpks,
                    currentTable.pageInfo.pageIndex,
                    currentTable.pageInfo.pageSize,
                );
            }

            data = this.pageSizeChange(val, pks);
        }
        pageSize = val;
        this.setState(this.state);
    };

    //===========================子表添加复选框===========================================

    //子表复选框全选
    // const onAllCheckChangeChild = (record, index) => {
    //   let checkedArray = [];
    //   let mainCheckObj = currentTable.childCheckObj[record.key];
    //   for (var i = 0; i < mainCheckObj.checkedArrayChild.length; i++) {
    //     checkedArray[i] = !mainCheckObj.checkedAllChild;
    //   }
    //   currentTable.mainCheckObj.checkedArray[
    //     index
    //   ] = mainCheckObj.checkedAllChild = !mainCheckObj.checkedAllChild;
    //   mainCheckObj.checkedArrayChild = checkedArray;
    //   checkOutTableAllSelected(currentTable);

    //   this.setState(this.state);

    //   // onAllCheckChangeEve(mainCheckObj.checkedAllChild,currentTable.mainCheckObj.data,currentTable.bodyData)
    // };

    //子表复选框单个勾选
    const onCheckboxChangeChild = (
        text,
        record,
        index,
        parents,
        parentsIndex,
    ) => {
        let allFlag = false;
        let childCheckObj = currentTable.childCheckObj[parents.key];
        childCheckObj.checkedArrayChild[index] = !childCheckObj.checkedArrayChild[
            index
        ];

        for (var i = 0; i < childCheckObj.checkedArrayChild.length; i++) {
            if (!childCheckObj.checkedArrayChild[i]) {
                allFlag = false;
                break;
            } else {
                allFlag = true;
            }
        }
        currentTable.mainCheckObj.checkedArray[
            parentsIndex
        ] = childCheckObj.checkedAllChild = allFlag;
        checkOutTableAllSelected(currentTable);
        if (typeof rowCheckChildChange === 'function') {
            rowCheckChildChange(
                childCheckObj.checkedArrayChild[index],
                record,
                currentTable,
            );
        }
        this.setState(this.state);
        // rowCheckChange(checkedArrayChild[index],currentTable.bodyData.data[bodyKey])
    };

    //子表  表格和复选框列组合到一起
    const renderColumnsMultiSelectChild = (columns, records, indexs) => {
        // if(!currentTable.childCheckObj.hasOwnProperty(bodyKey)){
        //     return false
        // }
        let { checkedArrayChild } = currentTable.childCheckObj[
            records.key
        ];
        let { multiSelect } = defaultProps;

        let indexCol = [
            {
                label:
                    currentTable.multilingual &&
                    currentTable.multilingual['insert-tree-table-003'],
                attrcode: 'indexCol',
                dataIndex: 'indexCol',
                width: '60px',
                className: 'nunberindex',
                title:
                    currentTable.multilingual &&
                    currentTable.multilingual['insert-tree-table-003'],
                render: (text, record2, index) => {
                    return <span>{index + 1}</span>;
                },
            },
        ]; // 添加序号列
        let newColumn = columns;
        if (needIndex) {
            newColumn = indexCol.concat(newColumn);
        }

        // let indeterminate_bool = false;
        if (multiSelect && multiSelect.type === 'checkbox') {
            // let i = checkedArrayChild.length;
            // while (i--) {
            //   if (checkedArrayChild[i]) {
            //     indeterminate_bool = true;
            //     break;
            //   }
            // }
            let defaultColumns = [
                {
                    label: '',
                    attrcode: 'innerCheckbox',
                    dataIndex: 'checkbox',
                    width: '120px',
                    render: (text, record, index) => {
                        // Socket改造
                        //let { billpkname, billtype, errorViewType } = this.nativeSocketBillInfo;

                        let values = record.values || record;
                        return values && values.saga_status && values.saga_status.value === '1' ? (
                            <ErrorPop
                                record={record}
                            />
                        ) : (
                            <Checkbox
                                className="table-checkbox"
                                checked={checkedArrayChild[index]}
                                onChange={onCheckboxChangeChild.bind(
                                    this,
                                    text,
                                    record,
                                    index,
                                    records,
                                    indexs,
                                )}
                            />
                        );
                    },
                },
            ];
            newColumn = defaultColumns.concat(newColumn);
        }

        return newColumn;
    };

    //子表添加序号
    const renderColumnsForChildOfIndex = columns => {
        // if(!currentTable.childCheckObj.hasOwnProperty(bodyKey)){
        //     return false
        // }
        // let { checkedAllChild, checkedArrayChild } = currentTable.childCheckObj[
        //   records.key
        // ];
        // let { multiSelect } = defaultProps;
        let indexCol = [
            {
                label:
                    currentTable.multilingual &&
                    currentTable.multilingual['insert-tree-table-003'],
                attrcode: 'indexCol',
                dataIndex: 'indexCol',
                width: '60px',
                className: 'nunberindex',
                title:
                    currentTable.multilingual &&
                    currentTable.multilingual['insert-tree-table-003'],
                render: (text, record2, index) => {
                    return <span>{index + 1}</span>;
                },
            },
        ]; // 添加序号列
        let newColumn = columns;
        if (needIndex) {
            newColumn = indexCol.concat(newColumn);
        }

        return newColumn;
    };

    // 处理模板
    const createNewColChild = column => {
        const multiCustomer =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-008'];
        const multiYes =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-009'];
        const multiNo =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-010'];

        return column.map(item => {
            let render = null;
            if (item.itemtype == 'customer') {
                item.width ? item.width : (item.width = '90px');
                item.title = multiCustomer;
                // item.fixed = "right";
            }
            if (
                item.itemtype !== 'customer' &&
                item.attrcode !== 'checkbox' &&
                item.attrcode !== 'indexCol'
            ) {
                item.width ? item.width : (item.width = '120px');
                item.title = (
                    <span
                        fieldid={getSysFieldid(item.attrcode)}
                        style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                        className={classnames('title-container', { alignRight: item.itemtype === 'number' })}
                    >
                        {item.required && <span className="mark-required">*</span>}
                        {' '}
                        {item.label}{' '}
                    </span>
                );

                if (item.render) {
                    render = (text, record, index) => {
                        return (
                            <div fieldid={getSysFieldid(item.attrcode)}>
                                <ToolCell context={item.render(text, record, index)} multipleRowCell={false} />
                            </div>
                        );
                    };
                } else {
                    render = (text, record) => {
                        if (record.hasOwnProperty(item.attrcode)) {
                            let display = record[item.attrcode].display;
                            // 行数据里的精度值
                            let valSca = record[item.attrcode].scale;
                            valSca = Number(valSca);
                            let value = record[item.attrcode].value;

                            // 如果是时间类型
                            // if (isTimeType(item.itemtype)) {
                            //   value = changeTime(value);
                            // }

                            // timeTypes: ['datepicker', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'timepicker', 'datetimepicker', 'rangepicker', 'datePickerNoTimeZone'],

                            switch (item.itemtype) {
                                case 'datepicker':
                                    value = changeTime(value);
                                    value = formatDatetime(value, 'date');
                                    break;
                                case 'NCTZDatePickerEnd':
                                    value = changeTime(value);
                                    value = formatDatetime(value, 'date');
                                    break;
                                // case 'timepicker':
                                //   value = changeTime(value, 'HH:mm:ss');
                                //   break;
                                case 'datetimepicker':
                                    value = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                    value = formatDatetime(value, 'datetime');
                                    break;
                            }

                            // 布尔值转换处理
                            const itemtypeBool = [
                                'switch',
                                'switch_browse',
                                'checkbox',
                                'checkbox_switch',
                            ];
                            if (itemtypeBool.includes(item.itemtype)) {
                                value = value === 'Y' || value === true ? multiYes : multiNo;
                            }

                            let dom = '';
                            if (display || display == 0) {
                                dom = display;
                            } else {
                                dom = value;
                            }
                            if (typeof dom === 'boolean') {
                                dom = dom ? multiYes : multiNo;
                            }

                            if (item.itemtype === 'number') {
                                let sc = Number(item.scale) > 0 ? Number(item.scale) : 0;

                                if (!isNaN(valSca) && valSca >= 0) {
                                    sc = valSca;
                                }

                                dom = formatAcuracy(value, sc);
                                dom = formatNumber(dom);
                                record[item.attrcode].renderValue = dom;
                            }

                            return (
                                <div
                                    fieldid={getSysFieldid(item.attrcode)}
                                    className={classnames({ alignRight: item.itemtype === 'number' })}
                                >
                                    <ToolCell context={dom} multipleRowCell={multipleRowCell} />
                                </div>
                            );
                        }
                    };
                }
            } else {
                render = item.render;
            }


            return { ...item, render, key: item.attrcode, title: item.title, required: null };
        });
    };
    //  处理数据
    const createNewData = (data, column) => {
        const colMap = new Map();

        if (column) {
            column.map(col => {
                if (col.itemtype && col.itemtype === 'number') {
                    if (!colMap.has(col.attrcode)) colMap.set(col.attrcode, {});

                    if (colMap.has(col.attrcode)) {
                        colMap.get(col.attrcode).scale = col.scale;
                    }
                }
            });
        }

        let datas = [];
        data.map((val, index) => {
            if (column) {
                colMap.forEach((value, key) => {
                    let itemScale = val.values[key] && val.values[key].scale;
                    itemScale = Number(itemScale);

                    let sc = Number(value.scale) > 0 ? Number(value.scale) : 0;

                    if (!isNaN(itemScale)) {
                        sc = itemScale;
                    }

                    if (val.values[key] && val.values[key].value) {
                        let renderValue = formatAcuracy(val.values[key].value, sc);
                        val.values[key].renderValue = renderValue;
                    }
                });
            }

            if (needIndex) {
                val.values.indexCol = { value: index };
            }
            datas.push(val.values);
        });
        // {value: "张三"}
        return datas;
    };
    // 判断是否为需要显示合计
    const isShowTotal = columns => {
        let isShowTotal = false;
        columns.map(v => {
            if (v.hasOwnProperty('istotal') && v.istotal) {
                isShowTotal = true;
            }
        });
        return isShowTotal;
    };

    const handleColAndSrc = (col, src) => {
        // 重新生成column
        let isGenerantorTotal = false;
        const heji =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-007'];

        const totalColumns = col.map(v => {
            // 如果有CheckBox就用这列做合计

            // 如果没有CheckBox 就用dataIndex做合计

            const obj = {};
            if (!v.istotal && !isGenerantorTotal) {
                obj.title = heji;
                obj.dataIndex = 'total';
                obj.key = 'total';
                obj.width = v.width ? v.width : '80px';
                isGenerantorTotal = true;
            } else if (
                (v.attrcode === 'checkbox' || v.dataIndex === 'checkbox') &&
                !isGenerantorTotal
            ) {
                obj.title = heji;
                obj.dataIndex = 'total';
                obj.key = 'total';
                obj.width = v.width ? v.width : '60px';
                isGenerantorTotal = true;
            } else if (v.attrcode === 'indexCol' && !isGenerantorTotal) {
                obj.title = heji;
                obj.dataIndex = 'total';
                obj.key = 'total';
                obj.width = v.width ? v.width : '';
                isGenerantorTotal = true;
            } else if (v.itemtype === 'customer' && !isGenerantorTotal) {
                obj.title = heji;
                obj.dataIndex = 'total';
                obj.key = 'total';
                obj.width = v.width ? v.width : '';
                isGenerantorTotal = true;
            } else {
                obj.title = v.title;
                obj.dataIndex = v.dataIndex || v.key;
                obj.width = v.width ? v.width : '';
                obj.key = v.key;
            }

            if (
                v.itemtype !== 'customer' &&
                v.attrcode !== 'checkbox' &&
                v.attrcode !== 'indexCol'
            ) {
                obj.width = v.width ? v.width : '120px';
            }
            obj.render = text => {
                return (
                    <div className="alignRight" fieldid={getSysFieldid(obj.dataIndex === 'total' ? 'firstcol' : v.attrcode)}>
                        <ToolCell context={text} isLineAll={true} />
                    </div>
                );
            };
            return obj;
        });
        const totalData = getTotal(col, src);
        return {
            totalColumns,
            totalData: [{ total: heji, ...totalData }],
        };
    };
    //表格展开显示的内容
    const expandedRowRender = (record, index) => {
        // if (JSON.stringify(bodyColumn) == "{}") {
        //   return false;
        // }
        if (!currentTable.childCheckObj.hasOwnProperty(record.key)) {
            return false;
        }

        //  根据组件类型，判断是否带复选框
        let newColumn = bodyColumn;
        // let classByType = null;
        if (comType === 'hasCheckBox_child') {
            newColumn = renderColumnsMultiSelectChild.call(
                this,
                newColumn,
                record,
                index,
            );
        }

        if (childNeedIndex) {
            // 给子表添加序号和父选框
            // newColumn = renderColumnsMultiSelectChild.call(
            //   this,
            //   newColumn,
            //   record,
            //   index
            // );
            newColumn = renderColumnsForChildOfIndex.call(
                this,
                newColumn,
                record,
                index,
            );
        }

        adddDataIndex(newColumn);

        const sortTypeChild = { //排序的类型 Object
            mode: 'single', //'multiple'(多列排序) 或'single'(单列排序)(如果前端排序的时候想多列排序，对象里就穿这个字段就可以了),
            backSource: false, //false是前端排序，值为true为后端排序,默认false
            sortFun: sortParam => {
                let sortObj = {};

                let colums = newColumn;
                sortParam.forEach(item => {
                    sortObj[item.field] = item;
                });

                colums.forEach(item => {
                    //保存返回的column状态，没有则终止order状态
                    if (sortObj[item.attrcode]) {
                        item.order = sortObj[item.attrcode].order;
                        item.orderNum = sortObj[item.attrcode].orderNum;
                    } else {
                        item.order = 'flatscend';
                        item.orderNum = '';
                    }
                });
            },
        };

        if (
            currentTable.bodyData.data &&
            currentTable.bodyData.data.hasOwnProperty(record.key)
        ) {
            let finalColumns = createNewColChild(newColumn);

            finalColumns = finalColumns.map(val => {

                if (
                    // val.itemtype == 'customer' ||
                    val.attrcode !== 'checkbox' &&
                    val.attrcode !== 'indexCol'
                ) {
                    sortColumn(val, sortTypeChild, customSortRule);
                }
                return val;
            });

            const fianlyData = createNewData(
                currentTable.bodyData.data[record.key].rows,
                newColumn,
            );

            let { oprColumn, columns: finalChildColumns } = hoverContent ? getHoverContent(finalColumns) : { columns: finalColumns };

            // 判断是否需要合计
            const isTotal = isShowTotal(finalChildColumns);
            let totalConfig = {};
            if (isTotal) {
                totalConfig = handleColAndSrc(finalChildColumns, fianlyData);
            }
            const childData = createNewData(currentTable.bodyData.data[record.key].rows);

            const expandTableHeight = getExpandTableHeight(childData, isTotal, ReactDOM.findDOMNode(inserttableDom));

            const expandTableBodyHeight = getExpandTableHeight(childData, isTotal, ReactDOM.findDOMNode(inserttableDom), true);


            return (
                <div fieldid={getSysFieldid(`${fieldid || bodyTableId}_table`)} style={{ height: `${expandTableHeight}px` }}>
                    <ComplexTable
                        // lazyload={false}
                        className="innerTable"
                        columns={[...finalChildColumns]}
                        data={childData}
                        isTotal={isTotal}
                        inserttableTotalConfig={totalConfig}
                        isDrag={isDrag}
                        bodyStyle={{ minHeight: '30px' }}
                        scroll={{ x: true, y: expandTableBodyHeight || 450 }}
                        sort={sortTypeChild}
                        totalBodyHeight={30}
                        hoverContent={
                            oprColumn && hoverContent
                                ? (record, index) => {
                                    console.log(record, index);
                                    return (
                                        <div className="hover-content">
                                            {record ? oprColumn.render("", record, index) : ""}
                                        </div>
                                    );
                                }
                                : undefined
                        }
                    // colsSettingParam={getColSetConfig()}
                    // cancelCustomRightMenu={cancelCustomRightMenu}
                    // updateColumnHandle={updateColumnAfterSetting.bind(this)}
                    />
                </div>
            );
        }
    };

    //当点击展开的时候才去请求内嵌表格数据
    const getData = (expanded, record) => {
        if (expanded) {
            currentTable.expandedRowKeys.push(record.key);
            bodyKey = record.key;

            //判断是否已经有该子表数据，如果有，不需要再次请求。
            let hasThisChild = currentTable.childCheckObj.hasOwnProperty(bodyKey);
            this.setTableBodyData(record, hasThisChild);
        } else {
            const index = currentTable.expandedRowKeys.indexOf(record.key);
            currentTable.expandedRowKeys.splice(index, 1);
        }
        this.setState(this.state);
    };

    //============================================添加复选框==================================

    //主表复选框全选点击事件
    const onAllCheckChange = () => {
        let checkedArray = [];
        let errorDataKey = {};
        let mainCheckObj = currentTable.mainCheckObj;
        for (var i = 0; i < mainCheckObj.checkedArray.length; i++) {
            let cd = currentTable.outerData[i],
                values = cd.values;
            if (values && values.saga_status && values.saga_status.value === '1') { // socket 错误数据过滤
                errorDataKey[cd.rowId || values.key] = cd.rowId || values.key;
                checkedArray[i] = false;
            } else {
                checkedArray[i] = !mainCheckObj.checkedAll;
            }
        }
        mainCheckObj.checkedAll = !mainCheckObj.checkedAll;
        mainCheckObj.checkedArray = checkedArray;
        let childCheckObj = currentTable.childCheckObj;
        //设置子表勾选
        for (let key in childCheckObj) {
            if (errorDataKey[key]) {
                // 如果主表错误  则子表也不能选中数据
                childCheckObj[key].checkedAllChild = false;
                childCheckObj[key].checkedArrayChild = childCheckObj[
                    key
                ].checkedArrayChild.map(() => {
                    return false;
                });
            } else {
                childCheckObj[key].checkedAllChild = mainCheckObj.checkedAll;
                childCheckObj[key].checkedArrayChild = childCheckObj[
                    key
                ].checkedArrayChild.map((val, i) => {
                    let bd = currentTable.bodyData.data[key].rows[i],
                        values = bd.values;
                    if (values && values.saga_status && values.saga_status.value === '1') { // socket 错误数据过滤
                        return false;
                    } else {
                        return mainCheckObj.checkedAll;
                    }
                });
            }
        }

        // 这里处理Socket saga_status 错误数据比较麻烦

        this.setState(this.state);

        if (typeof onAllCheckChangeEve === 'function') {
            onAllCheckChangeEve(
                currentTable.mainCheckObj.checkedAll,
                currentTable.mainCheckObj.data,
                currentTable.bodyData,
            );
        }
    };

    //主表复选框单个勾选
    const onCheckboxChange = (text, record, index) => {
        let allFlag = false;
        let thisKey = record.key;
        let mainCheckObj = currentTable.mainCheckObj;
        console.log(11122, currentTable);
        let oldState = mainCheckObj.checkedArray[index];
        mainCheckObj.checkedArray[index] = !mainCheckObj.checkedArray[index];
        if (mockRadio) {
            const resetCheckedArray = [];
            mainCheckObj.checkedArray.map((checked, i) => {
                if (index === i) {
                    resetCheckedArray.push(!oldState);
                } else {
                    resetCheckedArray.push(false);
                }
            });
            mainCheckObj.checkedArray = resetCheckedArray;
        }
        for (var i = 0; i < mainCheckObj.checkedArray.length; i++) {
            if (!mainCheckObj.checkedArray[i]) {
                allFlag = false;
                break;
            } else {
                allFlag = true;
            }
        }

        mainCheckObj.checkedAll = allFlag;

        //设置子表勾选
        if (
            JSON.stringify(currentTable.childCheckObj) !== '{}' &&
            currentTable.childCheckObj.hasOwnProperty(thisKey)
        ) {
            currentTable.childCheckObj[thisKey].checkedAllChild =
                mainCheckObj.checkedArray[index];
            currentTable.childCheckObj[thisKey].checkedArrayChild.map((val, ind) => {
                currentTable.childCheckObj[thisKey].checkedArrayChild[ind] =
                    mainCheckObj.checkedArray[index];
            });
        }

        this.setState(this.state);
        if (typeof rowCheckChange === 'function') {
            rowCheckChange(
                mainCheckObj.checkedArray[index],
                currentTable.mainCheckObj.data[index],
                currentTable,
            );
        }
    };

    //主表  表格和复选框列组合到一起
    const renderColumnsMultiSelect = columns => {
        const { checkedArray } = currentTable.mainCheckObj;
        const { multiSelect } = defaultProps;
        let indeterminate_bool = false; //主表半选
        let indexCol = [
            {
                label:
                    currentTable.multilingual &&
                    currentTable.multilingual['insert-tree-table-003'],
                attrcode: 'indexCol',
                dataIndex: 'indexCol',
                width: '60px',
                className: 'nunberindex',
                title:
                    currentTable.multilingual &&
                    currentTable.multilingual['insert-tree-table-003'],
                render: (text, record, index) => {
                    return <span style={{ width: '60px' }}>{index + 1}</span>;
                },
            },
        ]; // 添加序号列
        let newColumn = columns;
        if (needIndex) {
            newColumn = indexCol.concat(newColumn);
        }

        if (multiSelect && multiSelect.type === 'checkbox') {
            if (comType === 'hasCheckBox_child') {
                indeterminate_bool = checkOutTableSelected(checkedArray);
                if (!indeterminate_bool) {
                    indeterminate_bool = checkChildSelected(currentTable.childCheckObj);
                }
            } else {
                indeterminate_bool = checkOutTableSelected(checkedArray);
            }

            const checkTilte = (
                <Checkbox
                    className="table-checkbox"
                    checked={currentTable.mainCheckObj.checkedAll}
                    indeterminate={
                        indeterminate_bool && !currentTable.mainCheckObj.checkedAll
                    }
                    onChange={onAllCheckChange.bind(this)}
                />
            );

            const tableTitle = mockRadio ? null : checkTilte;

            let defaultColumns = [
                {
                    title: tableTitle,
                    attrcode: 'checkbox',
                    dataIndex: 'checkbox',
                    width: '80px',
                    render: (text, record, index) => {
                        let indeterminate_bool_child = false; //子表半选
                        if (
                            currentTable.childCheckObj &&
                            currentTable.childCheckObj.hasOwnProperty(record.key)
                        ) {
                            let checkedArrayChild =
                                currentTable.childCheckObj[record.key].checkedArrayChild;
                            let i = checkedArrayChild.length;

                            while (i--) {
                                if (checkedArrayChild[i]) {
                                    indeterminate_bool_child = true;
                                    break;
                                }
                            }
                        }

                        if (comType === 'hasCheckBox') {
                            // 如果是子表无复选框，那么主表就不需要半选状态
                            indeterminate_bool_child = false;
                        }

                        // Socket改造
                        //let { billpkname, billtype, errorViewType } = this.nativeSocketBillInfo;
                        let values = record.values || record;
                        return values && values.saga_status && values.saga_status.value === '1' ? (
                            <ErrorPop
                                record={record}
                            />
                        ) : (
                            <Checkbox
                                className="table-checkbox"
                                checked={currentTable.mainCheckObj.checkedArray[index]}
                                indeterminate={
                                    indeterminate_bool_child &&
                                    !currentTable.mainCheckObj.checkedArray[index]
                                }
                                onChange={onCheckboxChange.bind(this, text, record, index)}
                            />
                        );
                    },
                },
            ];
            newColumn = defaultColumns.concat(newColumn);
        }

        return newColumn;
    };

    if (data && currentTable.firstTime) {
        currentTable.firstTime = false;
        //初始化内嵌表格数据
        currentTable.bodyData = {
            column: {},
            data: {},
        };
        currentTable.childCheckObj = {}; //子表勾选情况

        //初始化复选框
        currentTable.mainCheckObj = {
            // 主表勾选情况
            checkedAll: false,
            checkedArray: [],
            data,
        };
        //设置每行是否勾选
        data.map(() => {
            currentTable.mainCheckObj.checkedArray.push(false);
        });
    }

    //  根据组件类型，判断是否带复选框
    let column = columns;
    let classByType = null;
    if (comType === 'hasCheckBox' || comType === 'hasCheckBox_child') {
        column = renderColumnsMultiSelect.call(this, columns);
        classByType = 'hasCheckBox';
    }

    // 处理模板
    const createNewCol = column => {
        const multiCustomer =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-008'];
        const multiYes =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-009'];
        const multiNo =
            currentTable.multilingual &&
            currentTable.multilingual['insert-tree-table-010'];

        return column.map((item, colIndex) => {
            let isLineAll = true;
            let render = null;
            if (item.itemtype == 'customer') {
                item.width ? item.width : (item.width = '90px');
                item.title = multiCustomer;
                // item.fixed = "right";
            }

            if (
                item.itemtype !== 'customer' &&
                item.attrcode !== 'checkbox' &&
                item.attrcode !== 'indexCol'
            ) {

                if (comType == 'normal' && colIndex == 0) {
                    isLineAll = false;
                }

                item.width ? item.width : (item.width = '120px');
                item.title = (
                    <span
                        fieldid={getSysFieldid(item.attrcode)}
                        style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                        className={classnames('title-container', { alignRight: item.itemtype === 'number' })}
                    >
                        {item.required && <span className="mark-required">*</span>}
                        {' '}
                        {item.label}{' '}
                    </span>
                );

                render = (text, record) => {

                    if (record.hasOwnProperty(item.attrcode)) {
                        let display = record[item.attrcode].display;
                        // 行数据里的精度值
                        let valSca = record[item.attrcode].scale;
                        valSca = Number(valSca);
                        let value = record[item.attrcode].value;

                        // 如果是时间类型

                        switch (item.itemtype) {
                            case 'datepicker':
                                value = changeTime(value);
                                value = formatDatetime(value, 'date');
                                break;
                            case 'NCTZDatePickerEnd':
                                value = changeTime(value);
                                value = formatDatetime(value, 'date');
                                break;
                            case 'timepicker':
                                value = value;
                                break;
                            case 'datetimepicker':
                                value = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                value = formatDatetime(value, 'datetime');
                                break;
                        }

                        // 布尔值转换处理
                        const itemtypeBool = [
                            'switch',
                            'switch_browse',
                            'checkbox',
                            'checkbox_switch',
                        ];
                        if (itemtypeBool.includes(item.itemtype)) {
                            value = value === 'Y' || value === true ? multiYes : multiNo;
                        }

                        let dom = '';
                        if (display || display == 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (typeof dom === 'boolean') {
                            dom = dom ? multiYes : multiNo;
                        }

                        if (item.itemtype === 'number') {
                            let sc = Number(item.scale) > 0 ? Number(item.scale) : 0;

                            if (!isNaN(valSca) && valSca >= 0) {
                                sc = valSca;
                            }

                            dom = formatAcuracy(value, sc);
                            dom = formatNumber(dom);
                            record[item.attrcode].renderValue = dom;
                        }
                        if (comType == 'normal' && colIndex == 0) {
                            return (
                                <span
                                    fieldid={getSysFieldid(item.attrcode)}
                                    className={classnames({ alignRight: item.itemtype === 'number' })}
                                >
                                    <ToolCell context={dom} multipleRowCell={multipleRowCell} isLineAll={isLineAll} />
                                </span>
                            );
                        } else {
                            return (
                                <span
                                    fieldid={getSysFieldid(item.attrcode)}
                                    className={classnames({ alignRight: item.itemtype === 'number' })}
                                >
                                    <ToolCell context={dom} multipleRowCell={multipleRowCell} isLineAll={isLineAll} />
                                </span>
                            );
                        }
                    }
                };
            } else {
                render = item.render;
            }

            return { ...item, render, key: item.attrcode, title: item.title, required: null };
        });
    };

    let finalColumns = createNewCol(column);

    finalColumns = finalColumns.map(val => {

        if (
            // val.itemtype == 'customer' ||
            val.attrcode !== 'checkbox' &&
            val.attrcode !== 'indexCol'
        ) {
            sortColumn(val, sortTypeHead, customSortRule);
        }
        return val;
    });

    const finalData = createNewData(currentTable.mainCheckObj.data, finalColumns);

    // 获取元素的样式属性值
    const getStyle = (obj, attr) => {
        if (obj.currentStyle) {
            return obj.currentStyle[attr];
        } else {
            return document.defaultView.getComputedStyle(obj, null)[attr];
        }
    };

    let { oprColumn, columns: finalParentColumns } = hoverContent ? getHoverContent(finalColumns) : { columns: finalColumns };

    const isTotal = isShowTotal(finalParentColumns);
    let totalConfig = {};
    if (isTotal) {
        totalConfig = handleColAndSrc(finalParentColumns, finalData);
    }

    const bottom = Number(getStyle(document.body, 'paddingBottom').replace('px', ''));

    currentTable.tableHeight = currentTable.tableHeight - bottom;

    return (
        <div
            className={['insertTable', classByType].join(' ')}
            fieldid={getSysFieldid(`${fieldid || headTableId}_table`)}
            style={{ overflow: 'hidden' }}
            ref={ref => {
                if (ref) {
                    inserttableDom = ref;
                }
            }}
        >
            <ComplexTable
                otherAreaHeight={headPaginationInfo ? 38 : 0}
                adaptionHeight={adaptionHeight}
                inModal={inModal}
                lazyload={false}
                // colsSettingParam={getColSetConfig()}
                // cancelCustomRightMenu={cancelCustomRightMenu}
                // updateColumnHandle={updateColumnAfterSetting.bind(this)}
                // key={Date.parse(new Date())}
                bodyStyle={{ minHeight: insertTableHeight || '419px' }}
                className="outerTable"
                isTotal={isTotal}
                columns={finalParentColumns}
                data={finalData}
                onExpand={getData.bind(this)}
                expandedRowKeys={currentTable.expandedRowKeys}
                expandedRowRender={expandedRowRender.bind(this)}
                scroll={{ x: true, y: currentTable.tableHeight }}
                inserttableTotalConfig={totalConfig}
                isDrag={isDrag}
                sort={sortTypeHead}
                hoverContent={
                    oprColumn && hoverContent
                        ? (record, index) => {
                            console.log(record, index);
                            return (
                                <div className="hover-content">
                                    {record ? oprColumn.render("", record, index) : ""}
                                </div>
                            );
                        }
                        : undefined
                }
            />
            <Col md={12} xs={12} sm={12}>
                {headPaginationInfo && currentTable.multilingual ? (
                    <div className="insertTable-component-paginationDiv" fieldid={getSysFieldid('pagination-div')}>
                        <div className="fl page-size">
                            <NCSelect
                                className="pageSizeDom"
                                size="lg"
                                value={Number(pageSize || '')}
                                style={{ width: 90, marginRight: 6 }}
                                onChange={pageSizeSelect.bind(this)}
                                fieldid="page-size"
                            >
                                <NCOption value={5}>
                                    5{currentTable.multilingual['insert-tree-table-004']}/
                                    {currentTable.multilingual['insert-tree-table-006']}
                                </NCOption>
                                <NCOption value={10}>
                                    10
                                    {currentTable.multilingual['insert-tree-table-004']}/
                                    {currentTable.multilingual['insert-tree-table-006']}
                                </NCOption>
                                <NCOption value={20}>
                                    20
                                    {currentTable.multilingual['insert-tree-table-004']}/
                                    {currentTable.multilingual['insert-tree-table-006']}
                                </NCOption>
                                <NCOption value={50}>
                                    50
                                    {currentTable.multilingual['insert-tree-table-004']}/
                                    {currentTable.multilingual['insert-tree-table-006']}
                                </NCOption>
                                <NCOption value={100}>
                                    100
                                    {currentTable.multilingual['insert-tree-table-004']}/
                                    {currentTable.multilingual['insert-tree-table-006']}
                                </NCOption>
                            </NCSelect>
                            {!!+pageInfo.total && (
                                <span className="NC_total">
                                    {' '}
                                    {currentTable.multilingual['insert-tree-table-005']}{' '}
                                    <span>{pageInfo.total}{' '}</span>
                                    {currentTable.multilingual['insert-tree-table-004']}{' '}
                                </span>
                            )}
                        </div>
                    </div>
                ) : null}
                {headPaginationInfo && pageInfo ? (
                    <Pagination
                        // className="Pagination"
                        prev
                        next
                        boundaryLinks
                        items={pageInfo.totalPage ? Number(pageInfo.totalPage) : 1} //总页数
                        maxButtons={5} //显示最多页数按钮
                        activePage={Number(pageIndex)}
                        onSelect={pageIndexChanges.bind(this)}
                    />
                ) : (
                    ''
                )}
            </Col>
        </div>
    );
}
/**
 * 列设置后更新模板
 * @param {*Array} newColumn 列设置后的模板
 * @param {*} areaCode 表格的区域id
 */
// export function updateColumnAfterSetting(newColumn, areaCode) {
//   let { meta } = this.state;
//   if (newColumn.length && areaCode) {
//     let finalColumn = [...meta[areaCode].items];

//     let sortIndexArr = [];
//     let sortObjArr = [];
//     newColumn.map(newItem => {
//       meta[areaCode].items.map((oldItem, index) => {
//         if (newItem.attrcode === oldItem.attrcode) {
//           oldItem.width = newItem.width;
//           oldItem.islock = newItem.islock;
//           oldItem.visible = newItem.visible;
//           sortIndexArr.push(index);
//           sortObjArr.push(oldItem);
//         };
//       });
//     });
//     sortIndexArr.sort((a, b) => a - b);
//     sortIndexArr.map((item, index) => {
//       finalColumn[item] = sortObjArr[index];
//     });
//     meta[areaCode].items = finalColumn;
//     this.setState({ meta });
//   }
// }

/**
 * 更新跨服务按钮状态
 * @param {*string} areaId 按钮区域id
 * @param {*object} data 配置参数
 * data示例：{
 *   isError: true //显示重试、回退；否则显示浏览器按钮
 * } 
 */
export function toggleErrorStatus(areaId, data) {
    if (!this.myCrossServiceBtn[areaId]) { 
        return
    }
    let btnInstance = this.myCrossServiceBtn[areaId];
  
    let newState = { ...btnInstance.state, ...data };
    btnInstance.setState({
        isError: newState.isError,
        tipContent: newState.tipContent,
    })
}
export function setBillHeadInfoVisible(data) {
    if (this.myBillInfo && this.myBillInfo.state && data) {
        //控制返回按钮
        if (data.hasOwnProperty('showBackBtn')) {
            if (data.showBackBtn != this.myBillInfo.state.showBackBtn) {
                this.myBillInfo.state.showBackBtn = data.showBackBtn;
            }
        }
        //控制单据编号
        if (data.hasOwnProperty('showBillCode')) {
            if (data.showBillCode != this.myBillInfo.state.showBillCode) {
                this.myBillInfo.state.showBillCode = data.showBillCode;
            }
        }

        //修改单据编号
        if (data.hasOwnProperty('billCode')) {
            if (data.billCode != this.myBillInfo.state.billCode) {
                this.myBillInfo.state.billCode = data.billCode;
            }
        }

          //修改标题
          if (data.hasOwnProperty('title')) {
            if (data.title != this.myBillInfo.state.title) {
                this.myBillInfo.state.title = data.title;
            }
        }

        this.myBillInfo.setState(
            this.myBillInfo.state
        )

    }
}
/*
 * @Author: summer
 * @Date: 2019-08-07 13:32:42
 * @LastEditTime: 2020-08-04 13:55:37
 * @LastEditors: wanglongx
 * @Description: 
 */
/*
*   查询区高级查询弹出框组件  --wangshhj
* */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Button as NCButton, HotKeys as NCHotKeys, Dnd as NCDnd } from '@platform/base';
import SearchAdvCom from './SearchAdvCom';
import { getSysFieldid } from '@platform/api';

require('./searchAdvModal.less');

export default class SearchAdvModal extends Component {
	constructor(props) {
		super(props);
	}

	state = { position: null }

	closeMadol = (e) => {
		if (e.keyCode == 27 && this.props.searchInfo.showAdvModal) {
			this.cancelClick()
		}
	}
	componentDidMount() {
		document.body.addEventListener("keydown", this.closeMadol)
	}
	componentWillUnmount() {
		document.body.removeEventListener("keydown", this.closeMadol)
	}
	componentWillReceiveProps(props) { }

	getFunction(savePlanFun, queryFun, cancelClick, addPlanFun) {
		// this.savePlanFun = savePlanFun;
		// this.queryFun = queryFun;
		// this.cancelClick = cancelClick;
		// this.addPlanFun = addPlanFun;
	}

	// 从新回到原来位置
	backPosition = () => {
		this.setState({ position: { x: 0, y: 0 } });
	}

	handStart = (a, b) => {
		this.setState({ position: { x: b.x, y: b.y } });
	}

	handleStop = (a, b) => {
		this.setState({ position: { x: b.x, y: b.y } });
	}
	getChildrenRef = ref => {
		if (ref) {
			this.savePlanFun = ref.saveSearchPlanClick;
			this.queryFun = ref.searchBtnClickEve;
			this.cancelClick = ref.cancelClick;
			this.addPlanFun = ref.addSearchPlanClick;
		}
	}

	render() {
		let { multiLang, searchModalTitle, fieldid } = this.props;
		// 业务组传入  title 
		let title = searchModalTitle || multiLang['page-search-0025'];
		return ReactDOM.createPortal(
			<div className={`NC_searchAdvModalBox ${this.props.className} search_${this.props.moduleId}`}>
				{/* <NCHotKeys
					keyMap={{ colse: NCHotKeys.USUAL_KEYS.ICON_CLOSE }}
					handlers={{
						colse: this.props.searchInfo.status === 'normal' ? () => {
							this.cancelClick()
						} : function () { }
					}}
					className="close-hotkeys-wrapper"
					focused={true}
					display="inline"
				/> */}
				<div className="NC_searchAdvModalBackground">
					<div className="NC_searchAdvModal" fieldid={getSysFieldid(fieldid) ? `${fieldid}-search_modal-area` : "search_modal-area"}>
						<NCDnd
							handle=".super-handle"
							cancel=".super-cancel"
							position={this.state.position}
							// onStart = {this.handStart}
							onStop={this.handleStop}
							bounds={`.search_${this.props.moduleId}`}
						>
							<div className="NC_searchAdvModal_drag">
								<div className="modalHead super-handle" fieldid={getSysFieldid('header-area')}>
									<img src={require("../../static/images/modal-img.png")} className="modal-header-img" />
									<span fieldid={getSysFieldid(`${title}_title`)} className="modalTitle">{title}</span>
									<span className="closeBtn iconfont icon-guanbi super-cancel" onClick={() => {
										this.cancelClick();
									}} title="Esc" />
								</div>
								<SearchAdvCom ref={this.getChildrenRef} {...this.props} backPosition={this.backPosition} />
								<div className="modalFooter" fieldid={getSysFieldid('bottom-area')}>
									{this.props.showAdvSearchPlanBtn && (
										<div className="saveSearchPlan">
											<NCButton
												onClick={() => { this.savePlanFun() }}
												shape="border"
												//colors="danger"
												className="secondary-button secondary-button-wrapper footer-button-left"
												fieldid="savePlan"
											>
												{multiLang['page-search-0008']}
											</NCButton>
											<NCButton
												onClick={() => { this.addPlanFun() }}
												shape="border"
												//colors="danger"//
												className="secondary-button secondary-button-wrapper footer-button-right"
												fieldid="addPlan"
											>
												{multiLang['page-search-0081']}
											</NCButton>
										</div>
									)}
									<div className='searchBtnGroup'>
										<div className="searchBtn search-btn-wrapper">
											<NCButton onClick={() => { this.queryFun() }} colors="danger" fieldid="search" className="button-primary button-primary-wrapper ">
												{this.props.searchBtnName || multiLang['page-search-0001']}
											</NCButton>
										</div>
										<div className="cancelBtn search-btn-wrapper">
											<NCButton onClick={() => {
												this.cancelClick();
											}} shape="border" fieldid="cancel" className="secondary-button secondary-button-wrapper">
												{multiLang['page-search-0010']}
											</NCButton>
										</div>
									</div>
								</div>
							</div>
						</NCDnd>
					</div>
				</div>
			</div>,
			document.body
		);
	}
}

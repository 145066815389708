/**
 * Created by wangshhj on 2018/1/16.
 */
import { createInsertTable } from './InsertTable';
import {
    getInsertTableValue,
    setInsertTableValue,
    setChildTableData,
    getInsertTableSelectedValue,
    getPageInfo, getCheckedRows,
    deleteTableRowsByIndex,
    updateDataByRefresh,
    changeCheck,
} from './methods';

export default {
    createInsertTable,
    getInsertTableValue,
    setInsertTableValue,
    setChildTableData,
    getInsertTableSelectedValue,
    getPageInfo,
    getCheckedRows,
    deleteTableRowsByIndex,
    updateDataByRefresh,
    changeCheck,
};
// 心跳检测  暂时不需要使用 send方法socket服务端不支持
export default class HeartCheck {
    constructor(props = {}) {
        this.props = props;
        this.count = props.count || 3;
        this.timeout = props.timeout || 3000;
    }

    start() {
        let { running } = this.props;
        console.log(this.timeout);
        this.timeoutObj = setInterval(() => {
            this.count--;
            running && running(this);
        }, this.timeout);
    }

    restart() {
        this.count = 3;
        this.stop();
        this.start();
    }

    stop() {
        clearInterval(this.timeoutObj);
    }

    destory() {
        // TODO
    }
}

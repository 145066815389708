import React, { Component } from 'react';
import classNames from 'classnames';
import {
    ButtonGroup as ButtonGroup,
    Button as Button,
    Upload as Upload,
} from '@platform/base';
import MyDropdown from '../MyDropDown';

import HelperTip from '../HelperTip';

require('./index.less');

// const IS_TEST_ENV = NODE_ENV === 'test' ? false : true;
// querySelector  不能識別數字開頭 不能識別特殊字符 ·/·
const replaceSpecialStr = function (str) {
    return str.replace(/\/|\\|,/g, '') || str;
};
export default class MyButtonGroup extends Component {

    /**
     * 获取快捷键提示方式 样式
     */
    getTipKeybodard = (item) => {
        let { tipKeybodard } = this.props;

        if (tipKeybodard === 'underline' && item.keyboard && item.disabled !== true) {
            // 现在只针对单键 以后可能会变成多键
            let kbs = item.keyboard.split('+');
            return [item.title, '(', <span className="text-decoration-underline">{kbs ? kbs[kbs.length - 1].toUpperCase() : item.keyboard}</span>, ')'];
        } else {
            return item.title;
        }
    };

    // data: 渲染 buttongroup 的数据结构
    renderButtonGroup = data => {
        let { area, tabindex = -1 } = this.props;
        let visibleData = data.filter(item => item.visible !== false);

        return visibleData.map(item => {
            switch (item.type) {
                case 'general_btn':
                    let className =
                        item.btncolor == 'button_main'
                            ? 'button-primary'
                            : null;
                    let Btn = (
                        <HelperTip
                            title={item.title}
                            keyboard={item.keyboard}
                            disabled={item.disabled}
                            delayShow={500}
                        >
                            <Button
                                key={item.key}
                                disabled={item.disabled}
                                className={className}
                                onClick={() => this.props.onClick({
                                    key: item.key,
                                    title: item.title
                                })
                                }
                                tabindex={tabindex}
                                fieldid={item.key}
                                btn-code={item.key}
                            >
                                {this.getTipKeybodard(item)}
                            </Button>
                        </HelperTip>
                    );
                    return item.uploadConfig ? (
                        <Upload
                            showUploadList={false}
                            {...item.uploadConfig}
                            className={replaceSpecialStr(item.parentCode ? ` nc-${item.area}-btn-${item.parentCode}-${item.key} ` : ` nc-${item.area}-btn-${item.key} `)}
                        >
                            {Btn}
                        </Upload>
                    ) : (
                            Btn
                        );
                case 'dropdown':
                    return (
                        <MyDropdown
                            list={item.children}
                            title={item.title}
                            area={area}
                            btncolor={item.btncolor}
                            disabled={item.disabled}
                            dropdownType={item.dropdownType}
                            popContainer={this.props.popContainer}
                            onClick={this.props.onClick}
                            tabindex={tabindex}
                            clskey={item.key}
                            className={classNames(
                                ` nc-${item.area}-${item.key}-panel fieldid_${item.key}_btn_list`
                            )}
                        />
                    );
            }
        });
    };

    getButtonStatus = list => {
        let bool = [];

        if (list && list.length) {
            for (let item of list) {
                if (item.visible === false) {
                    bool.push(item);
                }
            }
        }

        return bool.length === list.length;
    };

    render() {
        let bool = this.getButtonStatus(this.props.list);
        let data = this.props.list;
        let visibleData = data.filter(item => item.visible !== false);

        return (
            <div
                //className='buttonGroup-component '
                className={classNames({
                    'buttonGroup-component': true,
                    'singleGroup': visibleData && visibleData.length && visibleData.length === 1
                })}
                style={{ marginLeft: bool ? 0 : '6px' }}
            >
                <ButtonGroup popContainer={this.props.popContainer}>
                    {this.renderButtonGroup(
                        data,
                        this.props.buttonLimit
                    )}
                </ButtonGroup>
            </div>
        );
    }
}

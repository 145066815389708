/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-04 14:17:53
 * @Description: file content
 */
import React, { Component } from 'react';
import { Tooltip } from '@platform/base';


export default class BtnItemTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipStatus: false,
        };
        this.tipConfirm = false;
        this.dom = React.createRef();
    }

    componentDidMount() {
        //this.itemDom.clientWidth<this.itemDom.scrollWidth
        // 初始化是否显示tip
        if (this.dom) {
            let tipItem = this.dom.querySelector('.btn-item-left');
            if (tipItem && tipItem.clientWidth < tipItem.scrollWidth) {
                this.tipConfirm = true;
                this.setState({ tipStatus: true });
            }
        }
    }

    componentDidUpdate() {
        if (this.dom && !this.tipConfirm && this.props.wrapperVisible) {
            let tipItem = this.dom.querySelector('.btn-item-left');
            if (tipItem && tipItem.clientWidth < tipItem.scrollWidth) {
                this.tipConfirm = true;
                this.setState({ tipStatus: true });
            }
        }
    }

    render() {
        return (
            <Tooltip
                overlay={this.state.tipStatus ? this.props.overlay : ''}
                inverse
                delayShow={500}
                delay={1}
            >
                <span ref={
                    dom => {
                        this.dom = dom;
                    }
                }
                >
                    {this.props.children}
                </span>
            </Tooltip>
        );
    }
}

/**
 * 快捷键描述的静态数据 维护老麻烦了
 */

let langJson = {};

function getLangJson(code) {
    return langJson[code] || "";
}

function getData(type) {
    let data = [];
    switch (type) {
        case "grid":
            data = [
                {
                    name: "Tab",
                    desc: getLangJson("hk-0012")
                },
                {
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0013")
                },
                {
                    name: "↑",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "↓",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: "Enter",
                    desc: getLangJson("hk-0014")
                },
                {
                    name: "Esc",
                    desc: getLangJson("hk-0023")
                },
                {
                    name: "Alt+Y",
                    desc: getLangJson("hk-0015")
                },
                {
                    name: "Alt+N",
                    desc: getLangJson("hk-0016")
                },
                {
                    name: "Fn+↑、Pageup",
                    desc: getLangJson("hk-0017")
                },
                {
                    name: "Fn+↓、Pagedown",
                    desc: getLangJson("hk-0018")
                },
                {
                    name: "Fn+←",
                    desc: getLangJson("hk-0019")
                },
                {
                    name: "Fn+→",
                    desc: getLangJson("hk-0020")
                },
                {
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0021")
                },
                {
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0022")
                }
            ];
            break;
        case "gridMulti":
            data = [
                {
                    name: "Tab",
                    desc: getLangJson("hk-0012")
                },
                {
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0013")
                },
                {
                    name: "↑",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "↓",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: getLangJson("hk-0025"),
                    desc: getLangJson("hk-0024")
                },
                {
                    name: "Enter",
                    desc: getLangJson("hk-0014")
                },
                {
                    name: "Esc",
                    desc: getLangJson("hk-0023")
                },
                {
                    name: "Alt+Y",
                    desc: getLangJson("hk-0015")
                },
                {
                    name: "Alt+N",
                    desc: getLangJson("hk-0016")
                },
                {
                    name: "Fn+↑、Pageup",
                    desc: getLangJson("hk-0017")
                },
                {
                    name: "Fn+↓、Pagedown",
                    desc: getLangJson("hk-0018")
                },
                {
                    name: "Fn+←",
                    desc: getLangJson("hk-0019")
                },
                {
                    name: "Fn+→",
                    desc: getLangJson("hk-0020")
                },
                {
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0021")
                },
                {
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0022")
                }
            ];
            break;
        case "tree":
            data = [
                {
                    name: "Tab",
                    desc: getLangJson("hk-0012")
                },
                {
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0013")
                },
                {
                    name: "↑",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "↓",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: "←",
                    desc: getLangJson("hk-0026")
                },
                {
                    name: "→",
                    desc: getLangJson("hk-0027")
                },
                {
                    name: "Enter",
                    desc: getLangJson("hk-0014")
                },
                {
                    name: "Esc",
                    desc: getLangJson("hk-0023")
                },
                {
                    name: "Alt+Y",
                    desc: getLangJson("hk-0015")
                },
                {
                    name: "Alt+N",
                    desc: getLangJson("hk-0016")
                },
                {
                    name: "Fn+↑、Pageup",
                    desc: getLangJson("hk-0017")
                },
                {
                    name: "Fn+↓、Pagedown",
                    desc: getLangJson("hk-0018")
                },
                {
                    name: "Fn+←",
                    desc: getLangJson("hk-0019")
                },
                {
                    name: "Fn+→",
                    desc: getLangJson("hk-0020")
                },
                {
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0021")
                },
                {
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0022")
                }
            ];
            break;
        case "treeMulti":
            data = [
                {
                    name: "Tab",
                    desc: getLangJson("hk-0012")
                },
                {
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0013")
                },
                {
                    name: "↑",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "↓",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: "←",
                    desc: getLangJson("hk-0026")
                },
                {
                    name: "→",
                    desc: getLangJson("hk-0027")
                },
                {
                    name: getLangJson("hk-0025"),
                    desc: getLangJson("hk-0024")
                },
                {
                    name: "Enter",
                    desc: getLangJson("hk-0014")
                },
                {
                    name: "Esc",
                    desc: getLangJson("hk-0023")
                },
                {
                    name: "Alt+Y",
                    desc: getLangJson("hk-0015")
                },
                {
                    name: "Alt+N",
                    desc: getLangJson("hk-0016")
                },
                {
                    name: "Fn+↑、Pageup",
                    desc: getLangJson("hk-0017")
                },
                {
                    name: "Fn+↓、Pagedown",
                    desc: getLangJson("hk-0018")
                },
                {
                    name: "Fn+←",
                    desc: getLangJson("hk-0019")
                },
                {
                    name: "Fn+→",
                    desc: getLangJson("hk-0020")
                },
                {
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0021")
                },
                {
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0022")
                }
            ];
            break;
        case "gridTree":
            data = [
                {
                    name: "Tab",
                    desc: getLangJson("hk-0012")
                },
                {
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0013")
                },
                {
                    name: "↑",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "↓",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: "←",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "→",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: "Shift+←",
                    desc: getLangJson("hk-0028")
                },
                {
                    name: "Shift+→",
                    desc: getLangJson("hk-0029")
                },
                {
                    name: "Enter",
                    desc: getLangJson("hk-0014")
                },
                {
                    name: "Esc",
                    desc: getLangJson("hk-0023")
                },
                {
                    name: "Alt+Y",
                    desc: getLangJson("hk-0015")
                },
                {
                    name: "Alt+N",
                    desc: getLangJson("hk-0016")
                },
                {
                    name: "Fn+↑、Pageup",
                    desc: getLangJson("hk-0017")
                },
                {
                    name: "Fn+↓、Pagedown",
                    desc: getLangJson("hk-0018")
                },
                {
                    name: "Fn+←",
                    desc: getLangJson("hk-0019")
                },
                {
                    name: "Fn+→",
                    desc: getLangJson("hk-0020")
                },
                {
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0021")
                },
                {
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0022")
                }
            ];
            break;
        case "gridTreeMulti":
            data = [
                {
                    name: "Tab",
                    desc: getLangJson("hk-0012")
                },
                {
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0013")
                },
                {
                    name: "↑",
                    desc: getLangJson("hk-0010")
                },
                {
                    name: "↓",
                    desc: getLangJson("hk-0011")
                },
                {
                    name: "←",
                    desc: getLangJson("hk-0026")
                },
                {
                    name: "→",
                    desc: getLangJson("hk-0027")
                },
                {
                    name: "Shift+←",
                    desc: getLangJson("hk-0028")
                },
                {
                    name: "Shift+→",
                    desc: getLangJson("hk-0029")
                },
                {
                    name: getLangJson("hk-0025"),
                    desc: getLangJson("hk-0024")
                },
                {
                    name: "Enter",
                    desc: getLangJson("hk-0014")
                },
                {
                    name: "Esc",
                    desc: getLangJson("hk-0023")
                },
                {
                    name: "Alt+Y",
                    desc: getLangJson("hk-0015")
                },
                {
                    name: "Alt+N",
                    desc: getLangJson("hk-0016")
                },
                {
                    name: "Fn+↑、Pageup",
                    desc: getLangJson("hk-0017")
                },
                {
                    name: "Fn+↓、Pagedown",
                    desc: getLangJson("hk-0018")
                },
                {
                    name: "Fn+←",
                    desc: getLangJson("hk-0019")
                },
                {
                    name: "Fn+→",
                    desc: getLangJson("hk-0020")
                },
                {
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0021")
                },
                {
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0022")
                }
            ];
            break;
        case "view":
            data = [
                {
                    area: getLangJson("hk-0032"),
                    name: "Ctrl+/",
                    desc: getLangJson("hk-0037")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Ctrl+/",
                    desc: getLangJson("hk-0038")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+P",
                    desc: getLangJson("hk-0039")
                },
                {
                    area: getLangJson("hk-0032"),
                    // name: "Ctrl+Del",
                    name: "Ctrl+Backspace",
                    desc: getLangJson("hk-0040")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+C",
                    desc: getLangJson("hk-0041")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+B",
                    desc: getLangJson("hk-0042")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Ctrl+B",
                    desc: getLangJson("hk-0043")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+R",
                    desc: getLangJson("hk-0044")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Esc",
                    desc: getLangJson("hk-0045")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+Pageup",
                    desc: getLangJson("hk-0046")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+Pagedown",
                    desc: getLangJson("hk-0047")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "F5",
                    desc: getLangJson("hk-0048")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: getLangJson("hk-0051"),
                    desc: getLangJson("hk-0049")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: getLangJson("hk-0051"),
                    desc: getLangJson("hk-0050")
                },
                // --2
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+I",
                    desc: getLangJson("hk-0052")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+I",
                    desc: getLangJson("hk-0053")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+U",
                    desc: getLangJson("hk-0054")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+U",
                    desc: getLangJson("hk-0055")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+G",
                    desc: getLangJson("hk-0056")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+G",
                    desc: getLangJson("hk-0057")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+J",
                    desc: getLangJson("hk-0058")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+J",
                    desc: getLangJson("hk-0059")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+J",
                    desc: getLangJson("hk-0060")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+J",
                    desc: getLangJson("hk-0061")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+Alt+E",
                    desc: getLangJson("hk-0062")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+Alt+C",
                    desc: getLangJson("hk-0063")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+P",
                    desc: getLangJson("hk-0064")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Ctrl+K",
                    desc: getLangJson("hk-0065")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: getLangJson("hk-0051"),
                    desc: getLangJson("hk-0066")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: getLangJson("hk-0051"),
                    desc: getLangJson("hk-0067")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+O",
                    desc: getLangJson("hk-0068")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+D",
                    desc: getLangJson("hk-0069")
                },
                {
                    area: getLangJson("hk-0035"),
                    name: "Alt+L",
                    desc: getLangJson("hk-0070")
                },

                // -3

                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+Tab",
                    desc: getLangJson("hk-0071")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+Shift+Tab",
                    desc: getLangJson("hk-0072")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+W",
                    desc: getLangJson("hk-0073")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+R",
                    desc: getLangJson("hk-0074")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: getLangJson("hk-0025"),
                    desc: getLangJson("hk-0075")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Home",
                    desc: getLangJson("hk-0076")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "End",
                    desc: getLangJson("hk-0077")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl++",
                    desc: getLangJson("hk-0078")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+-",
                    desc: getLangJson("hk-0079")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+0",
                    desc: getLangJson("hk-0080")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+F",
                    desc: getLangJson("hk-0081")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+←",
                    desc: getLangJson("hk-0082")
                },
                {
                    area: getLangJson("hk-0036"),
                    name: "Ctrl+→",
                    desc: getLangJson("hk-0083")
                }
            ];
            break;
        case "edit":
        default:
            data = [
                // - 1
                {
                    area: getLangJson("hk-0030"),
                    name: "Tab",
                    desc: getLangJson("hk-0084")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Shift+Tab",
                    desc: getLangJson("hk-0085")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Esc",
                    desc: getLangJson("hk-0086")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "←",
                    desc: getLangJson("hk-0087")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "→",
                    desc: getLangJson("hk-0088")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "↑",
                    desc: getLangJson("hk-0089")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "↓",
                    desc: getLangJson("hk-0090")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "F3",
                    desc: getLangJson("hk-0091")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "F2",
                    desc: getLangJson("hk-0092")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Enter",
                    desc: getLangJson("hk-0093")
                },
                {
                    area: getLangJson("hk-0030"),
                    // name: "Del",
                    name: "Backspace",
                    desc: getLangJson("hk-0094")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Pageup",
                    desc: getLangJson("hk-0095")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Pagedown",
                    desc: getLangJson("hk-0096")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Alt+←",
                    desc: getLangJson("hk-0097")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Alt+→",
                    desc: getLangJson("hk-0098")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Alt+↑",
                    desc: getLangJson("hk-0095")
                },
                {
                    area: getLangJson("hk-0030"),
                    name: "Alt+↓",
                    desc: getLangJson("hk-0096")
                },

                // - 2
                {
                    area: getLangJson("hk-0031"),
                    name: "Alt+N",
                    desc: getLangJson("hk-0099")
                },
                {
                    area: getLangJson("hk-0031"),
                    // name: "Alt+Del",
                    name: "Alt+Backspace",
                    desc: getLangJson("hk-0100")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "Alt+I",
                    desc: getLangJson("hk-0101")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "Alt+C",
                    desc: getLangJson("hk-0102")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "Alt+V",
                    desc: getLangJson("hk-0103")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "Ctrl+Alt+V",
                    desc: getLangJson("hk-0104")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "F4",
                    desc: getLangJson("hk-0105")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: getLangJson("hk-0051"),
                    desc: getLangJson("hk-0106")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "Alt+P",
                    desc: getLangJson("hk-0107")
                },

                {
                    area: getLangJson("hk-0031"),
                    name: "Alt+R",
                    desc: getLangJson("hk-0108")
                },

                // - 3
                {
                    area: getLangJson("hk-0032"),
                    name: "F3",
                    desc: getLangJson("hk-0109")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Ctrl+S",
                    desc: getLangJson("hk-0110")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+S",
                    desc: getLangJson("hk-0111")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+T",
                    desc: getLangJson("hk-0112")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Alt+Q",
                    desc: getLangJson("hk-0113")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Ctrl+Alt+S",
                    desc: getLangJson("hk-0114")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Shift+←",
                    desc: getLangJson("hk-0115")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Shift+→",
                    desc: getLangJson("hk-0116")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Shift+↑",
                    desc: getLangJson("hk-0117")
                },
                {
                    area: getLangJson("hk-0032"),
                    name: "Shift+↓",
                    desc: getLangJson("hk-0118")
                },

                // - 4
                {
                    area: getLangJson("hk-0033"),
                    name: "Alt+Y",
                    desc: getLangJson("hk-0119")
                },
                {
                    area: getLangJson("hk-0033"),
                    name: "Alt+N",
                    desc: getLangJson("hk-0120")
                },
                {
                    area: getLangJson("hk-0033"),
                    name: "Enter",
                    desc: getLangJson("hk-0121")
                },
                {
                    area: getLangJson("hk-0033"),
                    name: "Esc",
                    desc: getLangJson("hk-0122")
                }
            ];
            break;
    }
    return data;
}

export let staticData = {
    setLangJson(json) {
        langJson = json || [];
    },
    getData: function(type, multi) {
        if (type == "tree" && multi) {
            type = "treeMulti";
        }
        if (type == "grid" && multi) {
            type = "gridMulti";
        }
        if (type == "gridTree" && multi) {
            type = "gridTreeMulti";
        }
        return getData(type);
    }
};

/**
 * 公式编辑器  zhanghengh
 * 2014/4
 * 主要用于资产、财务、供应链等
 *
 */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import MetaAttr from './components/metaattr';
import Surface from './components/surface';
import {
    Button,
    Row,
    Col,
    Tabs,
    TextArea,
    Modal,
} from '@platform/base';
import { isFunction, isString, ajax, toast, getMultiLang } from '@platform/api';

const { TabPane } = Tabs;
const { Header, Title, Body } = Modal;

require('./index.less');

export default class FormulaEditor extends Component {
    constructor(props) {
        super(props);
        // textarea 中的文本是否被全选
        this.selected = false;
        this.state = {
            formulaData: [], //公式的数据
            initValue: '',
            showData: '', //展示出来的数据,
            isShow: false, //控制model展示隐藏
            explainData: '', //解释数据
            optBtnsConfig: [
                //选项按钮配置
                { name: 7 },
                { name: 8 },
                { name: 9 },
                { name: 0 },
                { name: 4 },
                { name: 5 },
                { name: 6 },
                { name: '.' },
                { name: 1 },
                { name: 2 },
                { name: 3 },
                { name: '00' },
            ],
            computedBtnconfig: [
                { name: '+' },
                { name: '-' },
                { name: '*' },
                { name: '/' },
                { name: '->' },
                { name: '==' },
                { name: '(' },
                { name: ')' },
                { name: '<' },
                { name: '>' },
                { name: '<=' },
                { name: '>=' },
            ],
            json: {},
        };
    }

    async componentWillMount() {
        // 初始化调用getPlatformLang方法获取多语
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData });
        };
        await getMultiLang({ moduleId: 'container_formulaEditor', callback }); // moduleId为所需加载json文件夹名称前缀
        const { value = '', show = false, noShowFormula, formulaUrl } = this.props;
        let { json } = this.state;
        this.setShow(show); // 设置modal展示和隐藏
        this.handleTextAreaChange(value); // 设置编辑器初始值
        (Array.isArray(noShowFormula) && noShowFormula.length === 1 && noShowFormula[0] === 'all') ||
            ajax({
                url: isString(formulaUrl) ? formulaUrl : '/nccloud/formula/web/formulatype.do',
                loading: false,
                success: res => {
                    this.setState({ formulaData: res.data });
                },
                error: () => {
                    toast({ color: 'danger', content: `${json['formula0012']}` });
                },
            });
    }

    componentWillReceiveProps(nextProps) {
        const { value = '', show = false, noShowFormula, formulaUrl } = nextProps; //最新的props
        const { formulaUrl: oldFormulaUrl } = this.props; //上一次的props
        let { json } = this.state;
        if (this.state.initValue !== value) {
            this.handleTextAreaChange(value); // 设置编辑器初始值
        }
        // textarea 中的文本全选状态变成false
        this.selected && (this.selected = false);
        this.setShow(show); // 设置modal展示和隐藏
        if (formulaUrl !== oldFormulaUrl) {
            ////当传入的路径变化时才重新请求，做到优化作用
            (Array.isArray(noShowFormula) && noShowFormula.length === 1 && noShowFormula[0] === 'all') ||
                ajax({
                    url: isString(formulaUrl) ? formulaUrl : '/nccloud/formula/web/formulatype.do',
                    loading: false,
                    success: res => {
                        this.setState({ formulaData: res.data });
                    },
                    error: () => {
                        toast({ color: 'danger', content: `${json['formula0012']}` });
                    },
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //打开默认就获取焦点
        const { isShow: prevIsShow } = prevState;
        const { isShow } = this.state;
        if (this.textArea && isShow !== prevIsShow) {
            let textArea = this.textArea.querySelectorAll('textarea')[0];
            if (textArea) {
                this.setCursorPosition(textArea, textArea.value.length); // 兼容其他火狐浏览器,火狐浏览器默认光标在第一位，而chrome默认在最后一位
            }
        }
    }

    getControlBtnsConfig = () => {
        let { json } = this.state;
        return [
            //操作按钮配置
            { key: 'ok', name: json['formula003'], onClick: this.handleOk },
            { key: 'cancel', name: json['formula004'], onClick: this.handleCancel },
            {
                key: 'validate',
                name: json['formula005'],
                onClick: this.handleValidate,
            },
            {
                key: 'checkAll',
                name: json['formula006'],
                onClick: this.handleCheckAll,
            },
            { key: 'clear', name: json['formula007'], onClick: this.handleClear },
        ];
    };

    /**
     * 事件回调区域
     * ================================================
     */
    /**add by zhanghengh
     * TextArea change callback
     * value 文本域内容
     * e 事件对象
     */
    handleTextAreaChange = (value, initValue) => {
        this.setState({ showData: value, initValue: initValue || this.state.initValue });
    };

    /**add by zhanghengh
     * 向文本域设置选中值的方法
     * e | 事件对象
     */
    handelSetName = e => {
        const { name } = e.target.dataset;
        this.handlePushStr(name);
    };

    /**add by zhanghengh
     * 点击tabitem的回调
     * e | 事件对象
     */
    handleClickTabItem = e => {
        const { explain } = e.target.dataset;
        this.setExplain(explain);
    };

    /**add by zhanghengh
     * 点击清除按钮回调
     */
    handleClear = () => {
        this.setState({ showData: '' });
        this.textArea.querySelectorAll('textarea')[0].focus();
    };

    /**add by zhanghengh
     * 点击全选回调
     */
    handleCheckAll = () => {
        // 全选的时候将状态设置成true
        this.selected = true;
        this.textArea.querySelectorAll('textarea')[0].select(); //实现全选
    };

    /**add by zhanghengh
     * 点击验证回调
     */
    handleValidate = () => {
        const { showData, json } = this.state;
        const { validateUrl, validateData = {} } = this.props;
        ajax({
            url: isString(validateUrl) ? validateUrl : '/nccloud/formula/web/formulaverfify.do',
            loading: false,
            data: { formulastr: showData, ...validateData },
            success: res => {
                if (res.data.status === 'Y') {
                    toast({ color: 'success', content: `${json['formula0013']}` });
                } else if (res.data.status === 'N') {
                    toast({
                        color: 'danger',
                        content: res.data.error ? res.data.error : `${json['formula0014']}`,
                    });
                }
            },
            error: () => {
                toast({ color: 'danger', content: `${json['formula0015']}` });
            },
        });
    };

    /**add by zhanghengh
     * 点击取消回调
     */
    handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel && isFunction(onCancel)) {
            onCancel();
        }
    };

    /**add by zhanghengh
     * 设置公式编辑器的显示和隐藏
     */
    setShow = value => {
        this.setState({ isShow: value });
    };

    /**
     * zhanghengh
     *设置需要展示的解释字段方法
     */
    setExplain = data => {
        this.setState({ explainData: data });
    };

    /**add by zhanghengh
     * 点击确认回调
     */
    handleOk = () => {
        const { showData, json } = this.state;
        const { onOk, isValidateOnOK = true, validateUrl, validateData = {} } = this.props;
        if (isValidateOnOK) {
            //判断确定的时候是否需要校验
            ajax({
                url: isString(validateUrl) ? validateUrl : '/nccloud/formula/web/formulaverfify.do',
                loading: false,
                data: { formulastr: showData, ...validateData },
                success: res => {
                    if (res.data.status === 'Y') {
                        if (onOk && isFunction(onOk)) {
                            onOk(showData);
                        }
                    } else if (res.data.status === 'N') {
                        toast({
                            color: 'danger',
                            content: res.data.error ? res.data.error : `${json['formula0014']}`,
                        });
                    }
                },
                error: () => {
                    toast({ color: 'danger', content: `${json['formula0015']}` });
                },
            });
        } else {
            if (onOk && isFunction(onOk)) {
                onOk(showData);
            }
        }
    };

    /**add by zhanghengh
     * 在光标位置插入文本，插入文本后光标位置不变
     * @param {*} btnContent //按钮的内容
     */
    handlePushStr = btnContent => {
        let textArea = this.textArea.querySelectorAll('textarea')[0];
        if (this.selected) {
            // 全选后,在选择的值,直接覆盖之前的值解决  CLOUD-166749 这个bug, 财务 杨龙,和测试周崚 让改
            this.selected = false;
            this.setState({ showData: btnContent }, () => {
                this.setCursorPosition(textArea, btnContent.length);
            });
        } else {
            let textAreaLen = textArea.value.length;
            let textAreaValue = textArea.value;
            let selectionStart = textArea.selectionStart;
            let finalRange = (textAreaValue.substr(0, selectionStart) + btnContent).length;
            let finalValue =
                textAreaValue.substr(0, selectionStart) + btnContent + textAreaValue.substring(selectionStart, textAreaLen);
            this.setState({ showData: finalValue }, () => {
                this.setCursorPosition(textArea, finalRange);
            });
        }
    };

    /**
     * 设置输入域(input/textarea)光标的位置
     */
    setCursorPosition = (elem, index) => {
        var val = elem.value;
        var len = val.length;
        // 超过文本长度直接返回
        if (len < index) return;
        setTimeout(function () {
            elem.focus();
            if (elem.setSelectionRange) {
                // 标准浏览器
                elem.setSelectionRange(index, index);
            } else {
                // IE9- 这个里面有问题在看
                var range = elem.createTextRange();
                range.moveStart('character', -len);
                range.moveEnd('character', -len);
                range.moveStart('character', index);
                range.moveEnd('character', 0);
                range.select();
            }
        }, 10);
    };
    //=======================================================

    /**
     * 创建内容的函数区域
     * add by zhanghengh
     * 创建操作区
     */
    _createWorkStation = () => {
        let { showData, optBtnsConfig, computedBtnconfig } = this.state;
        const { noControlBtns } = this.props;
        if (Array.isArray(noControlBtns) && noControlBtns.length > 0) {
            //进行操作按钮可配置判断
            noControlBtns.forEach(eve => {
                this.getControlBtnsConfig().filter(item => {
                    const { key } = item;
                    return key !== eve;
                });
            });
        }
        return (
            <Row>
                <Col md={8} xs={8} sm={8}>
                    <div className="editor-submit-area">
                        <TextArea
                            ref={dom => {
                                this.textArea = findDOMNode(dom);
                            }}
                            selfRangControl={false}
                            onBlur={() => {
                                // 全选的时候让textarea里面的文本一直处于全选的状态
                                this.selected && (this.textArea.querySelectorAll('textarea')[0].select());
                            }}
                            value={showData}
                            className="editor-show-area"
                            onChange={this.handleTextAreaChange}
                            onEnter={()=>{}}//为了ctrl+enter换行
                            fieldid="editor"
                            isFormularEditor={true}
                        />
                        <div className="editor-control-btns">{this._createControlBtns(this.getControlBtnsConfig())}</div>
                    </div>
                </Col>
                <Col md={4} xs={4} sm={4}>
                    <div className="centerWrap">
                        <div className="editor-opt-btns">{this._createOptBtns(optBtnsConfig)}</div>
                        <div className="editor-comp-btns">{this._createOptBtns(computedBtnconfig)}</div>
                    </div>
                </Col>
            </Row>
        );
    };

    /**
     * add by zhanghengh
     * 创建标签组
     */
    _createTabs = () => {
        let {
            formulaConfig = [],
            attrConfig = [],
            noShowFormula,
            noShowAttr,
            defaultFormulaKey,
            tabActiveKey,
            defaultAttrKey,
            onTabChange,
        } = this.props;
        let { json } = this.state;
        let defaultFormulaObj = isString(defaultFormulaKey) ? { defaultActiveKey: defaultFormulaKey } : {}; //  公式tab的默认展示tab
        let defaultAttrObj = isString(defaultAttrKey) ? { defaultActiveKey: defaultAttrKey } : {}; //  属性tab的默认展示tab
        let tabActiveKeyObj = isString(tabActiveKey) ? { activeKey: tabActiveKey } : {};
        let { formulaData } = this.state;
        let tabAttrConfig = [];
        if (json['formula008']) {
            tabAttrConfig = [
                //右侧tabPane配置数据
                {
                    tab: `${json['formula008']}`,
                    key: `${json['formula008']}`,
                    TabPaneContent: MetaAttr,
                },
                {
                    tab: `${json['formula009']}`,
                    key: `${json['formula009']}`,
                    TabPaneContent: Surface,
                },
            ];
        }
        let tabFormulaConfig = formulaData.map(eve => {
            //左侧侧tabPane配置数据
            return {
                tab: eve.typeName,
                key: eve.typeName,
                TabPaneContent: this._createFormulaTabPane(eve.name2FormItemMap),
            };
        });
        Array.isArray(attrConfig) && (tabAttrConfig = tabAttrConfig.concat(attrConfig)); //判断右侧tabs配置
        if (Array.isArray(noShowAttr) && noShowAttr.length > 0) {
            //对用户不需要tab属性的进行筛选
            if (noShowAttr.length === 1 && noShowAttr[0] === 'all') {
                //  隐藏所有tab页签
                tabAttrConfig = [];
            } else if (noShowAttr.length === 1 && noShowAttr[0] === 'allBuiltIn') {
                tabAttrConfig = attrConfig;
            } else {
                tabAttrConfig = tabAttrConfig.filter(eve => {
                    let isChange = true;
                    noShowAttr.forEach(attr => {
                        if (attr === eve.key || (eve.key && eve.key.includes(attr))) {
                            // 这是实现有些问题，暂时兼容一下
                            isChange = false;
                        }
                    });
                    return isChange;
                });
            }
        }

        if (Array.isArray(noShowFormula) && noShowFormula.length > 0) {
            //对用户不需要的tab公式进行筛选
            if (noShowFormula.length === 1 && noShowFormula[0] === 'all') {
                //  隐藏所有tab页签
                tabFormulaConfig = [];
                formulaConfig = [];
            } else if (noShowFormula.length === 1 && noShowFormula[0] === 'allBuiltIn') {
                //  隐藏所有内置tab页签
                tabFormulaConfig = [];
            } else {
                tabFormulaConfig = tabFormulaConfig.filter(eve => {
                    //内置的
                    let isChange = true;
                    noShowFormula.forEach(attr => {
                        if (attr === eve.key) {
                            isChange = false;
                        }
                    });
                    return isChange;
                });
                formulaConfig = formulaConfig.filter(eve => {
                    //用户传入的
                    let isChange = true;
                    noShowFormula.forEach(attr => {
                        if (attr === eve.key) {
                            isChange = false;
                        }
                    });
                    return isChange;
                });
            }
        }

        const originalTabs = this._createFormulaTabPanes(tabFormulaConfig); //左侧初始Tabs
        return (
            <div className="detailInfoWrap" style={{ overflow: 'hidden' }}>
                <Row>
                    <Col md={6} xs={6} sm={6}>
                        <div className="tabs-left">
                            <Tabs
                                tabBarStyle="primary"
                                {...defaultFormulaObj}
                                onChange={key => {
                                    isFunction(onTabChange) && onTabChange(key);
                                }}
                            >
                                {this._createFormulaAllTabPanes(originalTabs, formulaConfig)}
                            </Tabs>
                        </div>
                    </Col>
                    <Col md={6} xs={6} sm={6}>
                        <div className="tabs-right">
                            <Tabs
                                tabBarStyle="primary"
                                {...defaultAttrObj}
                                onChange={key => {
                                    isFunction(onTabChange) && onTabChange(key);
                                }}
                                {...tabActiveKeyObj}
                            >
                                {this._createAttrTabPanes(tabAttrConfig)}
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    /**
     * add by zhanghengh
     * 创建左侧每项TabPane
     * 创建左侧TabPanes
     * tabPaneConfig | Array TabPane配置
     */
    _createFormulaTabPane = TabPaneContent => {
        return (
            <ul className="tab-content" onClick={this.handleClickTabItem} onDoubleClick={this.handelSetName}>
                {this._createTabPaneContent(TabPaneContent)}
            </ul>
        );
    };

    /**
     * add by zhanghengh
     * 创建TabPanes
     * tabPaneConfig | Array TabPane配置
     */
    _createFormulaTabPanes = tabPaneConfig => {
        return tabPaneConfig.map(eve => {
            const { TabPaneContent, tab, key } = eve;
            return (
                <TabPane tab={tab} style={{ overflow: 'auto', height: this.state.tabPaneHeight || 200 }} key={key}>
                    {TabPaneContent}
                </TabPane>
            );
        });
    };
    /**
     * add by zhanghengh
     * 创建全部的TabPanes
     * tabPaneConfig | Array TabPane配置
     */
    _createFormulaAllTabPanes = (originalTabs, introTabConfig) => {
        return originalTabs.concat(
            introTabConfig.map(eve => {
                const { TabPaneContent, tab, params = {}, key } = eve;
                return (
                    <TabPane tab={tab} style={{ overflow: 'auto', height: this.state.tabPaneHeight || 200 }} key={key}>
                        <TabPaneContent setExplain={this.setExplain} setName={this.handlePushStr} {...params} />
                    </TabPane>
                );
            }),
        );
    };

    /**
     * add by zhanghengh
     * 创建右侧TabPanes
     * tabPaneConfig | Array TabPane配置
     */
    _createAttrTabPanes = tabPaneConfig => {
        const { metaParam, metaUrl, surfaceUrl } = this.props;
        return tabPaneConfig.map(eve => {
            const { TabPaneContent, tab, params = {}, key } = eve;
            return (
                <TabPane tab={tab} style={{ overflow: 'auto', height: this.state.tabPaneHeight || 200 }} key={key}>
                    <TabPaneContent
                        setExplain={this.setExplain}
                        setName={this.handlePushStr}
                        metaParam={metaParam}
                        metaUrl={metaUrl}
                        surfaceUrl={surfaceUrl}
                        tabPaneHeight={this.state.tabPaneHeight}
                        {...params}
                    />
                </TabPane>
            );
        });
    };

    /**
     * add by zhanghengh
     * 创建TabPaneContent
     * TabPaneContent | Array TabPane配置
     */
    _createTabPaneContent = TabPaneContent => {
        return TabPaneContent.map(eve => {
            const { inputSig, displayName, hintMsg } = eve;
            return (
                <li className="tab-content-item" data-name={inputSig} data-explain={hintMsg}>
                    {displayName}
                </li>
            );
        });
    };

    /**
     * add by zhanghengh
     * 创建选项按钮组
     * btnConfig | Array 按钮配置项数组
     */
    _createOptBtns = btnConfig => {
        return btnConfig.map((eve, index) => {
            const { name } = eve;
            return (
                <Button onClick={this.handelSetName} key={index} data-name={name} className="opt-btn">
                    {name}
                </Button>
            );
        });
    };

    /**
     * add by zhanghengh
     * 创建操作按钮组
     * btnConfig | Array 按钮配置项数组
     */
    _createControlBtns = btnConfig => {
        return btnConfig.map((eve, index) => {
            const { name, onClick, key } = eve;
            return (
                <Button key={index} onClick={onClick} className="opt-btn" fieldid={key}>
                    {name}
                </Button>
            );
        });
    };

    render() {
        // 解释区域显示文字
        const { isShow, json, explainData } = this.state;
        const { onHide = () => { }, className = '', message = '', zIndex } = this.props;
        return (
            <div>
                <Modal
                    className={`${className} FormulaEditorWrap`}
                    size="lg"
                    show={isShow && !this.exiting}
                    backdrop="static" //是否展示遮罩层
                    modalAutoFocus={false} // 取消自动聚焦焦点的操作
                    onHide={onHide}
                    onExit={() => {
                        // 隐藏开始
                        this.setState({ showData: '' });
                        this.exiting = true;
                    }}
                    onExited={() => {
                        // 隐藏结束  为了解决input focus 弹出面板时  关闭弹窗触发focus事件  特此延时以让一定时间内不会二次弹出
                        setTimeout(() => {
                            this.exiting = false; // 不确定这里是否会产生 内存问题  可以考虑把这个变量放到全局去  应该也不会产生问题
                        }, 0);
                    }}
                    onResizeStop={() => {
                        let modalbody = document.querySelector('.nc-modal.u-modal.FormulaEditorWrap .u-modal-body')
                        if (modalbody) {
                            if (modalbody.offsetHeight > 0) {
                                let tabPaneHeight = modalbody.offsetHeight - 297
                                this.setState({ tabPaneHeight, containerHeight: modalbody.offsetHeight - 45 })
                            }
                        }
                    }}
                    onEnter={() => {
                        let containerHeight = document.body.offsetHeight - 42 - 45
                        if (containerHeight < 435) {
                            containerHeight = 435
                        } else if (containerHeight > 535) {
                            containerHeight = 535
                        }

                        this.setState({ containerHeight: containerHeight + 'px' })
                    }}
                    onEntered={dom => {
                        let modalbody = dom.querySelector('.u-modal-body')
                        if (modalbody) {
                            if (modalbody.offsetHeight > 0) {
                                let tabPaneHeight = modalbody.offsetHeight - 297
                                this.setState({ tabPaneHeight })

                            }

                        }
                    }}

                    zIndex={zIndex}
                    fieldid="formulaEditor"
                >
                    <Header closeButton>
                        <Title fieldid={`${json['formula0016']}`}>
                            <span >{json['formula0016']} </span>
                            <span style={{ marginLeft: '20px' }}>{message}</span>
                        </Title>
                    </Header>
                    <Body>
                        <div className="editor-containers" style={{ height: this.state.containerHeight }}>
                            {this._createWorkStation()}
                            {this._createTabs()}
                        </div>
                        <div className="explain-area">{explainData}</div>
                    </Body>
                </Modal>
            </div >
        );
    }
}

FormulaEditor.defaultProps = {
    formulaConfig: [], //左侧tab配置
    attrConfig: [], //右侧tab配置
    onOk: () => { }, //点击确定回调
};
FormulaEditor.propTypes = {
    formulaConfig: PropTypes.array, //左侧tab配置
    attrConfig: PropTypes.array, //右侧tab配置
    metaParam: PropTypes.object, //元素与属性请求参数
    metaUrl: PropTypes.string, //元素与属性的请求路径
    surfaceUrl: PropTypes.string, //表和字段的请求路径
    formulaUrl: PropTypes.string, //公式的的请求路径
    validateUrl: PropTypes.string, //自定义的验证接口
    onOk: PropTypes.func, //点击确定回调
    value: PropTypes.string, //设置编辑器的值
    show: PropTypes.bool, //控制模态框展示和隐藏
    noShowFormula: PropTypes.array, //公式tab不展示那些项
    noShowAttr: PropTypes.array, //属性tab不展示那些项
    onHide: PropTypes.func, //点击x按钮的回调
    noControlBtns: PropTypes.array, //不需要展示的操作按钮
    isValidateOnOK: PropTypes.bool, //点击确定后是否需要验证
    defaultAttrKey: PropTypes.string, //属性tabs默认展示的页签
    defaultFormulaKey: PropTypes.string, //公式tabs默认展示的页签
    tabActiveKey: PropTypes.string, //公式tabs当前展示的页签
};

/*
 * 同步树组件
 */
import React from "react";
import { BaseComponent } from "@platform/template";
import { Tree, Icon, Tooltip, Modal, Button } from '@platform/base';
import classnames from 'classnames';
import * as TreeUtils from './util';
import {
  isFunction,
  getMultiLang,
  getSysFieldid,
  getGlobalStorage
} from "@platform/api";
import Form from '@platform/form';
const TreeNode = Tree.TreeNode;

let strToLocaleLowerCase = str => {
  return Object.prototype.toString.call(str) === '[object String]' ? str.toLocaleLowerCase() : String(str).toLocaleLowerCase();
}

require("./Tree.less");

export default class SyncTree extends BaseComponent {
  constructor(props) {
    super(props);
    this.searchInputRef = null;
    this.searchRef = React.createRef();
    this.ncc_automated = "0";
    this.dropped = false;
    this.state = {
      langJson: {},
      _: "",
    };
    this.ncc_automated = getGlobalStorage("localStorage", "_ncc_automated_");

    //业务初始化数据 存store
    let { store, meta, metaId, treeType, formStore } = this.props;

    store.setCache('treeType', treeType);

    store.setStore('formStore', formStore);

    store.setStore("searchInputRef",this.searchRef);

    if (meta[metaId]) {
      formStore.setMeta(meta, metaId);
    }
    this.transerDefaultConfigToStore(props);
  }

  componentWillMount() {
    //调用多语
    let callback = (json) => {
      this.setState({ langJson: json });
    };

    getMultiLang({ moduleId: "containers_tree", callback });

  }


  componentWillUpdate(newProps, newState) {
    let { store, defaultExpandAll = false, treeType } = newProps;
    if (treeType === 'syncTree') {
      let firstTime = store.getStore("firstTime");
      let treeData = store.getStore("treeData");
      if (firstTime) {
        this.generateList(treeData, this.dataList);
        store.setCache("dataList", this.dataList);
        store.setStore("firstTime", false);
        if (defaultExpandAll) {
          store.setStore("expandedKeys", this.getAllNodeKeys(treeData));
        }
      }
      // let searchValue = store.getStore('searchValue');
      // if(searchValue){}
    }

  }

  componentWillReceiveProps(nextProps) {
    let { meta, store, metaId } = nextProps;
    let formStore = store.getStore('formStore');
    if (meta[metaId]) {
      formStore.setMeta(meta, metaId);
    }
    this.transerDefaultConfigToStore(nextProps);
  }

  transerDefaultConfigToStore = (props) => {
    this.listeners = [
      'onTreeStatusChange', 'selectedForInit', 'onSelectEve',
      'onSelectedChange', 'clickAddIconEve', 'clickEditIconEve', 'clickDelIconEve',
      'loadTreeData', //异步树
      'overWriteFilterTree',
    ]
    this.listeners.forEach(item => {
      if (typeof props[item] !== 'undefined') {
        this.props.store.setListener([item], props[item])
      }
    });

    this.lastData = [];//记录上次点击节点
    this.dataList = [];

    //业务初始化数据 存store
    let { store } = this.props;
    this.cacheProps = ['showModal', 'userControlIcon', 'metaId', 'disabledSearch', 'searchType', 'noCheckWhenSelect'];
    this.cacheProps.forEach(item => {
      if (typeof props[item] !== 'undefined') {
        store.setCache([item], props[item]);
      }
    })

    if (typeof props.needEdit !== 'undefined') {
      store.setStore('needEdit', props.needEdit);
    }
  }

  //  获取所有节点的key,返回数组
  getAllNodeKeys = data => {
    let allKeys = [];
    let getAllNodeKey = data => {
      data.forEach(item => {
        if (Array.isArray(item.children)) {
          allKeys.push(item.key);
          getAllNodeKey(item.children);
        }
      });
    };
    getAllNodeKey(data);
    return allKeys;
  };


  //  拆分数据，用于查询关键字
  generateList = (data, dataList) => {
    for (let i = 0; i < data.length; i++) {
      let node = data[i];
      let key = node.key || node.id || node.refpk;

      let beforeName = node.beforeName ? node.beforeName : '';
      let afterName = node.afterName ? node.afterName : '';
      let name = node.refname ? node.refname : '';

      if (typeof beforeName === 'string') {
        name = beforeName + name;
      }

      if (typeof afterName === 'string') {
        name = name + afterName;
      }
      dataList.push({
        key,
        title: name,
      });
      if (node.children) {
        this.generateList(node.children, dataList);
      }
    }
  };

  //---------搜索框事件--------------//
  handleClearState = () => {
    let { store } = this.props;
    store.setStore('searchValue', '');
    store.setStore('expandedKeys', store.getCache('saveExpandedKeys'));
    store.setStore('treeData', store.getCache('deepData'));
  }

  handleClearSearchVal = () => {
    let { getSearchVal, clearSearchVal, store } = this.props;

    if (this.searchInputRef) {
      this.searchInputRef.value = '';
    }
    // 处理清空状态
    this.handleClearState();
    store.setStateEve();
    if (isFunction(clearSearchVal)) {
      clearSearchVal();
    }
    if (isFunction(getSearchVal)) {
      getSearchVal(store.getStore('searchValue'));
    }
  }

  focusEve = () => {
    // let { store } = this.props;
    // let expandedKeys = store.getStore('expandedKeys');
    // let checkedKeys = store.getStore('checkedKeys');
    // if (store.getStore('treeData').length != store.getCache('deepData').length) return;
    // store.setCache('saveExpandedKeys', JSON.parse(JSON.stringify(expandedKeys)));
    // store.setCache('saveCheckedKeys', JSON.parse(JSON.stringify(checkedKeys)));
  }

  //----------树组件事件------------------//
  onDoubleClickHandler = (pk, node) => {
    let { onDoubleClickEve } = this.props;
    if (isFunction(onDoubleClickEve)) {
      onDoubleClickEve(pk, node);
    }

    this.expandedRows(pk, node);
  }

  // 双击行展开节点
  expandedRows = (pk, node) => {
    let { store, expandWhenDoubleClick } = this.props;
    if (expandWhenDoubleClick) {
      return;
    }
    let expandedKeys = store.getStore('expandedKeys');

    let selectedItemKeys = node.node.props.refpk;
    let copy = JSON.parse(JSON.stringify(expandedKeys));

    if (copy.indexOf(selectedItemKeys) === -1) {
      copy.push(selectedItemKeys);
    } else {
      copy.map((v, i) => {
        if (v === selectedItemKeys) copy.splice(i, 1);
      });
    }
    this.onExpand(copy, node);
  };

  //  节点展开事件
  onExpand = (expandedKeys, info) => {
    let { store, onTreeExpand, treeType } = this.props;
    store.setStore('expandedKeys', expandedKeys);
    //store.setStore('autoExpandParent', false);
    store.setStateEve();
    if (isFunction(onTreeExpand)) {
      onTreeExpand(expandedKeys, info);
    }
  };

  onSelect = (pk, node) => {
    let { onSelectCallBack, onSelectAndChecked, onSelectedChange, onSelectEve, onCheckEve, store, treeType, pageOutput, triggerCheckWhenSelect } = this.props;

    //经营会计 王建卫  利润中心成本动因 公式编辑器
    if (isFunction(onSelectCallBack)) {
      onSelectCallBack(pk, node);
    }

    if (!node.selected) return;
    store.setStore('selectedKeys', pk);
    let checkedKeys = store.getStore('checkedKeys');

    //------------TODO: 同步树逻辑，异步树没有
    //勾选节点操作，判断checkedKeys数组是否只有一个成员，如果有就勾选
    let noCheckWhenSelect = store.getCache('noCheckWhenSelect');
    if (checkedKeys.length < 2 && !noCheckWhenSelect) {
      checkedKeys = pk;
      store.setStore('checkedKeys', checkedKeys);
      let saveCheckedKeys = store.getCache("saveCheckedKeys");
      store.setCache("saveCheckedKeys", [...new Set([...saveCheckedKeys, ...pk])])
      //triggerCheckWhenSelect 设置选中时触发勾选回调
      if (triggerCheckWhenSelect) {
        let { selectedNodes, node: checkedNode } = node;
        typeof onCheckEve === 'function' && onCheckEve({ ...this.props, ...pageOutput }, checkedKeys, {
          checked: true,
          checkedNodes: selectedNodes,
          node: checkedNode,
          event: "check"
        });
      }
      if (onSelectAndChecked) {
        onSelectAndChecked(pk);
      }
    }
    //------------TODO: 同步树逻辑，异步树没有


    let treeData = store.getStore('treeData');

    let isChange = () => this.lastData[0] !== pk[0];
    let item = TreeUtils.getItem(treeData, pk[0]);

    if (isChange()) {
      if (onSelectedChange) {
        onSelectedChange(item);
      }
    }
    if (pk.length !== 0) {
      store.setCache('selectedNode', item);
    }
    if (typeof onSelectEve === 'function' && pk.length !== 0) {
      if (treeType === "syncTree") {
        onSelectEve(item.refpk, item, isChange(), node);
      } else {
        onSelectEve(pk, item, isChange(), node);
      }
      this.lastData[0] = pk[0];
    }
    store.setStateEve();
  }

  // 展开子元素事件
  onLoadData = treeNode => {
    let { loadTreeData } = this.props;
    if (isFunction(loadTreeData)) {
      return new Promise(resolve => {
        loadTreeData(treeNode.props.eventKey, treeNode);
        resolve();
      });
    }
  };


  onMouseLeave = () => {
    let { store } = this.props;
    store.setCache("isHover", '')
  }

  onMouseEnter = (e) => {
    let { store, onMouseEnterEve } = this.props;
    store.setCache("isHover", e.node.props.eventKey)
    if (onMouseEnterEve && typeof onMouseEnterEve === 'function') {
      /* iuap没给当前节点的所有数据，先自己获取，可能会有效率问题——wangshhj */
      onMouseEnterEve(e.node.props.eventKey, e.node.props);
    }
  }

  // 选中节点回调
  onCheck = (checkedKeys, { checked, checkedNodes, node, event }) => {
    let { store, onCheckEve, pageOutput, checkWithCache } = this.props;
    store.setStore("checkedKeys", checkedKeys);
    let saveCheckedKeys = store.getCache("saveCheckedKeys");
    if (checkWithCache) {
      if (checked) {
        saveCheckedKeys = [...new Set([...saveCheckedKeys, ...checkedKeys])]
      } else {
        let treeData = store.getStore('treeData');
        let allTreeKeys = TreeUtils.getAllTreeKeys(treeData, []);
        let notRemoveKeys = saveCheckedKeys.filter(x => !allTreeKeys.includes(x));
        saveCheckedKeys = [...new Set([...checkedKeys, ...notRemoveKeys])];
      }
      store.setCache("saveCheckedKeys", saveCheckedKeys);
    }
    typeof onCheckEve === 'function' &&
      //TODO page参数
      onCheckEve({ ...this.props, ...pageOutput }, checkedKeys, {
        checked,
        checkedNodes,
        node,
        event,
      }, saveCheckedKeys);
    store.setStateEve();
  }

  onDragOver = ({ event }) => {
    event.preventDefault();
    return false;
  }

  onDragStart = ({ event, node }) => {
    this.dropped = false;
    let { store, onDragStartEve } = this.props;
    store.setCache("moveKey", node.props.eventKey)

    if (isFunction(onDragStartEve)) {
      onDragStartEve({ event, node });
    }
  }

  onDrop = (info) => {
    let { store } = this.props;
    this.dropped = true // 如果正常拖动松手时，这个方法一定会执行，且在onDragEnd（如果也执行的话）之前执行。拖到外面时不执行。
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };
    const data = store.getStore('treeData');
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (info.dropToGap) {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      ar.splice(i, 0, dragObj);
    } else {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.push(dragObj);
      });
    }
    store.setStore('treeData', data);
    store.setStateEve();
  }

  onDragEnd = () => {
    if (!this.dropped) {
      let { store } = this.props;
      store.setStateEve();
    }
  }


  getFloadIcon = (item) => {
    let { store, hiddenDefaultIcon = false } = this.props;
    let expandedKeys = store.getStore('expandedKeys');

    let floadIcon = null;
    if (!hiddenDefaultIcon) {
      if (item.isleaf == false || (item.children && item.children.length != 0)) {
        floadIcon = expandedKeys.includes(item.refpk) ? (
          <i
            className={classnames(
              'icon iconfont  icon-wenjianjiadakai tree-wenjian',
              { 'stop-node': item.iconBox && item.iconBox.stopUpIon === 'stop' },
            )}
          />
        ) : (<i
          className={classnames(
            'icon iconfont  icon-wenjianjia tree-wenjian',
            { 'stop-node': item.iconBox && item.iconBox.stopUpIon === 'stop' },
          )}
        />);
      } else {
        floadIcon = (
          <i
            className={classnames('tree-dian', {
              'stop-node-dian':
                item.iconBox && item.iconBox.stopUpIon === 'stop',
            })}
          />
        );
      }
    }

    return floadIcon;
  }

  getEditIcon = (item) => {
    let { langJson, } = this.state;
    let { nodeIconTooltips = {} } = this.props;
    if (item && item.iconBox && item.iconBox.editIcon) {
      return <Tooltip
        placement="top"
        overlay={nodeIconTooltips.edit || langJson['tree-node-icon-edit']}
        delayHide={1}
        delayShow={1000}
      >
        <i
          className="icon iconfont icon-bianji edit-icon"
          onClick={e => item.disabled !== true && this.editRender.call(this, item, e)
          }
        />
      </Tooltip>
    }
    return null
  }

  getAddIcon = (item) => {
    let { langJson } = this.state;
    let { nodeIconTooltips = {} } = this.props;
    if (item && item.iconBox && item.iconBox.addIcon) {
      return <Tooltip
        placement="top"
        overlay={nodeIconTooltips.add || langJson['tree-node-icon-add']}
        delayHide={1}
        delayShow={1000}
      >
        <i
          className="icon iconfont icon-zengjia add-icon"
          onClick={e => item.disabled !== true && this.addRender(item, e)
          }
        />
      </Tooltip>
    }
    return null;
  }

  getDelIcon = (item) => {
    let { langJson } = this.state;
    let { nodeIconTooltips = {} } = this.props;
    if (item && item.iconBox && item.iconBox.delIcon) {
      return <Tooltip
        placement="top"
        overlay={nodeIconTooltips.delete || langJson['tree-node-icon-delete']}
        delayHide={1}
        delayShow={1000}
      >
        <i
          className="icon iconfont icon-shanchu delete-icon"
          onClick={e => item.disabled !== true && this.delRender.call(this, item, e)
          }
        />
      </Tooltip>
    }
    return null;
  }



  getStopUpIcon = (item) => {
    let { langJson } = this.state;
    let { nodeIconTooltips = {} } = this.props;
    if (item && item.iconBox && item.iconBox.stopUpIon) {
      return item.iconBox.stopUpIon === 'up' ? (
        <Tooltip
          placement="top"
          overlay={nodeIconTooltips.unlock || langJson['tree-node-icon-lock']}
          delayHide={1}
          delayShow={1000}
        >
          <i
            className="icon iconfont icon-shu_qiyong"
            onClick={e => {
              item.disabled !== true &&
                this.handleStopAndUp(item, 'up', e);
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          placement="top"
          overlay={nodeIconTooltips.lock || langJson['tree-node-icon-unlock']}
          delayHide={1}
          delayShow={1000}
        >
          <i
            className="icon iconfont icon-shu_tingyong"
            onClick={e => {
              item.disabled !== true &&
                this.handleStopAndUp(item, 'stop', e);
            }}
          />
        </Tooltip>
      );
    }

    return null;
  }

  handleStopAndUp = (item, type, e) => {
    let { clickStopIconEve, clickUpIconEve, store } = this.props;
    e.stopPropagation();
    //let getStopUpStatus = item.iconBox;
    switch (type) {
      case 'stop':
        if (isFunction(clickStopIconEve)) {
          clickStopIconEve(item);
        }
        // getStopUpStatus.stopUpIon = 'up';
        break;
      case 'up':
        if (isFunction(clickUpIconEve)) {
          clickUpIconEve(item);
        }
        //getStopUpStatus.stopUpIon = 'stop';
        break;
    }

    store.setStateEve();

  }

  getRightIcon = (item) => {
    let { nodeRightOpt } = this.props;
    if (nodeRightOpt && item.isdrag) {
      return <span
        className="NC_iconBox node_right_opt"
        onClick={nodeRightOpt.callback}
      >
        <Icon type={nodeRightOpt.icon} />
      </span>
    }
    return null;
  }

  getTitleInfo = (item, name, searchValue, index, nodeLevel, beforeName, afterName) => {
    let { store, tooltipLevels = [], tooltipField } = this.props;
    let beforeStr = name.substr(0, index);
    let searchFromName = name.substr(index, searchValue.length);
    let afterStr = name.substr(index + searchValue.length);

    let titleInfo = null;
    if (index > -1) {
      titleInfo = <span
        fieldid={getSysFieldid('tree-title')}
        className={classnames('title-middle', {
          'stop-node-title':
            item.iconBox && item.iconBox.stopUpIon === 'stop',
        })}
      >
        {beforeStr}
        <span className="u-tree-searchable-filter">{searchFromName}</span>
        {afterStr}
      </span>
    } else {
      titleInfo = <span
        fieldid={getSysFieldid('tree-title')}
        className={classnames('title-middle', {
          'stop-node-title':
            item.iconBox && item.iconBox.stopUpIon === 'stop',
        })}
      >{beforeName && typeof beforeName === 'string' ? item.beforeName : ''}{item.refname}{afterName && typeof afterName === 'string' ? item.afterName : ''}
      </span>
    }

    // 新需求: 节点根据设置的字段和层级显示 tooltip
    if (
      Array.isArray(tooltipLevels) &&
      tooltipLevels.includes(nodeLevel) &&
      tooltipField &&
      item[tooltipField]
    ) {

      titleInfo = (
        <Tooltip
          className="tooltip-word-color"
          placement="top"
          delayShow={300}
          delay={1}
          inverse
          overlay={item[tooltipField]}
        >
          {titleInfo}
        </Tooltip>
      );
    }

    return titleInfo;
  }

  createCustomerTreeIcon = (item) => {
    let { store, customizeIconClick } = this.props;
    let treeData = store.getStore('treeData');
    let customerIcons = item.customerIcons;
    return (
      <span className='NC_iconBox'>
        {
          customerIcons.map((value, index) => {
            if (typeof value === "string") {
              return (
                <i
                  key={index}
                  className={`icon iconfont icon-${value} customer-icon`}
                  onClick={e => {
                    if (isFunction(customizeIconClick)) {
                      customizeIconClick({ e, iconType: value, item, treeData });
                    }
                  }}
                />

              );
            } else if (typeof value === "object") {
              return (
                <Tooltip
                  placement="top"
                  overlay={value.title}
                  delayHide={1}
                  delayShow={1000}
                >
                  <i
                    key={index}
                    className={`icon iconfont icon-${value.icon} customer-icon`}
                    onClick={e => {
                      if (isFunction(customizeIconClick)) {
                        customizeIconClick({ e, iconType: value.icon, item, treeData });
                      }
                    }}
                  />
                </Tooltip>

              );
            }
          })
        }
      </span>
    );
  }

  //TODO: 同步树与异步树不同的API
  renderTreeTitle = (item, nodeLevel) => {
    let { store, treeType } = this.props;
    let searchValue = store.getStore('searchValue');
    let noDisableId = store.getStore('noDisableId');
    let needEdit = store.getStore('needEdit');

    let editIcon, addIcon, delIcon, stopUpIon, rightIcon, iconBox;
    let beforeName = item.beforeName ? item.beforeName : '';
    let afterName = item.afterName ? item.afterName : '';
    let name = item.refname ? item.refname : '';

    if (typeof beforeName === 'string') {
      name = beforeName + name;
    }
    if (typeof afterName === 'string') {
      name = name + afterName;
    }
    let index = (name.toLocaleLowerCase()).indexOf(searchValue.toLocaleLowerCase());

    let floadIcon = this.getFloadIcon(item);
    let titleInfo = null;
    if (treeType === 'asyncTree') {
      titleInfo = <span
        fieldid={getSysFieldid('tree-title')}
        className="title-middle"
      >{item.refname}</span>;
    } else {
      titleInfo = this.getTitleInfo(item, name, searchValue, index, nodeLevel, beforeName, afterName);
    }

    //  设置树的编辑性
    if (needEdit) {
      editIcon = this.getEditIcon(item);
      addIcon = this.getAddIcon(item);
      delIcon = this.getDelIcon(item);
      stopUpIon = this.getStopUpIcon(item);
    }
    //右侧Icon
    rightIcon = this.getRightIcon(item);

    if (item.customerIcons && item.customerIcons.length > 0) {
      iconBox = this.createCustomerTreeIcon(item);
    } else {
      if (treeType === 'asyncTree') {
        iconBox = (<span className="NC_iconBox">
          {editIcon} {addIcon} {delIcon}
        </span>);
      } else {
        iconBox = (
          <span className="NC_iconBox">
            {editIcon} {addIcon} {delIcon} {stopUpIon}
          </span>
        );
      }
    }

    if (treeType === 'asyncTree') {
      return (
        <div className="title-con">
          {floadIcon}
          {beforeName}
          {titleInfo}
          {afterName}
          {iconBox}
        </div>
      );
    }

    if (beforeName && typeof beforeName === 'string' || afterName && typeof afterName === 'string') {
      return (
        <div
          className={classnames(
            `title-con ${item.className ? item.className : ''}`,
            {
              'active-nodisable': noDisableId === item.refpk,
              'title-con-disabled': item && item.disabled,
            },
          )}
        >
          {floadIcon}
          {titleInfo}
          {iconBox}
          {rightIcon}
        </div>
      );
    } else {
      return (
        <div
          className={classnames(
            `title-con ${item.className ? item.className : ''}`,
            {
              'active-nodisable': noDisableId === item.refpk,
              'title-con-disabled': item && item.disabled,
            },
          )}
        >
          <span className="title-single">
            {floadIcon}
            {beforeName}
            {titleInfo}
            {afterName}
          </span>
          {iconBox}
          {rightIcon}
        </div>
      );
    }
  }

  getTreeNodesLazy = (treeData) => {

    //这里去tinper的treeData有bug  tinper给的treeData是上一次的bee-tree的state数据
    //传给tinper的treeData引用相同 数据未改变时 没有更新 因为tinper内部对比的引用
    // let { store } = this.props;
    // let treeData = store.getStore('treeData');
    //更新treeData时 目前给的新数据
    return this.loopLazy(treeData, 0);
  }

  // 组织节点dom
  loopLazy = (data, nodeLevel) => {
    let { store, neverDisable = false, switcherClass, mustExpandable = false } = this.props;
    let expandedKeys = store.getStore('expandedKeys');
    let selectedKeys = store.getStore('selectedKeys');

    let newData = data || [];
    return newData.map(item => {
      item.key = item.refpk;
      if (item.key === undefined) {
        console.error('树组件，数据格式错误， 缺少refpk字段，请检查数据格式');
        return false;
      }
      let fieldid = '';
      if (item.attrcode) {
        fieldid = `${item.attrcode}_node`;
      } else if (item.beforeName && typeof item.beforeName === 'string') {
        fieldid = `${item.beforeName.trim()}_node`;
      } else if (item.refname) {
        if (typeof item.refname === "string") {
          let refnameArr = item.refname.split(' ');
          let refname = refnameArr[0];
          if (!refname && refnameArr[1]) {
            refname = refnameArr[1];
          }
          fieldid = `${refname}_node`;
        } else {
          fieldid = `${item.refpk || item.key || item.id}_node`
        }
      }
      if (Array.isArray(item.children) && item.children.length) {
        return (
          <TreeNode
            liAttr={{ fieldid: getSysFieldid(fieldid), key: fieldid }}
            {...item}
            className={classnames(
              `node-item ${item.className || ''}`,
              { isexpend: expandedKeys.includes(item.key) },
              { disabledSelected: selectedKeys[0] === item.refpk },
            )}

            // className={item.isdrag === false ? 'noDrag' : null}
            key={item.key}
            title={this.renderTreeTitle(item, nodeLevel)}
            disabled={neverDisable ? false : item.disabled}
            switcherClass={item.switcherClass || switcherClass}
            mustExpandable={item.mustExpandable || mustExpandable}
            isLeaf={item.isLeaf}
          >
            {this.loop(item.children, nodeLevel + 1)}
          </TreeNode>
        );
      } else {
        if (item.isleaf === false) {
          return (
            <TreeNode
              liAttr={{ fieldid: getSysFieldid(fieldid), key: fieldid }}
              {...item}
              className={classnames(
                `node-item ${item.className || ''}`,
                { isexpend: expandedKeys.includes(item.key) },
                { disabledSelected: selectedKeys[0] === item.refpk },
              )}
              key={item.key}
              title={this.renderTreeTitle(item, nodeLevel)}
              disabled={neverDisable ? false : item.disabled}
              switcherClass={item.switcherClass || switcherClass}
              children={[]}
              mustExpandable={item.mustExpandable || mustExpandable}
              isLeaf={false}
            />
          );
        } else {
          return (
            <TreeNode
              liAttr={{ fieldid: getSysFieldid(fieldid), key: fieldid }}
              {...item}
              className={classnames(
                `node-item ${item.className || ''}`,
                { isexpend: expandedKeys.includes(item.key) },
                { disabledSelected: selectedKeys[0] === item.refpk },
              )}
              key={item.key}
              title={this.renderTreeTitle(item, nodeLevel)}
              disabled={neverDisable ? false : item.disabled}
              switcherClass={item.switcherClass || switcherClass}
              children={null}
              mustExpandable={item.mustExpandable || mustExpandable}
              isLeaf={true}
            />
          );
        }
      }
    });
  };

  getTreeNodes = (treeData) => {

    //这里去tinper的treeData有bug  tinper给的treeData是上一次的bee-tree的state数据
    //传给tinper的treeData引用相同 数据未改变时 没有更新 因为tinper内部对比的引用
    // let { store } = this.props;
    // let treeData = store.getStore('treeData');
    //更新treeData时 目前给的新数据
    return this.loop(treeData, 0);
  }

  // 组织节点dom
  loop = (data, nodeLevel) => {
    let { store, neverDisable = false, switcherClass, mustExpandable = false } = this.props;
    let expandedKeys = store.getStore('expandedKeys');
    let selectedKeys = store.getStore('selectedKeys');

    let newData = data || [];
    return newData.map(item => {
      item.key = item.refpk;
      if (item.key === undefined) {
        console.error('树组件，数据格式错误， 缺少refpk字段，请检查数据格式');
        return false;
      }
      let fieldid = '';
      if (item.attrcode) {
        fieldid = `${item.attrcode}_node`;
      } else if (item.beforeName && typeof item.beforeName === 'string') {
        fieldid = `${item.beforeName.trim()}_node`;
      } else if (item.refname) {
        if (typeof item.refname === "string") {
          let refnameArr = item.refname.split(' ');
          let refname = refnameArr[0];
          if (!refname && refnameArr[1]) {
            refname = refnameArr[1];
          }
          fieldid = `${refname}_node`;
        } else {
          fieldid = `${item.refpk || item.key || item.id}_node`
        }
      }
      if (Array.isArray(item.children) && (item.children.length || this.props.treeType === "asyncTree")) {
        return (
          <TreeNode
            liAttr={{ fieldid: getSysFieldid(fieldid), key: fieldid }}
            {...item}
            className={classnames(
              `node-item ${item.className || ''}`,
              { isexpend: expandedKeys.includes(item.key) },
              { disabledSelected: selectedKeys[0] === item.refpk },
            )}

            // className={item.isdrag === false ? 'noDrag' : null}
            key={item.key}
            title={this.renderTreeTitle(item, nodeLevel)}
            disabled={neverDisable ? false : item.disabled}
            switcherClass={item.switcherClass || switcherClass}
            mustExpandable={item.mustExpandable || mustExpandable}
          >
            {this.loop(item.children, nodeLevel + 1)}
          </TreeNode>
        );
      } else {
        if (item.isleaf === false) {
          return (
            <TreeNode
              liAttr={{ fieldid: getSysFieldid(fieldid), key: fieldid }}
              {...item}
              className={classnames(
                `node-item ${item.className || ''}`,
                { isexpend: expandedKeys.includes(item.key) },
                { disabledSelected: selectedKeys[0] === item.refpk },
              )}
              key={item.key}
              title={this.renderTreeTitle(item, nodeLevel)}
              disabled={neverDisable ? false : item.disabled}
              switcherClass={item.switcherClass || switcherClass}
              mustExpandable={item.mustExpandable || mustExpandable}
              children={[]}
              isLeaf={item.isleaf}
            />
          );
        } else {
          return (
            <TreeNode
              liAttr={{ fieldid: getSysFieldid(fieldid), key: fieldid }}
              {...item}
              className={classnames(
                `node-item ${item.className || ''}`,
                { isexpend: expandedKeys.includes(item.key) },
                { disabledSelected: selectedKeys[0] === item.refpk },
              )}
              key={item.key}
              title={this.renderTreeTitle(item, nodeLevel)}
              disabled={neverDisable ? false : item.disabled}
              switcherClass={item.switcherClass || switcherClass}
              mustExpandable={item.mustExpandable || mustExpandable}
              children={null}
              isLeaf={true}
            />
          );
        }
      }
    });
  };




  //-------------树节点编辑模态框事件 ---------------//

  addNodeEve = () => {
    let { verifyModalForm = false, addNodeCallBack, store } = this.props;
    let currentNode = store.getCache('currentNode');
    let formStore = store.getStore('formStore');

    if (!verifyModalForm) {
      formStore.setStatus('browse');
    }
    let data = formStore.getAllFormValue();

    if (isFunction(addNodeCallBack)) {
      let verify = addNodeCallBack(data, currentNode.refpk, currentNode);
      // 如果有回调 并且进行了校验
      if (verifyModalForm && verify) {
        store.setStore('showEditModal', false);
        store.setStateEve();
      }
    }
  };
  //  点击新增图标事件
  addRender = (item, e) => {
    e.stopPropagation();
    let { showModal = true, store, clickAddIconEve, addNodeCallBack, treeType } = this.props;

    store.setCache('selectedNode', item);
    store.setCache('currentNode', item);

    if (showModal) {
      let formStore = store.getStore('formStore');
      formStore.emptyAllFormValue();
      formStore.setStatus('edit');

      store.setCache('treeNodeOperation', 'add');
      store.setStore('showEditModal', true);
      store.setStateEve();
    } else if (treeType === 'asyncTree') {
      if (isFunction(addNodeCallBack)) {
        addNodeCallBack(item);
      }
    }


    if (treeType === 'asyncTree') {
      store.setStore('selectedKeys', [item.refpk])
    }

    if (isFunction(clickAddIconEve)) {
      clickAddIconEve(item);
    }
    store.setStateEve();
  };

  editRender = (item, e) => {
    let { showModal = true, store, clickEditIconEve, treeType, editNodeCallBack } = this.props;
    e.stopPropagation();
    store.setCache('currentNode', item);

    if (showModal) {
      let formStore = store.getStore('formStore');
      formStore.emptyAllFormValue();
      formStore.setStatus('edit');

      store.setCache('treeNodeOperation', 'edit');
      store.setStore('showEditModal', true);

    } else if (treeType === 'asyncTree') {
      if (isFunction(editNodeCallBack)) {
        editNodeCallBack(item);
      }
    }

    if (isFunction(clickEditIconEve)) {
      clickEditIconEve(item);
    }
    store.setStore('selectedKeys', [item.refpk]);
    store.setStateEve();
  }

  delNodeEve = () => {
    let { delNodeCallBack, store } = this.props;
    let currentNode = store.getCache('currentNode');
    isFunction(delNodeCallBack) && delNodeCallBack(currentNode);
  }

  // 点击删除图标事件，删除节点
  delRender = (item, e) => {
    let { showModal = true, store, clickDelIconEve, treeType, delNodeCallBack } = this.props;
    e.stopPropagation();
    store.setCache('currentNode', item);
    if (showModal) {

      store.setCache('treeNodeOperation', 'del');
      store.setStore('showEditModal', true);
    } else if (treeType === 'asyncTree') {
      if (isFunction(delNodeCallBack)) {
        delNodeCallBack(item);
      }
    }
    if (treeType === 'asyncTree') {
      store.setStore('selectedKeys', [item.refpk])
    }
    if (isFunction(clickDelIconEve)) {
      clickDelIconEve(item);
    }
    store.setStateEve();
  };


  editNodeEve = () => {
    let { verifyModalForm = false, editNodeCallBack, store, modal } = this.props;
    let currentNode = store.getCache('currentNode');
    let formStore = store.getStore('formStore');
    if (!verifyModalForm) {
      formStore.setStatus('browse');
    }
    let data = formStore.getAllFormValue();
    if (isFunction(editNodeCallBack)) {
      let verify = editNodeCallBack(data, currentNode.refpk, currentNode);
      // 如果有回调 并且进行了校验
      if (verifyModalForm && verify) {
        store.setStore('showEditModal', false);
        store.setStateEve();
      }
    }
  }

  beSureBtnClick = () => {
    let { treeType, store } = this.props;
    let status = store.getCache('treeNodeOperation');
    if (treeType === 'asyncTree') {
      store.setStore('showEditModal', false);
      if (isFunction(this.props[`${status}NodeCallBack`])) {
        let currentNode = store.getCache('currentNode');
        let formStore = store.getStore('formStore');
        this.props[`${status}NodeCallBack`](
          currentNode,
          formStore.getAllFormValue(),
        )
      }
    } else if (treeType === 'syncTree') {
      this[`${status}NodeEve`]();
    }
  }

  cancelBtnClick = () => {
    let { verifyModalForm, store } = this.props;
    let formStore = store.getStore('formStore');
    if (!verifyModalForm) {
      formStore.setStatus("browse");
    }
    formStore.cancel();
    store.setStore('showEditModal', false);
    store.setStateEve();
  }

  getScrollContainer = () => {
    if (this.treeDiv) {
      return this.treeDiv;
    } else {
      let treeDiv = document.querySelector(`#${this.props.treeId.trim()}`);
      if (treeDiv) {
        this.treeDiv = treeDiv.querySelector('.tree-hotkeys-wrapper');
      } else {
        this.treeDiv = document.querySelector('.tree-hotkeys-wrapper');
      }
      return this.treeDiv;
    }
  }


  render() {

    let {
      treeId,
      userDefine,
      needSearch = true, //是否显示搜索
      getSearchVal,
      disabledSearch = false,
      checkable = false,
      checkStrictly = true,
      showModal = true,
      verifyModalForm = false,
      modalSize,
      openIcon,
      closeIcon,
      draggable = false,
      store,
      modal,
      mustExpandable = false,//支持disabled的节点可以自定义展开收起，默认disabled的节点不可以展开收起。
      lazyLoad = false,
      expandWhenDoubleClick,
      showLine = false,
      treeType,
      metaId,
      otherAreaHeight = 0,
      canCloseFreely = true
    } = this.props;

    let { langJson } = this.state;
    let searchValue = store.getStore('searchValue');
    let focusable = store.getStore('focusable');
    let selectedKeys = store.getStore('selectedKeys');
    let expandedKeys = store.getStore('expandedKeys');
    let autoExpandParent = store.getStore('autoExpandParent');
    let checkedKeys = store.getStore('checkedKeys');
    let treeData = store.getStore('treeData');
    let showEditModal = store.getStore('showEditModal');
    let status = store.getCache('treeNodeOperation');
    let editModalTitle = this.props[`${status}ModalTitle`] || langJson[`containers-tree-${status}`];
    let formStore = store.getStore('formStore');
    let needEdit = store.getStore('needEdit');


    // if (treeData && treeData.length === 0) {
    //   return false;
    // }

    return (
      <div
        fieldid={getSysFieldid(`${treeId}_tree-area`)}
        className="syncTreeCom"
        id={treeId}
      >
        {isFunction(userDefine) && userDefine()}
        {
          needSearch &&
          <div className="NC_syncTreeSearch">
            <input
              ref={(ref) => {this.searchInputRef = ref;this.searchRef.current=ref;}}
              className="u-form-control"
              fieldid="search"
              style={{ width: "100%", height: 30, "padding-right": "50px" }}
              placeholder={langJson["containers-tree-001"]}
              onClick={() => {
                if (this.ncc_automated === "1") {
                  if (searchValue == "") return;
                  store.searchChange(searchValue);
                  store.setStateEve();
                }
              }}
              onChange={(event) => {
                let value = event.target.value;
                if (value == "" || value.trim() == "") {
                  // 处理清空状态
                  this.handleClearState();
                }
                if (this.ncc_automated != "1") {
                  // 处理搜索空值
                  this.interval = new Date().getTime();
                  let s = setTimeout(() => {
                    if (new Date().getTime() - this.interval >= 500) {
                      store.searchChange(value.trim());
                    }
                    if (isFunction(getSearchVal)) {
                      getSearchVal(value.trim());
                    }
                    clearTimeout(s);
                  }, 500);
                }
                searchValue = value.trim();
                store.setStore('searchValue', searchValue);
                store.setStateEve();
              }}
              onFocus={this.focusEve.bind(this)}
              disabled={disabledSearch}
              onBlur={this.blurEve}
            />

            <i className="icon iconfont icon-sousuo syncTreeSearchIcon" />

            {
              searchValue !== "" &&
              <i
                style={disabledSearch ? { display: "none" } : null}
                className="icon iconfont qingkong syncTreeSearchIcon icon-qingkong"
                onClick={this.handleClearSearchVal}
              />
            }
          </div>
        }

        <div
          fieldid={getSysFieldid(`${treeId}_tree`)}
          className="synctree-area"
        >
          <Tree
            lazyLoad={lazyLoad}
            renderTreeNodes={lazyLoad ? this.getTreeNodes : null}
            // renderTreeNodes={this.getTreeNodes}
            treeId={treeId}
            treeData={treeData}
            focusable={focusable}
            mustExpandable={mustExpandable}
            onDoubleClick={this.onDoubleClickHandler}
            selectedKeys={selectedKeys}
            onSelect={this.onSelect}
            onExpand={this.onExpand} // 节点展开事件
            expandedKeys={expandedKeys} //搜索节点关键字后要展开的父节点
            autoExpandParent={autoExpandParent} //是否展开所有节点
            onMouseLeave={this.onMouseLeave} //鼠标离开节点事件
            onMouseEnter={this.onMouseEnter} // 鼠标进入节点事件
            onCheck={this.onCheck}
            checkable={checkable}
            checkStrictly={checkStrictly}
            checkedKeys={checkedKeys || []}
            draggable={draggable}
            debounceDuration={5}
            onDragOver={this.onDragOver.bind(this)}
            onDragStart={this.onDragStart.bind(this)}
            onDrop={this.onDrop}
            onDragEnd={this.onDragEnd}
            getScrollContainer={this.getScrollContainer}
            otherAreaHeight={otherAreaHeight}
            openIcon={
              isFunction(openIcon) ? (
                openIcon()
              ) : (
                <i className="icon iconfont icon-shu_zk tree-swich" />
              )
            }
            closeIcon={
              isFunction(closeIcon) ? (
                closeIcon()
              ) : (
                <i className="icon iconfont icon-shushouqi tree-swich" />
              )
            }
            //异步树API
            expandWhenDoubleClick={expandWhenDoubleClick}
            loadData={treeType === 'asyncTree' ? this.onLoadData : null}
            showLine={showLine}
            canCloseFreely={canCloseFreely}//解决层层收起失效问题
          >
            {lazyLoad ? null : this.getTreeNodes(treeData)}
          </Tree>
        </div>
        {
          needEdit && showModal && Object.keys(langJson).length > 0 &&
          <Modal
            fieldid={`${status}Node`}
            show={showEditModal}
            backdrop={"static"}
            size={status !== 'del' && modalSize}
            userControl={status !== 'del' && verifyModalForm}
          >
            <Modal.Header>
              <Modal.Title
                fieldid={editModalTitle}
              >
                {editModalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                status === 'del' ?
                  langJson["containers-tree-003"] :
                  <div className="addModal">
                    <Form
                      name={metaId}
                      config={{}}
                      store={formStore}
                    />
                  </div>
              }
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.beSureBtnClick}
                colors="primary"
                style={{ marginRight: 8 }}>
                {langJson['containers-tree-sure-btn']}
              </Button>
              <Button
                onClick={this.cancelBtnClick}
                bordered
              >
                {langJson['containers-tree-cancel-btn']}
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
    );
  }
}